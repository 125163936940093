import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { first } from 'lodash'
import build from 'redux-object'
import Step, { StepHead, StepProps } from '../common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import telePic from 'assets/images/patient-ordinateur.svg'
import SvgIcon from 'components/SvgIcon'
import { I18n } from 'react-redux-i18n'
import { IS_CHARLES_PLATFORM } from "constants/config"

import { getNumberOfPayments } from 'utils/models/variants'
import T from 'types'
import tracker from 'utils/trackers'
import { getIcon } from 'assets/icons'

interface IProps extends RouteComponentProps<any>, StepProps {
  patientAttributes?: any
  program: T.Program
  academy: T.AcademyStore
  storefront: T.Storefront
}

export default class StepAcademyConfirmation extends Component<IProps> {
  public componentDidMount() {
    const { academy, program } = this.props

    const order = first(build(academy, 'cart')) as T.OrderV2
    const variant = order.variants[0]
    const numberOfPayments = getNumberOfPayments(variant)
    const variantName = `${program.shortName.toUpperCase()} en ${numberOfPayments} paiements`
    tracker.trackDidPayAcademyProgram(order.number, order.total, program, variant)
  }

  public render() {
    const { program, steps, goPrevious } = this.props
    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <LayoutStep>
          <LayoutMain variant="small">
            <h3 title={I18n.t(`academy.${program.slug}.welcomeMessage`)} />
            <div className="block-content">
              <SvgIcon icon={getIcon("clinic.envelope")} classname="academy-thankyou-image" />
              <h4 className="mt-3">Merci pour votre commande !</h4>
              <p className="thankyou-text mt-3">
                Vous allez recevoir un email pour créer votre compte sur {IS_CHARLES_PLATFORM ? "Charles" : "Mia"}. Pensez à vérifier dans vos messages indésirables.
              </p>
            </div>
          </LayoutMain>
        </LayoutStep>
      </Step>
    )
  }
}

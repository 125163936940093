import React, { createContext, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import T from 'types'
import { findIndex, first, isEmpty, isUndefined } from 'lodash'
import './styles.sass'

const buildUrl = (url: any, path: string) => `${url}/${path}`

export const WorkflowContext = createContext<T.WorkflowNavigation>({
  navigation: {
    goNext: () => null,
    goPrevious: () => null,
    complete: () => null,
    goBack: () => null,
    stepIndex: 0,
    steps: new Array<T.WorkflowStep>(),
    stepperStyle: 'none',
  },
})

class Workflow extends React.Component<T.WorkflowProps> {

  /*
  Evaluate at last moment if we have enough data to show step.
  - if we have enough data, we can skip the step. We need to
    recall that when clicking previous
  - if we don't, the step is shown.
  */
  shouldShow = (stepIndex: number): boolean => {
    const { steps } = this.props
    const requestedStep: T.WorkflowStep = steps[stepIndex]
    const isLastStep = (stepIndex === steps.length - 1)
    const shouldShow = isLastStep || isUndefined(requestedStep.show) || requestedStep.show(this.props)
    return shouldShow
  }

  skipStep = (stepIndex: number): void => {
    const { steps } = this.props
    const requestedStep: T.WorkflowStep = steps[stepIndex]
    requestedStep.onSkip && requestedStep.onSkip(this.props)
  }

  findCurrentStep = (): number => {
    const { history, match, steps, level } = this.props
    const fullLocation = history.location.pathname
    const checkoutLocation = match.url
    const fullChildPath = fullLocation.replace(checkoutLocation, "").substring(1)
    const immediateChildPath = first(fullChildPath.split("/"))
    const currentStepIndex = findIndex(steps, step => step.path === immediateChildPath)
    return currentStepIndex
  }

  goNext = (): void => {
    const { steps } = this.props
    const currentStep = this.findCurrentStep()
    if (currentStep === steps.length - 1 && this.props.complete) {
      this.props.complete()
    } else {
      this.anyGoNext(currentStep)
    }
  }

  goPrevious = (): void => {
    const currentStep = this.findCurrentStep()
    if (currentStep === 0 && this.props.goBack) {
      this.props.goBack()
    } else {
      this.anyGoPrevious(currentStep)
    }
  }

  anyGoNext = (step: number): void => {
    const { history, match, steps } = this.props
    const url = match.url
    let foundStep = -1
    let nextStep = step + 1
    while (foundStep < 0) {
      if (this.shouldShow(nextStep)) {
        foundStep = nextStep
      } else {
        this.skipStep(nextStep)
      }
      nextStep += 1
    }
    const foundStepObject = steps[foundStep]
    // -- tracker.trackOnboardingStepView(nextStep)
    history.push(buildUrl(url, foundStepObject.path))
  }

  anyGoPrevious = (step: number): void => {
    const { history } = this.props
    history.goBack()
  }

  complete = (): void => {
    const { complete } = this.props
    complete && complete()
  }

  goBack = (): void => {
    const { goBack } = this.props
    goBack && goBack()
  }

  render() {
    const { match, steps, stepperStyle } = this.props
    const url = match.url
    const firstAllowedStep = buildUrl(url, steps[0].path)

    const currentIndex = this.findCurrentStep()
    const percent = isEmpty(steps) ? 0 : ((currentIndex + 1) / steps.length) * 100
    return (
      <>
        {stepperStyle === "progressionBar" && (
          <div className="sub-step-stepper" style={{ width: `${percent}%` }} />
        )}
        <Switch>
          {steps.map((step, index) => {
            return (
              <Route
                key={`route_${index}`}
                path={buildUrl(url, step.path)}
              >
                <WorkflowContext.Provider value={{
                  navigation: {
                    goNext: this.goNext,
                    goPrevious: this.goPrevious,
                    complete: this.complete,
                    goBack: this.goBack,
                    stepIndex: index,
                    steps: steps,
                    stepperStyle: stepperStyle || "none"
                  }
                }}>
                  {step.comp}
                </WorkflowContext.Provider>
              </Route>
            )
          })}
          <Redirect from="*" to={firstAllowedStep} />
        </Switch>
      </>
    )
  }
}

export default withRouter(Workflow)


export function withNavigation<U extends T.WorkflowNavigation = T.WorkflowNavigation>(
  WrappedComponent: React.ComponentType<U>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithNavigation = (props: Omit<U, keyof T.WorkflowNavigation>) => {

    const navigationProps: T.WorkflowNavigation = useContext(WorkflowContext)
    return <WrappedComponent {...(props as U)} {...navigationProps} />;
  };

  ComponentWithNavigation.displayName = `withNavigation(${displayName})`;

  return ComponentWithNavigation
}

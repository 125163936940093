import React from 'react'
import cx from 'classnames'
import './styles.sass'

const LayoutStep = (props: { children: React.ReactNode }) => (
  <div className="lucas-change-onboarding-container">
    {props.children}
  </div>
)

interface LayoutMainProps {
  children: React.ReactNode
  variant?: "small" | "medium" | "auto" | "custom-small"
}

const LayoutMain = (props: LayoutMainProps) => (
  <div className="lucas-change-onboarding-container-main">
    <div className={cx("lucas-change-onboarding-container-main-element", `lucas-change-onboarding-container-main-element-${props.variant || 'large'}`)}>
      {props.children}
    </div>
  </div >
)
const LayoutSide = (props: { children: React.ReactNode }) => (
  <div className="lucas-change-onboarding-container-side">
    {props.children}
  </div>
)

export {
  LayoutStep,
  LayoutMain,
  LayoutSide
}

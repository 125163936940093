import React, { Component } from 'react'
import { withRouter, Route, RouteComponentProps } from 'react-router'
import { Model } from 'survey-core'
import T from 'types'
import { I18n } from 'react-redux-i18n'
import Step, { StepProps } from './Step'
import { surveyCss, buildSurvey } from './survey'
import { findSurveyPage } from 'utils/survey'
import tracker from 'utils/trackers'
import ONBOARDING_STEPS from './steps'
import { Survey } from 'survey-react-ui';

interface IProps extends RouteComponentProps<any>, StepProps {
  survey: Model,
  questionName: string,
  isRecovery: boolean,
  prefix: string,
  steps: T.StepperStep[],
  setSurveyObject: (survey: Model) => void,
  setSurveyQuestionName: (question: string) => void,
  goNext: () => void,
  goPrevious: () => void,
  didStartPrePaymentSurvey: () => void
}

interface IState {
  isComplete: boolean,
}

class StepSurvey extends Component<IProps, IState> {
  unlistenHistory: any
  model: Model

  constructor(props: IProps) {
    super(props)

    this.state = {
      isComplete: false,
    }

    const { survey, prefix } = props

    this.model = buildSurvey(survey, prefix)
  }

  public componentDidMount() {
    const { history } = this.props

    this.unlistenHistory = history.listen((location: any, action: any) => {
      if (action === 'POP') {
        const pageName = location.pathname.split('/').pop()
        this.focusPage(pageName)
      }
    })
  }

  public focusPage(name: string) {
    const { setSurveyQuestionName } = this.props

    if (this.model) {
      const page = this.model.getPageByName(name)

      if (page) {
        const questionName = page.questions[0].name
        setSurveyQuestionName(questionName)
      }
    }
  }

  private onValueChanged(survey: Model, options: any) {
    const { setSurveyObject } = this.props

    setSurveyObject(survey.data)
  }

  private isSurveyValid(data: any) {
    return !(data.adult === 'no')
  }

  private onComplete(survey: Model) {
    const { goNext, setSurveyObject } = this.props

    setSurveyObject(survey.data)
    tracker.trackDidFillPrePaymentSurvey()
    if (this.isSurveyValid(survey.data)) {
      goNext()
    } else {
      // TODO: send message to Charles
      this.setState({ isComplete: true })
    }
  }

  private onAfterRenderPage(sender: Model, options: any) {
    const { history } = this.props
    history.push(sender.currentPage.name)
    this.focusPage(sender.currentPage.name)
    tracker.trackQuestionView(sender, ONBOARDING_STEPS.SURVEY, options)
  }

  private findPage(survey: Model): string | undefined {
    const { questionName } = this.props
    const page = findSurveyPage(survey, questionName)

    return page && page.name
  }

  private renderSurvey() {

    const { survey, didStartPrePaymentSurvey } = this.props

    return (
      <Survey
        data={survey}
        css={surveyCss}
        currentPage={this.findPage(this.model)}
        model={this.model}
        onValueChanged={(s: Model, o: any) => this.onValueChanged(s, o)}
        onAfterRenderSurvey={(survey: Model) => didStartPrePaymentSurvey()}
        onComplete={(survey: Model) => this.onComplete(survey)}
        onAfterRenderPage={
          (sender: Model, options: any) =>
            this.onAfterRenderPage(sender, options)
        }
      />
    )
  }

  public render() {
    const { steps, goPrevious } = this.props
    return (
      <Step steps={steps} goPrevious={goPrevious}>
        {this.renderSurvey()}
      </Step >
    )
  }
}

export default withRouter(StepSurvey)

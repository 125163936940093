import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { isUndefined, isNil, get } from 'lodash'
import { getIcon } from 'assets/icons'
import T from 'types'
import SvgIcon from '../SvgIcon'

import './styles.sass'

interface IProps {
  icon?: string
  title?: string
  subTitle?: string
  cta?: T.Cta
  children?: React.ReactNode
}

class BlockHeader extends Component<IProps> {

  public render() {
    const { title, subTitle, children } = this.props

    return (
      <div className="block-header d-flex justify-content-between align-items-center">
        <div className="block-header-title d-flex align-items-center">
          <h4>
            {this.renderIcon()}
            {title}
            {children}
            {subTitle && <small className="text-muted">{` ${subTitle}`}</small>}
          </h4>
        </div>
        <div className="block-header-cta">
          {this.renderCTA()}
        </div>
      </div>
    )
  }

  private renderCTA() {
    const { cta } = this.props

    if (isUndefined(cta)) {
      return null
    }

    const { path, onClick, title, renderer } = cta

    if (!isUndefined(path) && !isUndefined(onClick)) {
      throw `Error on prop cta: path (${path}) and onClick (${onClick}) cannot be both provided`
    }

    return isUndefined(path) ? (
      <a onClick={cta.onClick} className="container-cta" href="">
        {title}
      </a>
    ) : (
      <NavLink to={`/${path}`} className="container-cta" exact={true}>
        {title}
      </NavLink>
    )
  }

  private renderIcon() {
    const { icon } = this.props

    if (isUndefined(icon)) {
      return <Fragment />
    }
    
    return (
      <SvgIcon classname="icon mr-3" icon={getIcon(icon)} />
    )
  }
}

export default BlockHeader


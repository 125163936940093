import { connect } from 'react-redux'
import build from 'redux-object'
import { isEmpty, find, first } from 'lodash'
import {
  applyCoupon,
  getPrograms,
  getPaymentMethods,
  enrollProgram,
  selectProgramVariant,
  purchaseProgram,
  getOrderPaymentMethods,
  startOnboardingWithProgram,
  setPersonalData,
  setTermsOptin,
  createAddress,
  setExtraPersonalData
} from 'actions'
import AcademyFunnel from 'page/AcademyFunnel'
import { programSlugFromURL, funnelSteps } from 'helpers/academy'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

const mapStateToProps = ({
  academy,
  storefront,
  onboarding: { termsOfService, patientAttributes },
}: T.Store) => {
  const programs: T.Program[] = build(academy, 'program')
  const selectedSlug = academy.onboarding.slug
  const program: T.Program | undefined = selectedSlug
    ? find(programs, (program: T.Program) => program.slug == selectedSlug)
    : first(programs)
  const steps = funnelSteps(selectedSlug)
  return {
    academy,
    storefront,
    patientAttributes,
    termsOfService,
    program,
    steps,
    loading: !isEmpty(academy.actionLoading),
  }
}

const mapDispatchToProps = (dispatch: Function, ownProps: any) => ({
  getPrograms: () => dispatch(getPrograms()),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  enrollProgram: (slug: string) =>
    dispatch(enrollProgram(slug)).then(() => dispatch(getOrderPaymentMethods())),
  createAddress: (data: any, enrollmentId: string | undefined, onSuccess: Function) =>
    dispatch(createAddress(data, enrollmentId, onSuccess)),
  setExtraPersonalData: (data: any) => dispatch(setExtraPersonalData(data)),
  applyCoupon: (coupon: string, orderToken: string) => dispatch(applyCoupon(coupon, orderToken)),
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) =>
    dispatch(selectProgramVariant(variantId, orderToken, onSuccess)),
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) =>
    dispatch(purchaseProgram(token, data, onSuccess)),
  setTermsOptin: () => dispatch(setTermsOptin()),
  setPersonalData: (data: object) => dispatch(setPersonalData(data)),
  startOnboardingWithProgram: (slug: string) => dispatch(startOnboardingWithProgram(slug)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcademyFunnel)

import {
  flatMap,
  includes,
  map,
  replace,
  isNil,
  find,
  isUndefined
} from 'lodash'
import T from 'types'
import { variantDuration } from 'utils/helperComponents/phytoHelpers'

export const recommendedProduct = (activePrescriptions: T.Prescription[], variantSlug: string): boolean => {
  const recommendedItemsMolecules = map(flatMap(activePrescriptions, prescription => prescription.prescriptionItems), (item: T.PrescriptionItem) => item.molecule)
  return includes(recommendedItemsMolecules, replace(variantSlug, 'emeis_', ''))
}

export const isSubscribable = (variant: T.Variant): boolean => {
  const subscribable = find(
    variant.optionValues,
    optionValue => optionValue.optionType.name === 'subscribable',
  )
  return !isNil(subscribable)
}

export const productProperties = (activePrescriptions: T.Prescription[], variants: T.Variant[]): T.SegmentPhytotherapyProductsProperties[] => {
  const variantsDetails: any[] = []
  map(variants, (variant: T.Variant) => {
    variantsDetails.push({
      slug: variant.slug,
      subscription: isSubscribable(variant),
      duration_in_months: variantDuration(variant),
      prescription: recommendedProduct(activePrescriptions, variant.slug)
    })
  })

  return variantsDetails
}

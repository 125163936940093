import React, { FC, useEffect, useState } from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { Button, SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import '../styles.sass'
import { withNavigation } from 'components/Workflow'
import VariantOptionCard from 'page/Pages/UnifiedCheckout/UCFunnel/UCPhyto/VariantOptionCard'
import { productList, variantTotalPrice } from 'utils/helperComponents/phytoHelpers'
import { Dictionary, isEmpty, isNull } from 'lodash'
import {
  createCheckoutVariantChoice,
  findVariant,
} from 'utils/models/variants'

const UCPhyto: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { consultation, navigation: { goNext }, profile, storefront } = props
  const phytoProducts = profile && productList(consultation.prescriptions, storefront, false, profile)
  const [selectedVariants, setSelectedVariants] = useState<Dictionary<T.CheckoutVariantChoice>>({})
  const [totalPrice, setTotalPrice] = useState<String>()

  // create an array of default selected variants
  const defaultSelectedVariants = phytoProducts && phytoProducts.map((item: any) => {
    return findVariant(item.product.variantsIncludingMaster, {
      subscribable: 'subscribable',
      'pills-per-box': item.recommendedDuration,
    })
  })

  const selectProductVariant = (product: any, variant: T.Variant | null) => {
    const buffer = selectedVariants
    if (!isNull(variant)) {
      buffer[product.product.id] = createCheckoutVariantChoice(variant)
    } else {
      delete buffer[product.product.id]
    }
    setSelectedVariants(buffer)
    setTotalPrice(variantTotalPrice(buffer))
  }

  return (
    <UCStep>
      <div className="checkout-phyto-main">
        <h3 className="">{I18n.t('unified-checkout.phytotherapy.title')}</h3>
        <div className="delivery-disclaimer d-xl-none">
          <SvgIcon classname="delivery-info-icon" icon={getIcon('shapes.roundedInfo')} />
          <div>{I18n.t('unified-checkout.phytotherapy.deliveryDisclaimer')}</div>
        </div>
        <div className="phyto-card-wrapper">
          {phytoProducts.map(phyto => (
            <VariantOptionCard phyto={phyto} handleSelectedPhyto={(variant: T.Variant | null) => selectProductVariant(phyto, variant)} />
          ))}
        </div>
        <div className="d-flex flex-column">
          <Button classname="mb-3">
            {/* TODO make price dynamics */}
            Valider la séléction : {totalPrice} € (dont 3€ de frais de port)
          </Button>
          <Button variant="btn-outline-primary">
            Ne pas suivre ces recommandations
          </Button>
          {phytoProducts.map((item: any) => (
            <div>ff</div>
            // <div>{item} {item.prescriptionItems[0].selected.toString()}</div>
          ))}
        </div>
      </div>
      <button onClick={goNext}>Suivant</button>
    </UCStep>
  )
}

export default withNavigation(UCPhyto)

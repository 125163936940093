import React, { Component, Fragment } from 'react'
import T from 'types'
import SubStepMediumChoice from './SubStepMediumChoice'
import SubStepDoctorChoice from './SubStepDoctorChoice'
import SubStepSlotChoice from './SubStepSlotChoice'
import SubStepAutochosenDoctorConfirmation from './SubStepAutochosenDoctorConfirmation'
import SubStepConsultationSurvey from './SubStepConsultationSurvey'
import './styles.sass'

interface IProps {
  loading?: boolean,
  collection: any[],
  setChosenTimeSlot: (value: any) => void,
  setChosenDoctor: (doctor: T.Doctor) => void,
  setChosenMedium: (medium: string) => void,
  confirm: () => void,
  classname?: string,
  doctor?: T.Doctor | undefined,
  slot?: Date,
  availableChannels: string[]
  handleChangeDoctor: () => void,
  handleAcceptDoctor: () => void,
  handleSelectConsult: (consultation: string) => void,
  handleSelectMedium: (medium: string) => void,
  handleSelectTimeSlot: (date: Date) => void,
  handleBackButtonClick: () => void,
  currentStep: string,
  consultation: string,
  medium: string,
  lastConsultSpeciality: string
  getSurveyAndSubmission: (specialty: string, onSuccess: () => void) => void,
  focusedSurvey?: any
  setSurveySubmission?: (surveyData: string) => void,
  handleFinishSurvey?: () => void,
  lastFocusedLocation?: any
}

class DoctorSelection extends Component<IProps> {

  public render() {
    const { currentStep } = this.props
    return (
      <div className="doctor-selection-container">
        {currentStep === 'survey' &&
          <SubStepConsultationSurvey {...this.props} />}
        {currentStep === 'media' &&
          <SubStepMediumChoice {...this.props} />}
        {currentStep === 'slot-choice' &&
          <SubStepSlotChoice {...this.props} />}
        {currentStep === 'autoChosenDocConfirmation' &&
          <SubStepAutochosenDoctorConfirmation {...this.props} />}
        {currentStep === 'booking' &&
          <SubStepDoctorChoice {...this.props} />}
      </div>
    )
  }
}

export default DoctorSelection

import React from 'react'
import { Block, Button } from 'components'
import T from 'types'
import './styles.sass'

interface IProps {
    doctorFullName: string
    doctorAvatarUrl?: string,
    openSpecialty: T.ConsultationSpecialty,
    onClick: (spe: T.ConsultationSpecialty) => void
}

class BCOpenConsultationPicker extends React.Component<IProps> {

  render() {

    const { openSpecialty, doctorFullName, doctorAvatarUrl, onClick } = this.props

    return (
        <div className="open-consultation-picker d-flex flex-column align-items-center justify-content-center">
            <h4 className="mb-4">Consultation de 30 min avec votre médecin :</h4>
            <Block classname="mb-4">
                <div className="teleconsultation-container">
                    <div className="teleconsultation-doctor-image-container">
                        <img src={doctorAvatarUrl} alt={doctorFullName} className="teleconsultation-doctor-image-img" />
                    </div>
                    <div className="teleconsultation-doctor">
                        <h4 className="doctor-name">Dr {doctorFullName}</h4>
                        <div className="doctor-specialty">Médecin sexologue</div>
                    </div>
                    <Button
                        classname="btn-block btn-primary doctor-button"
                        onClick={() => onClick(openSpecialty)}
                    >
                        <strong>Prendre une consultation</strong>
                    </Button>
                </div>
            </Block>
        </div>
    )
  }
}

export default BCOpenConsultationPicker


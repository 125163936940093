import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import classnames from 'classnames'
import PharmacistProfile from '../PharmacistProfile'
import { isUndefined, map } from 'lodash'
import T from 'types'
import './styles.sass'

interface IProps {
  classname?: string
  collection: T.Pharmacist[]
}

export default class PharmaciesList extends Component<IProps> {

  public render() {
    const { collection, classname } = this.props
    const active = classname ? classname : ''

    return (
      <div className={classnames('pharmacist pharmacies-list-component', active)}>
        {map(collection, pharmacist => this.renderPharmacist(pharmacist))}
      </div>
    )
  }

  private renderPharmacist(pharmacist: T.Pharmacist) {
    if (isUndefined(pharmacist) || isUndefined(pharmacist.name)) {
      return <Fragment />
    }

    return (
      <PharmacistProfile
        pharmacist={pharmacist}
        key={pharmacist.id}
      />
    )
  }
}

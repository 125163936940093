import React, { Component, Fragment } from 'react'
import BCSurvey from './BCSurvey'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'

class BCSurveyStart extends Component<BookingConsultationStepProps> {

  public render() {
    return (
      <BCSurvey
        {...this.props}
        prefix={"pre_"}
        questionName="" // TODO CLARIFY QuestionName props
        onComplete={() => this.props.navigation.goNext()}
      />
    )
  }
}

export default withNavigation(BCSurveyStart)

import React, { FC, useState } from 'react'
import T from 'types'
import { Workflow } from 'components'
import { map, flatten } from 'lodash'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import UCProgram from 'page/Pages/UnifiedCheckout/UCFunnel/UCAcademy/UCProgram'
import { withNavigation } from 'components/Workflow'

const UCAcademy: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext }, consultation } = props
  const { programs } = consultation

  const programsSteps: T.WorkflowStep[] = map(programs, (program: T.Program) => ({
    path: program.slug,
    comp: <UCProgram {...props} program={program} />,
  }))

  return (
    <UCStep>
      <Workflow
        steps={programsSteps}
        complete={goNext}
        goBack={goPrevious}
        level={3}
      />
    </UCStep>
  )
}

export default withNavigation(UCAcademy)

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isNil } from 'lodash'
import moment from 'constants/moment'
import { ApiService } from 'services'
import * as SC from 'utils/models/schemas/constants'
import { validate } from 'validcq'
import store from '../../../store'

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const mangopayEmailRegexp = /([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

const phoneValidation = (value: any) => {
  const regexp = RegExp(phoneRegExp)
  return !isNil(value) && regexp.test(value) && isValidPhoneNumber(value)
}

const schemaWithSSN = yup.object({
  ssn: yup.string().trim().test({
    name: 'ssn-validation',
    test: function (value: any) {
      const result = validate(value)
      if(!validate(value)){
        return this.createError({
          path: 'ssn',
          message: I18n.t(`onboarding.personnalData.error.ssnError`),
        })
      }
      return true;
    },
  }),
})

const schemaWithoutEmail = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(1)
    .required(I18n.t(`onboarding.personnalData.error.firstNameError`)),
  lastName: yup
    .string()
    .min(1)
    .trim()
    .required(I18n.t(`onboarding.personnalData.error.lastNameError`)),
  phoneNumber: yup
    .string()
    .trim()
    .test('valid-phone', 'Numéro de téléphone invalide', (value: any) => phoneValidation(value))
    .required('Ce champ est obligatoire'),
  birthDate: yup.string().test({
    name: 'birthDate-validation',
    test: function (value: any) {
      const birthDate = moment(value, SC.DATE_INPUT_FORMAT)
      if (!birthDate.isValid()) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.mandatory`),
        })
      }
      const age = moment().diff(birthDate, 'years')
      const isLegal = age >= 18
      const isAVampire = age >= 200
      if (!isLegal) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.underage`),
        })
      }
      if (isAVampire) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.overage`),
        })
      }
      return true
    },
  }),
  cguAccepted: yup
    .boolean()
    .oneOf([true], I18n.t(`onboarding.personnalData.error.cguAcceptedError`)),
  patientId: yup.string(),
})

const createSchema = (successCallack: (patientDataWithId: any) => void) => {
  const currentState = store.getState()
  const onboarding = currentState.onboarding
  const preSurveySubmission = onboarding.survey
  const schema = schemaWithoutEmail.shape({
    email: yup
      .string()
      .trim()
      .email(I18n.t(`onboarding.personnalData.error.emailError`))
      .required('Ce champ est obligatoire')
      .test({
        name: 'valid-email',
        test: (value: any, context: any) => {
          return new Promise<boolean | yup.ValidationError>((resolve, reject) => {
            const regexp = RegExp(mangopayEmailRegexp)
            const validValue = !isNil(value) && regexp.test(value)
            if (validValue) {
              const { firstName, lastName, email, birthDate, phoneNumber, specialty } = context.parent
              const patientData = {
                firstName,
                lastName,
                phoneNumber,
                email: email.trim(),
                ssn: '',
                birthDate: moment(birthDate, SC.DATE_INPUT_FORMAT).format(SC.DATE_OUTPUT_FORMAT),
              }
              ApiService.createPatient(patientData, specialty, preSurveySubmission)
                .then((serverData: any) => {
                  successCallack(serverData)
                  return resolve(true)
                })
                .catch((error: any) => {
                  if (!phoneValidation(phoneNumber)) {
                    return resolve(
                      context.createError({
                        path: 'phoneNumber',
                        message:'Numéro de téléphone invalide',
                      }),
                    )
                  } else {
                  return resolve(
                    context.createError({
                      path: 'email',
                      message: SC.UNAVAILABLE_EMAIL_KEY,
                    }),
                  )
                  }
                })
            } else {
              return resolve(
                context.createError({
                  path: 'email',
                  message: I18n.t(`onboarding.personnalData.error.emailError`),
                }),
              )
            }
          })
        },
      }),
  })
  return schema
}
const schema = createSchema(()=> {})
export default schema
export { createSchema, schemaWithoutEmail, schemaWithSSN }
import React, { Component } from 'react'
import { Button, SvgIcon } from 'components'
import send from 'assets/icons/icon-send.svg'
import { specialtyI18NKey } from 'helpers/doctor-type'
import { I18n } from 'react-redux-i18n'
import './styles.sass'

interface IProps {
  onSubmit: (message: string) => void
  lastConsultationSpeciality?: string
}

interface IState {
  message: string
}

class ContactForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { message: '' }
  }
  private handleSubmit(e: any) {
    // prevent wrong redirection when submitting
    e.preventDefault()

    const { onSubmit } = this.props
    const { message } = this.state

    if (message.length > 0) {
      onSubmit(message)
    }
  }

  public render() {
    const { message } = this.state
    const { lastConsultationSpeciality } = this.props

    return (
      <div className="contact-form-container">
        <form onSubmit={e => this.handleSubmit(e)}>
          <label
            className="contact-form-label"
            htmlFor="input-contact-form"
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                `onboarding.contactForm.${specialtyI18NKey(lastConsultationSpeciality)}.formTitle`,
              ),
            }}
          />
          <textarea
            id="input-contact-form"
            className="form-control contact-form-textarea"
            placeholder="Bonjour,"
            onChange={e => this.setState({ message: e.target.value })}
          />
          <div className="mt-2">
            <Button
              classname="btn btn-primary d-block m-auto"
              onClick={e => this.handleSubmit(e)}
              disabled={message.length === 0}
            >
              <span>
                <SvgIcon icon={send} classname="icon-send" />
              </span>
              <span>
                {I18n.t(
                  `onboarding.contactForm.${specialtyI18NKey(
                    lastConsultationSpeciality,
                  )}.formSubmit`,
                )}
              </span>
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export default ContactForm

import React, { Fragment } from 'react'
import { useState } from 'react'
import { get, some, isUndefined } from 'lodash'
import cx from 'classnames'
import T from 'types'
import { getIcon } from 'assets/icons'

import miaHeadphone from 'assets/icons/payment/mia/headphone.png'
import miaCancellation from 'assets/icons/payment/mia/annulation.png'
import miaWorld from 'assets/icons/payment/mia/world.png'
import miaSexo from 'assets/icons/payment/mia/therapist.png'

import secureLogos from 'assets/icons/payment/logos-securises-commencer.png'
import SvgIcon from 'components/SvgIcon'
import { I18n } from 'react-redux-i18n'
import { TARGET_PLATFORM } from 'constants/config'

import './styles.sass'

type Props = {
  amount: number,
  family: string,
  priceArgument?: string
}
interface SectionArgumentsProps {
  sections: T.ISection[]
  variant?: string
  itemClass?: string
}

export const MiaConsultationArguments = (props: Props) => (
  <Fragment>
    <ul className="onboarding-charles-advantages">
      <li className="onboarding-charles-advantage">
        <img src={miaSexo} className="picto" />
        <div>{I18n.t(`faq.payment_step.${props.family}.bullet_1`)}</div>
      </li>
      {props.amount <= 60 && (
        <li className="onboarding-charles-advantage">
          <img src={miaWorld} className="picto" />
          <div>{I18n.t(`faq.payment_step.${props.family}.bullet_2`, { price: props.amount })}</div>
        </li>
      )}
      <li className="onboarding-charles-advantage">
        <img src={miaCancellation} className="picto" />
        <div>{I18n.t(`faq.payment_step.${props.family}.bullet_3`)}</div>
      </li>
      <li className="onboarding-charles-advantage">
        <img src={miaHeadphone} className="picto" />
        <div>{I18n.t(`faq.payment_step.${props.family}.bullet_4`)}</div>
      </li>
    </ul>
    <div className="text-center mx-2 mt-5">
      Sécurisé par
      <hr className="dark-blue-separator" />
      <img className="img-fluid" src={secureLogos} alt="Paiement sécurisé HADS" />
    </div>
  </Fragment>
)

const buildSections = (amount: number, family: string, priceArgument?: string): T.ISection[] => [
  {
    title: 'Soyez serein, vous êtes entre de bonnes mains',
    items: [
      { icon: 'clinic.lock', text: 'Paiement sécurisé' },
      { icon: 'clinic.refresh', text: 'Annulation gratuite jusqu\'à 24h avant' },
      { icon: 'clinic.pig', text: priceArgument || I18n.t(`faq.payment_step.${family}.bullet_2`, { price: amount }), hide: (amount > 60) },
      { icon: 'clinic.doctor', text: I18n.t(`faq.payment_step.${family}.bullet_1`) },
    ],
  }, {
    title: 'Charles respecte votre vie privée',
    items: [
      { icon: 'clinic.shield', text: 'Vos données sont sécurisées et soumises au secret médical' },
      { icon: 'clinic.hardware', text: 'Charles utilise un Hébergeur Agréé de Données de Santé' },
      { icon: 'clinic.box', text: 'Livraison discrète chez vous ou en relais colis' },
    ],
  },
]

const hasDetails = (sections: T.ISection[]): boolean =>
  some(sections, (section: T.ISection) => some(section.items, (item: T.ISectionItem) => !isUndefined(item.details)))

export const SectionArgumentsItem = ({ item, itemClass }: { item: T.ISectionItem, itemClass?: string }) => {

  const [expanded, setExpanded] = useState(false)
  const isExpandable = !isUndefined(item.details)
  if (item.hide) {
    return null
  }
  return (
    <li className="onboarding-charles-advantage">
      <div
        className={cx(
          'd-flex justify-content-start align-items-center',
          'onboarding-charles-advantage-main',
          { clickable: isExpandable, expanded },
        )}
        onClick={() => isExpandable && setExpanded(!expanded)}
      >
        {item.icon && <SvgIcon icon={getIcon(item.icon)} classname="onboarding-charles-advantage-main-picto" />}
        <div className={cx('onboarding-charles-advantage-main-item mr-2', itemClass)}>{item.text}</div>
        {isExpandable && <div className={cx('ml-auto expand-button')} />}
      </div>
      {item.details && expanded && (<div className="onboarding-charles-advantage-details">{item.details}</div>)}
    </li>
  )
}

export const SectionArguments = (props: SectionArgumentsProps) => (
  <>
    {props.sections.map((section, index) => (
      <ul
        className={cx('onboarding-charles-advantages', {
          'with-details': hasDetails(props.sections),
          'white-variant': props.variant === 'white',
        })}
        key={`section.${index}`}
      >
        {section.title && <h3>{section.title}</h3>}
        {section.items.map((item, itemIndex) =>
          <SectionArgumentsItem
            item={item}
            itemClass={props.itemClass}
            key={`section.${index}.item.${itemIndex}`}
          />)}
      </ul>
    ))}
  </>
)

export const CharlesConsultationArguments = (props: Props) => (
  <SectionArguments sections={buildSections(props.amount, props.family, props.priceArgument)} />
)

const ConsultationArguments = TARGET_PLATFORM == 'mia' ? MiaConsultationArguments : CharlesConsultationArguments

export default ConsultationArguments

import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Step, { StepProps, StepHead, StepFooter } from './Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { isNil, get, isUndefined } from 'lodash'
import { Block, Button, PaymentFormV2 } from 'components'
import ConsultationArguments from 'page/common/ConsultationArguments'
import T from 'types'
import SvgIcon from 'components/SvgIcon'
import tracker from 'utils/trackers'
import { I18n } from 'react-redux-i18n'
import './styles.sass'
import { useSatismeterScore } from '../../utils/helperApiService/sheetsHelper'
import StarRating from '../../components/StarRating'
import TreatmentNotice from 'assets/icons/treatment-notice.svg'
import Pencil from 'assets/icons/pencil.svg'
import Calendar from 'assets/icons/calendar-icon.svg'
import EditProfileForm from '../../components/EditProfileForm'
import moment from 'constants/moment'
import { friendlyDisplayDay } from 'helpers/date-helper'
import { differenceFound } from 'helpers/patientAttributesHelper'
import { CHAT } from 'constants/doctorSelectionStates'

const COMPLETE = "complete"

interface IProps extends RouteComponentProps<any>, StepProps {
  patientAttributes?: any
  updatePatientData: (data: any) => void
  storefront: T.Storefront
  socialSecurityMode: boolean
  goNext: () => void
  goPrevious: () => void
  getPaymentMethods: () => void
  consultation: T.Consultation | null
  order: T.OrderV1 | null
  consultationPaymentSuccess: boolean | undefined
  loading: boolean
  returnFromThreeDSecure?: boolean
  createMessage: (userId: number, body: string, patientUserId: number) => void
  sendConsultationPayment: (
    {
      data,
      orderNumber,
      onPaymentSuccess,
      onPaymentError,
    }: {
      data: T.PaymentPayload
      orderNumber: string
      onPaymentSuccess: () => void
      onPaymentError: () => void,
    },
  ) => void
}

const StepPayment = ({
  consultationPaymentSuccess,
  steps,
  goPrevious,
  returnFromThreeDSecure,
  consultation,
  sendConsultationPayment,
  loading,
  socialSecurityMode,
  patientAttributes,
  storefront,
  goNext,
  getPaymentMethods,
  updatePatientData,
  order,
}: IProps) => {
  const [isComplete, setIsComplete] = useState(false)
  const [editMode, setEditMode] = useState(false)
  // Maybe check if it's useful to use these 3 variables
  const paymentCompleted = returnFromThreeDSecure || isComplete || consultationPaymentSuccess || get(order, "state") == COMPLETE
  const { ratingValue, ratingCount, maxRating } = useSatismeterScore()
  const submitPayment = (data: T.PaymentPayload) => {
    const orderNumber = get(consultation, 'orderNumber')
    if (!isNil(consultation) && !isNil(orderNumber)) {
      const onPaymentSuccess = () => {
        setIsComplete(true)
        const amount = (consultation && consultation.consultationPrice) || 0
        const transactionValue = amount.toString()
        tracker.trackDidPayConsultation(
          consultation.orderNumber,
          transactionValue,
          consultation.consultationType as string,
          consultation.communicationChannel as string,
          consultation.specialtySlug as string,
        )
      }
      const onPaymentError = () => {}
      sendConsultationPayment({ data, orderNumber, onPaymentSuccess, onPaymentError })
    } else {
      // TODO : Lucas yield errors ?
    }
  }

  const submitPatientInfos = (data: any) => {
    const infosChanged: boolean = differenceFound(data, patientAttributes)
    updatePatientData(data)
    tracker.trackPostPaymentInfosUpdate(infosChanged)
    setEditMode(false)
  }

  const showEditionMode = () => {
    setEditMode(true)
    tracker.trackPostPaymentInfosEdit()
  }

  const renderPaymentStep = () => {
    // See https://github.com/gocharles/charles-front/issues/640
    const amount = (consultation && consultation.consultationPrice) || 0
    const returnFrom3DS =
      returnFromThreeDSecure || (consultation && consultation.orderState === 'complete')
    const title = socialSecurityMode ?
      'Règlement du reste à charge' :
      'Règlement de votre consultation'
    const priceArgument = socialSecurityMode ?
      '25€ remboursés sur la consultation à 35€' :
      undefined

    return (
      <>
        <LayoutMain variant="small">
          <StepHead title={title} />
          <PaymentFormV2
            submitPayment={submitPayment}
            patientAttributes={patientAttributes}
            recurringPayment={false}
            loading={loading}
            cgu={I18n.t(`paymentForm.fields.labels.cgu`)}
            getPaymentMethods={getPaymentMethods} // TODO V2 : Make a container instead ?
            storefront={storefront} // TODO V2 : Make a container instead ?
            price={amount}
          />
          <div className="rating-container">
            <div className="rating-in-words">
              <span>{ratingValue} sur {ratingCount} avis</span>
            </div>
            <StarRating rating={ratingValue} maxRating={maxRating} starSize={20} />
          </div>
          {!returnFrom3DS && (
            <StepFooter>
              <Button
                variant="btn-outline-primary"
                classname="onb-prev-btn"
                onClick={() => goPrevious()}
              >
                Précédent
              </Button>
            </StepFooter>
          )}
        </LayoutMain>
        <LayoutSide>
          {consultation && consultation.categorySlug && (
            <ConsultationArguments
              amount={amount}
              family={consultation.categorySlug}
              priceArgument={priceArgument}
            />
          )}
        </LayoutSide>
      </>
    )
  }

  const renderPatientInfosEditMode = () => {
    return (
      <div className="form-edition-container row">
        <EditProfileForm
          patientAttributes={patientAttributes}
          onSubmit={submitPatientInfos}
          orderNumber={get(consultation, "orderNumber") || ""}
          cta={I18n.t('onboarding.infosPostPayment.editInfos')}
        />
      </div>
    )
  }

  const renderPatientInfos = () => {
    return (
      <div className="informations-confirmation row">
        <div className='patient-infos col-md-8'>
          <p className='patient-name'>{`${get(patientAttributes, 'firstName')} ${get(patientAttributes, 'lastName')}`}</p>
          <p>{get(patientAttributes, 'email')}</p>
          <p>{get(patientAttributes, 'phoneNumber')}</p>
          <p>Né le {moment(get(patientAttributes, 'birthDate'), ['DD/MM/YYYY', 'YYYY-MM-DD']).format('DD/MM/YYYY')}</p>
          <div className='edit-infos'>
            <span onClick={() => showEditionMode()}><u>{I18n.t('onboarding.infosPostPayment.edit')}</u></span>
            <SvgIcon classname="ml-1 icon-16px" icon={Pencil} />
          </div>
        </div>
        <div className='consultation-infos col-md-4'>
          <SvgIcon classname="mb-2 icon-20px" icon={Calendar} />
          <p className='doctor-name'>{get(consultation, "doctor")}</p>
          <p>{get(consultation, "communicationChannel") != CHAT && `${friendlyDisplayDay(moment(get(consultation, "appointmentAt")))} ${moment(get(consultation, "appointmentAt")).format('LT')}`}</p>
        </div>
      </div>
    )
  }

  const renderinfos = () => {
    return (
      <LayoutMain variant="small">
        <div className="onboarding-container-bloc">
          <h3 className='pb-3'>
            <span>{I18n.t('onboarding.infosPostPayment.AccountConfirmation')}</span>
          </h3>
          <Block classname="without-header">
            {editMode ? renderPatientInfosEditMode() : renderPatientInfos()}
          </Block>
          <div className='post-payment-notice my-5'>
            <SvgIcon classname="mr-2 icon-32px" icon={TreatmentNotice} />
            <span>{I18n.t('onboarding.infosPostPayment.notice')}</span>
          </div>
          <Button classname="onb-next-btn mx-auto mb-3" disabled={editMode} onClick={() => goNext()}>
            {I18n.t('onboarding.infosPostPayment.confirmInformations')}
          </Button>
        </div>
      </LayoutMain>
    )
  }

  return (
    <Step steps={steps} goPrevious={goPrevious}>
      <LayoutStep>
        {paymentCompleted
          ? renderinfos()
          : renderPaymentStep()}
      </LayoutStep>
    </Step>
  )
}

export default StepPayment

import React, { FC, useState } from 'react'
import T from 'types'
import { Workflow } from 'components'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import UCPaymentRecap from 'page/Pages/UnifiedCheckout/UCFunnel/UCPayment/UCPaymentRecap'
import { withNavigation } from 'components/Workflow'
import { get, isNil } from 'lodash'

const UCPayment: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {

  const { profile, navigation } = props
  const { steps, stepIndex, goPrevious, goNext } = navigation
  const paymentSteps: T.WorkflowStep[] = [
  {
      path: 'recapitulatif',
      comp: <UCPaymentRecap {...props} />,
    },

  ]

  return (
    <UCStep>
      <Workflow steps={paymentSteps} complete={goNext} goBack={goPrevious} level={2} stepperStyle="progressionBar" />
    </UCStep>
  )
}

export default withNavigation(UCPayment)

import React, { Component, Fragment, RefObject } from 'react'
import cx from 'classnames'
import build from 'redux-object'
import { isUndefined, map, first, find } from 'lodash'
import lock from 'assets/icons/program-lock.svg'
import play from 'assets/icons/program-play.svg'
import SvgIcon from 'components/SvgIcon'
import T from 'types'
import moment from 'constants/moment'
import './styles.sass'


export interface Props {
  lesson: T.Lesson
  attendance: T.Attendance | undefined
  selected: boolean
  selectLesson: (lesson: T.Lesson) => void
}

class LessonItem extends Component<Props> {

  public render() {
    const { lesson, attendance, selected, selectLesson } = this.props
    const isUnlocked = lesson.previewMode || !!attendance
    const lessonTitle = lesson.number > 0
                      ? `${lesson.number}. ${lesson.title}`
                      : lesson.title

    const backgroundImage = isUnlocked ? `center / cover no-repeat url(${lesson.videoThumbnail})` : '#C4C4C4'

    return (
      <div className={cx('lessons-list-item py-3', { "selected": selected })} onClick={() => selectLesson(lesson)}>
        <div className="d-flex px-3 align-items-start" style={{gap: '1rem'}}>
          <div className="lessons-list-item-thumbnail d-flex flex-column-reverse" style={{background: backgroundImage}}>
            <span className="lessons-list-item-duration align-self-end pr-1">{moment.utc(lesson.videoDuration * 1000).format('mm:ss')}</span>
            { !isUnlocked &&
              <SvgIcon icon={lock} classname="lesson-list-icon lock-icon flex-grow-1 align-self-center" />
            }
          </div>
          <div className="lessons-list-item-title">{lessonTitle}</div>
        </div>
      </div>
    )
  }
}

export default LessonItem

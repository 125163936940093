import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { capitalize, filter, get, isEmpty, isUndefined, map, toLower, startCase } from 'lodash'
import { I18n } from 'react-redux-i18n'
import moment from 'constants/moment'
import T from 'types'
import { dateFormater } from 'utils/helpers'

export const renderTableHeaders = (label: string) => {
  switch (label) {
    case 'cta':
      return <th key={label} className="actions" />
    default:
      return <th key={label}>{capitalize(I18n.t(`columns.${label}`))}</th>
  }
}

export const renderListItem = (obj: any, cta: T.Cta) =>
  map(obj, (field, index) => {
    const [label, value] = field

    return label === 'cta' ? (
      <li key={index} className="mb-2">
        {formatSpecificField(obj, index, label, value, cta)}
      </li>
    ) : (
        <li key={index} className="mb-2">
          <label className="m-0">{capitalize(I18n.t(`columns.${label}`))} :&nbsp;</label>
          {formatSpecificField(obj, index, label, value, cta)}
        </li>
      )
  })

export const formatSpecificField = (
  obj: any,
  index: string,
  label: any,
  value: any,
  cta: T.Cta
) => {

  const expirationData = filter(obj, data => data[0] === 'expiredAt')
  const expirationDate = !isEmpty(expirationData) && expirationData[0][1]
  const isOrderable = expirationDate && moment().isAfter(expirationDate)
  const formatedValue =
    ['status', 'state', 'dosage'].indexOf(label) > -1
      ? capitalize(I18n.t(`${label}.${value}`))
      : capitalize(value).concat()
  const date = dateFormater(formatedValue, 'll')

  switch (label) {
    case 'cta':
      return renderCtaBlock(isOrderable, index, value, cta)
    case 'lineItemsNames':
      return <span dangerouslySetInnerHTML={{ __html: value }} />
    case 'expirationDate':
      return renderExpirationDate(isOrderable, label, index, date)
    case 'doctor':
      return <span key={`${label}_${index}`}>{startCase(toLower(value))}</span>
    case 'date':
    case 'createdAt':
    case 'completedAt':
    case 'nextOccurrenceAt':
      return <span key={`${label}_${index}`}>{date}</span>
    case 'appointmentAt':
      const appointment = dateFormater(formatedValue, 'lll')
      return <span key={`${label}_${index}`}>{appointment}</span>
    case 'program':
      return <span key={`${label}_${index}`}>{value}</span>
    default:
      return <span key={`${label}_${index}`}>{formatedValue}</span>
  }
}

const renderExpirationDate = (isOrderable: boolean, label: string, index: string, date: string) => {
  return isOrderable ? 'Expirée' : dateFormater(date, 'll')
}

const renderCtaBlock = (isOrderable: boolean, index: string, value: number, cta: T.Cta) => {
  if (cta.onOms || !isOrderable) {
    return (
      <span className="actions" key={index}>
        {renderCta(value, cta)}
      </span>
    )
  }

  return (
    <span className="actions" key={index}>
      {renderCta(value, cta)}
      <button className="button for-order">Me faire livrer</button>
    </span>
  )
}

const renderCta = (id: number, cta: T.Cta) => {
  const { onClick, title, path, onOms, renderer } = cta

  if (!isUndefined(renderer)){
    return renderer(id)
  }
  if (onOms) {
    return <a href={`/${path}/${id}`}>{I18n.t(`table.cta.${title}`)}</a>
  }

  return isUndefined(onClick) ? (
    <NavLink to={`/${path}/${id}`} className="container-cta" exact={true}>
      {I18n.t(`table.cta.${title}`)}
    </NavLink>
  ) : (
      <a onClick={e => onClick(e, { id })}>{I18n.t(`table.cta.${title}`)}</a>
    )
}

import React, { Component } from 'react'

export interface Props {
  title: string
  description: string
  duration?: number
}

class TrailerItem extends Component<Props> {
  public render() {
    const { title, description, duration } = this.props
    return (
      <div className="program-viewer-description">
        <h3 className="mb-3 pb-2">{title}</h3>
        <p>{description}
        </p>
        { (duration && duration > 1) ? <p className="d-none d-md-block">
            Chaque épisode se débloque au bout de {duration} jours (correspond à la durée de chaque étape).
          </p> : null
        }

      </div>
    )
  }
}

export default TrailerItem

import { connect } from 'react-redux'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import ProgramManager from 'page/Pages/Academy/Programs/ProgramManager'
import T from 'types'
import {
  getPrograms,
  getEnrollments,
  enrollProgram,
  purchaseProgram,
  selectProgramVariant,
  applyCoupon,
  getPaymentMethods,
  showNotification,
  startRenewEnrollment,
  setExtraPersonalData,
  createAddress,
  getSleepCalendar,
  updateSleepCalendar
} from 'actions'

const mapStateToProps = ({
  academy,
  auth,
  storefront,
}: T.Store) => ({
  academy,
  auth,
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  applyCoupon: (coupon: string, orderToken: string) => dispatch(applyCoupon(coupon, orderToken)),
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) =>
    dispatch(selectProgramVariant(variantId, orderToken, onSuccess)),
  getSleepCalendar: () => dispatch(getSleepCalendar()),
  getPaymentMethods: (orderToken?: string) => dispatch(getPaymentMethods(orderToken)),
  getPrograms: () => dispatch(getPrograms()),
  getEnrollments: () => dispatch(getEnrollments()),
  enrollProgram: (slug: string) => dispatch(enrollProgram(slug)),
  startRenewEnrollment: (enrollmentId: number) => dispatch(startRenewEnrollment(enrollmentId)),
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: () => void) =>
    dispatch(purchaseProgram(token, data, onSuccess)),
  showNotification: (title: string, message: string) => dispatch(showNotification(title, message)),
  setExtraPersonalData: (data: any) => dispatch(setExtraPersonalData(data)),
  createAddress: (data: any, enrollmentId: string | undefined, onSuccess: Function) =>
    dispatch(createAddress(data, enrollmentId, onSuccess)),
  updateSleepCalendar: (params: object) => dispatch(updateSleepCalendar(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramManager)

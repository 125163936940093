import { connect } from 'react-redux'
import {
  getPrescriptions,
  getPharmacy,
} from 'actions'
import PharmacyComponent from 'page/Pages/Pharmacy'
import T from 'types'

const mapStateToProps = ({
  pharmacy: { pharmacy },
  prescriptions: { focusedPrescription },
}: T.Store) => ({
  pharmacy,
  prescription: focusedPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getPharmacy: (id: string) => dispatch(getPharmacy(id)),
  getPrescriptions: () => dispatch(getPrescriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyComponent)

import React, { FC } from 'react'
import T from 'types'
import '../../styles.sass'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'

const UCDrugsPrescriptionsThankyou: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation } = props
  const { steps, stepIndex, goPrevious, goNext } = navigation

  return (
    <UCStep>
      <div>Votre ordonnance a bien été ajouté à votre panier</div>
      <div>
        <button onClick={goNext}>Passer à la suite de mon traitement</button>
        <button onClick={goPrevious}>Mes ordonnances</button>
      </div>
    </UCStep>
  )
}

export default withNavigation(UCDrugsPrescriptionsThankyou)

import React, { Component, Fragment } from 'react'
import { get } from 'lodash'
import moment from 'constants/moment'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { I18n } from 'react-redux-i18n'
import { Button } from 'components'
import * as SC from 'utils/models/schemas/constants'
import schema from 'utils/models/schemas/profile'
import MaskedInput from 'react-text-mask'
import { Formik } from 'formik'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './styles.sass'

interface IProps {
  patientAttributes: any
  orderNumber: string
  onSubmit: (data: any) => void
  cta: string
}

class EditProfileForm extends Component<IProps> {
  public handleGlobalSubmit = (data: any) => {
    const { onSubmit } = this.props
    const { firstName, lastName, birthDate, phoneNumber, email, orderNumber } = data
    const formattedBirthdate = moment(birthDate, SC.DATE_INITIAL_VALUE_FORMAT).format(SC.DATE_OUTPUT_FORMAT)
    const patientData = {
      email,
      firstName,
      lastName,
      phoneNumber,
      orderNumber,
      birthDate: formattedBirthdate
    }
    onSubmit(patientData)
  }

  public render() {
    const { patientAttributes, cta, orderNumber } = this.props

    return (
      <div className="form-edition">
        <Formik
          validationSchema={schema}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: get(patientAttributes, 'firstName', ''),
            lastName: get(patientAttributes, 'lastName', ''),
            phoneNumber: get(patientAttributes, 'phoneNumber', '') || '',
            birthDate: moment(get(patientAttributes, 'birthDate', '')).format(
              SC.DATE_INITIAL_VALUE_FORMAT,
            ),
            email: get(patientAttributes, 'email', ''),
            orderNumber: orderNumber,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>

              <div className="onboarding-container-bloc">
                <Fragment>
                  <div className="onboarding-small-inner-container">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>{I18n.t(`onboarding.personnalData.firstName`)}</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.firstName}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.firstName`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>{I18n.t(`onboarding.personnalData.lastName`)}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.lastName}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.lastName`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>{I18n.t(`onboarding.personnalData.email`)}</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.email}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.email`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email && (
                          <>
                            {errors.email === SC.UNAVAILABLE_EMAIL_KEY ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: I18n.t(
                                    `onboarding.personnalData.error.emailNotAvailableError`,
                                  ),
                                }}
                              />
                            ) : (
                              errors.email
                            )}
                          </>
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>{I18n.t(`onboarding.personnalData.birthDate`)}</Form.Label>
                      <Form.Control
                        autoComplete="bday"
                        as={MaskedInput}
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        placeholderChar=" "
                        type="tel"
                        name="birthDate"
                        value={values.birthDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.birthDate}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.birthDate`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.birthDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="validationFormik04">
                      <Form.Label>
                        {I18n.t(`onboarding.personnalData.phoneNumber`)}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="why-phone-is-required">{I18n.t(`onboarding.personnalData.tooltips.phoneNumber`)}</Tooltip>}
                        >
                          <span className="tooltip-info-icon ml-1">i</span>
                        </OverlayTrigger>
                      </Form.Label>
                      <PhoneInput
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.phoneNumber`)}
                        numberInputProps={{ className: 'form-control' }}
                        value={values.phoneNumber}
                        onChange={(value: string) => {
                          setFieldValue('phoneNumber', value)
                        }}
                        onBlur={handleBlur}
                        defaultCountry="FR"
                        countryOptionsOrder={[
                          'FR',
                          'GP',
                          'GF',
                          'MQ',
                          'YT',
                          'NC',
                          'PF',
                          'RE',
                          'BL',
                          'MF',
                          'PM',
                          '|',
                          '...',
                        ]}
                      />
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button classname="submit-button onb-next-btn" type="submit">
                      {cta}
                    </Button>
                  </div>
                </Fragment>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

export default EditProfileForm;

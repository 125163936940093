import React, { Component, Fragment } from 'react'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { get, camelCase, isUndefined } from 'lodash'
import CreditCardForm from './CreditCardForm'
import PaypalForm from './PaypalForm'
import './styles.sass'
import SvgIcon from 'components/SvgIcon'
import T from 'types'
import ccVisaSvg from 'assets/icons/payment/methods/cc-visa.svg'
import ccMastercardSvg from 'assets/icons/payment/methods/cc-mastercard.svg'
import paypalPaymentSVG from 'assets/icons/payment/methods/paypal2.svg'
import { INVALID_PAYMENT_METHOD } from 'constants/config'
import { money } from 'utils/models/money'

interface IProps {
  newConsultation?: boolean
  patientAttributes: T.Profile
  loading?: boolean
  price?: number
  cgu?: string
  recurringPayment: boolean
  storefront: T.Storefront
  submitPayment: (p: T.PaymentPayload) => void
  getPaymentMethods: () => void
  applyCoupon?: (coupon: string) => void
  activeCoupon?: string
}
interface IState {
  paymentMethod: string
}

class PaymentForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      paymentMethod: 'credit_card',
    }
  }

  public componentDidMount() {
    const { getPaymentMethods } = this.props

    getPaymentMethods()
  }

  // @returns { paymentMethod: paymentMethodId }
  private getPaymentMethodById(paymentMethodName: string): T.PaymentMethodHash {
    const { storefront } = this.props

    const paymentMethods = storefront.paymentMethod

    for (let paymentMethodId in paymentMethods)
      if (
        get(paymentMethods[paymentMethodId], 'attributes.name', '').toLowerCase() ==
        paymentMethodName.toLowerCase()
      )
        return { [camelCase(paymentMethodName)]: parseInt(paymentMethodId) }

    return INVALID_PAYMENT_METHOD
  }

  private isPaypalPaymentEnabled(): boolean {
    const { recurringPayment } = this.props
    if (recurringPayment || this.getPaymentMethodById('paypal') == INVALID_PAYMENT_METHOD)
      return false
    return true
  }
  private hasRegisteredCreditCards(profile: T.Profile): boolean {
    return ((profile.creditCards && profile.creditCards.length) || 0) > 0
  }

  public render() {
    const { patientAttributes, cgu, submitPayment, price, loading, applyCoupon, activeCoupon } = this.props
    const isPaypalPaymentEnabled = this.isPaypalPaymentEnabled()

    const suffix : string = isUndefined(price) ? '' : ` ${money(price.toString()).toFormat()}`
    const submitPaymentLabel = `Payer${suffix}`

    return (
      <div className="payment-container">
        <div className="payment-block block-content">
          <div className="credit-card-block-v2 w-100">
            <div className="credit-card-security">
              <div className="credit-card-payment-options">
                <label className={cx('credit-card-payment-option', { 'selected': this.state.paymentMethod === 'credit_card' })}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="credit_card"
                    checked={this.state.paymentMethod === 'credit_card'}
                    onChange={() => this.setState({ paymentMethod: 'credit_card' })}
                  />
                  <SvgIcon icon={ccVisaSvg} classname="mr-2"/>
                  <SvgIcon icon={ccMastercardSvg} classname="mr-2"/>
                  <span className="d-none d-sm-inline">Carte bancaire</span>
                  <span className="d-sm-none">CB</span>
                </label>
                {isPaypalPaymentEnabled && (
                  <label className={cx('credit-card-payment-option', { 'selected': this.state.paymentMethod === 'paypal' })}>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="paypal"
                      checked={this.state.paymentMethod === 'paypal'}
                      onChange={() => this.setState({ paymentMethod: 'paypal' })}
                    />
                    <SvgIcon icon={paypalPaymentSVG} classname="mr-2"/>
                    Paypal
                  </label>
                )}
              </div>
            </div>
            <div className={cx({ 'd-none': this.state.paymentMethod !== 'credit_card' })}>
              <CreditCardForm
                cgu={cgu}
                loading={loading}
                submitPayment={submitPayment}
                paymentMethod={this.getPaymentMethodById('mangoPay')}
                profile={patientAttributes}
                paymentButtonLabel={submitPaymentLabel}
                applyCoupon={applyCoupon}
                activeCoupon={activeCoupon}
              />
            </div>
            {isPaypalPaymentEnabled && (
              <div className={cx({ 'd-none': this.state.paymentMethod !== 'paypal' })}>
                <PaypalForm
                  cgu={cgu}
                  loading={loading}
                  submitPayment={submitPayment}
                  paymentMethod={this.getPaymentMethodById('paypal')}
                  profile={patientAttributes}
                  paymentButtonLabel={submitPaymentLabel}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default PaymentForm

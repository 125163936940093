import T from '../../types'
import {
  PRESCRIPTION_TYPE_ERECTION_DRUGS, PRESCRIPTION_TYPE_HAIRLOSS_DRUGS,
  PRESCRIPTION_TYPE_PHYTOTHERAPY, PRESCRIPTION_TYPE_WEIGHT_DRUGS,
} from '../../constants/prescriptions'
import { History } from 'history'
import { find } from 'lodash'

export const findPrescriptionFromV2 = (
  collectionPrescription: T.Prescription[],
  pres: T.PrescriptionV2
) => {
  return find(collectionPrescription, (p: T.Prescription) => p.id === pres.id)
}

export const handleOrderPrescription = (
  prescription: T.Prescription,
  setFocusedPrescription: (p: T.Prescription) => void,
  history: History<any>
) => {
  switch (prescription.prescriptionType) {
    case PRESCRIPTION_TYPE_PHYTOTHERAPY:
      history.push(`/phytotherapy/${prescription.id}`)
      break
    case PRESCRIPTION_TYPE_ERECTION_DRUGS:
    case PRESCRIPTION_TYPE_HAIRLOSS_DRUGS:
    case PRESCRIPTION_TYPE_WEIGHT_DRUGS:
      setFocusedPrescription(prescription)
      history.push(`/ordonnance/${prescription.id}`)
      break
  }
}

export const handleDownloadPrescription = (
  prescription: T.Prescription,
  setFocusedPrescription: (p: T.Prescription) => void,
  history: History<any>
) => {
  setFocusedPrescription(prescription)
  history.push(`/download-prescription/${prescription.id}`)
}

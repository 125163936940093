import React, { FC, useState } from 'react'
import { capitalize, isUndefined, map } from 'lodash'
import T from 'types'
import '../styles.sass'
import { I18n } from 'react-redux-i18n'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'
import { Block, Button, SvgIcon } from 'components'
import './styles.sass'
import { getIcon } from 'assets/icons'
import { SectionArguments } from 'page/common/ConsultationArguments'

const UCDrugsPrescriptions: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation, consultation, setDrugsFunnelChoice } = props
  const { steps, stepIndex, goPrevious, goNext, complete } = navigation
  const [cguChecked, setCguChecked] = useState(false)

  const sections: T.ISection[] = [
    {
      title: 'Les avantages du service Charles',
      items: [
        { icon: 'clinic.box', text: 'Livraison partout en France en moins de 48h' },
        { icon: 'clinic.lock', text: 'Ordonnance sécurisée et rappels automatiques' },
        { icon: 'clinic.phone', text: 'Service patient 7j/7' },
      ],
    },
  ]

  const setFunnelChoice = (forPrescriptions: boolean) => {
    if (!isUndefined(setDrugsFunnelChoice)) {
      if (cguChecked) {
        console.log('cguChecked', cguChecked)
        setDrugsFunnelChoice(forPrescriptions)
        goNext()
      }
    }
  }

  // select store
  // send pharmacy id + consultation.id and one prescription.id (currently work with prescritpion.id)

  return (
    <UCStep>
      <div className="checkout-drugs-prescription-main">
        <div className="">
          <h3>Ordonnance de médicaments</h3>
          <Block classname="uc-prescription-card">
            <h4>Mes Ordonnances</h4>
            <div className="prescription-card-heading-section">
              <img src={consultation.doctor.avatarUrl} className="prescription-card-img" alt="Ordonnance de médicaments" />
              <div className="prescription-card-heading">
                <div className="prescription-card-heading-title">{consultation.doctor.formattedName}</div>
                <div>{consultation.doctor.speciality}</div>
              </div>
            </div>
            <div className="prescription-card-drugs-title">
              <SvgIcon icon={getIcon('clinic.pill')} classname="prescription-card-drugs-icon" />
              Médicaments
            </div>
            { map(consultation.drugsPrescriptions, (prescription, idx) =>
              <div className="prescription-card-prescription-list">
                <div className="prescription-list-title">{idx + 1}ère Ordonnance :</div>
                <ul className="item-list">
                  {map(prescription.prescriptionItems, item =>
                    <li>
                      <span>{capitalize(I18n.t(`molecule.${item.molecule}`))}, {I18n.t(`grammage.${item.grammage}`)}</span> :<br /> {I18n.t(`dosage.${item.dosage}`)}
                    </li>)}
                </ul>
              </div>)}
            <div className="d-flex align-items-start prescription-card-cgu">
              <input className="mr-3" type="checkbox" id="use-condition" onChange={(e) => { setCguChecked(e.target.checked) }} />
              <div dangerouslySetInnerHTML={{ __html: I18n.t('pharmacy.acceptCguText') }} />
            </div>
            <Button variant="btn-primary" classname="w-100" onClick={() => setFunnelChoice(false)}>Me faire livrer</Button>
          </Block>
          <Block classname="dom-tom-delivery-block">
            <div className="dom-tom-delivery-title">
              Vous ne souhaitez pas être livré ou vous êtes à l'étranger et les DOM-TOM ?
            </div>
            <Button variant="btn-primary" classname="mb-3" onClick={() => setFunnelChoice(true)}>Récupérer mon ordonnance</Button>
            <Button variant="btn-outline-primary" classname="" onClick={() => complete()}>Récupérer plus tard</Button>
          </Block>
        </div>
        <div className="section-arguments-wrapper">
          <SectionArguments sections={sections} />
        </div>
      </div>
    </UCStep>
  )
}

export default withNavigation(UCDrugsPrescriptions)

import React, { Fragment } from 'react'
import Intercom from 'react-intercom'
import { isEmpty, isUndefined } from 'lodash'
import T from 'types'
import { intercomSettings } from 'utils/intercom'

const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID

type Props = {
  active: boolean
  profile: T.Profile
  patientWorkflow: "onboarding" | "personalSpace" | "academy"
  showLauncher: boolean
  alignment?: "left" | "right"
}

class IntercomWrapper extends React.Component<Props> {
  public render() {
    const { active, showLauncher, profile, patientWorkflow, alignment } = this.props
    const actualAlignment = alignment || 'right'

    if (!active || isUndefined(INTERCOM_ID)) {
      return <Fragment />
    }
    const fullName = `${profile.firstName} ${profile.lastName}`
    const profileSettings = isEmpty(profile) ? {} : {
      user_id: `${profile.id}`,
      email: profile.email,
      name: fullName,
    }

    const intercom = {
      open: false,
      hide_default_launcher: !showLauncher,
      alignment: actualAlignment,
      appID: INTERCOM_ID,
      patientWorkflow: patientWorkflow,
      ...profileSettings,
      ...intercomSettings(),
      onOpen: () => {},
      onClose: () => {},
      onUnreadCountChange: (unreadCount: any) => {},
      onInitialization: (intercom: any) => {},
    }

    return <Intercom {...intercom} />
  }
}

export default IntercomWrapper

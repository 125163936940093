import React, { Component, Fragment, RefObject } from 'react'
import { I18n } from 'react-redux-i18n'
import classnames from 'classnames'
import 'react-phone-number-input/style.css'

import countries from 'constants/iso-countries'
import schema from 'utils/models/schemas/address'
import { sortBy, map, get } from 'lodash'
import T from 'types'
import { Formik } from 'formik'
import { Col, Form } from 'react-bootstrap'
import { Button } from 'components'
import './styles.sass'
interface Country {
  iso: string
  name: string
}
interface IProps {
  profile: T.Profile
  loading?: boolean
  classname?: string
  onSubmit: (data: T.Forms.ShippingAddress) => void
  children?: JSX.Element | JSX.Element[] | string
}

class ShippingAddress extends Component<IProps> {
  countriesData: countries.LocalizedCountryNames

  constructor(props: IProps) {
    super(props)

    this.countriesData = countries.getNames('fr')
  }

  private handleSubmit = (data: T.Forms.ShippingAddress) => {
    const { onSubmit } = this.props

    onSubmit(data)
  }

  public render() {
    const { profile } = this.props

    return (
      <Formik
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          firstname: get(profile, 'address.firstname') || get(profile, 'firstName'),
          lastname: get(profile, 'address.lastname') || get(profile, 'lastName'),
          address1: get(profile, 'address.address1') || '',
          address2: get(profile, 'address.address2') || '',
          city: get(profile, 'address.city') || '',
          zipcode: get(profile, 'address.zipcode') || '',
          countryIso: get(profile, 'address.countryIso') || 'FR',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="text-left">
            <Fragment>
              <Form.Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.firstname`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.firstname}
                      placeholder={I18n.t(`personalInfo.address.placeholders.firstname`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik02">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.lastname`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.lastname}
                      placeholder={I18n.t(`personalInfo.address.placeholders.lastname`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik03">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.address1`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.address1}
                      placeholder={I18n.t(`personalInfo.address.placeholders.address1`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik04">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.address2`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.address2}
                      placeholder={I18n.t(`personalInfo.address.placeholders.address2`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik05">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.zipcode`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.zipcode}
                      placeholder={I18n.t(`personalInfo.address.placeholders.zipcode`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="validationFormik06">
                    <Form.Label>{I18n.t(`personalInfo.address.labels.city`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.city}
                      placeholder={I18n.t(`personalInfo.address.placeholders.city`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Group controlId="validationFormik07">
                <Form.Label>{I18n.t(`personalInfo.address.labels.countryIso`)}</Form.Label>
                <Form.Control
                  as="select"
                  name="countryIso"
                  value={values.countryIso}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.countryIso}
                  placeholder={I18n.t(`personalInfo.address.placeholders.countryIso`)}
                >
                  {map(this.sortedCountries(), (country: Country) =>
                    <option
                      key={`country_option_${country.iso}`}
                      label={country.name}
                      value={country.iso}
                    />
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.countryIso}</Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                // loading={loading} // TODO V2 Loading
              >
                Mettre à jour
              </Button>
            </Fragment>
          </Form>
        )}
      </Formik>
    )
  }

  private sortedCountries = (): Country[] => {
    const countryDictionary = this.countriesData
    const countryIsos: string[] = Object.keys(countryDictionary)
    const countryObjects: Country[] = map(countryIsos, (key: string) => ({
      iso: key,
      name: countryDictionary[key],
    }))
    const sortedCountries = sortBy(countryObjects, (country: Country) => country.name)
    return sortedCountries
  }
}

export default ShippingAddress

import { connect } from 'react-redux'
import DoctorProfile, { DoctorProfileProps } from 'components/DoctorProfile'
import T from 'types'
import { specialtyToFamily } from 'utils/trackers/utils'

const familySpecificDescription: { [key: string]: string } = {
  sleep: [
    "Docteur en médecine",
    "Formé à la prise en charge du mauvais sommeil et de l'insomnie",
    "Formé à la téléconsultation",
  ].join("\n"),
  hair: [
    "Docteur en médecine",
    "Formé à la prise en charge de la chute de cheveux, alopécie, perte de densité",
    "Formé à la téléconsultation",
  ].join("\n"),
  digestion: [
    "Docteur en médecine",
    "Formé à la prise en charge des problèmes digestifs, au microbiote et à la nutrition",
    "Formé à la téléconsultation",
  ].join("\n"),
}

const mapStateToProps = ({
  onboarding: { consultationSpecialty },
}: T.Store, ownProps: DoctorProfileProps) => {
  const family = specialtyToFamily(consultationSpecialty!)
  const familyDescription = familySpecificDescription[family]
  const contextualDescription: string | undefined = familyDescription || ownProps.doctor.description
  return {
    ...ownProps,
    doctor: {
      ...ownProps.doctor,
      description: contextualDescription
    }
  }
}

const mapDispatchToProps = (dispatch: Function) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoctorProfile)

import { useEffect, useRef } from "react";
import useVisioConfiguration from "helpers/hooks/useVisioConfiguration"
import tracker from 'utils/trackers';
import { MediaConfig, MediaSetupTrackingContext } from "helpers/hooks/useVisioConfiguration";

const useTrackedVisioConfiguration = (props: MediaSetupTrackingContext): MediaConfig => {
  const config = useVisioConfiguration()
  const { initializing } = config
  const previousConfigRef = useRef<MediaConfig>(config)

  useEffect(() => {
    const prevConfig = previousConfigRef.current
    if(!initializing){
      if(
        prevConfig.wizardState !== config.wizardState ||
        prevConfig.permissionForCamera !== config.permissionForCamera ||
        prevConfig.permissionForMic !== config.permissionForMic ||
        config.promptRefusedToOpen
      ){
        tracker.trackMediaSetupEvent(config, props)
      }
    }
    previousConfigRef.current = config
  }, [config])

  return config
}

export default useTrackedVisioConfiguration
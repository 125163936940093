import React, { Component, Fragment } from 'react'
import Media from 'react-media'
import { I18n } from 'react-redux-i18n'
import { map } from 'lodash'
import moment from 'constants/moment'
import { Table, Button } from '../..'
import { practitionerTitle } from 'helpers/doctor-type'
import {
  renderLegendLastPrescription,
} from 'utils/helpers'
import T from 'types'

interface IProps {
  prescription: T.Prescription,
}

class PrescriptionConsultationInfo extends Component<IProps> {

  private renderLastPrescription() {
    const { prescription } = this.props
    const { consultationSpecSlug } = prescription

    return (
      <div className="prescription-table">
        <table className="custom-table table table-borderless">
          <tbody>
            {renderLegendLastPrescription(practitionerTitle(consultationSpecSlug))}
            <tr className="table-content">
              <td>{I18n.t(`prescriptions.type.${prescription.prescriptionType}`)}</td>
              <td>{prescription.doctor}</td>
              <td>{moment(prescription.createdAt).format('L')}</td>
              <td>
                Expire le {moment(prescription.expiredAt).format('L')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  private renderListLastPrescription() {
    const { prescription } = this.props
    const { consultationSpecSlug } = prescription

    return (
      <div className="prescription-table">
        <ul className="table-container">
          <li>
            <label>Votre ordonnance :&nbsp;</label>
            <span>{I18n.t(`prescriptions.type.${prescription.prescriptionType}`)}</span>
          </li>
          <li>
            <label>{practitionerTitle(consultationSpecSlug)} :&nbsp;</label>
            <span>{prescription.doctor}</span>
          </li>
          <li>
            <label>En date du :&nbsp;</label>
            <span>{moment(prescription.createdAt).format('L')}</span>
          </li>
          <li>
            <label>Expire le :&nbsp;</label>
            <span>
              {moment(prescription.expiredAt).format('L')}
            </span>
          </li>
        </ul>
      </div>
    )
  }

  public render() {
    return (
      <Fragment>
        <Media
          key="table"
          query="(min-width: 768px)"
          render={() => this.renderLastPrescription()}
        />
        <Media
          key="list"
          query="(max-width: 767px)"
          render={() => this.renderListLastPrescription()}
        />
      </Fragment>
    )
  }

}

export default PrescriptionConsultationInfo

import React, { Fragment } from 'react'
import { camelizeKeys } from 'humps'
import { MainContainer } from 'containers'
import {
  ConsultationList,
  PrescriptionList,
} from 'components'
import '../styles.sass'
import { cableFactory } from 'helpers/actioncable-helper'
import T from 'types'

let room: any

interface IProps {
  profile: T.Profile
  collection: any[]
  collectionPrescription: T.Prescription[]
  getConsultations: () => void
  getPrescriptions: () => void
  getPrescriptionPdf: (url: string) => void
  updateConsultation: (c: T.Consultation) => void
  uploadMedicalDocument: (data: FormData) => void
  setFocusedPrescription: (p: T.Prescription) => void
}

interface IState {
  showPrediag: boolean
}

class MedicalInfo extends React.Component<IProps, IState> {
  cable: any

  constructor(props: IProps) {
    super(props)

    this.state = {
      showPrediag: false,
    }

    this.cable = cableFactory()
  }

  public componentDidMount() {
    const { getConsultations, getPrescriptions } = this.props

    getConsultations()
    getPrescriptions()
    this.initSocket()
  }

  componentWillUnmount() {
    if (room) {
      this.cable.subscriptions.remove(room)
    }
  }

  initSocket() {
    const { updateConsultation } = this.props

    room = this.cable.subscriptions.create(
      { channel: 'ConsultationsChannel' },
      {
        connected: () => null,
        create: () => null,
        received: (consultation: T.Consultation) =>
          updateConsultation(camelizeKeys(consultation) as T.Consultation),
      },
    )
  }

  public render() {

    return (
      <MainContainer>
        <div className="page medical-info">
          <div className="medical-info-container">
            <div className="infos-container">{this.renderResponsiveContent()}</div>
          </div>
        </div>
      </MainContainer>
    )
  }

  public renderResponsiveContent() {

    const { collectionPrescription, setFocusedPrescription, collection, profile } = this.props
    return (
      <Fragment>
        <PrescriptionList
          prescriptions={collectionPrescription}
          setFocusedPrescription={setFocusedPrescription}
        />
        <ConsultationList
          consultations={collection}
          prescriptions={collectionPrescription}
        />
      </Fragment>
    )
  }
}

export default MedicalInfo

import React, { FC, useEffect } from 'react'
import { isUndefined, map } from 'lodash'
import T from 'types'
import '../../styles.sass'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'
import { Block } from 'components'

const UCDrugsPharmacy: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  useEffect(() => {
    const { getPharmacies } = props
    if(!isUndefined(getPharmacies)) {
      getPharmacies()
    }
  }, [])

  const {
    navigation,
    collectionPharmacies,
    storeUnifiedCheckoutPharmacist,
    setCurrentStore,
    order
  } = props

  const { steps, stepIndex, goPrevious, goNext } = navigation

  function selectPharmacist(pharmacistId: number) {
    if(!isUndefined(storeUnifiedCheckoutPharmacist)) {
      storeUnifiedCheckoutPharmacist(pharmacistId)
      if(!isUndefined(setCurrentStore)) {
        setCurrentStore(pharmacistId, order.number)
      }
      goNext()
    }
  }

  return (
    <UCStep>
      <div>
        <h2>Choisissez la pharmacie qui traitera votre ordonnance</h2>
        <div className="d-flex">
          <div className="pharmacy-wrapper">
            {map(collectionPharmacies, (pharmacist: T.Pharmacist) => (
              <Block>
                <span>{pharmacist.name}</span>
                <div>{pharmacist.address.address1}</div>
                <div>{pharmacist.address.address2}</div>
                <span><button onClick={() => selectPharmacist(pharmacist.id)}>Choisir cette pharmacie</button></span>
              </Block>
            ))}
          </div>
        </div>
      </div>

      <br/>
      <button onClick={goPrevious}>previous</button>
    </UCStep>
  )
}

export default withNavigation(UCDrugsPharmacy)

import { includes } from 'lodash'
import { ACADEMY_PROGRAMS, FUNNEL_ACADEMY, FUNNEL_VAGINISME, VAGINISME, SOMMEIL } from 'constants/academy'
import T from 'types'

export const programSlugFromURL = (): string | undefined => {
  const search = window.location.search
  const slug = search.split('?')[1].split('=')[1]
  const slugToReturn = includes(ACADEMY_PROGRAMS, slug) ? slug : undefined
  return slugToReturn
}

export const funnelSteps = (slug: string | undefined) : T.StepperStep[] => {
  switch (slug) {
    case VAGINISME:
      return FUNNEL_VAGINISME;
    default:
      return FUNNEL_ACADEMY;
  }
}

import React from 'react'
import { I18n } from 'react-redux-i18n'
import telePic from 'assets/images/tele.png'

const UnavailabilityNotice = () => (
  <div className="d-flex flex-column align-items-center">
    <p className="info-empty-page">{I18n.t('onboarding.noticeInfo.emptyList')}</p>
    <img src={telePic} alt="tele" className="thankyou-image" style={{ maxWidth: '100%' }}/>
  </div>
)

export default UnavailabilityNotice

import React from 'react'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import yellowUnderline from 'assets/icons/icon-soulignement.svg'
import T from 'types'
import { WizardStepProps } from 'helpers/hooks/useVisioConfiguration'
import { detect } from 'detect-browser'

const HelpToAcceptPermissions = (props: WizardStepProps) => {
  const browser = detect()
  const showArrowDirectionToPopup = !(browser && browser.name == 'safari')
  return (
    <div>
      <div className="d-none d-md-block">
        <p>Une fenêtre vient d’apparaître en haut à gauche de votre écran.</p>
        {showArrowDirectionToPopup && <SvgIcon icon={getIcon('setup.arrowUpLeft')} classname="central-icon"/>}
        <h2>
          Cliquez sur <span className="grant-wording">Autoriser<SvgIcon icon={yellowUnderline} classname="grant-underline" /></span>
        </h2>
      </div>
      <div className="d-md-none">
        <p>Une fenêtre vient d’apparaître sur votre écran.</p>
        <h2>
          Cliquez sur <span className="grant-wording">Autoriser<SvgIcon icon={yellowUnderline} classname="grant-underline" /></span>
        </h2>
      </div>
    </div>
  )
}

export default HelpToAcceptPermissions
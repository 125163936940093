import { isUndefined, isEmpty, get, join, values, first, isNil } from 'lodash'
import { Model } from 'survey-core'
import * as A from 'constants/actions'
import { showNotification, startSecureChallenge } from './ux'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

import store from '../store'
import build from 'redux-object'

import { ApiService, StorefrontApiService } from 'services'
import {IS_CHARLES_PLATFORM} from "../constants/config";

export const sendSupportMessage = (value: string) => ({
  meta: {
    onFailure: () => {
      store.dispatch(showNotification('error', "Erreur lors de la demande d'assistance"))
    },
    onSuccess: () => {
      store.dispatch(showNotification('success', "Votre demande d'assistance a bien été envoyé."))
    },
  },
  promise: ApiService.supportMessageSubmission(value),
  type: A.SET_SUPPORT_MESSAGE,
})
export const getPrograms = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération des programmes Academy'),
      )
    },
  },
  promise: ApiService.getPrograms(),
  type: A.GET_PROGRAMS,
})

export const getEnrollments = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération des programmes en cours'),
      )
    },
  },
  promise: ApiService.getEnrollments(),
  type: A.GET_ENROLLMENTS,
})

export const enrollProgram = (slug: string) => {
  const currentState = store.getState()
  const onboarding = currentState.onboarding
  const patientAttributes = onboarding.patientAttributes
  return {
    meta: {
      onFailure: () => {
        // store.dispatch(showNotification('error', 'Erreur lors de la récupération des programmes Academy'))
      },
    },
    promise: ApiService.startPurchaseProgram(slug, patientAttributes),
    type: A.ENROLL_PROGRAM,
  }
}

export const createAddress = (data: any, enrollmentId: string | undefined, successCallback: Function) => ({
  type: A.CREATE_ADDRESS,
  promise: ApiService.createAddress(data, enrollmentId),
  meta: {
    onSuccess: (response: any) => successCallback(response),
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'error')

      if (isEmpty([messages])) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', [messages].join(' ')))
      }
    },
  },
})

export const createSleepCalendarItem = (data: any, successCallback: Function) => ({
  type: A.CREATE_SLEEP_CALENDAR_ITEM,
  promise: ApiService.createSleepCalendarItem(data),
  meta: {
    onSuccess: (response: any) => successCallback(response),
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'error')

      if (isEmpty([messages])) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', [messages].join(' ')))
      }
    },
  },
})

export const getSleepCalendar = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération du calendrier'),
      )
    },
  },
  promise: ApiService.getSleepCalendar(),
  type: A.GET_SLEEP_CALENDAR,
})

export const updateSleepCalendar = (data: any) => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération du calendrier'),
      )
    },
  },
  promise: ApiService.updateSleepCalendar(data),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const lowerCalendarBedtime = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la mise à jour du calendrier'),
      )
    },
  },
  promise: ApiService.lowerCalendarBedtime(),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const increaseCalendarBedtime = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la mise à jour du calendrier'),
      )
    },
  },
  promise: ApiService.increaseCalendarBedtime(),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const skipCalendarBedtime = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la mise à jour du calendrier'),
      )
    },
  },
  promise: ApiService.skipCalendarBedtime(),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const updateSleepCalendarItem = (sleepCalendarItemId: number, data: any) => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération du calendrier'),
      )
    },
  },
  promise: ApiService.updateSleepCalendarItem(sleepCalendarItemId, data),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const getSleepCalendarItems = () => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Erreur lors de la récupération du calendrier'),
      )
    },
  },
  promise: ApiService.getSleepCalendarItems(),
  type: A.GET_SLEEP_CALENDAR, // TODO: change?
})

export const setExtraPersonalData = (data: object) => ({
  type: A.SET_EXTRA_PERSONAL_DATA,
  payload: data,
})

export const startRenewEnrollment = (enrollmentId: number) => {
  return {
    meta: {
      onFailure: () => {
        store.dispatch(showNotification('error', 'Erreur lors de la préparation du paiement.'))
      },
    },
    promise: ApiService.startRenewEnrollment(enrollmentId),
    type: A.START_RENEW_ENROLLMENT,
  }
}
export const interruptEnrollment = (enrollmentId: number) => {
  return {
    meta: {
      onFailure: () => {
        store.dispatch(showNotification('error', 'Erreur lors de l\'interruption du programme.'))
      },
    },
    promise: ApiService.interruptEnrollment(enrollmentId),
    type: A.INTERRUPT_ENROLLMENT,
  }
}


export const startLesson = (attendance: T.Attendance) => ({
  promise: ApiService.startLesson(attendance.id),
  type: A.START_LESSON,
})

export const evaluateLesson = (attendance: T.Attendance, survey: Model) => ({
  promise: ApiService.evaluateLesson(attendance.id, survey),
  type: A.EVALUATE_LESSON,
})

export const purchaseProgram = (
  token: IToken,
  data: T.PaymentPayload,
  onSuccess: Function,
) => ({
  meta: {
    onSuccess: (response: { data: string }) => {
      const cart = first(values(get(response, 'cart')))
      const gatewaySecureModeRedirectUrl = get(get(cart, 'attributes'), 'gatewaySecureModeRedirectUrl')
      const paymentMethods = get(data, 'paymentMethod')

      if (isNil(gatewaySecureModeRedirectUrl)) {
        store.dispatch(showNotification('success', 'Paiement effectué avec succès'))
        onSuccess()
      } else {
        const notificationMsg: string = ((paymentMethods && 'paypal' in paymentMethods) ?
          "Vous allez être redirigé vers l’interface Paypal" : "Vous allez être redirigé vers l’interface 3DSecure de votre banque")
        store.dispatch(showNotification('info', notificationMsg))

        const successActionCallback = () => {
          onSuccess()
          // This should be refactored, we should not have to throw an dummy action
          return ({ type: "DUMMY_USELESS_ACTION" })
        }
        const errorActionCallback = () => {
          // This should be refactored, we should not have to throw an dummy action
          return ({ type: "DUMMY_USELESS_ACTION" })
        }
        store.dispatch(startSecureChallenge({
          gatewaySecureModeRedirectUrl,
          successAction: successActionCallback,
          failureAction: errorActionCallback
        }))
      }
    },
    onFailure: (response: { errors: { base: string[] } }) => {
      const messages = get(response, 'errors.base', ['Une erreur est survenue'])
      store.dispatch(showNotification('error', messages.join(' ')))
    },
  },
  type: A.PURCHASE_PROGRAM,
  promise: StorefrontApiService.purchase(token, data),
})

export const applyCoupon = (coupon: string, orderToken: string) => {
  return {
    meta: {
      onSuccess: (response: { data: string }) => {
        store.dispatch(showNotification('success', 'Le code promo a bien été appliqué'))
      },
      onFailure: (response: { errors: { base: string[] } }) => {
        const messages = get(response, 'errors.base')
        if (isUndefined(messages)) {
          store.dispatch(
            showNotification('error', "Ce code promo n'existe pas ou n'est pas applicable."),
          )
        } else {
          store.dispatch(showNotification('error', messages.join(' ')))
        }
      },
    },
    type: A.APPLY_COUPON,
    promise: StorefrontApiService.applyCoupon(coupon, orderToken),
  }
}

export const selectProgramVariant = (
  variantId: number,
  orderToken: string,
  onSuccess: any,
) => {
  return {
    meta: {
      onSuccess: () => onSuccess()
    },
    type: A.SELECT_PROGRAM_VARIANT,
    promise: StorefrontApiService.changeCartToVariant(variantId, orderToken),
  }
}
export const getOrderPaymentMethods = () => {
  const orders = build(store.getState().academy, 'cart')
  const order = first(orders) as T.OrderV2

  return {
    type: A.GET_PAYMENT_METHODS,
    promise: StorefrontApiService.getPaymentMethods(order.token),
  }
}

export const startOnboardingWithProgram = (slug: string) => {
  const defaultSlug = IS_CHARLES_PLATFORM ? 'time-program' : 'vaginisme-program'
  const targetSlug = slug || defaultSlug

  return {
    type: A.START_ONBOARDING_WITH_PROGRAM,
    payload: targetSlug,
  }
}

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import cerclePresentation from "assets/images/cercle/cercle-presentation.jpg"
import AppStoreIcon from 'assets/icons/cercle/app-store-icon.svg'
import GooglePlayIcon from 'assets/icons/cercle/play-store-icon.svg'
import LetterBox from 'assets/icons/cercle/letter-box.svg'
import './styles.sass'
import { SvgIcon } from '../../../components'
import { Link } from 'react-router-dom'

interface IProps extends RouteComponentProps<any> {}

class JoinCercle extends React.Component<IProps> {
  render() {
    return (
      <div className="join-container page phytotherapy">
        <div className='block'>
          <h1>Maximiser votre perte de poids avec Cercle</h1>
          <p>Grâce à l'application Cercle, entamez votre chemin vers un vous plus sain.</p>
          <div className='sub-block'>
            <img src={cerclePresentation} className="cercle-presentation" alt="cercle" />
            <h2>Téléchargez gratuitement Cercle</h2>
            <p>
              <span className="line">l'application qui vous accompagne&nbsp;</span>
              <span className="line">avec :</span>
            </p>
            <div className='list-section'>
              <ul>
                <li><b>Un coach en nutrition</b> spécialiste des traitements <b>GLP-1</b></li>
                <li><b>8 vidéos</b> sur les fondamentaux de la nutrition</li>
                <li>Des <b>recettes</b> et des <b>menus</b> adaptés à vos gouts</li>
              </ul>
            </div>
            <div className='password-section'>
              <SvgIcon classname="store-icon" icon={LetterBox} />
              <p>Votre mot de passe est déjà dans votre boite mail !</p>
            </div>
            <a href="https://apps.apple.com/fr/app/cercle-by-charles/id6446853329">
              <SvgIcon classname="store-icon" icon={AppStoreIcon} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=co.charles.cercle">
              <SvgIcon classname="store-icon" icon={GooglePlayIcon} />
            </a>
            <p>Vous avez des questions ? <a href="mailto:contact@charles.co"><b>Contactez-nous</b></a></p>
          </div>
        </div>
        <div className='footer'>
          <Link className='btn btn-primary' to='/home'>
              Suivant
          </Link>
        </div>
      </div>
    )
  }
}

export default withRouter(JoinCercle)
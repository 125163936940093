import { get, isArray, isNil, isObject, isUndefined, join, values, isEmpty } from 'lodash'
import * as A from 'constants/actions'
import { ApiService } from 'services'
import T from 'types'
import { showNotification } from './ux'
import store from '../store'

export const getPrescriptions = () => ({
  type: A.GET_PRESCRIPTIONS,
  promise: ApiService.getPrescriptions().then(({ data }) => ({ prescriptions: data })),
})

export const getPrescriptionById = (id: number) => ({
  type: A.SHOW_PRESCRIPTION,
  promise: ApiService.getPrescriptionById(id),
})

export const getPrescriptionPdf = (url: string) => ({
  type: A.GET_PRESCRIPTION_PDF,
  promise: ApiService.getPrescriptionPdf(url).then(({ data }) => {
    const file = new Blob([data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')

    link.setAttribute('target', '_blank')
    link.href = fileURL
    link.click()
  }),
})

export const setFocusedPrescription = (prescription: T.Prescription) => ({
  type: A.SET_FOCUSED_PRESCIPTION,
  payload: prescription,
})

export const createPrescriptionDelivery = (
  prescription: T.Prescription,
  data: T.Forms.ShippingAddress,
  onSuccess: () => void,
) => ({
  type: A.CREATE_PRESCRIPTION_DELIVERY,
  promise: ApiService.createPrescriptionDelivery(prescription.id, data),
  meta: {
    onSuccess: (response: { data: string }) => {
      onSuccess()
    },
    onFailure: (response: { data: string; status: number; statusText: string }) => {
      const messages = get(response, 'data.errors.base') || [get(response, 'data.error')]
      if (isUndefined(messages) || isEmpty(messages)) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', messages.join(' ')))
      }
    },
  },
})

export const finalizePrescriptionDelivery = (orderNumber: string, onSuccess: () => void) => ({
  type: A.FINALIZE_PRESCRIPTION_DELIVERY,
  promise: ApiService.finalizePrescriptionDelivery(orderNumber),
  meta: {
    onSuccess: (response: { data: string }) => {
      onSuccess()
    },
    onFailure: (response: { data: string; status: number; statusText: string }) => {
      const messages = get(response, 'data.errors.base') || [get(response, 'data.error')]
      if (isUndefined(messages) || isEmpty(messages)) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', messages.join(' ')))
      }
    },
  },
})

export const updateFocusedPresc = (paidPrescriptionId: number) => ({
  type: A.GET_PRESCRIPTION,
  promise: ApiService.getPrescriptions().then(data =>
    data.data.filter((el: any) => el.id === paidPrescriptionId),
  ),
})

export const startPurchasePrescription = (prescription: T.Prescription) => ({
  meta: {
    //TODO Analyze response
    onFailure: (response: { data: string; status: number; statusText: string }) => {
      const messages = get(response, 'data.errors.base') || [get(response, 'data.error')]
      if (isUndefined(messages) || isEmpty(messages)) {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      } else {
        store.dispatch(showNotification('error', messages.join(' ')))
      }
    },
  },
  type: A.START_PURCHASE_PRESCRIPTION_DOWNLOAD,
  promise: ApiService.startPurchasePrescription({
    prescriptionId: prescription.id,
  }),
})

export const purchasePrescription = (prescription: T.Prescription, data: T.PaymentPayload, onSuccess: any) => {
  if (isUndefined(prescription.unlockingOrderNumber)) {
    return null;
  }
  return {
    meta: {
      onSuccess: (response: { data: any }) => {
        const redirectUrl = get(response.data, 'gatewaySecureModeRedirectUrl')

        if (isNil(redirectUrl)) {
          store.dispatch(updateFocusedPresc(prescription.id))
        } else {
          // TODO V2 Refactor - Reproduce in all payment methods ?
          const paymentMethod = get(data, 'paymentMethod', {})

          if ('paypal' in paymentMethod)
            store.dispatch(
              showNotification(
                'info',
                'Vous allez être redirigé vers l’interface Paypal',
              ),
            )
          else
            store.dispatch(
              showNotification(
                'info',
                'Vous allez être redirigé vers l’interface 3DSecure de votre banque',
              ),
            )
        }

        onSuccess(redirectUrl)
      },
      onFailure: (response: { data: string; status: number; statusText: string }) => {
        const messages = get(response, 'data.errors.base') || [get(response, 'data.error')]
        if (isUndefined(messages) || isEmpty(messages)) {
          store.dispatch(showNotification('error', 'Une erreur est survenue'))
        } else {
          store.dispatch(showNotification('error', messages.join(' ')))
        }
      },
    },
    type: A.PURCHASE_PRESCRIPTION_DOWNLOAD,
    promise: ApiService.finalizeOrderPaymentV2({
      data,
      orderNumber: prescription.unlockingOrderNumber,
    })
  }
}

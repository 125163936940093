import React, { Component } from 'react'
import { MainContainer } from 'containers'
import '../styles.sass'

type IProps = {
  match: any,
}

class Consultation extends Component<IProps> {

  render() {

    return (
      <MainContainer>
        <div className="page" />
      </MainContainer>
    )
  }
}

export default Consultation

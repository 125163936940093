import { connect } from 'react-redux'
import DownloadPrescription from 'page/Pages/DownloadPrescription'
import {
  getPharmacies,
  getPrescriptions,
  showPopup,
  getPrescriptionById,
  setFocusedPrescription,
} from 'actions'
import T from 'types'

const mapStateToProps = ({
  prescriptions: { collectionPrescription, focusedPrescription, lastPrescription },
}: T.Store) => ({
  collectionPrescription,
  prescription: focusedPrescription || lastPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getPharmacies: () => dispatch(getPharmacies()),
  getPrescriptions: () => dispatch(getPrescriptions()),
  setFocusedPrescription: (prescription: any) => dispatch(setFocusedPrescription(prescription)),
  getPrescriptionById: (id: number) => dispatch(getPrescriptionById(id)),
  showPopup: ({
    classPopup,
    popupTitle,
    popupType,
    popupProps,
  }: {
    classPopup: string
    popupTitle: string
    popupType: string
    popupProps: any
  }) =>
    dispatch(
      showPopup({
        classPopup,
        popupTitle,
        popupType,
        popupProps,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadPrescription)

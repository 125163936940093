import React from 'react'
import T from 'types'
import SvgIcon from 'components/SvgIcon'
import Button from 'components/Button'
import { icons } from 'assets/icons'
import phone from 'assets/icons/small-assistance.svg'
import question from 'assets/icons/question-circle.svg'
import arrow from 'assets/icons/arrow.svg'
import RoutingStepper, { SimpleStepper } from 'page/common/simple-stepper'

import { IS_CHARLES_PLATFORM, SUPPORT_PHONE_NUMBER } from 'constants/config'

import logoCharles from 'assets/icons/charles-co-logo.svg'
import logoMia from 'assets/images/mia-logo.svg'
import { isUndefined } from 'lodash'

type Props = {
  brandLogo?: any
  logoClassName?: string
  steps: T.StepperStep[]
  goPrevious: Function,
  currentStep?: number,
  showPhoneNumber?: boolean,
}

const logo: any = (IS_CHARLES_PLATFORM ? logoCharles : logoMia)

// {false &&<SvgIcon icon={props.brandLogo || logo} classname={props.logoClassName || "charles-logo-svg"} />
const Index = (props: Props) => (
  <nav className="onboarding-navbar">
    <div className="container-fluid navbar">
      <div className="onboarding-back" onClick={() => props.goPrevious()}>
        <SvgIcon icon={arrow} classname="onboarding-back-icon mr-1" />
        <span className="d-none d-xl-inline">Retour</span>
      </div>
      { isUndefined(props.currentStep) ? <RoutingStepper steps={props.steps} /> : <SimpleStepper steps={props.steps} currentStep={props.currentStep} />}
      <ul className="navbar-nav">
        {props.steps.length <= 5
          ? (
            <li className="nav-item ml-auto">
              <a className="d-inline d-xl-none" href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`}>
                <div className="d-flex align-items-center justify-content-between onboarding-navbar-help">
                  <SvgIcon icon={question} classname="onboarding-phone-icon mr-1" />Aide
                </div>
              </a>
              <div className="d-none d-xl-block onboarding-phone-help">
                <SvgIcon icon={phone} classname="mr-1" />
                <a className="font-weight-bold mr-1" href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`}>{SUPPORT_PHONE_NUMBER}</a>
                <span className="font-weight-normal">Gratuit</span>
              </div>
            </li>
          ) : (
            <li className="nav-item ml-auto">
              <a className="d-inline" href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`}>
                <div className="d-flex align-items-center justify-content-between onboarding-navbar-help">
                  <SvgIcon icon={question} classname="onboarding-phone-icon mr-1" />Aide
                </div>
              </a>
            </li>
          )}
      </ul>
    </div>
  </nav>
)

export default Index

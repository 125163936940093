import axios, { AxiosInstance, AxiosResponse } from "axios"
import { camelizeKeys, decamelizeKeys } from "humps"
import T from 'types'
import { CookiesHelper } from "helpers"
import { isUndefined, every } from "lodash"
import loadSpeSurveyFromCache from "services/survey-cache"
import normalize from "json-api-normalizer"
import {
  buildAuthParams,
  buildJsonFormData,
  createPaymentRequestPayload
} from "utils/helpers"
import { authToken } from "actions"

const FORM_DATA_CONTENT_TYPE = "multipart/form-data"

class ApiService {
  apiInstance: AxiosInstance

  constructor() {
    this.apiInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })

    this.apiInstance.interceptors.request.use((config) => {
      // Prevent decamelizing data for multipart/form-data
      // because it converts the content type to JSON
      const shouldDecamelize =
        config.headers["Content-Type"] !== FORM_DATA_CONTENT_TYPE
      return {
        ...config,
        data: shouldDecamelize ? decamelizeKeys(config.data) : config.data,
        headers: {
          Authorization: CookiesHelper.getOauthAccessToken(),
        },
      }
    })

    this.apiInstance.interceptors.response.use(
      (config) => ({ ...config, data: camelizeKeys(config.data) }),
      ({ config, response }) => {
        // handle case where accessToken has been expired: refresh it!
        if (
          config &&
          !config.url.includes("spree_oauth") &&
          response &&
          response.status === 401
        ) {
          return this.refreshSession().then(() => {
            config.headers.Authorization = CookiesHelper.getOauthAccessToken()
            return axios.request(config)
          })
        }

        return Promise.reject(response)
      }
    )
  }

  createToken(username: string, password: string, cookiesEnabled: boolean) {
    return this.apiInstance.post("/spree_oauth/two_factor_token", {
      username,
      password,
      cookiesEnabled,
    })
  }

  createSession(authParams: T.AuthParams) {
    return this.apiInstance
      .post("/spree_oauth/token", {
        ...buildAuthParams(authParams),
        grant_type: "password",
      })
      .then(({ data }) => CookiesHelper.setOauthToken(data))
  }

  refreshSession() {
    return this.apiInstance
      .post("/spree_oauth/token", {
        grant_type: "refresh_token",
        refresh_token: CookiesHelper.getOauthRefreshToken(),
      })
      .then(({ data }) => CookiesHelper.setOauthToken(data))
  }

  destroySession() {
    return this.apiInstance
      .post("/spree_oauth/revoke", {
        token: CookiesHelper.getPureOauthAccessToken(),
      })
      .then(() => CookiesHelper.destroyOauthToken())
  }

  getProfile() {
    return this.apiInstance.get("/api/v1/profile")
  }

  updateProfile(data: any) {
    return this.apiInstance.patch("/api/v1/profile", { profile: data })
  }

  updatePatientData(data: any) {
    return this.apiInstance.patch("/api/v1/profile", { profile: data })
  }

  uploadDocuments(data: FormData) {
    return this.apiInstance.patch("/api/v1/profile/medical_documents", data, {
      headers: {
        "Content-Type": FORM_DATA_CONTENT_TYPE,
      },
    })
  }

  deleteCreditCard(id: number) {
    return this.apiInstance
      .delete(`/api/v1/profile/credit_cards/${id}`)
      .then(() => ({ id }))
  }

  surveySubmission(surveyId: number, survey: object) {
    return this.apiInstance.post("/api/v1/pre_diags", {
      preDiag: { surveyId, surveySubmission: survey },
    })
  }

  getSurvey(params: T.OnboardingParams) {

    const statelessRequest = every(
      [params.d, params.email, params.pdid, params.returnFromThreeDSecure, params.orderNumber],
      (value: any) => isUndefined(value)
    )
    if (statelessRequest) {
      const cachedSurvey = loadSpeSurveyFromCache(params.spe)
      if (!isUndefined(cachedSurvey)) {
        return Promise.resolve(camelizeKeys(cachedSurvey))
      }
    }
    const targetURL = `/api/v1/survey?${new URLSearchParams(
      decamelizeKeys(params) as any
    ).toString()}`
    return this.apiInstance.get(targetURL).then(({ data }) => data)

  }

  getConsultations(limit?: object) {
    return this.apiInstance.get("api/v1/consultations", { params: limit })
  }

  getConsultation(consultationId: string) {
    return this.apiInstance.get(`api/v1/consultations/${consultationId}`)
  }

  getSurveyAndSubmission(consultSpecialty: string) {
    return this.apiInstance.get(`api/v1/surveys/${consultSpecialty}`)
  }

  getPrescriptionById(id: number) {
    return this.apiInstance.get(`api/v1/prescriptions/${id}`)
  }

  // getMedication = () => Promise.resolve(
  //   {
  //     data: [
  //       {
  //         id: 1,
  //         name: 'Sildenafil 20mg',
  //         dosage: '1 comprimé/rapport',
  //         notice: '<p>Dénomination du médicament</br> SILDENAFIL ACCORD 25 mg, \
  //           comprimé pelliculé</br> Sildénafil Encadré</br> \
  //           Veuillez lire attentivement cette notice avant de prendre ce médicament \
  //            car elle contient des informations importantes pour vous.</br> \
  //           Gardez cette notice. Vous pourriez avoir besoin de la relire.</br> \
  //           Si vous avez d’autres questions, interrogez votre médecin, votre pharmacien \
  //           ou votre infirmier/ère.</br> \
  //           Ce médicament vous a été personnellement prescrit. Ne le donnez pas à \
  //           d’autres personnes. Il pourrait leur être nocif, même si les signes de \
  //           leur maladie sont identiques aux vôtres</br> \
  //           Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin, \
  //            votre pharmacien ou votre infirmier/ère. Ceci s’applique aussi à tout effet \
  //            indésirable qui ne serait pas mentionné dans cette notice. Voir rubrique </br> \
  //           4. Que contient cette notice ? </br>\
  //           1. Qu’est-ce que SILDENAFIL ACCORD 25 mg, comprimé pelliculé et dans \
  //           quels cas est-il utilisé ? </br>\
  //           2. Quelles sont les informations à connaître avant de prendre SILDENAFIL \
  //           ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           3. Comment prendre SILDENAFIL ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           4. Quels sont les effets indésirables éventuels ? </br>\
  //           5. Comment conserver SILDENAFIL ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           6. Contenu de l’emballage et autres informations.</p>',
  //       },
  //       {
  //         id: 2,
  //         name: 'Sildenafil 5mg',
  //         dosage: '1 comprimé/rapport',
  //         notice: '<p>Dénomination du médicament</br> SILDENAFIL ACCORD 25 mg, \
  //           comprimé pelliculé</br> Sildénafil Encadré</br> \
  //           Veuillez lire attentivement cette notice avant de prendre ce médicament \
  //            car elle contient des informations importantes pour vous.</br> \
  //           Gardez cette notice. Vous pourriez avoir besoin de la relire.</br> \
  //           Si vous avez d’autres questions, interrogez votre médecin, votre pharmacien \
  //           ou votre infirmier/ère.</br> \
  //           Ce médicament vous a été personnellement prescrit. Ne le donnez pas à \
  //           d’autres personnes. Il pourrait leur être nocif, même si les signes de \
  //           leur maladie sont identiques aux vôtres</br> \
  //           Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin, \
  //            votre pharmacien ou votre infirmier/ère. Ceci s’applique aussi à tout effet \
  //            indésirable qui ne serait pas mentionné dans cette notice. Voir rubrique </br> \
  //           4. Que contient cette notice ? </br>\
  //           1. Qu’est-ce que SILDENAFIL ACCORD 25 mg, comprimé pelliculé et dans \
  //           quels cas est-il utilisé ? </br>\
  //           2. Quelles sont les informations à connaître avant de prendre SILDENAFIL \
  //           ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           3. Comment prendre SILDENAFIL ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           4. Quels sont les effets indésirables éventuels ? </br>\
  //           5. Comment conserver SILDENAFIL ACCORD 25 mg, comprimé pelliculé ? </br>\
  //           6. Contenu de l’emballage et autres informations.</p>',
  //       },
  //     ],
  //   },
  // )

  getPrescriptions() {
    return this.apiInstance.get("api/v1/prescriptions")
  }

  getPrescriptionPdf(url: string) {
    return this.apiInstance.get(
      `${url}?access_token=${CookiesHelper.getPureOauthAccessToken()}`
    )
  }

  getPharmacies() {
    return this.apiInstance.get("api/v1/pharmacists")
  }

  getPharmacy(id: string) {
    return this.apiInstance.get(`api/v1/pharmacists/${id}`)
  }

  getDoctors(consultationSpecialty: string | undefined) {
    return this.apiInstance.get(
      `api/v1/doctors?specialty=${consultationSpecialty}`
    )
  }

  getDoctor(openConsultationSlug: string) {
    return this.apiInstance.get(`api/v1/doctors/${openConsultationSlug}`)
  }

  createPrescriptionDelivery(prescriptionId: number, data: T.Forms.ShippingAddress) {

    return this.apiInstance.post(
      `/api/v1/prescriptions/${prescriptionId}/create_delivery`,
      {
        shippingAddress: data,
      }
    )
  }

  getCommunicationChannels(consultationSpecialty: string | undefined) {
    return this.apiInstance.get(
      `api/v1/doctors/${consultationSpecialty}/available_communication_channels`
    )
  }

  // TODO V2 Add type T.Forms.ShippingAddress
  createDelivery(data: T.Forms.ShippingAddress) {

    return this.apiInstance
      .patch(`/api/v2/storefront/phyto_checkout/create_delivery`, {
        shippingAddress: data,
      })
      .then((response: AxiosResponse<any>) => {
        const result = normalize(response.data)
        return Promise.resolve(result)
      })
      .catch((response: AxiosResponse<any>) => {
        return Promise.reject(response.data)
      })
  }

  createAddress(data: any, enrollmentId: string | undefined) {
    return this.apiInstance
      .patch(`api/v2/academy/enrollments/${enrollmentId}/create_delivery`, {
        shippingAddress: data,
      })
      .then((response: AxiosResponse<any>) => {
        const result = normalize(response.data)
        return Promise.resolve(result)
      })
      .catch((response: AxiosResponse<any>) => {
        return Promise.reject(response.data)
      })
  }

  // TODO V2 Refactor - Deprecated - favor finalizeOrderPaymentV2
  finalizePrescriptionDelivery(orderNumber: string) {
    return this.apiInstance.put(`/api/v1/checkouts/${orderNumber}`, {})
  }

  createConsultation({
    communicationChannel,
    consultationType,
    specialtySlug,
    surveyId,
    surveyUuid,
    surveySubmission,
    patientId,
    doctorId,
    appointmentAt,
  }: T.Consultation) {
    return this.apiInstance.post("/api/v1/consultations", {
      consultation: {
        communicationChannel,
        surveyId,
        surveyUuid,
        surveySubmission,
        patientId,
        doctorId,
        appointmentAt,
        consultationType,
        specialtySlug,
      },
    })
  }

  updateConsultation(id: number, patientId: number, ssn: string) {
    return this.apiInstance.patch(`/api/v1/consultations/${id}/apply_ssn`, {
      consultation: {
        patientId,
        ssn
      }
    })
  }

  startPurchasePrescription({ prescriptionId }: { prescriptionId: number }) {
    return this.apiInstance.post(
      `/api/v1/prescriptions/${prescriptionId}/start_purchase`
    )
  }

  finalizeOrderPaymentV2({
    orderNumber,
    data,
  }: {
    orderNumber: string
    data: T.PaymentPayload
  }) {
    const paymentRequestPayload: T.PaymentRequestPayload = createPaymentRequestPayload(data)
    return this.apiInstance.put(`/api/v1/checkouts/${orderNumber}`, paymentRequestPayload)
  }

  getMessages(userId: number) {
    return this.apiInstance.get("api/v1/messages", {
      params: { user_id: userId },
    })
  }

  getMessage(messageId: string) {
    return this.apiInstance.get(`api/v1/messages/${messageId}`)
  }

  createMessage(userId: number, body: string, patientUserId?: number) {
    if (isUndefined(patientUserId)) {
      return this.apiInstance.post("api/v1/messages", {
        message: { body, userId },
      })
    }

    // create message without auth (from onboarding)
    return this.apiInstance.post("api/v1/messages/create_first", {
      message: { body, userId, patientUserId },
    })
  }

  createPatient({
    email,
    firstName,
    lastName,
    birthDate,
    ssn,
    phoneNumber,
  }: {
    email: string
    firstName?: string
    lastName?: string
    birthDate?: string
    ssn?: string
    phoneNumber?: string
  },
    source: string,
    preSurveySubmission?: any
  ) {
    return this.apiInstance
      .post("api/v1/patients", {
        patient: decamelizeKeys({
          firstName,
          lastName,
          birthDate,
          ssn,
          spreeUserAttributes: {
            email,
            phoneNumber,
          }
        }),
        source,
        preSurveySubmission
      }).then(({ data }) => data)
  }

  cleanDrop(email: string) {
    if (typeof navigator.sendBeacon === "function") {
      const targetURL = `${process.env.REACT_APP_API_BASE_URL}api/v1/users/drop`
      const data = new FormData()
      data.append("email", email)
      navigator.sendBeacon(targetURL, data)
    }
  }

  uploadSurvey(orderNumber: string | undefined, survey: object) {
    if (!isUndefined(orderNumber)) {
      return this.apiInstance.post(
        `api/v1/pre_diags/${orderNumber}/update_survey`,
        {
          survey,
        }
      )
    }
  }

  acceptInvitation(data: any) {
    return this.apiInstance.put(
      'registration',
      {
        spreeUser: data,
      }
    )
  }

  supportMessageSubmission(value: string) {
    return this.apiInstance
      .post("api/v2/academy/programs/assist", { request: value })
      .then((response) => normalize(response.data))
  }

  getPrograms() {
    return this.apiInstance
      .get("api/v2/academy/programs")
      .then((response) => normalize(response.data))
  }

  getEnrollments() {
    return this.apiInstance
      .get("api/v2/academy/enrollments")
      .then((response) => normalize(response.data))
  }

  startPurchaseProgram(programSlug: string, patientAttributes: any) {
    return this.apiInstance
      .post(`api/v2/academy/enrollments`, {
        enrollment: {
          programSlug,
          patientId: patientAttributes.id,
        },
      })
      .then((response) => normalize(response.data))
  }

  startRenewEnrollment(enrollmentId: number) {
    return this.apiInstance
      .post(`api/v2/academy/enrollments/${enrollmentId}/start_renewal`)
      .then((response) => normalize(response.data))
  }

  interruptEnrollment(enrollmentId: number) {
    return this.apiInstance
      .post(`api/v2/academy/enrollments/${enrollmentId}/stop_renewal`)
      .then((response) => normalize(response.data))
  }

  startLesson(attendanceId: number) {
    return this.apiInstance
      .post(`/api/v2/academy/attendances/${attendanceId}/start`)
      .then((response) => normalize(response.data))
  }

  evaluateLesson(attendanceId: number, survey: object) {
    return this.apiInstance
      .post(`/api/v2/academy/attendances/${attendanceId}/evaluate`, {
        attendance: { evaluation: survey },
      })
      .then((response) => normalize(response.data))
  }

  getSubscriptions() {
    return this.apiInstance
      .get("api/v2/subscriptions")
      .then((response) => normalize(response.data))
  }

  cancelSubscription(subscriptionId: number) {
    return this.apiInstance
      .patch(`/api/v2/subscriptions/${subscriptionId}/cancel`)
      .then((response) => normalize(response.data))
  }

  getSleepCalendar() {
    return this.apiInstance
      .get("/api/v2/academy/sleep_calendar")
      .then((response) => normalize(response.data))
  }

  lowerCalendarBedtime() {
    return this.apiInstance
      .put("/api/v2/academy/sleep_calendar/lower_bedtime")
      .then((response) => normalize(response.data))
  }

  increaseCalendarBedtime() {
    return this.apiInstance
      .put("/api/v2/academy/sleep_calendar/increase_bedtime")
      .then((response) => normalize(response.data))
  }

  skipCalendarBedtime() {
    return this.apiInstance
      .put("/api/v2/academy/sleep_calendar/skip_bedtime")
      .then((response) => normalize(response.data))
  }

  updateSleepCalendar(params: object) {
    return this.apiInstance
      .patch("/api/v2/academy/sleep_calendar", params)
      .then((response) => normalize(response.data))
  }

  createSleepCalendarItem(params: object) {
    return this.apiInstance
      .post("/api/v2/academy/sleep_calendar_items", params)
      .then((response) => normalize(response.data))
  }

  getSleepCalendarItems() {
    return this.apiInstance
      .get("/api/v2/academy/sleep_calendar_items")
      .then((response) => normalize(response.data))
  }

  updateSleepCalendarItem(sleepCalendarId: number, params: object) {
    return this.apiInstance
      .patch(`/api/v2/academy/sleep_calendar_items/${sleepCalendarId}`, params)
      .then((response) => normalize(response.data))
  }

  getPostConsultationRecommendations(id: number) {
    return this.apiInstance
      .get(`/api/v2/checkout/consultations/${id}`)
      .then((response) => normalize(response.data))
  }

  getLastFinishedConsultation() {
    return this.apiInstance
      .get('/api/v2/checkout/consultations/last_finished')
      .then((response) => normalize(response.data))
  }

  updateCurrentStore(pharmacistId: number, orderNumber: string) {
    return this.apiInstance
      .post(`api/v2/checkout/orders/${orderNumber}/update_current_store`, { pharmacist_id: pharmacistId })
      .then((response) => normalize(response.data))
  }

  getDrugsVariants(orderNumber: string, consultationId: number, pharmacistId: number | undefined) {
    return this.apiInstance.get(`api/v2/checkout/orders/${orderNumber}/products`, {
      params: { pharmacist_id: pharmacistId, consultation_id: consultationId },
    })
  }
}

export default new ApiService()

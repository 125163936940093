import T from 'types'
import {
  Dictionary,
  filter,
  find,
  includes,
  isEmpty,
  isUndefined,
  last,
  map,
  max,
  reduce,
  values,
} from 'lodash'
import build from 'redux-object'
import { money } from 'utils/models/money'
import {
  groupUnsubscribableAndSubscribableVariants,
  productFrequencyForVariant,
} from 'utils/models/variants'

const DEFAULT_RECOMMENDED_DURATION = 3

/**
 *  Iterates over products and prescriptions, returning a sorted by recommended array
 *  of all products or not depending to {shoNonRecommended}
 *
 *  @param prescriptions
 *  @param products The products to iterate over.
 *  @param showNonRecommended
 *
 *  @return Returns the new sorted array.
 */
export const filterProducts = (prescriptions: T.Prescription[], products: any[], showNonRecommended: boolean) => {

  // return every prescription that has this product
  const findRelatedPrescriptions = (product: any): T.Prescription[] => {
    return filter(prescriptions, (prescription) => {
      // first and second version for molecules are for products with old slugs and for the new ones (with new price)
      const moleculesFirstVersion = map(prescription.prescriptionItems, item => item.molecule)
      const moleculesSecondVersion = map(
        prescription.prescriptionItems,
        item => `emeis_${item.molecule}`,
      )
      return (
        includes(moleculesFirstVersion, product.slug) ||
        includes(moleculesSecondVersion, product.slug)
      )
    })
  }

  /**
   * Iterates over products, returning an array of all products with infos about recommendations.
   *
   * @param products The products to iterate over.
   * @return Returns the new array for every product {product, recommendedDuration, recommended, prescriptions}
   */
  const recommendedProducts = map(products, (product: any) => {
    const relatedPrescriptions = findRelatedPrescriptions(product)
    const recommendedDuration = 3
    // TODO REACTIVATE WHEN DONE
/*    const recommendedDuration = isEmpty(relatedPrescriptions)
      ? DEFAULT_RECOMMENDED_DURATION
      : max(
        map(relatedPrescriptions, (relatedPrescription: any) => {
          parseInt(relatedPrescription.renewability.split('_')[0], 10)
        },
        ),
      )*/
    return {
      product,
      recommendedDuration,
      recommended: !isEmpty(relatedPrescriptions),
      prescriptions: relatedPrescriptions,
    }
  })

  const filteredProducts = filter(
    recommendedProducts,
    product => product.recommended || showNonRecommended,
  )

  const sortedProducts = filteredProducts.sort((productA: any, productB: any) => {
    if (productA.recommended === productB.recommended) {
      const slugA = productA.product.slug || ''
      const slugB = productB.product.slug || ''
      return slugA.localeCompare(slugB)
    } return productA.recommended ? -1 : 1
  })
  return sortedProducts
}

// return true if current patient has this product recommended
export const filterPhytoProducts = (product: any, profile: T.Profile) => {
  const { phytoProductsIds } = profile
  for (const item of phytoProductsIds || []) {
    if (item == product.id && !isUndefined(product.slug))  return true
  }
  return false
}

export const deriveSelectedPrescription = (collectionPrescription: T.Prescription[], match: any) => {
  const prescriptionId = match.params.id
  const prescription = isUndefined(prescriptionId)
    ? undefined
    : find(
      collectionPrescription,
      (prescription: T.Prescription) => prescription.id === parseInt(prescriptionId, 10),
    )
  return prescription
}

// return an array of products sorted by recommended or not
// (depending on phyto products variants from profile)
export const productList = (
  collectionPrescription: T.Prescription[],
  storefront: T.Storefront,
  showNonRecommended: boolean,
  profile: T.Profile,
) => {
  const taxons = build(storefront, 'taxon')
  const phytoTaxon = find(taxons, (taxon: any) => taxon.name === 'phytotherapy')
  const allPhytoProducts = isUndefined(phytoTaxon) ? [] : phytoTaxon.products
  const phytoProducts = allPhytoProducts.filter((product: any) => filterPhytoProducts(product, profile))
  return filterProducts(collectionPrescription, phytoProducts, showNonRecommended)
}

// return image path for a product
export const productImagePath = (product: any) => {
  const image: any = last(product.images)
  const imageStyle: any = image.styles[2]
  return `${process.env.REACT_APP_API_BASE_URL}${imageStyle.url}`
}

export const variantTotalPrice = (variantList: Dictionary<T.CheckoutVariantChoice>) => {
  const selectedChoices: T.CheckoutVariantChoice[] = values(variantList)
  const total = reduce(
    selectedChoices,
    (sum: number, choice: T.CheckoutVariantChoice) => sum + parseFloat(choice.variant.price), 0)
  return money(total).toFormat()
}

export const variantDuration = (variant: T.Variant): string => {
  const quantityOptionValue = find(
    variant.optionValues,
    optionValue => optionValue.optionType.name === 'pills-per-box',
  )
  if(!isUndefined(quantityOptionValue)) {
    return quantityOptionValue.name
  } else {
    return ''
  }
}

// return the grouped variants for a product and a duration
export const getVariantsGroupedByDuration = (product: any, duration: number) => {
  const variants: any = groupUnsubscribableAndSubscribableVariants(
    product.variantsIncludingMaster).filter((variant: any) => {
      const frequency = productFrequencyForVariant(variant.variant)
      if (!isUndefined(frequency)) {
        return frequency.monthsCount === duration
      }
    })
  return variants[0]
}

import React, { Component } from 'react'
import { isNull, map, find } from 'lodash'
import classnames from 'classnames'
import T from 'types'
import ConsultationBookingItem from './ConsultationBookingItem'
import './styles.sass'

interface IProps {
  collection: any[],
  setChosenDoctor: (doctor: T.Doctor) => void,
  setChosenTimeSlot: (value: any) => void,
  confirm: () => void,
  medium?: string,
  loading?: boolean,
  consultationType?: string,
  slot?: Date,
}

interface IState {
  doctorId: number | null,
}

class ConsultationBookingList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { doctorId: null }
    this.handleChange = this.handleChange.bind(this)
  }

  private handleChange(doctorId: number, value: boolean) {
    this.setState({ doctorId: value ? doctorId : null })
  }

  private handleDisplaySlotClick(id: number) {
    const { collection } = this.props

    find(collection, [id, id])
  }

  private handleConfirm() {
    const { confirm } = this.props

    confirm()
  }

  private renderBookingItem(doctor: T.Doctor) {
    const {
      setChosenDoctor,
      setChosenTimeSlot,
      medium,
      loading,
      consultationType,
      slot,
    } = this.props
    const { doctorId } = this.state

    return (
      <ConsultationBookingItem
        doctor={doctor}
        key={doctor.id}
        classname={classnames({ 'disabled-doctor': !isNull(doctorId) && doctor.id !== doctorId })}
        onDisplaySlotClick={() => this.handleDisplaySlotClick(doctor.id)}
        setTimeSlot={setChosenTimeSlot}
        setDoctor={setChosenDoctor}
        onConfirm={() => this.handleConfirm()}
        onChange={value => this.handleChange(doctor.id, value)}
        medium={medium}
        consultationType={consultationType}
        slot={slot}
        loading={loading}
        isToggleOn={doctor.id === doctorId}
      />
    )
  }

  public render() {
    const { collection } = this.props

    const renderCollection = map(collection, doctor => (this.renderBookingItem(doctor)))

    return (
      <div className="doctor-list">{renderCollection}</div>
    )
  }
}

export default ConsultationBookingList

import { connect } from 'react-redux'
import {
  getTaxons,
  getProducts,
  beginCheckout,
  getPrescriptionPdf,
  getPrescriptions,
  getAuthProfile,
  addToCart,
} from 'actions'
import Wowtech from 'page/Pages/Wowtech'
import T from 'types'

const mapStateToProps = ({ auth: { profile }, storefront, prescriptions: { collectionPrescription } }: T.Store) => ({
  profile,
  storefront,
  collectionPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getProducts: () => dispatch(getProducts()),
  getTaxons: () => dispatch(getTaxons()),
  getPrescriptions: () => dispatch(getPrescriptions()),
  beginCheckout: (variants: T.CheckoutVariantChoice[], onSuccess: Function) =>
    dispatch(beginCheckout(variants, onSuccess)),
  addToCart: (variant: T.Variant, onSuccess: Function) =>
    dispatch(addToCart(variant, onSuccess)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Wowtech)

import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { isUndefined, isNil, get } from 'lodash'
import cx from 'classnames'
import T from 'types'
import BlockHeader from '../BlockHeader'
import './styles.sass'

interface IProps {
  icon?: string
  title?: string
  subTitle?: string
  note?: string
  children: React.ReactNode
  classname?: string
  cta?: T.Cta
  reportDownload?: boolean
  reportUrl?: string
  headerInside?: boolean
}

class Block extends Component<IProps> {
  static defaultProps = {
    headerInside: false,
  }
  public render() {
    const { title, subTitle, icon, cta, headerInside, children, classname } = this.props
    const blockHeader = title && <BlockHeader title={title} subTitle={subTitle} cta={cta} icon={icon} />
    return (
      <div className={cx("block-container", classname)}>
        {!headerInside && blockHeader}
        <div className={cx("block-content", { "block-with-header-inside": headerInside })}>
          {headerInside && blockHeader}
          {children}
        </div>
      </div>
    )
  }
}

export default Block

import React, { Fragment } from 'react'
import { filter, isEmpty, get, map } from 'lodash'
import { MainContainer } from 'containers'
import { RouteComponentProps } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import SvgIcon from 'components/SvgIcon'
import hourglass from 'assets/icons/clinic/32/hourglass.svg'
import eye from 'assets/icons/clinic/32/eye.svg'
import {
  MedicalDocumentList,
  Block,

} from 'components'
import '../styles.sass'
import { CookiesHelper } from 'helpers'
import T from 'types'
import { PRESCRIPTION_TYPE_ANALYSIS } from 'constants/prescriptions'
import { dateFormater } from 'utils/helpers'

interface IProps extends RouteComponentProps<any> {
  collection: T.Consultation[]
  profile: T.Profile
  collectionPrescription: any[]
  uploadMedicalDocument: (data: FormData) => void
  getPrescriptions: () => void
  setFocusedPrescription: (p: any) => void
  showPopup: (
    {
      classPopup,
      popupTitle,
      popupType,
      popupProps,
    }: {
      classPopup: string
      popupTitle: string
      popupType: string
      popupProps: any
    },
  ) => void
}

class MedicalDocuments extends React.Component<IProps> {
  
  public componentDidMount() {
    const { getPrescriptions } = this.props
    getPrescriptions()
  }

  public render() {
    return (
      <MainContainer title="Mes analyses">
        <div className="page medical-info">
          <div className="medical-info-container">
            <div className="infos-container">
              {this.renderMedicalAnalysisPrescriptions()}
              <br />
              {this.renderMedicalDocuments()}
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }

  private renderMedicalDocuments() {
    const { medicalDocuments } = this.props.profile
    const { uploadMedicalDocument, showPopup } = this.props

    return (
      <MedicalDocumentList
        medicalDocuments={medicalDocuments}
        uploadMedicalDocument={uploadMedicalDocument}
        showPopup={showPopup}
      />
    )
  }

  private renderMedicalAnalysisPrescriptions() {
    const { collectionPrescription, profile } = this.props
    const { lastConsultationSpeciality } = profile
    const analysisPrescriptions = filter(
      collectionPrescription,
      prescription => prescription.prescriptionType === PRESCRIPTION_TYPE_ANALYSIS
    )
    return (
      <Fragment>
        <Block classname="pharmacist">
          <div className="clinic-medical-container">
            {isEmpty(analysisPrescriptions) ? (
              <div>Vous n'avez pas reçu d'ordonnance d'analyses médicales</div>
            ) : (
              map(analysisPrescriptions, prescription => this.renderPrescription(prescription))
            )}
          </div>
        </Block>
      </Fragment>
    )
  }

  private renderPrescription(prescription: any) {
    const { id, downloadUrl, doctorAvatarUrl } = prescription

    return (
      <div className="clinic-medical-row row" key={id}>
        <div className="doctor-section col-md-4">
          <div className="doctor-infos-block">
            <div className="doctor-avatar-img">
              <img className="doctor-image mr-2" src={prescription.doctorAvatarUrl} alt={prescription.doctor} />
            </div>
            <div className="doctor-name-specialty pt-3">
              <strong>{get(prescription, 'doctor')}</strong>
              <div className="mt-2">{prescription.doctorSpecialty || 'Praticien'}</div>
            </div>
          </div>
        </div>
        <div className="col-md-4 pt-4">
          <div className="date-section">
            <SvgIcon icon={hourglass} classname="warning-icon mr-2" />
            <span>
              Ordonnance valable du <span className="range">{dateFormater(get(prescription, 'createdAt'), 'L')} au {dateFormater(get(prescription, 'expiredAt'), 'L')}</span>
            </span>
          </div>
        </div>
        <div className="col-md-4 pt-4">
          <div className="context-link">
            <SvgIcon icon={eye} classname="warning-icon mr-2" />
            <span>
              <a href={`${downloadUrl}?access_token=${CookiesHelper.getPureOauthAccessToken()}`} target="_blank">
                Mon ordonnance pour {I18n.t(`prescriptions.type.${get(prescription, 'prescriptionType')}`).toLowerCase()}
              </a>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default MedicalDocuments

import { connect } from 'react-redux'
import {
  acceptInvitation,
  createMessage,
  getDoctors,
  getCommunicationChannels,
  setChosenDoctor,
  createConsultation,
  setChosenTimeSlot,
  getSurvey,
  setSurveyObject,
  uploadSurvey,
  setSurveyQuestionName,
  createPatient,
  setPersonalData,
  setTermsOptin,
  setChosenMedium,
  setChosenConsultType,
  setFocusedSpecialty,
  getOpenConsultationDoctor,
  resetOpenConsultationDoctor,
  setSocialSecurityMode,
  sendConsultationPayment,
  getPaymentMethods,
  didStartPrePaymentSurvey,
  updateConsultationWithSSN,
  updatePatientData,
} from 'actions'
import OnBoarding from 'page/OnBoarding'
import T from 'types'

const mapStateToProps = ({
  doctors: { collection, doctor, slot, availableChannels },
  onboarding: {
    loaded,
    termsOfService,
    survey,
    surveyId,
    surveyFetchedAt,
    isRecovery,
    questionName,
    patientAttributes,
    medium,
    consultationType,
    returnFromThreeDSecure,
    socialSecurityMode,
    skipDoctorSelection,
    invitationToken,
    invitationAcceptedAt,
  },
  consultations: {
    focusedItem: consultation,
    focusedOrder: order,
    loading,
    consultationPaymentSuccess,
  },
  storefront
}: T.Store) => ({
  doctor,
  collection,
  consultation,
  consultationType,
  consultationPaymentSuccess,
  loading,
  loaded,
  questionName,
  medium,
  patientAttributes,
  order,
  slot,
  availableChannels,
  survey,
  surveyId,
  surveyFetchedAt,
  invitationToken,
  invitationAcceptedAt,
  isRecovery,
  socialSecurityMode,
  returnFromThreeDSecure,
  termsOfService,
  skipDoctorSelection,
  alwaysFalse: false,
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  createMessage: (userId: number, body: string, patientUserId: number) =>
    dispatch(createMessage(userId, body, patientUserId)),
  setTermsOptin: () => dispatch(setTermsOptin()),
  getDoctors: () => dispatch(getDoctors()),
  getCommunicationChannels: () => dispatch(getCommunicationChannels()),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  setChosenDoctor: (doctor: T.Doctor) => dispatch(setChosenDoctor(doctor)),
  getSurvey: (
    params: { pdid?: string, email?: string, orderNumber?: string },
  ) => dispatch(getSurvey(params)),
  setSurveyObject: (survey: object) => dispatch(setSurveyObject(survey)),
  setSurveyQuestionName: (questionName: string) => dispatch(setSurveyQuestionName(questionName)),
  setPersonalData: (data: object) => dispatch(setPersonalData(data)),
  createPatient: (data: object, source: string) => dispatch(createPatient(data, source)),
  setChosenTimeSlot: (slot: Date) => dispatch(setChosenTimeSlot(slot)),
  createConsultation: (
    onCreateSuccess: () => void,
    onSlotUnavailable: () => void,
  ) => dispatch(createConsultation(
    onCreateSuccess,
    onSlotUnavailable
  )),
  setChosenMedium: (medium: string) => dispatch(setChosenMedium(medium)),
  setChosenConsultType: (
    consultationType: string,
  ) => dispatch(setChosenConsultType(consultationType)),
  setFocusedSpecialty: (
    consultationSpecialty: string,
  ) => dispatch(setFocusedSpecialty(consultationSpecialty)),
  setOpenConsultationDoctor: (
    openConsultationDoctor: string | undefined
  ) => {
    if (openConsultationDoctor) {
      return dispatch(getOpenConsultationDoctor(openConsultationDoctor))
    }
    return dispatch(resetOpenConsultationDoctor())
  },
  setSocialSecurityMode: (socialSecurityMode?: boolean) => dispatch(setSocialSecurityMode(socialSecurityMode)),
  sendConsultationPayment: ({
    data,
    orderNumber,
    onPaymentSuccess,
    onPaymentError,
  }: {
    data: T.PaymentPayload,
    orderNumber: string,
    onPaymentSuccess: () => void,
    onPaymentError: () => void,
  }) =>
    dispatch(sendConsultationPayment({ data, orderNumber, onPaymentSuccess, onPaymentError })),
  updateConsultationWithSSN: (onCreateSuccess: () => void) => dispatch(updateConsultationWithSSN(onCreateSuccess)),
  uploadSurvey: (
    orderNumber: string | undefined,
    survey: object,
  ) => dispatch(uploadSurvey(orderNumber, survey)),
  updatePatientData: (data: object) => dispatch(updatePatientData(data)),
  didStartPrePaymentSurvey: () => dispatch(didStartPrePaymentSurvey()),
  acceptInvitation: (data: any, onSuccess: () => void) => dispatch(acceptInvitation(data, onSuccess)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)

import React from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'

import bullet90Days from 'assets/icons/med-gabarit-icon.svg'
import bulletSupport from 'assets/icons/med-gabarit-icon-doctor.svg'
import bulletRhythm from 'assets/icons/large-gabarit-icon.svg'
import bulletImprovement from 'assets/icons/thumb-up.svg'
import howAssistance from 'assets/icons/academy/master/menPhone.jpeg'
import howProgression from 'assets/icons/academy/master/blueStep.jpeg'
import howStepByStep from 'assets/images/iphone-mockup-digital-therapy.png'
import whyMental from 'assets/images/yoga-old-man.jpg'
import whyPhysics from 'assets/images/yoga-man.jpg'
import whyRhythm from 'assets/images/new-gilbert-couch.jpg'
import franceInfo from 'assets/icons/logo/france-info.svg'
import franceInter from 'assets/icons/logo/france-inter.svg'
import leMonde from 'assets/icons/logo/le-monde.svg'
import gq from 'assets/icons/logo/gq.svg'

// This is a function because I18n is not available until redux state is set
const buildData: () => T.ProgramPresentation = () => ({
  about: [
    {
      items: [
        {
          text: I18n.t('academy.time-program.about.question.item1.text'),
          details: I18n.t('academy.time-program.about.question.item1.details'),
        },
        {
          text: I18n.t('academy.time-program.about.question.item2.text'),
          details: I18n.t('academy.time-program.about.question.item2.details'),
        },
        {
          text: I18n.t('academy.time-program.about.question.item3.text'),
          details: I18n.t('academy.time-program.about.question.item3.details'),
        },
      ],
    }],
  press: [
    {
      icon: gq,
    },
    {
      icon: franceInter,
    },
    {
      icon: franceInfo,
    },
    {
      icon: leMonde,
    },
  ],
  arguments: [
    {
      icon: bulletImprovement,
      label: I18n.t('academy.time-program.presentation.bullets.improvement'),
    },
    {
      icon: bullet90Days,
      label: I18n.t('academy.time-program.presentation.bullets.days'),
    },
    {
      icon: bulletRhythm,
      label: I18n.t('academy.time-program.presentation.bullets.rhythm'),
    },
    {
      icon: bulletSupport,
      label: I18n.t('academy.time-program.presentation.bullets.support'),
    },
  ],
  howItWorks: [
    {
      icon: howStepByStep,
      title: I18n.t('academy.time-program.presentation.howItWorks.steps.title'),
      label: I18n.t('academy.time-program.presentation.howItWorks.steps.label'),
    },
    {
      icon: howAssistance,
      title: I18n.t('academy.time-program.presentation.howItWorks.assistance.title'),
      label: I18n.t('academy.time-program.presentation.howItWorks.assistance.label'),
    },
    {
      icon: howProgression,
      title: I18n.t('academy.time-program.presentation.howItWorks.progression.title'),
      label: I18n.t('academy.time-program.presentation.howItWorks.progression.label'),
    },
  ],
  whyIsItEfficient: [
    {
      icon: whyPhysics,
      title: I18n.t('academy.time-program.presentation.whyIsItEfficient.physics.title'),
      label: I18n.t('academy.time-program.presentation.whyIsItEfficient.physics.label'),
    },
    {
      icon: whyMental,
      title: I18n.t('academy.time-program.presentation.whyIsItEfficient.mental.title'),
      label: I18n.t('academy.time-program.presentation.whyIsItEfficient.mental.label'),
    },
    {
      icon: whyRhythm,
      title: I18n.t('academy.time-program.presentation.whyIsItEfficient.rhythm.title'),
      label: I18n.t('academy.time-program.presentation.whyIsItEfficient.rhythm.label'),
    },
  ],
  faq: [
    {
      items: [
        {
          text: I18n.t('academy.time-program.faq.faq1.title'),
          details: I18n.t('academy.time-program.faq.faq1.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq2.title'),
          details: I18n.t('academy.time-program.faq.faq2.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq3.title'),
          details: I18n.t('academy.time-program.faq.faq3.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq4.title'),
          details: I18n.t('academy.time-program.faq.faq4.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq5.title'),
          details: I18n.t('academy.time-program.faq.faq5.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq6.title'),
          details: I18n.t('academy.time-program.faq.faq6.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq7.title'),
          details: I18n.t('academy.time-program.faq.faq7.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq8.title'),
          details: I18n.t('academy.time-program.faq.faq8.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq9.title'),
          details: I18n.t('academy.time-program.faq.faq9.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq10.title'),
          details: I18n.t('academy.time-program.faq.faq10.content'),
        },
        {
          text: I18n.t('academy.time-program.faq.faq11.title'),
          details: I18n.t('academy.time-program.faq.faq11.content'),
        },
      ],
    },
  ],
})

export default buildData

import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { SvgIcon, CreatePasswordForm, ContactForm, Button } from 'components'
import { isEmpty, isNil, isUndefined, includes, map, some, get } from 'lodash'
import Step, { StepProps, StepHead, StepFooter } from './Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { surveyCss, buildSurvey } from './survey'
import tracker from 'utils/trackers'
import T from 'types'
import ONBOARDING_STEPS from './steps'
import { I18n } from 'react-redux-i18n'
import { specialtyI18NKey } from 'helpers/doctor-type'
import { getIcon } from 'assets/icons'

interface IProps extends RouteComponentProps<any>, StepProps {
  doctor: T.Doctor,
  consultation: T.Consultation | null,
  order: T.OrderV1 | null,
  medium: string,
  survey: Model,
  questionName: string,
  isRecovery: boolean,
  invitationToken: string | null,
  invitationAcceptedAt: Date | null,
  createMessage: (userId: number, body: string, patientUserId: number) => void,
  prefix: string,
  uploadSurvey: (orderNumber: string | undefined, survey: Model) => void,
  acceptInvitation: (data: any, onSuccess: () => void) => void,
  goNext: () => void,
  goPrevious: () => void,
  patientAttributes: object,
}

interface IState {
  isComplete: boolean,
  shouldDisplayContactForm: boolean,
}

export default class StepSurveyFinish extends Component<IProps, IState> {
  model: Model

  constructor(props: IProps) {
    super(props)
    const { medium } = props

    this.state = {
      isComplete: false,
      shouldDisplayContactForm: medium === 'chat',
    }

    const { survey, prefix } = props

    this.model = buildSurvey(survey, prefix)
  }

  private onValueChanged(result: any) {
    return void 0
  }

  private handleMessageSubmit(message: string) {
    const { order, createMessage, doctor } = this.props

    if (isUndefined(order) || isNil(order)) {
      console.error('T.OrderV1 is missing in store')
    } else {
      const userId = (order.userId as number)
      createMessage(doctor.userId, message, userId)
    }

    this.setState({ shouldDisplayContactForm: false })
  }

  private onComplete(survey: Model) {
    const { consultation, uploadSurvey } = this.props
    if (!isNil(consultation)) {
      uploadSurvey(consultation.orderNumber, survey.data)
    }
    this.setState({ isComplete: true })
  }

  private onAfterRenderQuestion(sender: Model, options: any) {
    tracker.trackQuestionView(sender, ONBOARDING_STEPS.SURVEY_FINISH, options)
  }

  private renderSurvey() {
    const { survey, prefix } = this.props
    const { isComplete } = this.state

    if (isEmpty(survey)) {
      return <Fragment />
    }

    if (isComplete) {
      this.model.doComplete()
    }

    return (
      <Survey
        data={survey}
        css={surveyCss}
        model={this.model}
        onValueChanged={(result: any) => this.onValueChanged(result)}
        onComplete={(survey: Model) => this.onComplete(survey)}
        onAfterRenderQuestion={this.onAfterRenderQuestion}
      />
    )
  }

  public render() {
    const { steps, goNext, goPrevious, patientAttributes, invitationToken, invitationAcceptedAt, acceptInvitation } = this.props
    const { isComplete, shouldDisplayContactForm } = this.state

    const email = get(patientAttributes, 'email')
    if (!isComplete) {
      return (
        <Step steps={steps} goPrevious={goPrevious}>
          {this.renderSurvey()}
        </Step>
      )
    }
    if (shouldDisplayContactForm) {
      return (
        <Step steps={steps} goPrevious={goPrevious}>
          <LayoutStep>
            <LayoutMain variant="small">
              <StepHead title={I18n.t(`onboarding.contactForm.${specialtyI18NKey(undefined)}.stepTitle`)} />
              <ContactForm onSubmit={message => this.handleMessageSubmit(message)} />
            </LayoutMain>
          </LayoutStep>
        </Step>
      )
    }
    if (!isNil(invitationAcceptedAt)) {
      return (
        <Step steps={steps} goPrevious={goPrevious}>
          <LayoutStep>
            <LayoutMain variant="small">
              <SvgIcon icon={getIcon('clinic.calendar')} classname="booking-payment-confirmed-icon" />
              <StepHead title={'Votre rendez-vous est confirmé'} />
              <p>
                Cliquez ici pour vous connecter à votre espace patient sécurisé et accéder à votre rendez-vous.
              </p>
              <a className="mt-4 btn btn-primary m-auto" href="/me.html#/">Se connecter</a>
            </LayoutMain>
          </LayoutStep>
        </Step>
      )
    }
    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <LayoutStep>
          <LayoutMain variant="small">
            <div className="block-container login-container without-header m-auto">
              <div className="block-content">
                <div className="login-block">
                  <StepHead title={'Première connexion'} />
                  <p className="phone-info">
                    Votre numéro de téléphone sera utilisé pour vous connecter de manière sécurisée à votre compte
                  </p>
                  <CreatePasswordForm
                    patientAttributes={patientAttributes}
                    invitationToken={invitationToken}
                    onSubmit={(data: any) => acceptInvitation(data, () => goNext())}/>
                </div>
              </div>
            </div>
          </LayoutMain>
        </LayoutStep>
      </Step>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { appendMessage } from 'actions'
import { isNil, get } from 'lodash'
import { I18n } from 'react-redux-i18n'
import {
  Chatbox,
  MainContainer,
} from 'containers'
import { cableFactory } from 'helpers/actioncable-helper'
import T from 'types'
import '../styles.sass'

let room: any

interface IProps {
  appendMessage: (message: T.Message) => void,
  doctor?: T.LightDoctor,
  pharmacist?: T.LightPharmacist,
  doctoruserId: number,
  pharmacistuserId: number,
}

class Chat extends React.Component<IProps> {
  cable: any

  constructor(props: IProps) {
    super(props)

    this.cable = cableFactory()
  }
  componentDidMount() {
    this.initSocket()
  }

  componentWillUnmount() {
    if (room) {
      this.cable.subscriptions.remove(room)
    }
  }

  initSocket() {
    const { appendMessage } = this.props

    room = this.cable.subscriptions.create(
      { channel: 'MessagesChannel' },
      {
        connected: () => null,
        create: () => null,
        received: (message: T.Message) => appendMessage(message),
      },
    )
  }

  public render() {
    const { doctor, pharmacist } = this.props

    return (
      <MainContainer>
        <div className="page">
          {(isNil(pharmacist)) ? (
            <p>{I18n.t('ordersHistory.emptyOrder')}</p>
          ) : (null)
          }
          <div className="chatrooms">
            {pharmacist && (
              <div>
                <Chatbox
                  title={'Échanger avec votre pharmacien'}
                  notice={I18n.t('pharmacy.deliverySupportInformation')}
                  userId={pharmacist.userId}
                  />
              </div>
            )}
          </div>
        </div>
      </MainContainer>
    )
  }
}

const mapStateToProps = ({
  auth: {
    profile: {
      doctor,
      pharmacist,
    },
  },
}: T.Store) => ({
  doctor,
  pharmacist,
})

const mapDispatchToProps = (dispatch: any) => ({
  appendMessage: (message: T.Message) =>
    dispatch(appendMessage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)

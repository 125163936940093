import React from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'

const buildData: () => T.ProgramPresentation = () => ({
  about: [
  ],
  press: [
    {
      icon: 'gq',
    },
    {
      icon: 'franceInter',
    },
    {
      icon: 'franceInfo',
    },
    {
      icon: 'leMonde',
    },
  ],
  arguments: [
  ],
  howItWorks: [
  ],
  whyIsItEfficient: [
  ],
  faq: [
    {
      items: [
        {
          text: I18n.t('academy.digital-therapy.faq.faq1.title'),
          details: I18n.t('academy.digital-therapy.faq.faq1.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq2.title'),
          details: I18n.t('academy.digital-therapy.faq.faq2.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq3.title'),
          details: I18n.t('academy.digital-therapy.faq.faq3.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq4.title'),
          details: I18n.t('academy.digital-therapy.faq.faq4.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq5.title'),
          details: I18n.t('academy.digital-therapy.faq.faq5.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq6.title'),
          details: I18n.t('academy.digital-therapy.faq.faq6.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq7.title'),
          details: I18n.t('academy.digital-therapy.faq.faq7.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq8.title'),
          details: I18n.t('academy.digital-therapy.faq.faq8.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq9.title'),
          details: I18n.t('academy.digital-therapy.faq.faq9.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq10.title'),
          details: I18n.t('academy.digital-therapy.faq.faq10.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq11.title'),
          details: I18n.t('academy.digital-therapy.faq.faq11.content'),
        },
        {
          text: I18n.t('academy.digital-therapy.faq.faq12.title'),
          details: I18n.t('academy.digital-therapy.faq.faq12.content'),
        },
      ],
    },
  ],
})

export default buildData

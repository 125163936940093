import { get, isArray, isNil, first, isObject, isUndefined, join, values, cloneDeep, isEmpty } from 'lodash'
import * as A from 'constants/actions'
import { buildPatientAttributes, getPaymentMethod } from 'utils/helpers'
import store from '../store'
import { showNotification, startSecureChallenge } from './ux'
import { ApiService } from 'services'
import T from 'types'
import { isCharlesTherapy, defaultSpecialty } from 'helpers/new-consultation-helper'
import tracker from 'utils/trackers'

export const sendConsultationPayment = ({
  data,
  orderNumber,
  onPaymentSuccess,
  onPaymentError
}: {
  data: T.PaymentPayload,
  orderNumber: string,
  onPaymentSuccess: () => void,
  onPaymentError: () => void,
}) => {
  const consultationId = get(store.getState().consultations.focusedItem, 'id')
  return {
    meta: {
      onSuccess: (response: { data: string }) => {
        const paymentMethods = get(data, 'paymentMethod')
        const gatewaySecureModeRedirectUrl = get(response, 'order.gatewaySecureModeRedirectUrl')
        if (isNil(gatewaySecureModeRedirectUrl)) {
          store.dispatch(showNotification('success', 'Paiement effectué avec succès'))
          onPaymentSuccess()
        } else {
          if (paymentMethods && 'paypal' in paymentMethods) {
            store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface Paypal'))
          } else {
            store.dispatch(showNotification('info', 'Vous allez être redirigé vers l’interface 3DSecure de votre banque'))
          }
          if (!isUndefined(consultationId)) {
            const successActionCallback = () => {
              onPaymentSuccess();
              // This should be refactored, we should not have to throw an dummy action
              return ({ type: "DUMMY_USELESS_ACTION" })
            }
            const errorActionCallback = () => {
              onPaymentError();
              // This should be refactored, we should not have to throw an dummy action
              return ({ type: "DUMMY_USELESS_ACTION" })
            }
            store.dispatch(startSecureChallenge({
              gatewaySecureModeRedirectUrl,
              successAction: successActionCallback,
              failureAction: errorActionCallback
            }))
          }
        }
      },
      onFailure: (response: { data: string; status: number; statusText: string }) => {
        const messages = get(response, 'data.errors.base') || [get(response, 'data.error')]
        if (isUndefined(messages) || isEmpty(messages)) {
          store.dispatch(showNotification('error', 'Une erreur est survenue'))
        } else {
          store.dispatch(showNotification('error', messages.join(' ')))
        }
      },
    },
    type: A.SEND_CONSULTATION_PAYMENT,
    promise: ApiService.finalizeOrderPaymentV2({
      data,
      orderNumber,
    }).then(({ data: order }) => ({
      order: {
        ...order,
            success: true
          },
          paymentMethod: getPaymentMethod(data)
    }))
  }
}

export const createConsultationFromProfile = (onCreateSuccess: () => void) => {
  const charlesTherapy = isCharlesTherapy()
  const charlesTherapySpecialty = defaultSpecialty()

  const currentState = store.getState()
  const onboarding = currentState.onboarding
  // this variable is used only for non medical charles consultations
  const consultations = currentState.consultations.collection

  const consultationType = onboarding.consultationType || 'simple'
  const surveySubmission = onboarding.survey
  const consultationSpecialty = onboarding.consultationSpecialty
  const surveyId = onboarding.surveyId

  const patientAttributes = buildPatientAttributes({...currentState.auth.profile, ...currentState.onboarding.patientAttributes})

  const consultationPayload = {
    patientAttributes,
    appointmentAt: currentState.doctors.slot,
    doctorId: currentState.doctors.doctor.id,
    surveyId: surveyId,
    surveySubmission: surveySubmission,
    consultationType: consultationType,
    communicationChannel: onboarding.medium,
    specialtySlug: consultationSpecialty
  }
  return {
    meta: {
      onSuccess: () => {
        if (onCreateSuccess) {
          onCreateSuccess()
        }
      },
      onFailure: () => {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      },
    },
    type: A.SET_CONSULTATION_FROM_PROFILE,
    promise: ApiService.createConsultation(consultationPayload).then(({ data }) => ({
      consultation: data,
    })),
  }
}

export const createConsultation = (onCreateSuccess: () => void, onSlotUnavailable: () => void) => {
  const currentState = store.getState()
  const onboarding = currentState.onboarding
  const consultationPayload = {
    appointmentAt: currentState.doctors.slot,
    doctorId: currentState.doctors.doctor.id,
    surveyId: onboarding.surveyId,
    surveyUuid: onboarding.surveyUuid,
    surveySubmission: onboarding.survey,
    specialtySlug: onboarding.consultationSpecialty,
    consultationType: onboarding.consultationType,
    communicationChannel: onboarding.medium,
    patientId: onboarding.patientAttributes.id,
  }
  return {
    meta: {
      onSuccess: () => {
        if (onCreateSuccess) {
          onCreateSuccess()
        }
        const survey = store.getState().onboarding.survey
        const patient = store.getState().onboarding.patientAttributes
        const uuid = get(store.getState().consultations.focusedItem, 'patientUserUuid')
        const traits = {
          birthday: patient.birthDate,
          email: patient.email,
          firstName: patient.firstName,
          lastName: patient.lastName,
          gender: survey.gender,
          phone: patient.phoneNumber,
        }
        if (uuid) {
          tracker.identifyUser(uuid, traits)
        }
      },
      onFailure: (resp: any) => {
        const errorMessage = isObject(resp.data.message)
          ? values(resp.data.message)
          : resp.data.message
        const message = isArray(errorMessage) ? join(errorMessage, ', ') : errorMessage

        store.dispatch(showNotification('error', `Une erreur est survenue : ${message}`))

        if (resp.data.code == 'SLOT_UNAVAILABLE' && onSlotUnavailable)
          onSlotUnavailable()
      },
    },
    type: A.SET_CONSULTATION,
    promise: ApiService.createConsultation(consultationPayload).then(({ data }) => ({
      consultation: data,
    })),
  }
}

export const updateConsultationWithSSN = (onUpdateSuccess: () => void) => {
  const currentState = store.getState()
  const consultationId = get(currentState.consultations.focusedItem, 'id')
  const patientId = get(currentState.consultations.focusedItem, 'patientId')
  const ssn = currentState.onboarding.patientAttributes.ssn
  if(!consultationId || !patientId)
    return
  return {
    meta: {
      onSuccess: () => {
        if (onUpdateSuccess) {
          onUpdateSuccess()
        }
        const uuid = get(store.getState().consultations.focusedItem, 'patientUserUuid')
        const traits = {
          nir: "validated"
        }
        if (uuid) {
          tracker.identifyUser(uuid, traits)
        }
      },
      onFailure: (resp: any) => {
        const errorMessage = isObject(resp.data.message)
          ? values(resp.data.message)
          : resp.data.message
        const message = isArray(errorMessage) ? join(errorMessage, ', ') : errorMessage

        store.dispatch(showNotification('error', `Une erreur est survenue : ${message}`))
      },
    },
    type: A.UPDATE_CONSULTATION_WITH_SSN,
    promise: ApiService.updateConsultation(consultationId, patientId, ssn).then(({ data }) => ({
      consultation: data,
    })),
  }
}

export const getConsultations = () => ({
  type: A.GET_CONSULTATIONS,
  promise: ApiService.getConsultations().then(({ data }) => ({ consultations: data })),
})

export const storePaidConsultationSpecialty = (specialties: any[]) => ({
  type: A.STORE_PAID_CONSULTATION_SPECIALTY,
  payload: specialties,
})

export const storePendingConsultationSpecialty = (specialties: any[]) => ({
  type: A.STORE_PENDING_CONSULTATION_SPECIALTY,
  payload: specialties,
})

export const updateFocusedConsultation = (consultationId: string) => {
  return {
    meta: {
      onSuccess: (resp: any) => {
        const { consultation } = resp

        if (!isUndefined(consultation) && consultation.orderState === 'payment') {
          store.dispatch(showNotification('error', 'Une erreur est survenue et le paiement a échoué'))
        }
      },
    },
    type: A.UPDATE_FOCUSED_CONSULTATION,
    promise: ApiService.getConsultation(consultationId.toString()).then(({ data }) => ({ consultation: data })),
  }
}

export const updateConsultation = (consultation: T.Consultation) => ({
  consultation,
  type: A.UPDATE_CONSULTATION,
})

export const getLastConsultation = () => ({
  type: A.GET_LAST_CONSULTATION,
  promise: ApiService.getConsultations({ limit: 1 }).then(({ data }) => ({ consultations: data })),
})

export const getConsultationById = (consultationId: number) => ({
  type: A.GET_PRESCRIPTIONS,
  promise: ApiService.getConsultation(`${consultationId}`).then(({ data }) => ({ consultation: data }))
})

import React, { Component, RefObject } from 'react'
import classnames from 'classnames'
import { map } from 'lodash'
import './styles.sass'
import cx from 'classnames'
import { I18n } from 'react-redux-i18n'
import T from 'types'

import SvgIcon from 'components/SvgIcon'
import chatChevronUp from 'assets/icons/chat-chevron-down.svg'
import chatChevronDown from 'assets/icons/chat-chevron-up.svg'


interface IProps {
  displayMessage(message: T.Message): any,
  refreshMessages(consultation: T.Consultation): any,
  renderSendForm(): any,
  handleSelectConsultation(consultation: T.Consultation | null): any,
  consultation: T.Consultation,
  conversationMessages: T.Message[],
  showPatientNotification: boolean,
  selectedConsultation: T.Consultation | null,
  chatMessagesList: RefObject<HTMLDivElement>
  actionLoading?: string[]
}

class ChatboxV2ConsultMobile extends Component<IProps> {

  public render() {
    const { consultation, selectedConsultation, showPatientNotification, conversationMessages, chatMessagesList, handleSelectConsultation, displayMessage, renderSendForm } = this.props
    const isSelected = selectedConsultation && consultation.id === selectedConsultation.id

    return (
      <div className="ongoing-chat mb-4" key={`mobile-consultation-${consultation.id}`}>
        <div className="ongoing-chat-doctor">
          <div className={cx("ongoing-chats-doctor d-flex align-items-center nav-link flex-row", { active: isSelected })}>
            <img className="doctor-image mr-2" src={consultation.doctorAvatarUrl} alt={consultation.doctor} />
            <div className="doctor-right flex-grow-1">
              {consultation.lastChatMessage && consultation.lastChatMessage.isUnread && <div className="unread-message">Nouveau message</div>}
              <div className="doctor-right-name">{consultation.doctor}</div>
              <div className="doctor-right-specialty">{(consultation.fullDoctor && consultation.fullDoctor.speciality) || 'Praticien'}</div>
              <div className={cx("doctor-toggle font-weight-bold mt-2", { 'is-open': isSelected })}>
                {isSelected ?
                  <div className="d-flex justify-content-between" onClick={() => handleSelectConsultation(null)}>
                    <span>Réduire la conversation</span>
                    <SvgIcon icon={chatChevronDown} />
                  </div> :
                  <div className="d-flex justify-content-between" onClick={() => handleSelectConsultation(consultation)}>
                    <span>Ouvrir la conversation</span>
                    <SvgIcon icon={chatChevronUp} />
                  </div>}
              </div>
            </div>
          </div>
        </div>
        {isSelected && <div className="ongoing-chat-footer">
          <div className="chatroom chatbox-v2">
            <div ref={chatMessagesList} className="chat-list">
              {map(conversationMessages, message => displayMessage(message))}
            </div>
            <p className={classnames('chat-patient-notification', { active: showPatientNotification })}>
              {I18n.t('chat.consultation.sentMessage')}
            </p>
            {renderSendForm()}
          </div>
        </div>}
      </div>
    )
  }
}

export default ChatboxV2ConsultMobile

import React, { Component } from 'react'
import { compact, first, get, includes, map } from 'lodash'
import build from 'redux-object'
import cx from 'classnames'

import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { getNumberOfPayments, organizeProgramVariants } from 'utils/models/variants'

import { money } from 'utils/models/money'
import ProgramVariantPaymentForm from 'page/AcademyFunnel/ProgramVariantPaymentForm'
import { enrollmentForProgram } from 'utils/data/academy'

import './styles.sass'

interface IProps {
  patientAttributes?: any
  program: T.Program
  academy: T.AcademyStore
  storefront: T.Storefront
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  goNext: () => void
  goPrevious?: () => void
  getPaymentMethods: () => void
  rootVariantId?: number
  order: T.OrderV2
}

interface IState {
  selectedVariantId?: number
  showSelectedVariant: boolean
}

export default class AcademyPaymentForPatients extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      showSelectedVariant: false,
      selectedVariantId: undefined,
    }
  }

  public getPriceOfVariant(variant: T.Variant) {
    const selected = includes(map(this.props.order.variants, variant => variant.id), variant.id)
    const promoAmount = money(selected ? this.props.order.promoTotal : '0')
    return money(variant.price).add(promoAmount)
  }

  renderMenuItem(variant: T.Variant) {
    const { order } = this.props
    const numberOfPayments = getNumberOfPayments(variant)
    const isStepByStepPaymentVariant = numberOfPayments > 3
    const isOneShotPayment = numberOfPayments === 1
    const isSplitPaymentVariant = !isStepByStepPaymentVariant && !isOneShotPayment

    let price
    let title
    if (isSplitPaymentVariant) {
      title = `En ${numberOfPayments} fois`
      price = `${numberOfPayments} x ${this.getPriceOfVariant(variant).toFormat()}`
    } else if (isStepByStepPaymentVariant) {
      title = 'Étape par étape'
      price = this.getPriceOfVariant(variant).toFormat()
    } else {
      title = 'En 1 fois'
      price = this.getPriceOfVariant(variant).toFormat()
    }

    return (
      <label
        className={cx('step-academy-payment-submenu-block', {
          selected: this.state.selectedVariantId === variant.id,
        })}
      >
        <div className="step-academy-payment-submenu-title">
          {title}
          <div className="step-academy-payment-submenu-price">{price}</div>
        </div>
        <div className="step-academy-payment-submenu-right">
          <input
            type="radio"
            name="variantSelection"
            value={`${variant.id}`}
            className="step-academy-payment-submenu-radio-input"
            checked={this.state.selectedVariantId === variant.id}
            onChange={e => this.handleSelectVariant(variant.id)}
          />
        </div>
      </label>
    )
  }

  public componentDidMount() {
    const { selectedVariantId } = this.state
    const { program, academy, rootVariantId } = this.props
    const filteredVariants = variantsToShow(program, academy, rootVariantId)
    if (filteredVariants[0]) this.handleSelectVariant(filteredVariants[0].id)
  }

  public render() {
    const {
      academy,
      patientAttributes,
      program,
      goPrevious,
      storefront,
      goNext,
      applyCoupon,
      purchase,
      getPaymentMethods,
      order,
    } = this.props
    const { selectedVariantId, showSelectedVariant } = this.state

    const { loading } = academy
    const variants = program.product.variantsIncludingMaster
    const filteredVariants = variantsToShow(program, academy, this.props.rootVariantId)
    return (
      <div>
        {filteredVariants.length > 1 && (
          <div className="step-academy-payment-submenu">
            {filteredVariants.map((variant: T.Variant, index: number) => (
              this.renderMenuItem(variant)
            ))}
          </div>
        )}
        <div className="step-academy-payment-variants">
          {filteredVariants.map((variant: T.Variant, index: number) => (
            selectedVariantId === variant.id && (
              <div>
                <ProgramVariantPaymentForm
                  key={`program_payment_variant_${index}`}
                  patientAttributes={patientAttributes}
                  variant={variant}
                  loading={loading}
                  academy={academy}
                  order={order}
                  storefront={storefront}
                  program={program}
                  show={showSelectedVariant && selectedVariantId === variant.id}
                  purchase={purchase}
                  goNext={goNext}
                  getPaymentMethods={getPaymentMethods}
                  applyCoupon={applyCoupon}
                />
              </div>
            )
          ))}
        </div>
      </div>
    )
  }

  private handleSelectVariant = (variantId: number) => {
    const { selectProgramVariant, order } = this.props
    const orderToken = order.token

    this.setState({ showSelectedVariant: true })
    if (!includes(map(this.props.order.variants, variant => variant.id), variantId)) {
      selectProgramVariant(variantId, orderToken, () => {
        this.setState(
          { selectedVariantId: variantId,
          })
      })
    }
    this.setState({ selectedVariantId: variantId })
  }
}

const variantsToShow = (program: T.Program, academy: any, selectedVariantId: number | undefined): T.Variant[] => {
  const enrollment = enrollmentForProgram(academy, program)
  const hasFreeEpisode = get(enrollment, 'hasFreeEpisode') == true
  const forPatients = hasFreeEpisode ? false : true
  const { stepByStepVariant, fullSplitPayment, fullOneTimePayment } = organizeProgramVariants(
    program,
    forPatients,
    hasFreeEpisode,
  )
  const isStepByStepSelected = stepByStepVariant && stepByStepVariant.id == selectedVariantId

  if (isStepByStepSelected) {
    return [stepByStepVariant]
  } else {
    return compact([fullOneTimePayment, fullSplitPayment])
  }
}

import React, { Component, Fragment } from 'react'
import { get } from 'lodash'
import { RouteComponentProps } from 'react-router'
import 'react-phone-number-input/style.css'
import 'rc-tooltip/assets/bootstrap.css'
import { I18n } from 'react-redux-i18n'
import Step, { StepProps, StepHead } from 'page/common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Button } from 'components'
import ProgramArguments from './ProgramArguments'
import tracker from 'utils/trackers'
import T from 'types'
import * as SC from 'utils/models/schemas/constants'
import { createSchema } from 'utils/models/schemas/academyPersonalInformation'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import cx from 'classnames'
import { IS_CHARLES_PLATFORM, API_BASE_URL } from '../../constants/config'

const VAGINISME = 'vaginisme-program'
interface IProps extends RouteComponentProps<any>, StepProps {
  loading: boolean
  academy: T.AcademyStore
  program: T.Program
  setPersonalData: (data: object) => void
  enrollProgram: (slug: string) => void
  goNext: () => void
  goPrevious: () => void
  setSurveyQuestionName?: (questionName: string) => void
  termsOfService: boolean
  patientAttributes: object
}

export default class StepAcademyPersonalInfos extends Component<IProps> {
  private handleGlobalSubmit = (data: any) => {
    const { program, academy, enrollProgram, goNext } = this.props
    const { email, firstName, lastName, programSlug } = data
    const cleanEmail = email.trim()

    if (programSlug === VAGINISME) {
      enrollProgram(academy.onboarding.slug)
      goNext()
    }
    else {
      const signupRoute = new URL(`${API_BASE_URL}academy_registrations/new`)
      signupRoute.searchParams.append('slug', programSlug)
      signupRoute.searchParams.append('spree_user[email]', cleanEmail)
      window.location.href = signupRoute.toString()
    }
  }

  public render() {
    const { loading, academy, patientAttributes, steps, goPrevious, setPersonalData, program } = this.props
    const title = <span>1. Vos informations</span>
    let platformName = 'Charles.co'
    let dataPolicyLink = 'https://www.charles.co/information-consentement/'
    if (!IS_CHARLES_PLATFORM) {
      platformName = 'Mia'
      dataPolicyLink = 'https://mia.co/conditions-generales/'
    }
    const schema = createSchema((data: any) => {
      setPersonalData(data)
      tracker.trackAcademyLead(program)
    })
    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <div className="personal-info-step">
          <Formik
            validationSchema={schema}
            onSubmit={this.handleGlobalSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              email: get(patientAttributes, 'email', ''),
              firstName: get(patientAttributes, 'firstName', ''),
              lastName: get(patientAttributes, 'lastName', ''),
              cguChecked: false,
              programSlug: get(program, 'slug')
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <LayoutStep>
                  <LayoutMain>
                    <h3>Entrez votre e-mail</h3>
                    <Fragment>
                      <div className="onboarding-small-inner-container">
                        <Form.Group controlId="validationFormik03">
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.email}
                            placeholder={I18n.t(`onboarding.personnalDataPlaceholders.email`)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email && (
                              <>
                                {errors.email === SC.UNAVAILABLE_EMAIL_KEY ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: I18n.t(
                                        `onboarding.personnalData.error.betterPriceAvailableError`,
                                      ),
                                    }}
                                  />
                                ) : (
                                  errors.email
                                )}
                              </>
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <div className="small text-center text-lg-left">
                          <Form.Group>
                            <Form.Check id={'validationFormik04'}>
                              <Form.Check.Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.cguChecked}
                                checked={values.cguChecked}
                                name="cguChecked"
                              />
                              <Form.Check.Label className="no-color-on-errors">
                                En continuant, je consens à la politique de{' '}
                                <a href={dataPolicyLink} target="_blank">
                                  traitement des données
                                </a>{' '}
                                de {platformName}
                              </Form.Check.Label>
                              <Form.Control.Feedback type="invalid">
                                {errors.cguChecked}
                              </Form.Control.Feedback>
                            </Form.Check>
                          </Form.Group>
                        </div>

                        <Button
                          classname="mx-auto mt-3 onboarding-payment-cta"
                          type="submit"
                          loading={loading}
                        >
                          Valider
                        </Button>
                      </div>
                    </Fragment>
                  </LayoutMain>
                </LayoutStep>
              </Form>
            )}
          </Formik>
        </div>
      </Step>
    )
  }
}

import { Block, Button, SvgIcon } from 'components'
import { I18n } from 'react-redux-i18n'
import React, { FC, useEffect, useRef, useState } from 'react'
import T from 'types'
import OptionPicker from 'components/OptionPicker'
import './styles.sass'
import ToggleCheckbox from 'components/ToggleCheckbox'
import { getIcon } from 'assets/icons'
import { productImagePath } from 'utils/helperComponents/phytoHelpers'
import { findVariant } from 'utils/models/variants'
import { money } from 'utils/models/money'

type IProps = {
  phyto: any,
  handleSelectedPhyto: (variant: T.Variant | null) => void,
}

const VariantOptionCard: FC<IProps> = (props: IProps) => {
  const { phyto, handleSelectedPhyto } = props
  const { product } = phyto
  const monthRange = product.subscriptionFrequencies.map((item: any) => { return item.monthsCount })
  const [month, setMonth] = useState(phyto.recommendedDuration)
  const [selected, setSelected] = useState(true)
  const [isUnique, setIsUnique] = useState(false)
  const carousel = useRef<HTMLDivElement>(null)

  const handleCardNavigation = (direction: string) => {
    if (carousel.current) {
      const width = carousel.current.offsetWidth + 32
      carousel.current.scrollTo({
        left: direction === 'left' ? -width : width,
        behavior: 'smooth',
      })
    }
  }

  // get price depending on the selected month & subscription frequency
  const getAccurateProductPrice = (product: any, optionValues: any) => {
    const variant = findVariant(product.variantsIncludingMaster, optionValues)
    if (variant) {
      return variant.displayPrice
    }
    return product.displayPrice
  }

  useEffect(() => {
    const options: any = { 'pills-per-box': month }
    if (!isUnique) { options.subscribable = 'subscribable' }
    const selectedVariant = findVariant(product.variantsIncludingMaster, options)
    if (selectedVariant) { handleSelectedPhyto(selected ? selectedVariant : null) }
  },        [month, isUnique, selected])

  return (
    <Block classname="phyto-card">
      <div ref={carousel} className="phyto-card-carousel-part" style={!selected ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        <div className="phyto-card-blocks phyto-card-first-block">
          <img src={productImagePath(product)} alt="" className="phyto-card-image" />
          <h3 className="mb-2">{product.name}</h3>
          <a
            className="mb-2 phyto-card-information d-none d-md-inline-block"
            href={I18n.t(
              `prescriptions.productsInformation.${product.slug}.pathToDescription`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            Télécharger la fiche informative
          </a>
          <div className="phyto-card-description mb-3">
            {I18n.t(`prescriptions.productsInformation.${product.slug}.indication`)}
          </div>
          <div className="phyto-card-disclaimer mb-3">
            {I18n.t(`prescriptions.productsInformation.${product.slug}.contraindication`)}
          </div>
          <a
            className="d-md-none mb-2 phyto-card-information"
            href={I18n.t(
              `prescriptions.productsInformation.${product.slug}.pathToDescription`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            Fiche informative
          </a>
          {/*TODO make renouvelable dynamic*/}
          <div className="d-none mb-md-3 d-md-inline-block"><span className="font-weight-semi">Traitement choisi</span> : {month} mois {!isUnique ? 'renouvelable' : 'livraison unique'}</div>
          <Button variant="btn-outline-primary" classname="d-flex phyto-card-next-page-button" onClick={() => handleCardNavigation('right')}>
            <span className="phyto-card-next-page-button-text">Modifier la durée et le mode de livraion</span>
            <SvgIcon icon={getIcon('clinic.chevron')} classname="phyto-card-next-page-button-icon" />
          </Button>
        </div>
        <div className="phyto-card-blocks phyto-card-second-block">
          <h4>Choix de la durée de votre traitement :</h4>
          <OptionPicker range={monthRange} defaultIndex={monthRange.indexOf(month)} unit="mois" handleClick={month => setMonth(month)} />
          <div className="phyto-card-recommended">Recommandé par votre médecin</div>
          <div className="phyto-card-price-options">
            <div className="phyto-card-price-options-item">
              <div className="phyto-card-price-options-item-title" onClick={() => setIsUnique(false)}>
                <input checked={!isUnique} type="radio" />
                <label htmlFor="renew">{getAccurateProductPrice(product, { 'pills-per-box': month, subscribable: 'subscribable' })} Renouvelable</label>
              </div>
              <div className="phyto-card-price-options-item-description">
                {I18n.t('unified-checkout.phytotherapy.renewPriceDescription')}
              </div>
            </div>
            <div className="phyto-card-price-options-item">
              <div className="phyto-card-price-options-item-title" onClick={() => setIsUnique(true)}>
                <input checked={isUnique} className="" type="radio" />
                <label htmlFor="unique">{getAccurateProductPrice(product, { 'pills-per-box': month })} Livraison Unique</label>
              </div>
              <div className="phyto-card-price-options-item-description">
                {I18n.t('unified-checkout.phytotherapy.uniquePriceDescription')}
              </div>
            </div>
          </div>
          <Button variant="btn-outline-primary" classname="phyto-card-button mb-3 d-none d-md-flex">
            {I18n.t('unified-checkout.phytotherapy.confirmChoice')}
          </Button>
          <div className="back-to-phyto-description d-none d-md-flex" onClick={() => handleCardNavigation('left')}>
            {I18n.t('unified-checkout.phytotherapy.backToDescription')}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="phyto-card-toggle-title">Suivre la recommandation</div>
        <ToggleCheckbox handleToggle={(bool: boolean) => setSelected(bool)} />
      </div>
      {/*<div>{phyto.molecule.toString()} {selected.toString()}</div>*/}
    </Block>
  )
}

export default VariantOptionCard

import React, { Component, Fragment } from 'react'
import { get, isEmpty, isNil } from 'lodash'
import moment from 'constants/moment'
import { RouteComponentProps } from 'react-router'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { I18n } from 'react-redux-i18n'
import Step, { StepProps, StepHead, StepFooter } from './Step'
import { Button } from 'components'
import tracker from 'utils/trackers'
import * as SC from 'utils/models/schemas/constants'
import { createSchema } from 'utils/models/schemas/personalInformation'
import MaskedInput from 'react-text-mask'
import { Formik } from 'formik'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TARGET_PLATFORM } from 'constants/config'

interface IProps extends RouteComponentProps<any>, StepProps {
  loading: boolean
  setPersonalData: (data: object) => void
  createPatient: (data: object, source: string) => void
  goNext: () => void
  goPrevious: () => void
  setSurveyQuestionName?: (questionName: string) => void
  createConsultation: (onCreateSuccess: () => void, onSlotUnavailable: () => void) => void
  termsOfService: boolean
  patientAttributes: object
  specialty: string
}
export default class StepPersonalInfos extends Component<IProps, any> {
  public handleGlobalSubmit = (data: any) => {
    const { goNext } = this.props

    goNext()
  }

  public render() {
    const { setPersonalData, patientAttributes, steps, goPrevious, specialty } = this.props
    const title = (
      <span>
        Transmettez vos coordonnées au {TARGET_PLATFORM == 'mia' ? 'sexologue' : 'médecin'}
      </span>
    )
    const schema = createSchema((data: any) => {
      setPersonalData(data)
      tracker.trackLead(specialty)
    })
    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <div className="personal-info-step">
          <Formik
            validationSchema={schema}
            onSubmit={this.handleGlobalSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              firstName: get(patientAttributes, 'firstName', ''),
              lastName: get(patientAttributes, 'lastName', ''),
              phoneNumber: get(patientAttributes, 'phoneNumber', ''),
              birthDate: moment(get(patientAttributes, 'birthDate', '')).format(
                SC.DATE_INITIAL_VALUE_FORMAT,
              ),
              email: get(patientAttributes, 'email', ''),
              cguAccepted: get(patientAttributes, 'cguAccepted', false),
              specialty: specialty
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>

                <div className="onboarding-container-bloc">
                  <StepHead title={title} />
                  <Fragment>
                    <div className="onboarding-small-inner-container">
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>{I18n.t(`onboarding.personnalData.firstName`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.firstName}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.firstName`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik02">
                        <Form.Label>{I18n.t(`onboarding.personnalData.lastName`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.lastName}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.lastName`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik03">
                        <Form.Label>{I18n.t(`onboarding.personnalData.email`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.email}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.email`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email && (
                            <>
                              {errors.email === SC.UNAVAILABLE_EMAIL_KEY ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: I18n.t(
                                      `onboarding.personnalData.error.emailNotAvailableError`,
                                    ),
                                  }}
                                />
                              ) : (
                                errors.email
                              )}
                            </>
                          )}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik03">
                        <Form.Label>{I18n.t(`onboarding.personnalData.birthDate`)}</Form.Label>
                        <Form.Control
                          autoComplete="bday"
                          as={MaskedInput}
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          placeholderChar=" "
                          type="tel"
                          name="birthDate"
                          value={values.birthDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.birthDate}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.birthDate`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.birthDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik04">
                        <Form.Label>
                          {I18n.t(`onboarding.personnalData.phoneNumber`)}
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="why-phone-is-required">{I18n.t(`onboarding.personnalData.tooltips.phoneNumber`)}</Tooltip>}
                          >
                            <span className="tooltip-info-icon ml-1">i</span>
                          </OverlayTrigger>
                        </Form.Label>
                        <PhoneInput
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.phoneNumber`)}
                          numberInputProps={{ className: 'form-control' }}
                          value={values.phoneNumber}
                          onChange={(value: string) => {
                            setFieldValue('phoneNumber', value)
                          }}
                          onBlur={handleBlur}
                          defaultCountry="FR"
                          countryOptionsOrder={[
                            'FR',
                            'GP',
                            'GF',
                            'MQ',
                            'YT',
                            'NC',
                            'PF',
                            'RE',
                            'BL',
                            'MF',
                            'PM',
                            '|',
                            '...',
                          ]}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="onboarding-medium-inner-container">
                      <Form.Group>
                        <Form.Check name="cguAccepted" id="validationFormik10">
                          <Form.Check.Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.cguAccepted}
                            checked={values.cguAccepted}
                            name="cguAccepted"
                          />
                          <Form.Check.Label
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html: I18n.t('onboarding.personnalData.cgu'),
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cguAccepted}
                          </Form.Control.Feedback>
                        </Form.Check>
                      </Form.Group>
                    </div>
                  </Fragment>
                </div>
                <StepFooter>
                  <Button
                    classname="onb-next-btn"
                    type="submit"
                  // loading={loading} // TODO V2 Loading
                  >
                    Valider
                  </Button>
                  <Button
                    variant="btn-outline-primary"
                    classname="onb-prev-btn"
                    onClick={() => this.handlePrevious()}
                    type="button"
                  >
                    Précédent
                  </Button>
                </StepFooter>
              </Form>
            )}
          </Formik>
        </div>
      </Step>
    )
  }

  private handlePrevious() {
    const { goPrevious, setSurveyQuestionName } = this.props
    setSurveyQuestionName && setSurveyQuestionName('lastPage')
    goPrevious()
  }
}

import T from 'types'
import build from 'redux-object'
import { find } from 'lodash'

export const attendanceForLesson = (academy: T.AcademyStore, lesson: T.Lesson): T.Attendance | undefined => {
  const attendances = build(academy, 'attendance')
  const attendance = find(attendances, attendance => attendance.lesson.id == lesson.id)
  return attendance
}

export const enrollmentForProgram = (academy: T.AcademyStore, program: T.Program): T.Enrollment | undefined => {
  const enrollments = build(academy, 'enrollment') || []
  const enrollment = find(enrollments, (enrollment: any) => enrollment.program.id == program.id)
  return enrollment
}

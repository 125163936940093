import { connect } from 'react-redux'
import { hideNotification, showNotification } from 'actions'
import { Notifications } from 'components'
import T from 'types'

const mapStateToProps = (
  {
    ux: { notificationShow },
    ux: { notificationText },
    ux: { classNotification },
  }: T.Store) => ({
    notificationShow,
    classNotification,
    notificationText,
  })

const mapDispatchToProps = (dispatch: Function) => ({
  showNotification: (classNotification: string, value: string) =>
    dispatch(showNotification(classNotification, value)),
  hideNotification: () => dispatch(hideNotification()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)

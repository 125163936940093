import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { LoginForm, Navbar, Sidebar } from 'containers'
import { sample, sumBy, get, map, isEmpty } from 'lodash'
import Button from 'components/Button'
import build from 'redux-object'
import cx from 'classnames'
import T from 'types'

import wowtechProductData from 'page/Pages/WowtechProduct/products.json'

import './styles.sass'
import SvgIcon from '../../../components/SvgIcon'
import CartIcon from '../../../assets/icons/shopping-cart.svg'

interface IProps extends RouteComponentProps<any>{
  storefront: T.Storefront
  getCart: () => void
  removeFromCart: (itemId : number) => void
  setVariantQuantity: (lineItemId: number, quantity: number) => void
}

interface IState {
  sidebarExpanded: boolean
  filteringOutBadItems: boolean
}

class CartSlidableContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      sidebarExpanded: false,
      filteringOutBadItems: false
    }
  }

  componentDidMount() {
    const { getCart } = this.props
    getCart()
  }

  componentDidUpdate() {
    const { storefront } = this.props;

    const cart = build(storefront, 'cart') || []
    const currentCart = sample(cart)

    if (currentCart && currentCart.lineItems)
      this.handleFilterBadItems(currentCart.lineItems)
  }

  beginCheckout(cart: T.Cart) {
    const { history } = this.props
    history.push(`/checkout/${cart.number}`)
  }

  expandSidebar() {
    this.setState({sidebarExpanded: true})
  }

  hideSidebar() {
    this.setState({sidebarExpanded: false})
  }

  handleLineItemQuantityChange(e: React.FormEvent<HTMLSelectElement>, item: T.LineItem) {
    const newQuantity = parseInt(e.currentTarget.value);
    const { setVariantQuantity } = this.props;

    if (newQuantity > 0)
      setVariantQuantity(item.id, newQuantity)
  }

  productImagePath = (productData: any, key : string) => {
    const path = get(productData, key)

    if (!path)
      return '' // TODO - use placeholder

    return `wow/${path}`
  }

  quantityDropdown = (item: T.LineItem) : JSX.Element => {
    let options : JSX.Element[] = [];
    const maxQuantity = item.quantity > 10 ? item.quantity : 10

    for (let i = 1; i <= maxQuantity; i++)
      options.push(<option value={i} key={`quantity_dropdown_${i}`}>{i}</option>)

    return <select
        value={item.quantity}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => this.handleLineItemQuantityChange(e, item)}
      >
        { options }
      </select>
  }

  // TODO -- handle phyto / programs in the future
  lineItemRow(item: T.LineItem) {
    const { removeFromCart } = this.props;

    const productData = get(wowtechProductData, item.slug);

    if (productData)
      return <div className="line-item-container" key={`cart_slidable_item_${item.id}`}>
        <img src={this.productImagePath(productData, 'images.product_1')} className="line-item-image" />

        <div className="line-item-body">
          <h5>{item.name}</h5>
          <p>{productData.subtitle}</p>

          <div className="line-item-body-bottom">
            { this.quantityDropdown(item)}

            <span className="line-item-total">
              {item.displayTotal}
            </span>
          </div>
        </div>
        <div className="line-item-remove">
          <a onClick={() => removeFromCart(item.id)} className="">X</a>
        </div>
      </div>

    // Its not a wowtech product
    return <div className="line-item-container" key={`cart_slidable_item_${item.id}`}>
        <div className="line-item-image" />
        <div className="line-item-body">
          <h5>{item.name}</h5>
          <p>{}</p>

          <div className="line-item-body-bottom">
            <span className="line-item-total">
              {item.displayTotal}
            </span>
          </div>
        </div>
        <div className="line-item-remove">
          <a onClick={() => removeFromCart(item.id)} className="">X</a>
        </div>
    </div>
  }

  handleFilterBadItems(items: T.LineItem[]) {
    const { removeFromCart } = this.props;
    const { filteringOutBadItems } = this.state;

    if (filteringOutBadItems)
      return;

    this.setState({filteringOutBadItems: true})

    items.map((item : T.LineItem) => {
      if (!(item.variant.product && map(item.variant.product.taxons, (taxon: T.Taxon) => taxon.name).includes('wowtech'))) {
        console.error("Item has invalid type, removing from cart...", item)
        removeFromCart(item.id)
      }
    })
  }

  public render() {
    const { storefront } = this.props
    const { sidebarExpanded } = this.state

    const cart = build(storefront, 'cart') || []
    const currentCart = sample(cart)

    if (currentCart && currentCart.lineItems) {
      const quantity = sumBy(currentCart.lineItems, 'quantity')

      return <>
        <div className="d-flex justify-content-end">
          <a onClick={() => this.expandSidebar()} className="expand-sidebar-cta" >
            <div>Mon panier</div>
            <SvgIcon icon={CartIcon} classname="expand-sidebar-cart-icon" />
            <div className="expand-sidebar-quantity">
              {quantity}
            </div>
          </a>
        </div>
        <a onClick={() => this.hideSidebar()} className="" >
          <div className={cx('expandable-sidebar-overlay', 'fade', 'modal-backdrop', {'show': sidebarExpanded, 'd-none': !sidebarExpanded})}/>
        </a>
        <div className={cx("expandable-sidebar-container", {'d-none': !sidebarExpanded})}>
          <div className="expandable-sidebar">
            <div className="expandable-sidebar-header">
              <a onClick={() => this.hideSidebar()} className="float-right">X</a>
              <h3>Mon panier</h3>
            </div>
            <div className="expandable-sidebar-body">
              { currentCart.lineItems.map((item : any, index : number) => this.lineItemRow(item)) }
            </div>
            <div className="expandable-sidebar-footer">
              {
                !isEmpty(currentCart.lineItems) && (
                  <Button onClick={() => this.beginCheckout(currentCart)}>Finaliser la commande - {currentCart.displayTotal}</Button>
                ) || (<p>Votre panier est vide</p>)
              }
            </div>
          </div>
        </div>
      </>
    }
    return null
  }
}

export default withRouter(CartSlidableContainer)

import { isUndefined } from 'lodash'
import React, { memo, Component } from 'react'
import T from 'types'
import NavbarStepper from 'page/common/NavbarStepper'

type StepHeadProps = {
  title?: string | JSX.Element,
  index?: number,
  isComplete?: boolean,
}

const StepHead = memo(({ title, isComplete }: StepHeadProps) => {
  if (isComplete) {
    return null
  }
  if (isUndefined(title)) {
    return null
  }
  return (
    <div className="step-header">
      <h3 className="step-title">{title}</h3>
    </div>
  )
})

type StepFooterProps = {
  children: React.ReactNode
}

const StepFooter = ({ children }: StepFooterProps) => (
  <div className="onboarding-container-footer">
    <div className="onboarding-container-footer-content">
      {children}
    </div>
  </div>
)

export { StepHead, StepFooter }

export interface StepProps {
  children?: React.ReactNode
  steps: T.StepperStep[]
  goPrevious: Function
  currentStep?: number
}

export default class Step extends Component<StepProps> {

  public render() {
    const { children, steps, goPrevious, currentStep } = this.props
    return (
      <div>
        <NavbarStepper
          steps={steps}
          goPrevious={goPrevious}
          currentStep={currentStep}
        />
        <div
          className="container onboarding-container"
          id="onboarding-container"
        >
          {children}
        </div>
      </div>
    )
  }
}

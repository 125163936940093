import React, { Component, Fragment, RefObject } from 'react'
import { I18n } from 'react-redux-i18n'
import classnames from 'classnames'
import moment from 'constants/moment'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { get } from 'lodash'
import MaskedInput from 'react-text-mask'
import Block from '../Block'
import T from 'types'
import './styles.sass'
import InputText from '../InputText' // TODO V2 REFACTOR - check all usage
import schema from 'utils/models/schemas/profile'
import { Formik } from 'formik'
import { Col, Form } from 'react-bootstrap'
import { Button } from 'components'

interface IProps {
  title: string
  profile: T.Profile
  classname?: string
  cta?: T.Cta
  onSubmit: (data: any) => void
  children?: JSX.Element | JSX.Element[] | string
  buttonText?: string
}

class PersonalInfo extends Component<IProps> {
  private handleSubmit = (data: any) => {
    const { onSubmit } = this.props
    onSubmit(data)
  }

  public render() {
    const { title, cta, classname, onSubmit, buttonText, profile } = this.props
    return (
      <Block title={title} classname={classnames(classname, 'container-m-800')}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: get(profile, 'firstName', ''),
            lastName: get(profile, 'lastName', ''),
            phoneNumber: get(profile, 'phoneNumber', '') || '',
            birthDate: moment(get(profile, 'birthDate', '')).format('DD/MM/YYYY'),
            email: get(profile, 'email', ''),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fragment>
                <Form.Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>{I18n.t(`onboarding.personnalData.firstName`)}</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.firstName}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.firstName`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>{I18n.t(`onboarding.personnalData.lastName`)}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.lastName}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.lastName`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>{I18n.t(`onboarding.personnalData.birthDate`)}</Form.Label>
                      <Form.Control
                        type="tel"
                        name="birthDate"
                        autoComplete="birthday"
                        as={MaskedInput}
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        placeholderChar=" "
                        value={values.birthDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.birthDate}
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.birthDate`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.birthDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="validationFormik04">
                      <Form.Label>{I18n.t(`onboarding.personnalData.phoneNumber`)}</Form.Label>
                      <PhoneInput
                        placeholder={I18n.t(`onboarding.personnalDataPlaceholders.phoneNumber`)}
                        numberInputProps={{ className: 'form-control' }}
                        value={values.phoneNumber}
                        onChange={(value: string) => {
                          setFieldValue('phoneNumber', value)
                        }}
                        onBlur={handleBlur}
                        defaultCountry="FR"
                        countryOptionsOrder={[
                          'FR',
                          'GP',
                          'GF',
                          'MQ',
                          'YT',
                          'NC',
                          'PF',
                          'RE',
                          'BL',
                          'MF',
                          'PM',
                          '|',
                          '...',
                        ]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Group controlId="validationFormik03">
                  <Form.Label>{I18n.t(`onboarding.personnalData.email`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.email}
                    placeholder={I18n.t(`onboarding.personnalDataPlaceholders.email`)}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Button
                  classname="btn btn-primary onb-next-btn"
                  type="submit"
                  // loading={loading} // TODO V2 Loading
                >
                  Mettre à jour
                </Button>
              </Fragment>
            </Form>
          )}
        </Formik>
      </Block>
    )
  }
}

export default PersonalInfo

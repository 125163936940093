import { connect } from 'react-redux'
import {
  getAuthProfile,
  showNotification,
  redirectAfterLogin,
  redirectedToUrlAfterLogin,
  toggleSidebar,
  storeOneTimeAuthToken,
} from 'actions'
import Me from 'page/Me'
import T from 'types'

const mapStateToProps = ({ auth: { signedIn, profile }, ux: { sidebarActive, redirectUrlAfterLogin } }: T.Store) => ({
  signedIn,
  sidebarActive,
  profile,
  redirectUrlAfterLogin,
})

const mapDispatchToProps = (dispatch: Function) => ({
  showNotification: (type: string, content: string) =>
    dispatch(showNotification(type, content)),
  setCurrentProfile: () => dispatch(getAuthProfile()),
  redirectAfterLogin: (url?: string) => dispatch(redirectAfterLogin(url)),
  redirectedToUrlAfterLogin: () => dispatch(redirectedToUrlAfterLogin()),
  closeSidebar: () => dispatch(toggleSidebar(false)),
  storeOneTimeAuthToken: (authToken: string | null) => dispatch(storeOneTimeAuthToken(authToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Me)

import React, { Component, Fragment } from 'react'
import { get, isUndefined } from 'lodash'
import classnames from 'classnames'
import { Button, Block, AnyPrescriptionContent } from '..'
import T from 'types'
import './styles.sass'
import moment from 'constants/moment'
import { IS_CHARLES_PLATFORM } from 'constants/config'

interface IProps {
  title: string
  cta?: T.Cta
  lastPrescription: T.Prescription
  getPrescriptions: () => void
  onClick: (p: T.Prescription) => void
}

// TODO CLEAN UP - DEAD CODE
class LastPrescription extends Component<IProps> {
  public componentDidMount() {
    const { getPrescriptions } = this.props

    getPrescriptions()
  }

  private renderBtn(prescription: T.Prescription) {

    const { onClick } = this.props
    const expireAt = moment(prescription.expiredAt)
    const prescriptionStatus = isUndefined(prescription)
      ? 'none'
      : get(prescription, 'canBeReleased')
        ? 'validated'
        : 'exhaust'

    if (isUndefined(prescription)) {
      return <Fragment />
    }

    if (prescriptionStatus === 'exhaust') {
      if (expireAt.diff(moment()) > 0) {
        return (
          <Button classname="yellow disabled" disabled={true}>
            Commande maximale mensuelle atteinte
          </Button>
        )
      }
      return (
          <Button classname="yellow disabled" disabled={true}>
            Cette ordonnance est expirée
          </Button>
      )

    }

    return (
      <div className={classnames('last-consultation')}>
        <Button onClick={() => onClick(prescription)}>
          Me faire livrer à domicile depuis une pharmacie
        </Button>
      </div>
    )
  }

  public render() {
    const { title, cta, lastPrescription } = this.props

    if (!IS_CHARLES_PLATFORM) return null

    return (
      <Block icon="blocks.pharmacy" title={title} cta={cta} classname="treatment">
        <AnyPrescriptionContent prescription={lastPrescription} />
        {this.renderBtn(lastPrescription)}
      </Block>
    )
  }
}

export default LastPrescription

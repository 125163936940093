import classnames from 'classnames'
import moment from '../../../constants/moment'
import React from 'react'

type Props = {
  slot: Date
  isActive: boolean
  isAvailable: boolean
  handleClick: (e: React.MouseEvent<HTMLElement>, slot: Date) => void
}

const Slot = ({ slot, isActive, isAvailable, handleClick }: Props) => {
  if (!isAvailable) {
    return (
      <div
        className={classnames('btn btn-outline-primary btn-sm disabled')}
      >
        <del>{moment(slot).format('LT')}</del>
      </div>
    )
  } else {
    return (
      <div
        className={classnames('btn btn-outline-primary btn-sm', { active: isActive })}
        onClick={e => handleClick(e, slot)}
      >
        {moment(slot).format('LT')}
      </div>
    )
  }
}

export default Slot

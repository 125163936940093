import { connect } from 'react-redux'
import DeliverPrescription from 'page/Pages/DeliverPrescription'
import {
  getPrescriptions,
  getAuthProfile,
  createPrescriptionDelivery,
  finalizePrescriptionDelivery,
} from 'actions'
import T from 'types'

const mapStateToProps = ({
  prescriptions: { focusedPrescription, focusedDeliveryOrder, loading },
  auth: { profile },
}: T.Store) => ({
  profile,
  loading,
  prescription: focusedPrescription,
  order: focusedDeliveryOrder,
})

const mapDispatchToProps = (dispatch: Function) => ({
  setCurrentProfile: () => dispatch(getAuthProfile()),
  getPrescriptions: () => dispatch(getPrescriptions()),
  createPrescriptionDelivery: (
    prescription: T.Prescription,
    data: T.Forms.ShippingAddress,
    onSuccess: () => void,
  ) => dispatch(createPrescriptionDelivery(prescription, data, onSuccess)),
  finalizePrescriptionDelivery: (orderNumber: string, onSuccess: () => void) =>
    dispatch(finalizePrescriptionDelivery(orderNumber, onSuccess)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliverPrescription)

import { connect } from 'react-redux'
import { getCompletedOrders, getPrescriptions, getProducts, getTaxons } from 'actions'
import OrdersHistory from 'page/Pages/OrdersHistory'
import T from 'types'

const mapStateToProps = ({ storefront, prescriptions: { lastPrescription } }: T.Store) => ({
  storefront,
  lastPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getCompletedOrders: () => dispatch(getCompletedOrders()),
  getPrescriptions: () => dispatch(getPrescriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory)

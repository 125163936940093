import React, { Fragment } from 'react'
import { get, map, some } from 'lodash'
import { Block } from 'components'
import { renderConsultationsBody } from 'utils/helpers'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import { Row, Col } from 'react-bootstrap'

import T from 'types'
import { CookiesHelper } from 'helpers'

interface IProps {
  consultations: any[]
  prescriptions: T.Prescription[]
}

class ConsultationList extends React.Component<IProps> {

  public render() {

    return (
      <Fragment>
        {this.renderConsultations()}
      </Fragment>
    )
  }

  private renderConsultations = () => {
    const { consultations } = this.props

    return (
      <>
        <h4 className="consultation-history-title" >Historique des téléconsultations</h4>
        <Block title="">
          {map(consultations, consultation => this.renderConsultation(consultation))}
        </Block>
      </>
    )
  }

  private renderConsultation = (consultation: Object) => {
    const { prescriptions } = this.props
    const shouldDisplayLinkToAdvices = IS_CHARLES_PLATFORM && get(consultation, 'categorySlug') === "sexual" && some(
      prescriptions,
      prescription => prescription.canBeDownloaded,
    )
    const reportUrl = get(consultation, 'reportUrl')
    const links = []
    if (reportUrl) {
      links.push(
        <a
          className="for-download"
          target="_blank"
          href={`${reportUrl}?access_token=${CookiesHelper.getPureOauthAccessToken()}`}
        >
          Compte-rendu
        </a>,
      )
    }

    if (shouldDisplayLinkToAdvices) {
      links.push(
        <a
          className="for-download"
          target="_blank"
          href="https://www.charles.co/fiche-conseils-psycho-sexo.pdf"
        >
          Fiche conseils
        </a>,
      )
    }
    return (
      <Row key={get(consultation, 'id')} className="consultations-history-row">
        {renderConsultationsBody(consultation, links)}
      </Row>
    )
  }
}

export default ConsultationList

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Block, Loader } from 'components'
import { MainContainer } from 'containers'
import T from 'types'
import build from 'redux-object'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import CheckoutSummary from './CheckoutSummary'
import CheckoutShipping from './CheckoutShipping'
import CheckoutPayment from './CheckoutPayment'
import CheckoutConfirmation from './CheckoutConfirmation'
import './styles.sass'

const STEPS = [
  {
    path: 'livraison',
    renderer: (props: any) => <CheckoutShipping {...props} />,
  }, {
    path: 'validation',
    renderer: (props: any) => <CheckoutSummary {...props} />,
  }, {
    path: 'paiement',
    renderer: (props: any) => <CheckoutPayment {...props} />,
  }, {
    path: 'confirmation',
    renderer: (props: any) => <CheckoutConfirmation {...props} />,
  }
]
const buildUrl = (url: any, path: string) => `${url}/${path}`

interface IProps extends RouteComponentProps<any> {
  getOrderFromNumber: (orderNumber: string) => void,
  purchaseWith3dsChallenge: (
    token: IToken,
    data: T.PaymentPayload,
    onSuccess: () => void
  ) => void
  createDelivery: (
    data: T.Forms.ShippingAddress,
    onSuccess: () => void
  ) => void
  applyCoupon: (coupon: string, orderToken: string) => void
  getPaymentMethods: () => void
  storefront: T.Storefront
  auth: T.Auth
  collectionPrescription: T.Prescription[]
}

type IState = {
  // currentStep: number
}

class Checkout extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    const { getPaymentMethods } = this.props
    // Todo v2 - maybe remove some of those calls
    getPaymentMethods()

    const { getOrderFromNumber } = this.props
    const { match } = this.props
    const orderNumber = match.params.number

    getOrderFromNumber(orderNumber)
  }

  goNext(step: number): void {

    const { history, match } = this.props
    const url = match.url
    const nextStep = step + 1
    const nextStepObject = STEPS[nextStep]

    //-- tracker.trackOnboardingStepView(nextStep)
    history.push(buildUrl(url, nextStepObject.path))
  }

  goPrevious(step: number): void {
    const { history } = this.props
    history.goBack()
  }

  render() {

    const { storefront, match } = this.props
    const { loading } = storefront
    const orderNumber = match.params.number.toString()
    const url = match.url
    const orders = build(storefront, 'cart') || []
    const order = orders.find((currentOrder: any) => currentOrder.number === orderNumber)
    const goNext = (step: number) => () => this.goNext(step)
    const goPrevious = (step: number) => () => this.goPrevious(step)
    const firstAllowedStep = buildUrl(url, STEPS[0].path)

    return (
      <MainContainer>
        <div className="page pharmacy">
          <div className="home-container">
            <div className="infos-container">
              <Switch>
                {STEPS.map((step, index) =>
                  <Route
                    key={`route_${index}`}
                    path={buildUrl(url, step.path)}
                    render={props => step.renderer({
                      ...props,
                      ...this.props,
                      order,
                      goNext: goNext(index),
                      goPrevious: goPrevious(index),
                    })}
                  />)}
                <Redirect from="*" to={firstAllowedStep} />
              </Switch>
            </div>
          </div>
        </div>

      </MainContainer>
    )
  }
}

export default withRouter(Checkout)

import React, { Component } from 'react'

import T from 'types'
import { isEmpty, map } from 'lodash'
import { SvgIcon, BlockHeader, PrescriptionCard } from 'components'
import { getIcon, icons } from 'assets/icons'
import './styles.sass'
import Carousel, { CarouselSlide } from 'components/Carousel'

interface IProps {
  onPrescriptionClick: (p: T.Prescription) => void
  prescriptions: T.Prescription[],
  title: string,
  icon: string,
  rightTitleCta?: React.ReactNode
}

export default class PrescriptionCarousel extends Component<IProps> {

  public render() {
    const { prescriptions, title, icon, onPrescriptionClick, rightTitleCta } = this.props

    return isEmpty(prescriptions) ? null : (
      <div className="home-page-carousel">
        <BlockHeader title={title} icon={icon}>
          { rightTitleCta }
        </BlockHeader>
        <Carousel
          breakpoints={{}}
          slidesPerView="auto"
          spaceBetween={20}
          stretchItems={true}
        >
          {map(prescriptions, (prescription: T.Prescription, index: number) => (
            <CarouselSlide key={`prescription_slide_${index}`} className="">
              <PrescriptionCard
                prescription={prescription}
                onClick={() => onPrescriptionClick(prescription)}
              />
            </CarouselSlide>
          ))}
        </Carousel>
      </div>
    )
  }
}


// breakpoints={{
//   320: {
//     slidesPerView: 1,
//     spaceBetween: 20
//   },
//   450: {
//     slidesPerView: 1.5,
//     spaceBetween: 20
//   },
//   750: {
//     slidesPerView: 2.5,
//     spaceBetween: 20
//   },
//   1050: {
//     slidesPerView: 3.5,
//     spaceBetween: 20
//   },
//   1350: {
//     slidesPerView: 4.5,
//     spaceBetween: 20
//   },
//   1650: {
//     slidesPerView: 5.5,
//     spaceBetween: 20
//   },
//   1950: {
//     slidesPerView: 6.5,
//     spaceBetween: 20
//   },
// }}

import React, { Component } from 'react'
import cx from 'classnames'
import { get, reverse, some, sortBy } from 'lodash'
import NewCreditCardForm from './NewCreditCardForm'
import RegisteredCreditCardForm from './RegisteredCreditCardForm'

import T from 'types'
import moment from 'constants/moment'

interface IProps {
  cgu?: string
  terms?: string
  loading?: boolean
  profile: T.Profile
  submitPayment: (p: T.PaymentPayload) => void
  paymentMethod: T.PaymentMethodHash
  paymentButtonLabel: string
  applyCoupon?: (coupon: string) => void
  activeCoupon?: string
}

interface IState {
  paymentMethod: string
}

class PaymentForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      paymentMethod: this.hasValidRegisteredCreditCards(props.profile)
        ? 'registered_credit_card'
        : 'credit_card',
    }
  }

  private hasValidRegisteredCreditCards(profile: T.Profile): boolean {
    return some(profile.creditCards, creditCard => !RegisteredCreditCardForm.isCardExpired(creditCard))
  }

  private hasRegisteredCreditCards(profile: T.Profile): boolean {
    return ((profile.creditCards && profile.creditCards.length) || 0) > 0
  }

  public render() {
    const { profile, cgu, submitPayment, paymentButtonLabel, paymentMethod, loading, applyCoupon, activeCoupon } = this.props
    const hasRegisteredCreditCards = this.hasRegisteredCreditCards(profile)

    return (
      <div>
        {this.state.paymentMethod === 'registered_credit_card' && (
          <div>
            <RegisteredCreditCardForm
              profile={profile}
              cgu={cgu}
              loading={loading}
              submitPayment={submitPayment}
              paymentButtonLabel={paymentButtonLabel}
              onAddNewCard={() => this.setState({ paymentMethod: 'credit_card' })}
              applyCoupon={applyCoupon}
              activeCoupon={activeCoupon}
            />
          </div>
        )}
        {this.state.paymentMethod === 'credit_card' && (
          <div>
            <NewCreditCardForm
              cgu={cgu}
              loading={loading}
              submitPayment={submitPayment}
              paymentMethod={paymentMethod}
              defaultFirstName={get(profile, 'firstName', '')}
              defaultLastName={get(profile, 'lastName', '')}
              paymentButtonLabel={paymentButtonLabel}
              onUseExistingCard={hasRegisteredCreditCards ? () => this.setState({ paymentMethod: 'registered_credit_card' }) : undefined}
              applyCoupon={applyCoupon}
              activeCoupon={activeCoupon}
            />
          </div>
        )}
      </div>
    )
  }
}

export default PaymentForm

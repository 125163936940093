import React, { Component, Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import T from 'types'
import { findIndex } from 'lodash'
import IntercomWrapper from '../IntercomWrapper'
import StepSurvey from '../StepSurvey'
import StepPersonalInfos from '../StepPersonalInfos'
import StepConsultationChoices from '../StepConsultationChoices'
import StepPreConsultationSetup from '../StepPreConsultationSetup'
import StepSurveyFinish from '../StepSurveyFinish'
import StepWelfare from '../StepWelfare'
import StepPayment from '../StepPayment'
import PropTypes from 'prop-types'
import { Model } from 'survey-core'
import tracker from 'utils/trackers'

const CATEGORY = 'Onboarding Consultation'

const ORIGINAL_STEPS = [
  {
    label: 'Questionnaire',
    path: '/questions'
  },
  {
    label: 'Informations',
    path: '/informations'
  },
  {
    label: 'Réservation',
    path: '/choix'
  },
  {
    label: 'Paiement',
    path: '/paiement'
  },
  {
    label: 'Dossier médical',
    path: '/dossier-medical'
  },
  {
    label: 'Fin',
    path: '/fin',
    hideInStepper: true
  },
]
const STEPS_WITH_SSN = [
  {
    label: 'Questionnaire',
    path: '/questions'
  },
  {
    label: 'Informations',
    path: '/informations'
  },
  {
    label: 'Réservation',
    path: '/choix'
  },
  {
    label: 'Remboursement',
    path: '/remboursement'
  },
  {
    label: 'Paiement',
    path: '/paiement'
  },
  {
    label: 'Dossier médical',
    path: '/dossier-medical'
  },
  {
    label: 'Fin',
    path: '/fin',
    hideInStepper: true
  },
]

interface IProps {
  history: any
  returnFrom3DS?: boolean
  consultationPaymentSuccess: boolean | undefined
  prefixStepSurvey: string
  prefixSurveyFinish: string
  specialty: string
  loaded: boolean
  termsOfService: boolean
  loading: boolean
  collection: any[]
  availableChannels: string[]
  storefront: T.Storefront
  consultation: T.Consultation | null
  consultationType: string
  doctor: T.Doctor
  survey: Model
  surveyId: number
  surveyFetchedAt?: Date
  isRecovery: boolean
  questionName: string
  patientAttributes: object
  order: T.OrderV1 | null
  slot: Date
  skipDoctorSelection: boolean
  socialSecurityMode: boolean
  invitationToken: string | null
  invitationAcceptedAt: Date | null
  medium: string
  createMessage: (userId: number, body: string, patientUserId: number) => void
  setTermsOptin: () => void
  getDoctors: () => void
  updatePatientData: (data: any) => void
  getCommunicationChannels: () => void
  createConsultation: (onCreateSuccess: () => void, onSlotUnavailable: () => void) => void
  getSurvey: (
    {
      pdid,
      email,
      orderNumber,
    }: {
      pdid?: string
      email?: string
      orderNumber?: string
    },
  ) => void
  setSurveyQuestionName: (question: string) => void
  setSurveyObject: (survey: object) => void
  getPaymentMethods: () => void
  acceptInvitation: (data: any, onSuccess: () => void) => void
  uploadSurvey: (orderNumber: string | undefined, survey: object) => void
  setPersonalData: (data: object) => void
  createPatient: (data: object, source: string) => void
  setChosenTimeSlot: (value: Date) => void
  setChosenDoctor: (doctor: T.Doctor) => void
  setChosenMedium: (medium: string) => void
  setChosenConsultType: (type: string) => void
  setOpenConsultationDoctor: (slug: string | undefined) => void
  sendConsultationPayment: (
    {
      data,
      orderNumber,
      onPaymentSuccess,
      onPaymentError
    }: {
      data: T.PaymentPayload
      orderNumber: string
      onPaymentSuccess: () => void
      onPaymentError: () => void
    },
  ) => void
  setFocusedSpecialty: (specialty: string) => void
  updateConsultationWithSSN: (onCreateSuccess: () => void) => void
  didStartPrePaymentSurvey: () => void
}

type TrackingListenerProps = {
  children: any
}

class TrackingListener extends React.Component<TrackingListenerProps> {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    this.sendPageView(this.context.router.history.location)
    this.context.router.history.listen(this.sendPageView)
  }

  sendPageView(location: Location) {
    tracker.trackPageViewV2(CATEGORY, location.pathname)
  }

  render() {
    return this.props.children
  }
}

export default class OnboardingSteps extends Component<IProps> {
  getSteps(): T.StepperStep[] {
    const { socialSecurityMode } = this.props
    return socialSecurityMode ? STEPS_WITH_SSN : ORIGINAL_STEPS
  }

  goNext(step: number): void {
    const { history } = this.props
    const nextStep = step + 1
    const steps: T.StepperStep[] = this.getSteps()
    history.push(steps[nextStep].path)
    tracker.trackOnboardingStepView(nextStep)
  }

  goPrevious(step?: number): void {
    const { history } = this.props
    history.goBack()
  }

  getStepIndex(path: string): number {
    const steps: T.StepperStep[] = this.getSteps()
    return findIndex(steps, (s) => s.path === path)
  }

  render() {
    const { returnFrom3DS, specialty } = this.props
    const goNext = (step: number) => () => this.goNext(step)
    const goPrevious = (step?: number) => () => this.goPrevious(step)
    const firstAllowedStep = returnFrom3DS ? '/paiement' : '/questions/'

    return (
      <Fragment>
        <div className="charles step-progress">
          <TrackingListener>
            <Switch>
              {!returnFrom3DS && (
                <Route
                  path="/questions"
                  render={props => (
                    <StepSurvey
                      {...props}
                      {...this.props}
                      prefix="pre_"
                      steps={this.getSteps()}
                      goNext={goNext(this.getStepIndex('/questions'))}
                      goPrevious={goPrevious(this.getStepIndex('/questions'))}
                    />
                  )}
                />
              )}
              {!returnFrom3DS && (
                <Route
                  path="/informations"
                  render={props => (
                    <StepPersonalInfos
                      {...props}
                      {...this.props}
                      steps={this.getSteps()}
                      specialty={specialty}
                      goNext={goNext(this.getStepIndex('/informations'))}
                      goPrevious={goPrevious(this.getStepIndex('/informations'))}
                    />
                  )}
                />
              )}
              {!returnFrom3DS && (
                <Route
                  path="/choix"
                  render={props => (
                    <StepConsultationChoices
                      {...props}
                      {...this.props}
                      steps={this.getSteps()}
                      goNext={goNext(this.getStepIndex('/choix'))}
                      goPrevious={goPrevious(this.getStepIndex('/choix'))}
                    />
                  )}
                />
              )}
              {!returnFrom3DS && (
                <Route
                  path="/remboursement"
                  render={props => (
                    <StepWelfare
                      {...props}
                      {...this.props}
                      steps={this.getSteps()}
                      goNext={goNext(this.getStepIndex('/remboursement'))}
                      goPrevious={goPrevious(this.getStepIndex('/remboursement'))}
                    />
                  )}
                />
              )}
              <Route
                path="/paiement"
                render={props => (
                  <StepPayment
                    {...props}
                    {...this.props}
                    steps={this.getSteps()}
                    goNext={goNext(this.getStepIndex('/paiement'))}
                    goPrevious={goPrevious(this.getStepIndex('/paiement'))}
                  />
                )}
              />
              <Route
                path="/dossier-medical"
                render={props => (
                  <StepSurveyFinish
                    {...props}
                    {...this.props}
                    prefix="post_"
                    steps={this.getSteps()}
                    goNext={goNext(this.getStepIndex('/dossier-medical'))}
                    goPrevious={goPrevious(this.getStepIndex('/dossier-medical'))}
                  />
                )}
              />
              <Route
                path="/fin"
                render={props => (
                  <StepPreConsultationSetup
                    {...props}
                    {...this.props}
                    prefix="pre_"
                    steps={this.getSteps()}
                    goNext={goNext(this.getStepIndex('/fin'))}
                    goPrevious={goPrevious(this.getStepIndex('/fin'))}
                  />
                )}
              />
              <Redirect from="/*" to={firstAllowedStep} />
            </Switch>
          </TrackingListener>
        </div>
        <IntercomWrapper
          active={true}
          patientWorkflow="onboarding"
          profile={{}}
          showLauncher={true}
          alignment="left"
        />
      </Fragment>
    )
  }
}

import React, { Fragment } from 'react'
import { get, isNil} from 'lodash';
import { practitionerTitle } from 'helpers/doctor-type'
import T from 'types'
import './styles.sass';
import { Chatbox } from 'containers'

type Props = {
  consultation: T.Consultation
  show: boolean
}

const TeleconsultationContentChat = ( {
  consultation,
  show
 } : Props) => {

  const { state } = consultation
  const started = state === 'started'
  const finished = state === 'finished'
  const doctorUserId = get(consultation, 'doctorUserId')

  if (isNil(doctorUserId)) {
    console.error('Chat cannot be rendered: doctor has not been provided')
    return null
  }

  if (show || started || finished){
    return (
      <Fragment>
        {finished && (
          <p>
            Retrouvez ci-dessous l'intégralité de votre conversation avec votre{' '}
            {practitionerTitle(consultation.specialtySlug)} :
          </p>
        )}
        <Chatbox userId={doctorUserId} disabled={finished} isPatient={true} />
      </Fragment>
    )
  }
  return null
}

export default TeleconsultationContentChat
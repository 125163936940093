import React from 'react'
import cx from 'classnames'
import SwiperCore, { SwiperOptions, Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

import 'swiper/swiper-bundle.css'
import './styles.sass'
import useSwiperRef from './useSwiperRef'

interface CharlesSliderProps extends Swiper {
  stretchItems?: boolean
}

// Custom Swiper navigation buttons using Ref thanks to this thread
// https://github.com/nolimits4web/swiper/issues/3855#issuecomment-721669079
const Carousel = (props: CharlesSliderProps) => {
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()
  const [paginationEl, paginationElRef] = useSwiperRef<HTMLDivElement>()

  const defaultOptions: Swiper = {
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      1080: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      },
    },
    onInit: (swiper) => {
      // @ts-ignore
      swiper.params.navigation.prevEl = prevEl
      // @ts-ignore
      swiper.params.navigation.nextEl = nextEl
      // @ts-ignore
      swiper.params.pagination.el = paginationEl
      swiper.params.watchOverflow = true

      swiper.navigation.update()
    },
    scrollbar: { draggable: true },
    navigation: {
      nextEl,
      prevEl,
    },
    pagination: {
      clickable: true,
      el: paginationEl,
    },
  }

  const hideButtons = React.Children.count(props.children) <= 1
  return (
    <div className={cx('custom-slider', { 'custom-slider-stretch-items': props.stretchItems })}>
      <Swiper
        {...defaultOptions}
        {...props}
      >
        {props.children}
      </Swiper>
      <div className="mt-2 mb-4 d-flex navigation-controls">
        <button ref={prevElRef} className={cx("btn btn-outline-primary navigation-control slider-prev mr-4", {'d-none': hideButtons})}></button>
        <div ref={paginationElRef}></div>
        <button ref={nextElRef} className={cx("btn btn-outline-primary navigation-control slider-next ml-4", {'d-none': hideButtons})}></button>
      </div>
    </div>
  )
}

export default Carousel
export { SwiperSlide, SwiperSlide as CarouselSlide }

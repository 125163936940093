import { isEmpty, includes } from 'lodash'
import T from 'types'
import { I18n } from 'react-redux-i18n'

export const checkoutGlobalSteps = (consultation: T.ConsultationV2) => {
  let steps = []
  if (!isEmpty(consultation.drugsPrescriptions)) {
    let drugs: Array<any> = []
    consultation.drugsPrescriptions.map(prescription => {
      drugs.push(prescriptionRecommendedProducts(prescription))
    })
    steps.push(["Médicaments", [].concat.apply([], drugs)])
  }
  if (!isEmpty(consultation.phytoPrescriptions)) {
    let phytos: Array<any> = []
    consultation.phytoPrescriptions.map(prescription => {
      phytos.push(prescriptionRecommendedProducts(prescription))
    })
    steps.push(["Phytothérapies", [].concat.apply([], phytos)])
  }
  if (!isEmpty(consultation.programs)) {
    let programs: string[] = []
    consultation.programs.map(program => {
      programs.push(program.title)
    })
    steps.push(["Thérapie digitale", programs])
  }
  return steps
}

export const prescriptionRecommendedProducts = (prescription: T.PrescriptionV2) => {
  let recommendations: string[] = []
  prescription.prescriptionItems.map(item => {
    recommendations.push(`${item.molecule} ${I18n.t(`grammage.${item.grammage}`)}`)
  })
  return recommendations
}

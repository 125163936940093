import React, { FC, useState } from 'react'
import T from 'types'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { map, find } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import { getIcon } from 'assets/icons'
import { getProgramPresentation } from 'components/ProgramPresentation/data'
import build from 'redux-object'
import ReactJWPlayer from 'react-jw-player'
import { I18n } from 'react-redux-i18n'
import { Button, SvgIcon } from 'components'
import '../style.sass'
import { withNavigation } from 'components/Workflow'



interface IProps extends T.UnifiedCheckoutStepProps {
  program: T.Program
  handleShowProgram: (program: T.Program) => void
}

const AcademyPage: FC<IProps> = (props: IProps) => {
  const {
    consultation,
    program,
    navigation: { complete, goPrevious, goNext },
    handleShowProgram
  } = props

  const [viewMore, setViewMore] = useState(false)

  const handlePurchase = () => {
    handleShowProgram(program)
    goNext()
  }
  const handleSkip = () => {
    complete()
  }

  const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)


  return (
    <UCStep>
      <div>
        <h2>ceci est le test Academy</h2>
        <button onClick={goPrevious}>previous</button>
        <button onClick={goNext}>next</button>

        <div>
          <div className="onboarding-container-bloc">
            <div className='header-presentation'>
              <div className='title-body-presentation'>
                <h3 className='title-body-presentation mb-3'>{I18n.t(`unified-checkout.program.${program.slug}.title`)}</h3>
                <div className='b-3 subtitle-presentation'><div>{I18n.t(`unified-checkout.program.${program.slug}.description.1`)}<div className='subtitle-presentation'>{I18n.t(`unified-checkout.program.${program.slug}.description.2`)}</div></div></div>
                <span className='d-md-none subtitle-price subtitle-presentation-duration'>{I18n.t(`unified-checkout.program.${program.slug}.price.1`)}</span><span className='d-md-none'>{I18n.t(`unified-checkout.program.${program.slug}.price.2`)}</span>
                <div className='pb-5 d-none d-md-flex flex-column'>
                  <Button
                    classname="d-none d-lg-flex mt-5 mb-2 w-100"
                    onClick={handlePurchase}
                  >
                    {I18n.t(`unified-checkout.program.${program.slug}.button.desktop-button.buy`)}
                  </Button>
                  <button
                    className="btn btn-outline-primary mx-auto mt-auto w-100"
                    onClick={handleSkip}
                  >
                    {I18n.t(`unified-checkout.program.${program.slug}.button.desktop-button.skip`)}
                  </button>
                </div>
              </div>
              <div className="video-container">
                <ReactJWPlayer
                  playerId="sommeil-mobile-video"
                  playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                  playlist={program.videoPayload.playlist}
                />
              </div>
              <div className='pb-5 d-lg-none'>
                <Button
                  classname="mx-auto mt-auto w-100 mb-3"
                  onClick={handlePurchase}
                >
                  {I18n.t(`unified-checkout.program.${program.slug}.button.mobile-button.buy`)}
                </Button>
                <button
                  className="btn btn-outline-primary mx-auto mt-auto w-100"
                  onClick={handleSkip}
                >
                  {I18n.t(`unified-checkout.program.${program.slug}.button.mobile-button.skip`)}
                </button>
              </div>
            </div>
            <Container className="info-container-academy mb-5 pt-4">
              <Row className='info-row-academy'>
                {map(I18n.t(`unified-checkout.program.${program.slug}.bullets`), (bullet: any, index: BigInteger) => (
                  <Col xs={12} md={12} lg={6} xl={4} className='info-body'>
                    <div key={`program_variant_choice_bullet_${index}`} className="d-flex align-items-center program-variant-row mb-3 ">
                      <SvgIcon icon={getIcon(I18n.t(`unified-checkout.program.${program.slug}.bullets.${index}.icon`))} classname="mr-2 checkout-presentation-bullets-svg" />
                      <div className="checkout-presentation-bullets-body">{I18n.t(`unified-checkout.program.${program.slug}.bullets.1.content`)}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
            {!viewMore &&
              <button
                className="btn btn-outline-primary mx-auto mt-auto checkout-button-view-more w-100 mb-5"
                onClick={() => setViewMore(true)}
              >
                <span className='font-bold'>+ </span>Plus d’informations
              </button>
            }
          </div>
        </div>
        {viewMore &&
          <div className='w-100'>
            <div className="bg-c-blue pt-5 hiw-program-onboarding">
              <h2 className="text-white text-center pt-5">Comment Charles vous accompagne ?</h2>
              <SvgIcon classname="hiw-program-black-underline" icon={getIcon('clinic.blackUnderline')} />
              <Container className="hiw-program-container">
                <Row className="justify-content-center hiw-program-row">
                  {map(presentation.howItWorks, (el, index) => (
                    <Col xs={12} md={8} xl={4} className="text-white hiw-program-col">
                      <div className="hiw-program-col-img">
                        <img src={el.icon} />
                      </div>
                      <div className="h4 text-white hiw-program-col-title">{index + 1}. {el.title}</div>
                      <div>{el.label}</div>
                    </Col>
                  ))}
                </Row>
                <div className="d-flex flex-column text-center d-md-none">
                  <div className='pb-5'>
                    <Button
                      classname="mx-auto mt-auto w-100 mb-3"
                      onClick={handlePurchase}
                    >
                      {I18n.t(`unified-checkout.program.${program.slug}.button.mobile-button.buy`)}
                    </Button>
                    <button
                      className="btn btn-outline-secondary mx-auto mt-auto w-100"
                      onClick={handleSkip}
                    >
                      {I18n.t(`unified-checkout.program.${program.slug}.button.mobile-button.skip`)}
                    </button>
                  </div>
                </div>
                <div className='pb-5 d-none d-md-flex'>
                  <button
                    className="btn btn-outline-secondary mt-auto mr-4 w-100"
                    onClick={handlePurchase}
                  >
                    {I18n.t(`unified-checkout.program.${program.slug}.button.desktop-button.skip`)}
                  </button>
                  <Button
                    classname="d-none d-lg-flex mt-5 w-50"
                    onClick={handleSkip}
                  >
                    {I18n.t(`unified-checkout.program.${program.slug}.button.desktop-button.buy`)}
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        }
      </div>
    </UCStep>
  )
}

export default withNavigation(AcademyPage)

import React, { Component } from 'react'
import cx from 'classnames'
import DoctorNoticeInfo from './DoctorNoticeInfo'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Form } from 'react-bootstrap'
import {
  CHAT,
  PHONE,
  VISIO,
  MEDIUM,
} from 'constants/doctorSelectionStates'
import SvgIcon from 'components/SvgIcon'
import { getIcon } from 'assets/icons'
import { filter, includes, isEmpty, map } from 'lodash'
import T from 'types'

interface IProps {
  handleSelectMedium: (medium: string) => void
  consultation: string
  availableChannels: string[]
  medium: string
  lastFocusedLocation?: any
  collection: T.Doctor[]
}

class SubStepMediumChoice extends Component<IProps> {

  private isAvailable(mediumToCheck: string) {
    const { collection, consultation } = this.props
    const filteredCollection = filter(
      collection,
      (doctor: T.Doctor) =>
        includes(doctor.communicationChannels, mediumToCheck) && // filter out doctor without required channel
        // filter out doctor without required consultation type
        includes(doctor.consultationTypes, consultation) &&
        // keep all if chat
        (mediumToCheck === CHAT ||
          // filter out doctor without any availability for given consultation type
          !isEmpty(
            doctor[consultation === 'simple' ? 'freeSimpleSlots' : 'freeComplexSlots'],
          )),
    )
    return !isEmpty(filteredCollection)
  }

  private renderMediumItem(chosenMedium: string, name: string, icon: string) {
    const { medium, handleSelectMedium } = this.props
    return (
      <Form.Group onClick={() => handleSelectMedium(chosenMedium)} className={cx("btn onboarding-choice", { 'disable-onboarding-choice': !this.isAvailable(chosenMedium) })}>
        <Form.Check name="cguAccepted" id="validationFormik10">
          <Form.Check.Input
            type="radio"
            name={chosenMedium}
            checked={medium == chosenMedium}
          />
          <Form.Check.Label className='card-text flex'>
            <span>
              <SvgIcon icon={getIcon(icon)} classname="consultation-choice-medium-svg" />
              {name}
            </span>
            {!this.isAvailable(chosenMedium) && <span className='unavailable'>{'(Plus de créneaux disponibles)'}</span>}
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    )
  }

  private renderChannel(channel: string) {
    switch (channel) {
      case CHAT:
        return this.renderMediumItem(CHAT, 'Par messages écrits', 'clinic.chat')
      case PHONE:
        return this.renderMediumItem(PHONE, 'Par téléphone', 'clinic.yellowSquarePhone')
      case VISIO:
        return this.renderMediumItem(VISIO, 'Par visioconférence', 'clinic.camera')
    }
  }

  public render() {
    const { lastFocusedLocation, availableChannels } = this.props
    return (
      <LayoutStep>
        <LayoutMain variant="small">
          <h3 className='consultation-choice-title'>Comment souhaitez-vous faire votre téléconsultation ?</h3>
          <div className="consultation-choices">
            {map(availableChannels, channel => this.renderChannel(channel))}
          </div>
        </LayoutMain>
        <LayoutSide>
          <DoctorNoticeInfo channels={availableChannels || []} items={MEDIUM} lastFocusedLocation={lastFocusedLocation} />
        </LayoutSide>
      </LayoutStep>
    )
  }
}

export default SubStepMediumChoice

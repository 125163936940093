import { connect } from "react-redux"
import {
  authSignout,
  toggleSidebar,
  setFocusedPrescription,
  dropdownSidebarToggleElement,
} from "actions"
import { Sidebar } from "components"
import T from 'types'

const mapStateToProps = ({
  auth: { profile },
  ux: { sidebarActive, activeLabels },
  consultations,
  prescriptions: { lastPrescription },
}: T.Store) => ({
  profile,
  consultations,
  sidebarActive,
  lastPrescription,
  activeLabels
})

const mapDispatchToProps = (dispatch: Function) => ({
  closeSidebar: () => dispatch(toggleSidebar(false)),
  signOut: () => dispatch(authSignout()),
  setFocusedPrescription: (p: T.Prescription) =>
    dispatch(setFocusedPrescription(p)),
  dropdownSidebarToggleElement: (value: string) =>
    dispatch(dropdownSidebarToggleElement(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

import OrderComplete from '../OrderComplete'
import { Loader, Block } from 'components'
import T from 'types'

interface IProps extends RouteComponentProps<any> {
  goNext: () => void,
  goPrevious: () => void,
  order: any,
  storefront: T.Storefront
}


class Checkout extends React.Component<IProps> {

  render() {
    const { order, storefront, goPrevious, goNext } = this.props
    const { loading } = storefront
    return loading ? <Loader /> : <OrderComplete order={order} />
  }
}

export default withRouter(Checkout)


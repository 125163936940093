import React, { FC, useRef, useState } from 'react'
import cx from 'classnames'
import './styles.sass'

type IProps = {
  range: any[],
  defaultIndex: any,
  unit: string,
  handleClick?: (month: number) => void,
}

/**
 *  @param {IProps} props
 *  @return Returns the component.
 */
const OptionPicker: FC<IProps> = (props: IProps) => {
  const { range, defaultIndex, unit } = props
  const variantParent = useRef<HTMLDivElement>(null)
  const [optionIndex, setOptionIndex] = useState(defaultIndex)

  const getOffsetLeft = () => {
    return optionIndex / range.length * 100
  }

  const getOffsetWidth = () => {
    return 1 / range.length * 100
  }

  const handleClickTimeVariant = (value: number, index: number): void => {
    const { handleClick } = props
    handleClick && handleClick(value)
    setOptionIndex(index)
  }

  return (
    <>
      <div className="phyto-card-subscription-time-picker" ref={variantParent}>
        {range.map((value, i) => (
          <div
            className={cx(
              'phyto-card-subscription-time-picker-item',
              { 'phyto-card-subscription-time-picker-item-active': optionIndex === i })}
            onClick={e => handleClickTimeVariant(value, i)}
          >
            <div className="phyto-card-subscription-time-picker-item-title">{value} {unit}</div>
          </div>
        ))}
      </div>
      <div className="phyto-card-subscription-time-picker-clone">
        <div
          className="phyto-card-subscription-time-picker-item-phantom-selector"
          style={{ left: `${getOffsetLeft()}%`, width: `${getOffsetWidth()}%` }}
        >
          <div
            className="phyto-card-subscription-time-picker-item-selector"
          />
        </div>
      </div>
    </>
  )
}

export default OptionPicker

import React, { Component } from 'react'
import { capitalize, values, groupBy, isEqual, map, slice, sortedUniq, includes, isEmpty } from 'lodash'
import moment from 'constants/moment'
import { friendlyDisplayDay } from 'helpers/date-helper'
import T from 'types'
import { ResponsiveTabs } from 'components'
import AllConsultationSlotsForDay from './AllConsultationSlotsForDay'

import { NUMBER_OF_AVAIBILITY_DAYS_DISPLAYED } from 'constants/config'

interface IProps {
  availabilities: any[]
  freeSlots: any[]
  onSelectSlot: (e: any) => void
}

interface IState {
  chosenSlot: Date | null
}

class AllConsultationSlots extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      chosenSlot: null,
    }
  }

  private handleChosenSlot = (e: React.MouseEvent<HTMLElement>, slot: Date) => {
    e.stopPropagation()
    this.setState({ chosenSlot: slot })
    this.props.onSelectSlot(slot)
  }

  private renderAvailabilityDay(arrayofDay: any[], index: number) {
    const { chosenSlot } = this.state
    const { freeSlots } = this.props
    return (
      <AllConsultationSlotsForDay
        key={`slots-for-${index}`}
        slot={chosenSlot}
        freeSlots={freeSlots}
        availabilities={arrayofDay}

        handleChosenSlot={this.handleChosenSlot}
      />
    )
  }

  public render() {
    const { availabilities, freeSlots } = this.props

    const sortedArray = groupBy(sortedUniq(availabilities.sort()), date => {
      return moment(date)
        .startOf('day')
        .format()
    })

    const sortedFreeSlots = groupBy(sortedUniq(freeSlots.sort()), date => {
      return moment(date)
        .startOf('day')
        .format()
    })

    const firstFreeSlot = isEmpty(sortedFreeSlots) ? null : Object.values(sortedFreeSlots)[0][0]
    let selected = 0
    const truncatedArray = slice(values(sortedArray), 0, NUMBER_OF_AVAIBILITY_DAYS_DISPLAYED)
    const tabs: T.UI.IResponsiveTabsItem[] = map(truncatedArray, (availabilitiesOfDay: any[], index: number) => {
      const title = capitalize(friendlyDisplayDay(moment(availabilitiesOfDay[0])))
      if (includes(availabilitiesOfDay, firstFreeSlot)) {
        selected = index
      }
      return {
        title,
        isActive: availabilitiesOfDay.some((availability: any) => includes(freeSlots, availability)),
        content: () => this.renderAvailabilityDay(availabilitiesOfDay, index)
      }
    })

    return (
      <div className="consultation-slots-container">
        <ResponsiveTabs selected={selected} tabs={tabs} chunkSize={4} />
      </div>
    )
  }
}

export default AllConsultationSlots

import React from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'

import bullet8weeks from 'assets/icons/academy/vaginisme/bullet-8-weeks.png'
import bulletSupport from 'assets/icons/academy/vaginisme/bullet-support.png'
import bulletRhythm from 'assets/icons/academy/vaginisme/bullet-rythme.png'
import bulletImprovement from 'assets/icons/academy/vaginisme/bullet-improvement.png'
import howAssistance from 'assets/icons/academy/vaginisme/how-assistance.png'
import howProgression from 'assets/icons/academy/vaginisme/how-progression.png'
import howStepByStep from 'assets/icons/academy/vaginisme/how-step-by-step.png'
import whyMental from 'assets/icons/academy/vaginisme/why-mental.png'
import whyPhysics from 'assets/icons/academy/vaginisme/why-physique.png'
import whyRhythm from 'assets/icons/academy/vaginisme/why-rythme.png'

// in this file you should add the right images (add all needed images under assets/icons/academy/vaginisme) a fill up all the labels and titles with the right wordings
const buildData: () => T.MiaProgramPresentation = () => ({
  about: [],
  press: [
    {
      icon: 'gq',
    },
    {
      icon: 'franceInter',
    },
    {
      icon: 'franceInfo',
    },
    {
      icon: 'leMonde',
    },
  ],
  arguments: [
    {
      icon: bulletImprovement,
      label: I18n.t('academy.vaginisme-program.presentation.bullets.improvement'),
    },
    {
      icon: bullet8weeks,
      label: I18n.t('academy.vaginisme-program.presentation.bullets.days'),
    },
    {
      icon: bulletRhythm,
      label: I18n.t('academy.vaginisme-program.presentation.bullets.rhythm'),
    },
    {
      icon: bulletSupport,
      label: I18n.t('academy.vaginisme-program.presentation.bullets.support'),
    },
  ],
  howItWorks: [
    {
      icon: howStepByStep,
      title: I18n.t('academy.vaginisme-program.presentation.howItWorks.steps.title'),
      label: I18n.t('academy.vaginisme-program.presentation.howItWorks.steps.label'),
    },
    {
      icon: howAssistance,
      title: I18n.t('academy.vaginisme-program.presentation.howItWorks.assistance.title'),
      label: I18n.t('academy.vaginisme-program.presentation.howItWorks.assistance.label'),
    },
    {
      icon: howProgression,
      title: I18n.t('academy.vaginisme-program.presentation.howItWorks.progression.title'),
      label: I18n.t('academy.vaginisme-program.presentation.howItWorks.progression.label'),
    },
  ],
  whyIsItEfficient: [
    {
      icon: whyPhysics,
      title: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.physics.title'),
      label: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.physics.label'),
    },
    {
      icon: whyMental,
      title: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.mental.title'),
      label: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.mental.label'),
    },
    {
      icon: whyRhythm,
      title: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.rhythm.title'),
      label: I18n.t('academy.vaginisme-program.presentation.whyIsItEfficient.rhythm.label'),
    },
  ],
  faq: [
    {
      items: [
        {
          text: I18n.t('academy.vaginisme-program.faq.faq1.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq1.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq2.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq2.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq3.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq3.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq4.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq4.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq5.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq5.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq6.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq6.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq7.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq7.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq8.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq8.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq9.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq9.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq10.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq10.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq11.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq11.content'),
        },
        {
          text: I18n.t('academy.vaginisme-program.faq.faq12.title'),
          details: I18n.t('academy.vaginisme-program.faq.faq12.content'),
        },
      ],
    },
  ],
})

export default buildData

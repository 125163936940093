import React, { Component } from 'react'
import Block from '../Block'
import { formatedOpenedHours } from 'utils/helpers'
import T from 'types'
import { get } from 'lodash'
import deliveryGuy from 'assets/images/livraison-medoc.png'
import './styles.sass'

interface IProps {
  pharmacy: T.Pharmacist,
}

export default class Pharmacy extends Component<IProps> {
  public render() {
    const { pharmacy } = this.props
    const { address } = pharmacy

    return (
      <div className="pharmacy-container text-left">
        <div className="d-none d-sm-block column">
          <img className="img-fluid" src={deliveryGuy} />
        </div>
        <div className="delivery-infos-block">
          <ul className="delivery-infos">
            <li>Livraison partout en France en moins de 48h</li>
            <li>Ordonnance sécurisée et renouvelable chaque mois</li>
            <li>Service patient 7j/7</li>
            <li>Messagerie directe avec le pharmacien</li>
          </ul>
        </div>
      </div>
    )
  }
}

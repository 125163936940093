import { connect } from 'react-redux'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import Academy from 'page/Pages/Academy'
import T from 'types'
import {
  getPrograms,
  getEnrollments,
  enrollProgram,
  purchaseProgram,
  selectProgramVariant,
  applyCoupon,
  getPaymentMethods,
  showNotification,
  startRenewEnrollment,
} from 'actions'

const mapStateToProps = ({
  academy,
  auth,
  storefront,
}: T.Store) => ({
  academy,
  auth,
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  applyCoupon: (coupon: string, orderToken: string) => dispatch(applyCoupon(coupon, orderToken)),
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => dispatch(selectProgramVariant(variantId, orderToken, onSuccess)),
  getPaymentMethods: (orderToken?: string) => dispatch(getPaymentMethods(orderToken)),
  getPrograms: () => dispatch(getPrograms()),
  getEnrollments: () => dispatch(getEnrollments()),
  enrollProgram: (slug: string) => dispatch(enrollProgram(slug)),
  startRenewEnrollment: (enrollmentId: number) => dispatch(startRenewEnrollment(enrollmentId)),
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: () => void) =>
    dispatch(purchaseProgram(token, data, onSuccess)),
  showNotification: (title: string, message: string) => dispatch(showNotification(title, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Academy)

import React, { Component, Fragment } from 'react'
import { filter, isUndefined, map, last, find, get, includes } from 'lodash'
import build from 'redux-object'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import T from 'types'
import { MainContainer } from 'containers'
import { SleepCalendar, Block } from 'components'
import { enrollmentForProgram } from 'utils/data/academy'
import { I18n } from 'react-redux-i18n'

interface IProps {
  sleepCalendar: T.SleepCalendar
  sleepCalendarItems: T.SleepCalendarItem[]
  getSleepCalendar: () => void
  lowerCalendarBedtime: () => void
  increaseCalendarBedtime: () => void
  skipCalendarBedtime: () => void
  createSleepCalendarItem: (data: object, onSuccess: any) => void
  updateSleepCalendar: (params: object) => void
  getSleepCalendarItems: () => void
  updateSleepCalendarItem: (sleepCalendarItemId: number, params: object) => void
}

interface IState {
}

class SleepCalendarMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
    }
  }

  public componentDidMount() {
    const { getSleepCalendar, getSleepCalendarItems } = this.props
    getSleepCalendar()
    getSleepCalendarItems()
  }
  public render() {
    const {
      sleepCalendar
    } = this.props

   return (
      <MainContainer unlimitedWidth>
        <div className="page personnal-space-program-manager">
          { sleepCalendar && <SleepCalendar {...this.props} /> }
        </div>
      </MainContainer>
    )
  }
}

export default SleepCalendarMenu

import { isUndefined } from 'lodash'
/*import premature_ejaculation from './premature_ejaculation.json'
import andropause from './andropause.json'
import libido from './libido.json'
import porn_addiction from './porn_addiction.json'
import lack_of_pleasure from './lack_of_pleasure.json'
import no_ejaculation from './no_ejaculation.json'
import penile_curvature from './penile_curvature.json'
import other from './other.json'
import sleep from './sleep.json'
import hair from './hair.json'
import fertility from './fertility.json'
import weight from './weight.json'*/

const speToSurvey: {
  [key: string]: any
} = {
/*  fertility,
  premature_ejaculation,
  andropause,
  libido,
  porn_addiction,
  lack_of_pleasure,
  no_ejaculation,
  penile_curvature,
  other,
  sleep,
  hair,
  weight*/
}
// "sexual_wellness": sexual_wellness,
// "sexual_desire": sexual_desire,
// "pain": pain,
// "couple_therapy": couple_therapy,
// "reach_orgasm": reach_orgasm,
// "mia_other": mia_other,

const loadSpeSurveyFromCache = (spe: string | undefined) => {
  if (isUndefined(spe)) {
    return undefined
  }
  return speToSurvey[spe]
}

export default loadSpeSurveyFromCache

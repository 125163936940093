import React, { Component, Fragment, RefObject, useRef } from 'react'
import classnames from 'classnames'
import { filter, includes, get, map, sortBy, isEmpty, first, isUndefined } from 'lodash'

import T from 'types'
import './styles.sass'
import Button from '../Button'
import cx from 'classnames'
import { Nav } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/lists'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import Media from 'react-media'
import moment from 'constants/moment'
import { Editor } from '@tinymce/tinymce-react'

import ChatboxMessage from './ChatboxMessage'
import ChatboxV2Mobile from './Mobile'
import ChatboxV2Desktop from './Desktop'
import { SvgIcon, BlockHeader, Block, VisioBox } from 'components'

const MAILBOXER_MESSAGE = 'mailboxer/message'
const MAILBOXER_MESSAGE_VISIO = 'mailboxer/message/visio'

interface IProps {
  createMessage: (userId: number, body: string) => void
  getMessages: (userId: number) => void
  messages: T.Message[]
  actionLoading?: string[]
  consultations: T.Consultation[]
  profile: T.Profile
}

interface IState {
  draft: string
  showPatientNotification: boolean
  selectedConsultation: T.Consultation | null
  showFinishedConsult: boolean
}

class ChatboxV2 extends Component<IProps, IState> {
  chatMessagesList: RefObject<HTMLDivElement>

  constructor(props: IProps) {
    super(props)

    this.chatMessagesList = React.createRef()
    this.state = {
      draft: '',
      showPatientNotification: false,
      selectedConsultation: this.props.consultations[0],
      showFinishedConsult: false
    }
  }

  public componentDidMount() {
    if (this.state.selectedConsultation)
      this.refreshMessages(this.state.selectedConsultation)
  }

  public componentDidUpdate() {
    // force scroll to bottom of chatbox
    if (this.chatMessagesList.current)
      this.chatMessagesList.current.scrollTop = 9999999
  }

  public refreshMessages = (consultation: T.Consultation) => {
    const { getMessages } = this.props
    const { doctorUserId } = consultation

    if (doctorUserId)
      getMessages(doctorUserId)
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.sendMessage()
  }

  private sendMessage = () => {
    const { createMessage } = this.props
    const { draft, selectedConsultation } = this.state

    if (!selectedConsultation)
      return

    const { doctorUserId } = selectedConsultation

    if (draft.length === 0 || !doctorUserId)
      return false

    createMessage(doctorUserId, draft)

    this.setState(
      {
        draft: '',
        showPatientNotification: true,
      },
      () => setTimeout(() => this.setState({ showPatientNotification: false }), 4000),
    )
  }

  private displayMessage = (message: T.Message) => {
    const { profile } = this.props

    return <ChatboxMessage key={message.id} message={message} profile={profile} />
  }

  private renderSendForm = () => {
    const { actionLoading } = this.props
    const { draft, selectedConsultation } = this.state

    const enableConsult = get(selectedConsultation, 'state') === 'started'

    return (
      <div>
        {enableConsult ?
          <form className="chat-form" onSubmit={e => this.handleSubmit(e)}>
            <Editor
              init={{
                height: 200,
                menubar: false,
                content_style:
                  '.aligned-to-right { text-align: right; list-style-position: inside; }' +
                  '.aligned-to-left { text-align: left; }' +
                  '.aligned-to-center { text-align: center; list-style-position: inside; }' +
                  '.underlined-message { text-decoration: underline }' +
                  'html { font-family: noto-sans, Calibri, Helvetica, Sans-Serif;cursor: text; }',
                plugins: 'link lists paste',
                toolbar: 'bold italic underline | link | alignleft aligncenter alignright | bullist numlist',
                default_link_target: '_blank',
                link_assume_external_targets: true,
                paste_as_text: true,
                link_title: false,
                target_list: false,
                branding: false,
                resize: false,
                statusbar: false,
                formats: {
                  alignleft: { selector: 'p,div,ul,ol,li', classes: 'aligned-to-left' },
                  aligncenter: { selector: 'p,div,ul,ol,li', classes: 'aligned-to-center' },
                  alignright: { selector: 'p,div,ul,ol,li', classes: 'aligned-to-right' },
                  underline: { inline: 'span', classes: 'underlined-message', exact: true },
                }
              }}
              onEditorChange={(newText) => this.setState({ draft: newText })}
              value={draft}
            />
            <div className="p-2">
              <Button type="submit" loading={!isEmpty(actionLoading)} classname="btn-sm btn-block">
                Envoyer
              </Button>
            </div>
          </form>
          :
          <div className="chat-form-disable">
            <div className="chat-form-disable-text">Cette consultation est terminée</div>
          </div>
        }
      </div>
    )
  }

  public handleSelectConsultation = (consultation: T.Consultation | null) => {
    if (consultation == this.state.selectedConsultation)
      return;

    this.setState({ selectedConsultation: consultation })

    if (consultation)
      this.refreshMessages(consultation)
  }

  public render() {
    const { consultations, messages } = this.props;
    const { showPatientNotification, selectedConsultation } = this.state
    const sortedConsultations = sortBy(consultations, (consultation: T.Consultation) => consultation.appointmentAt).reverse()
    const ongoingConsultations = filter(sortedConsultations, (consultation: T.Consultation) => consultation.state === 'started')
    const finishedConsultations = filter(sortedConsultations, (consultation: T.Consultation) => consultation.state === 'finished')
    const conversationMessages = selectedConsultation ?
      sortBy(
        filter(messages, message =>
          includes(map(get(message, 'receipts'), receipt => receipt.receiver.id === selectedConsultation.doctorUserId), true),
        ),
        'id',
      ) : []

    return (
      <div className="chatbox-v2-component">
        <Media
          key="desktop"
          query="(min-width: 1024px)"
          render={() =>
            <ChatboxV2Desktop
              ongoingConsultations={ongoingConsultations}
              finishedConsultations={finishedConsultations}
              conversationMessages={conversationMessages}
              showPatientNotification={showPatientNotification}
              selectedConsultation={selectedConsultation}
              handleSelectConsultation={this.handleSelectConsultation}
              refreshMessages={this.refreshMessages}
              displayMessage={this.displayMessage}
              renderSendForm={this.renderSendForm}
              chatMessagesList={this.chatMessagesList}
            />
          }
        />
        <Media
          key="mobile"
          query="(max-width: 1023px)"
          render={() =>
            <ChatboxV2Mobile
              ongoingConsultations={ongoingConsultations}
              finishedConsultations={finishedConsultations}
              conversationMessages={conversationMessages}
              showPatientNotification={showPatientNotification}
              selectedConsultation={selectedConsultation}
              handleSelectConsultation={this.handleSelectConsultation}
              refreshMessages={this.refreshMessages}
              displayMessage={this.displayMessage}
              renderSendForm={this.renderSendForm}
              chatMessagesList={this.chatMessagesList}
            />
          }
        />
      </div>
    )
  }
}

export default ChatboxV2

import React, { Component, Fragment } from 'react'
import { SIMPLE_CONSULTATION_PRICE } from 'constants/config'
import { RouteComponentProps } from 'react-router'
import { flatten, map, find } from 'lodash'
import { Button } from 'components'
import './styles.sass'
import { SectionArguments } from 'page/common/ConsultationArguments'
import remy from "assets/images/focus-remi-lombard.jpg"
import pierre from "assets/images/pierre-schneider.jpeg"
import gilbert from "assets/images/gilbert-face.jpg"
import cocaul from "assets/images/cocaul-face.jpg"
import thomas from "assets/images/thomas-killian.jpg"
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import { LayoutStep, LayoutMain, LayoutSide } from '../LayoutStep'
import T from 'types'
import { I18n } from 'react-redux-i18n'

const sections = (consultationSpecialty?: T.ConsultationSpecialty): T.ISection[] => [
  {
    title: 'Charles, la santé repensée :',
    items: [
      { icon: 'clinic.doctor', text: 'Téléconsultez un spécialiste' },
      { icon: 'clinic.pig', text: `${consultationSpecialty ? consultationSpecialty.price : `${SIMPLE_CONSULTATION_PRICE}€`} au lieu de 80€ en moyenne en cabinet` },
      { icon: 'clinic.pill', text: 'Recevez un traitement personnalisé' },
      { icon: 'clinic.checklist', text: 'Bénéficiez d\'un suivi par nos équipes' },
    ],
  }, {
    items: [
      { icon: 'clinic.check', text: 'Médecins spécialistes français' },
      { icon: 'clinic.check', text: 'Plan de traitements holistiques' },
      { icon: 'clinic.check', text: 'Traitements à l\'efficacité prouvée' },
    ],
  }, {
    title: 'Charles respecte votre vie privée :',
    items: [
      { icon: 'clinic.shield', text: 'Vos données sont sécurisées et soumises au secret médical' },
      { icon: 'clinic.hardware', text: 'Charles utilise un Hébergeur Agréé de Données de Santé' },
      { icon: 'clinic.box', text: 'Livraison discrète chez vous ou en relais colis' },
    ],
  },
]

const fertilitySections = (consultationSpecialty?: T.ConsultationSpecialty): T.ISection[] => [
  {
    title: 'Charles, la santé repensée :',
    items: [
      { icon: 'clinic.doctor', text: 'Téléconsultez un spécialiste' },
      { icon: 'clinic.pig', text: `${consultationSpecialty ? consultationSpecialty.price : `${SIMPLE_CONSULTATION_PRICE}€`} au lieu de 80€ en moyenne en cabinet` },
      { icon: 'clinic.checklist', text: 'Recevez une prescription pour des examens' },
      { icon: 'clinic.team', text: 'Bénéficiez d\'un suivi par nos équipes' },
    ],
  }, {
    items: [
      { icon: 'clinic.check', text: 'Médecins spécialistes français' },
      { icon: 'clinic.check', text: 'Accompagnement et suivi' },
      { icon: 'clinic.check', text: 'Analyse des résultats' },
    ],
  }, {
    title: 'Charles respecte votre vie privée :',
    items: [
      { icon: 'clinic.shield', text: 'Vos données sont sécurisées et soumises au secret médical' },
      { icon: 'clinic.hardware', text: 'Charles utilise un Hébergeur Agréé de Données de Santé' },
      { icon: 'clinic.box', text: 'Livraison discrète chez vous ou en relais colis' },
    ],
  },
]

const consultationSection = (consultationSpecialty?: T.ConsultationSpecialty) : T.ISection[] => {
  return consultationSpecialty && consultationSpecialty.slug === 'fertility' ? fertilitySections(consultationSpecialty) : sections(consultationSpecialty)
}

class BCConsultPresentation extends Component<BookingConsultationStepProps, any> {
  public render() {
    const { consultationSpecialties, consultationSpecialty, navigation } = this.props
    const consultationSpecialtyObject: T.ConsultationSpecialty | undefined = find(consultationSpecialties, (specialty: T.ConsultationSpecialty) => specialty.slug === consultationSpecialty)

    const getDoctorImage = (consultationSpecialty?: string) => {
      switch (consultationSpecialty) {
        case 'sleep':
          return remy
        case 'hair':
          return pierre
        case 'digestion':
          return thomas
        case 'weight':
          return cocaul
        default:
          return gilbert
      }
    }

    return (
      <LayoutStep>
        <LayoutMain>
          {consultationSpecialtyObject!.medical &&
            <div className='d-flex flex-column align-items-center'>
              <h1 className="section-title-presentation-consult main-title text-center font-weight-normal">
                {" "}
                {I18n.t(`presentationConsult.${consultationSpecialty}.title`)}{" "}
              </h1>
              <div className="d-md-none">
                <Button
                  classname="onb-next-btn my-3 mb-3 mb-md-0"
                  type="button"
                  onClick={() => navigation.goNext()}
                >
                  Démarrer le diagnostic
                </Button>
              </div>
              <p className="start-text text-center d-flex start-text-general">
                {I18n.t(`presentationConsult.${consultationSpecialty}.description`)}
              </p>
              <div className="doctor-message-div d-flex mt-3">
                <div className=" ">
                  <img src={getDoctorImage(consultationSpecialty)} className="doctor-round-face p-0 mr-2" alt="remi lombard" />
                </div>
                <div className=" ">
                  <div className="doctor-talks ml-2 text-left">
                    {I18n.t(`presentationConsult.${consultationSpecialty}.firstParagraph`)}
                  </div>
                </div>
              </div>
              <div className="doctor-message-div d-flex mt-3">
                <div className=" ">
                  <img src={getDoctorImage(consultationSpecialty)} className="doctor-round-face p-0 mr-2" alt="remi lombard" />
                </div>
                <div className=" ">
                  <div className="doctor-talks ml-2 text-left">
                    {I18n.t(`presentationConsult.${consultationSpecialty}.secondParagraph`)}
                  </div>
                </div>
              </div>
              <Button
                classname="onb-next-btn my-5 mb-3 mb-md-0"
                type="button"
                onClick={() => navigation.goNext()}
              >
                Démarrer le diagnostic
              </Button>
            </div>
          }
        </LayoutMain>
        <LayoutSide><SectionArguments sections={consultationSection(consultationSpecialtyObject)} /></LayoutSide>
      </LayoutStep>
    )
  }
}

export default withNavigation(BCConsultPresentation)

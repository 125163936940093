import React, { Component } from 'react'
import { get, isNil, isEmpty, capitalize } from 'lodash'
import classnames from 'classnames'
import moment from 'constants/moment'
import './styles.sass'
import { SvgIcon, Button } from 'components'
import imageFile from 'assets/images/avatar.jpg'
import { getIcon } from 'assets/icons'
import T from 'types'
import { CHAT } from 'constants/doctorSelectionStates'
import { friendlyDisplayDay } from 'helpers/date-helper'
import DoctorDetails from '../DoctorSelection/DoctorDetails'

export interface DoctorProfileProps {
  doctor: T.Doctor
  loading?: boolean
  isToggleOn?: boolean
  isSlotSelected?: boolean
  onSelect?: () => void
  onButtonClick?: () => void
  classname?: string
  isRecap?: boolean
  callToAction?: JSX.Element
  withPicture?: boolean
  selectedSlot?: Date
  medium?: string
  slot?: Date
  showBorders?: boolean
}
interface IState {
  showProfile: boolean
}

class DoctorProfile extends Component<DoctorProfileProps, IState> {
  constructor(props: DoctorProfileProps) {
    super(props)
    this.state = {
      showProfile: false,
    }
  }
  private handleSelect() {
    const { onSelect, doctor } = this.props
    const { available } = doctor

    if (isNil(onSelect) || !available) {
      return
    }

    onSelect()
  }

  private handleClickShowProfile = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    const { showProfile } = this.state
    this.setState({ showProfile: !showProfile })
  }

  public render() {
    const {
      children,
      doctor,
      callToAction,
      showBorders,
    } = this.props
    const { showProfile } = this.state
    const { speciality, avatarUrl, description } = doctor
    const profileImage = isNil(avatarUrl) ? imageFile : avatarUrl
    const hasProfile = !isEmpty(description)
    return (
      <div className={classnames('block-content doctor-profile-container', { bordered: showBorders })}>
        <div
          className="d-flex flex-wrap"
          onClick={() => this.handleSelect()}
        >
          <div className="doctor-main-profile d-flex align-self-start mb-3 mb-md-0">
            <img className="doctor-profile-img mr-3" src={profileImage} alt={doctor.fullName} />
            <div className="text-left">
              <h4 className="doctor-profile-name mb-1">{doctor.fullName}</h4>
              {speciality && <div className="doctor-profile-specialty mb-1">{speciality}</div>}
              {hasProfile && <div className="doctor-profile-see-profile" onClick={this.handleClickShowProfile}>
                <SvgIcon icon={getIcon('clinic.profile')} classname="icon" />
                Voir le profil
              </div>}
            </div>
          </div>
          <div className="doctor-profile-right-block">
            {this.renderBlockBasedOnAvailability()}
          </div>
          <div className={classnames('doctor-details-container', { active: showProfile })}>
            <DoctorDetails doctor={doctor} ulClassName="doctor-details-list" />
          </div>
        </div>
        {children}
      </div>
    )
  }

  private renderBlockBasedOnAvailability() {
    const { doctor, medium, callToAction, isSlotSelected, isRecap, onButtonClick, loading } = this.props
    const { available, nextFreeSimpleTimeSlot } = doctor
    const date = moment(nextFreeSimpleTimeSlot)

    return available ? (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        {medium !== CHAT && this.renderDoctorAvailability(date)}
        {callToAction ? callToAction : (
          <Button
            classname={classnames('doctor-profile-see-slots-button', {
              active: isSlotSelected
            })}
            onClick={onButtonClick}
            loading={loading}
          >
            {this.doctorCtaText()}
          </Button>
        )}
      </div>
    ) : (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        {this.renderDoctorUnavailability()}
        {callToAction ? callToAction : (
          <Button
            classname={classnames('doctor-profile-see-slots-button')}
            disabled={true}
          >
            Indisponible
          </Button>
        )}
      </div>
    )
  }

  private renderDoctorUnavailability() {
    const { doctor } = this.props
    const { unavailableUntil, fullName } = doctor

    return (
      <div className="doctor-profile-unavailability">
        <span className="filled-red-circle" />
        {fullName} sera absent(e) jusqu'au {moment(unavailableUntil).format('DD MMMM YYYY')}
      </div>
    )
  }

  private renderDoctorAvailability(date: any) {
    const { isSlotSelected, slot } = this.props
    const temporarySlot = moment(slot)

    return isSlotSelected ? (
      <div className="doctor-profile-availability">
        <span className="filled-green-circle" />
        Rendez-vous :
        <div className="font-weight-bold">
          Le {temporarySlot.format('dddd Do MMMM')} à {temporarySlot.format('LT')}
        </div>
      </div>
    ) : (
      this.renderSecondPart(date)
    )
  }

  private renderSecondPart(date: any) {
    const { selectedSlot } = this.props
    const decidedSlot = moment(selectedSlot)

    return selectedSlot ? (
      <div className="doctor-profile-selected-slot d-flex flex-column align-items-center">
        <div><SvgIcon icon={getIcon('clinic.calendar')} /></div>
        <div className="font-weight-semi">
          <span className="text-capitalize">
            {friendlyDisplayDay(decidedSlot)}
          </span> à {decidedSlot.format('LT')}
        </div>
      </div>
    ) : (
      <div className="doctor-profile-availability">
        <span className="filled-green-circle" />
        Disponibilité :
        <div className="font-weight-bold">{friendlyDisplayDay(date)} à {date.format('HH:mm')}</div>
      </div >
    )
  }

  private doctorCtaText() {
    const { isToggleOn, isSlotSelected, isRecap, medium } = this.props

    if (isRecap) {
      return 'Modifier'
    }

    if (medium === CHAT && !isToggleOn) {
      return 'Sélectionner'
    }

    if (medium === CHAT) {
      return 'Confirmer'
    }

    if (isSlotSelected) {
      return 'Réserver'
    }

    if (!isToggleOn) {
      return 'Voir les horaires'
    }

    return 'Masquer les horaires'
  }
}

export default DoctorProfile

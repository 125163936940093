import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import uuidv4 from 'uuid'
import { I18n } from 'react-redux-i18n'
import {
  map,
  first,
  find,
  isEmpty,
  isNull,
  filter,
  values,
  reduce,
  isUndefined,
  Dictionary,
} from 'lodash'
import {
  ProductVariantsChoice,
  NewProductVariantsChoice,
  Button,
  StoreRedirectionScreen,
} from 'components'
import leafImage from 'assets/icons/leaf.png'
import { MainContainer } from 'containers'
import './styles.sass'
import T from 'types'
import build from 'redux-object'
import { money } from 'utils/models/money'
import { TARGET_PLATFORM } from 'constants/config'
import {
  productList,
} from 'utils/helperComponents/phytoHelpers'

const DEFAULT_RECOMMENDED_DURATION = 3
interface IProps extends RouteComponentProps<any> {
  collectionPrescription: T.Prescription[]
  profile: T.Profile
  getProducts: () => void
  getTaxons: () => void
  getPrescriptions: () => void
  beginCheckout: (variants: T.CheckoutVariantChoice[], successCallback: Function) => void
  storefront: T.Storefront
}

type IState = {
  selected: Dictionary<T.CheckoutVariantChoice>
  sessionUUID: string
  showLoadingScreen: boolean
}

class Phytotherapy extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      selected: {},
      sessionUUID: uuidv4(),
      showLoadingScreen: true,
    }
  }

  componentDidMount() {
    const { storefront, getProducts, getTaxons, getPrescriptions } = this.props

    // TODO REACTIVATE WHEN DONE
    if (isEmpty(storefront.product) || isEmpty(storefront.taxon)) {
      getTaxons()
      getProducts()
      getPrescriptions()
    }
    setTimeout(() => {
      this.setState({ showLoadingScreen: false })
    },         2000)
  }

  didBeginCheckout = (data: any) => {
    const { history } = this.props
    const cart: any = first(build(data, 'cart'))
    history.push(`/checkout/${cart.number}`)
  }

  // handle selected product variants and remove the product if variant is null
  selectProductVariant = (product: any, choice: T.CheckoutVariantChoice | null) => {
    const selected = this.state.selected

    if (!isNull(choice)) {
      selected[product.id] = choice
    } else {
      delete selected[product.id]
    }

    const products = this.deriveProductList()
    const autoCheckout = products.length === 1

    this.setState({ selected })

    if (autoCheckout) {
      this.handleStartCheckout()
    }
  }

  computeTotals = () => {
    const { selected } = this.state

    const selectedChoices: T.CheckoutVariantChoice[] = values(selected)
    const total = reduce(
      selectedChoices,
      (sum: number, choice: T.CheckoutVariantChoice) => sum + parseFloat(choice.variant.price),
      0,
    )
    const totalMoney = money(total.toString())

    return (
      <span className="checkout-cart">
        <span className="checkout-cart-product-count mr-2">
          {I18n.t('storefront.phytotherapy.product', { count: selectedChoices.length })}
        </span>
        <span className="checkout-cart-total">TOTAL : {totalMoney.toFormat()}</span>
      </span>
    )
  }

  handleStartCheckout = () => {
    const { beginCheckout, storefront } = this.props
    const { selected } = this.state

    const products = build(storefront, 'product') || []
    const choices: T.CheckoutVariantChoice[] = products
      .map((product: T.Product) => selected[product.id])
      .filter((id: string) => !isUndefined(id))

    beginCheckout(choices, this.didBeginCheckout)
  }

  // return an array of every phyto products sorted by recommended OR if there is a prescription ID in url return an array of a single product
  deriveProductList = () => {
    const { storefront, collectionPrescription, profile } = this.props
    const prescription = this.deriveSelectedPrescription()

    if (isUndefined(prescription)) {
      return productList(collectionPrescription, storefront, true, profile)
    }
		return productList([prescription], storefront, false, profile)
  }

  // check if there is a prescription ID in url then return prescription or undefined if not
  deriveSelectedPrescription = () => {
    const { collectionPrescription, match } = this.props

    const prescriptionId = match.params.id
    const prescription = isUndefined(prescriptionId)
      ? undefined
      : find(
        collectionPrescription,
        (prescription: T.Prescription) => prescription.id === parseInt(prescriptionId, 10),
      )
    return prescription
  }

  productSort(items: any[]) {
    const phytoSlugsOrder =
      TARGET_PLATFORM === 'charles'
        ? [
          'libido_active', // Libido Testo + (ex-Libido Homme)
          'emeis_libido_active', // Libido Testo + (ex-Libido Homme)
          'equilibre_serenite', // Stress Protect (ex-Contrôle)
          'emeis_equilibre_serenite', // Stress Protect (ex-Contrôle)
          'safrania', // Sérénité + (ex-Optimisme)
          'emeis_safrania', // Sérénité + (ex-Optimisme)
          'melatonine_plus', // Mélatonine +
          'valeriane_plus', // Valériane +
          'keratine_plus', // Kératine +
          'endoprostate', // Prostate Confort
          'equilibre_emotionnel', // Recharge
          'emeis_equilibre_emotionnel', // Recharge
          'emeis_endoprostate', // Prostate Confort
          'emeis_libido_femme', // Libido Femme
          ]
        : [
          'emeis_libido_femme', // Libido Femme
          'safrania', // Sérénité + (ex-Optimisme)
          'emeis_safrania', // Sérénité + (ex-Optimisme)
          'equilibre_serenite', // Stress Protect (ex-Contrôle)
          'emeis_equilibre_serenite', // Stress Protect (ex-Contrôle)
          'equilibre_emotionnel', // Recharge
          'emeis_equilibre_emotionnel', // Recharge
          'melatonine_plus', // Mélatonine +
          'valeriane_plus', // Valériane +
          'keratine_plus', // Kératine +
          'libido_active', // Libido Testo + (ex-Libido Homme)
          'emeis_libido_active', // Libido Testo + (ex-Libido Homme)
          'endoprostate', // Prostate Confort
          'emeis_endoprostate', // Prostate Confort
        ]

    const slugToPosition: { [key: string]: number } = reduce(
      phytoSlugsOrder,
      (acc: { [key: string]: number }, currentSlug: string, currentPosition: number) => {
        acc[currentSlug] = currentPosition + 1
        return acc
      },
      {},
    )
    const lowPriority = phytoSlugsOrder.length + 1
    const orderedItems = items.sort(
      (a: any, b: any) =>
        (slugToPosition[a.product.slug] || lowPriority) -
        (slugToPosition[b.product.slug] || lowPriority),
    )
    return orderedItems
  }

  render() {
    const { storefront } = this.props
    const { showLoadingScreen } = this.state
    const products = this.deriveProductList()
    const prescription = this.deriveSelectedPrescription()

    const recommendedProducts = filter(products, product => product.recommended)
    const otherProducts = filter(products, product => !product.recommended)

    const orderedOtherProducts = this.productSort(otherProducts)
    const sortedProducts = (recommendedProducts ? recommendedProducts : [])
      .concat(orderedOtherProducts ? orderedOtherProducts : [])

    return (
      <MainContainer title="Phytothérapies">
        <div className="page phytotherapy">
          <div className="home-container">
            <div className="infos-container">
              {showLoadingScreen || !isEmpty(storefront.actionLoading) ? (
                this.renderLoadingScreen()
              ) : (
                <Fragment>
                  {isEmpty(products) ? (
                    <span>Il n'y a pas encore de produits disponibles en phytothérapie</span>
                  ) : (
                    <Fragment>
                      {sortedProducts.length === 1
                        ? this.renderNewProduct(recommendedProducts[0], 0)
                        : map(sortedProducts, (product: any, index: number) =>
                          this.renderProduct(product, index))
                      }
                      {!isEmpty(this.state.selected) && (
                        <div className="sticky-cta d-flex align-items-center justify-content-end p-3 p-md-5">
                          <div className="cart-selection mr-2">{this.computeTotals()}</div>
                          <Button classname="m-0" onClick={() => this.handleStartCheckout()}>
                            Commander
                          </Button>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }

  renderLoadingScreen = () => {
    return (
      <StoreRedirectionScreen
        storeName={'Espace bien-être'}
        redirectionLabel="Vous allez être redirigé vers l'espace bien-être de Charles.co."
        icon={leafImage}
        spinnerColor="#4F785F"
      />
    )
  }

  renderProduct = (product: any, index: number) => (
    <ProductVariantsChoice
      key={`product_${product.id}_choice_${index}`}
      product={product.product}
      recommended={product.recommended}
      recommendedDuration={product.recommendedDuration}
      handleNewSelection={this.selectProductVariant}
    />
  )
  renderNewProduct = (product: any, index: number) => (
    <NewProductVariantsChoice
      key={`product_${product.id}_choice_${index}`}
      product={product.product}
      recommended={product.recommended}
      recommendedDuration={product.recommendedDuration}
      handleNewSelection={this.selectProductVariant}
    />
  )
}

export default withRouter(Phytotherapy)

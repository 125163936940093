import React, { FC, useState } from 'react'
import T from 'types'
import { Workflow } from 'components'
import { map, flatten } from 'lodash'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import AcademyPage from 'page/Pages/UnifiedCheckout/UCFunnel/UCAcademy/AcademyPage'
import SelectionProgram from 'page/Pages/UnifiedCheckout/UCFunnel/UCAcademy/SelectionProgram'
import { withNavigation } from 'components/Workflow'

interface IProps extends T.UnifiedCheckoutStepProps {
  program: T.Program
}

const UCProgram: FC<IProps> = (props: IProps) => {
  const {
    program,
    navigation: { stepIndex, steps, goPrevious, goNext },
    consultation,
  } = props
  const [showSelectionStep, setShowSelection] = useState(false)

  const showProgram = (program: T.Program): void => {
    setShowSelection(true)
  }
  const academyStep: T.WorkflowStep[] = [
    {
      path: 'presentation',
      comp: <AcademyPage {...props} program={program} handleShowProgram={showProgram} />,
    },
    {
      path: 'offres',
      show: () => {
        // TODO - does not work right now, for some reason, this is not called?
        return showSelectionStep
      },
      comp: <SelectionProgram {...props} program={program} />,
    },
  ]

  return (
    <UCStep>
      <Workflow steps={academyStep} complete={goNext} goBack={goPrevious} level={4} />
    </UCStep>
  )
}

export default withNavigation(UCProgram)

import 'constants/sass/custom.sass'
import React from 'react'
import ReactDOM from 'react-dom'
import { Portal } from 'react-portal'
import { Provider } from 'react-redux'
import { Me, Notifications, Popup, SecureChallengeWrapper } from 'containers'
import 'constants/sass/index.sass'
import store from '../store'
import 'formdata-polyfill'
import Dinero from 'dinero.js'
import 'survey-core/i18n/french'
import { settings } from 'survey-core'
settings.autoAdvanceDelay = 0

Dinero.globalLocale = 'fr-FR'
Dinero.defaultCurrency = 'EUR'

const rootElement = document.getElementById('root-profile')

ReactDOM.render(
  <Provider store={store}>
    <Me />
    <Portal>
      <Notifications />
      <Popup />
      <SecureChallengeWrapper />
    </Portal>
  </Provider>,
  rootElement,
)

import React, { Component, Fragment, RefObject } from 'react'
import cx from 'classnames'
import { I18n } from 'react-redux-i18n'
import build from 'redux-object'
import { isEmpty, get, isNil, isUndefined, map, chunk, filter } from 'lodash'
import Block from '../Block'
import T from 'types'
import { Button } from 'components'
import { enrollmentForProgram } from 'utils/data/academy'

interface IProps {
  academy: T.AcademyStore
  interruptEnrollment: (id: number) => void
}

class ProgramManager extends Component<IProps> {
  public handleInterrupt = (enrollmentId: number) => {
    const { interruptEnrollment } = this.props
    interruptEnrollment(enrollmentId)
  }
  public render() {
    const { academy } = this.props

    const enrollments = filter(build(academy, 'enrollment'), (enrollment) => enrollment.state !== "pending")
    if (isEmpty(enrollments)) {
      return null
    }
    return (
      <Block title={'Programmes en cours'} classname={'container-m-800 mt-5'}>
        {map(enrollments, enrollment => (
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              {enrollment.program.title} - {I18n.t(`enrollmentState.${enrollment.state}`)}
            </div>
            <div>
              <Button classname={cx('btn-small', { 'invisible' : !enrollment.isInterruptable })} onClick={() => this.handleInterrupt(enrollment.id)}>
                Interrompre
              </Button>
            </div>
          </div>
        ))}
      </Block>
    )
  }
}

export default ProgramManager

import React from 'react'
import './styles.sass'
import { SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import { I18n } from 'react-redux-i18n'

export const MangopayBanner = () => {

  const ranges = [
    { from: '2023-04-26T00:00:00+02:00', to: '2023-04-26T08:00:00+02:00' },
    { from: '2023-04-27T00:00:00+02:00', to: '2023-04-27T06:00:00+02:00' },
    { from: '2023-04-28T00:00:00+02:00', to: '2023-04-28T04:00:00+02:00' },
  ]
  const isBetweenRanges = () => {
    const now = new Date()
    for (let i = 0; i < ranges.length; i++) {
      const from = new Date(ranges[i].from)
      const to = new Date(ranges[i].to)
      if (now >= from && now <= to) {
        return true
      }
    }
    return false
  }

  if (isBetweenRanges())  {
    return (
      <div className="mangopay-banner">
        <div>Pour des raisons de maintenance, <u>le paiement est actuellement indisponible</u> jusqu'à 8h00 (CET)</div>
        <a href="mailto:contact@charles.co" className="d-flex ml-3 font-weight-normal">
          <SvgIcon classname="mr-2 icon-16px" icon={getIcon('blocks.letter')} />
          {I18n.t(`academy.charlesEmail`)}
        </a>
      </div>
    )
  }
  return null
}

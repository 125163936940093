import { connect } from 'react-redux'
import {
  getTaxons,
  getProducts,
  beginCheckout,
  getPrescriptionPdf,
  getPrescriptions,
  getAuthProfile,
  createCart,
  addToCart
} from 'actions'
import WowtechProduct from 'page/Pages/WowtechProduct'
import T from 'types'

const mapStateToProps = ({ auth: { profile }, storefront }: T.Store) => ({
  profile,
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getProducts: () => dispatch(getProducts()),
  createCart: () => dispatch(createCart()),
  addToCart: (variant: T.Variant, onSuccess: Function) =>
    dispatch(addToCart(variant, onSuccess)),
  getTaxons: () => dispatch(getTaxons()),
  getPrescriptions: () => dispatch(getPrescriptions()),
  beginCheckout: (variants: T.CheckoutVariantChoice[], onSuccess: Function) =>
    dispatch(beginCheckout(variants, onSuccess)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WowtechProduct)

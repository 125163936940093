import { connect } from 'react-redux'
import Prescription from 'page/Pages/Prescription'
import { getPharmacies } from 'actions'
import T from 'types'

const mapStateToProps = ({
  pharmacy: { collectionPharmacies },
}: T.Store) => ({
  collectionPharmacies,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getPharmacies: () => dispatch(getPharmacies()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Prescription)

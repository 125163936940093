import React, { Component, Fragment } from 'react'
import moment from 'constants/moment'
import { reverse, sortBy, isUndefined, uniqueId } from 'lodash'
import cx from 'classnames'
import { Button, CouponInput, SvgIcon } from 'components'
import schema from 'utils/models/schemas/registeredCreditCard'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import T from 'types'
import securePaymentSvg from 'assets/icons/payment/methods/securePayment.svg'

import './styles.sass'

interface IProps {
  cgu?: string
  terms?: string
  loading?: boolean
  profile: T.Profile
  submitPayment: (p: T.PaymentPayload) => void
  paymentButtonLabel: string
  onAddNewCard: () => void
  applyCoupon?: (coupon: string) => void
  activeCoupon?: string
}

class RegisteredCreditCardForm extends Component<IProps> {

  uniquePrefix: string

  constructor(props: IProps) {
    super(props)
    this.uniquePrefix = uniqueId()
  }

  public handleGlobalSubmit = (data: any) => {
    const { submitPayment } = this.props

    const paymentPayload: T.ExistingCardPayload = {
      existingCard: data.registeredCardId,
    }

    submitPayment(paymentPayload)
  }

  public render() {
    const { profile, cgu, terms, paymentButtonLabel, loading, onAddNewCard, applyCoupon, activeCoupon } = this.props
    const creditCards = reverse(
      sortBy(profile.creditCards, creditCard => moment([creditCard.year, creditCard.month - 1])),
    )
    if (isUndefined(creditCards) || creditCards.length === 0) {
      return null
    }
    const firstCreditCard = creditCards[0]
    return (
      <Fragment>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            cguChecked: isUndefined(cgu),
            termsChecked: isUndefined(terms),
            registeredCardId: firstCreditCard.id.toString(),
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="registered-cc-payment-form">
              <div className="registered-card-container mb-2">
                <div className="card-container-header">
                  <span className="paymentExpiry">Vos cartes de paiement</span>
                  <span className="paymentExpiry">Date d'expiration</span>
                </div>
                <ul className="card-list">
                  {creditCards.map((card, index) => {
                    const expired = RegisteredCreditCardForm.isCardExpired(card)
                    const checked = values.registeredCardId === card.id.toString()
                    return (
                      <li
                        key={`card-list_${index}`}
                        className={cx('card-list-element', { expired })}
                      >
                        <label htmlFor={this.scopedIdFor(index)} className="row">
                          <div className="col-1 px-0">
                            <Form.Check
                              type="radio"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id={this.scopedIdFor(index)}
                              className="default-radio"
                              checked={checked}
                              name="registeredCardId"
                              value={card.id}
                            />
                          </div>
                          <div className="col-4 col-sm-5 px-0 card-list-element-type">
                            <div className="d-none d-sm-block">
                              {this.formattedCardType(card.ccType)[0]}
                            </div>
                            <div className="d-sm-none">
                              {this.formattedCardType(card.ccType)[1]}
                            </div>
                          </div>
                          <div className="col-4 col-sm-3 px-0">***-{card.lastDigits}</div>
                          <div className="col-3 px-0 text-right">
                            <div className="d-none d-sm-block">
                              {expired ? 'Expirée' : `${card.month}/${card.year}`}
                            </div>
                            <div className="d-sm-none">
                              {expired ? 'Exp.' : `${card.month}/${this.formatYear(card.year)}`}
                            </div>
                          </div>
                        </label>
                      </li>
                    )
                  })}
                </ul>
                <a className="switch-credit-card-method" onClick={() => onAddNewCard()}>
                  Ajouter une nouvelle carte
                </a>
              </div>
              {applyCoupon && (
                <CouponInput activeCoupon={activeCoupon} loading={loading} applyCoupon={applyCoupon} />
              )}
              <div>
                {cgu && (
                  <Form.Group>
                    <Form.Check id={this.scopedIdFor("registered-cc-cgu")}>
                      <Form.Check.Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.cguChecked}
                        checked={values.cguChecked}
                        name="cguChecked"
                      />
                      <Form.Check.Label className="cgu-label">
                        <span dangerouslySetInnerHTML={{ __html: cgu }} />
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.cguChecked}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                )}
                {terms && (
                  <Form.Group>
                    <Form.Check id={this.scopedIdFor("registered-cc-terms")}>
                      <Form.Check.Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.termsChecked}
                        checked={values.termsChecked}
                        name="termsChecked"
                      />
                      <Form.Check.Label className="cgu-label">
                        <span dangerouslySetInnerHTML={{ __html: terms }} />
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.termsChecked}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                )}
              </div>
              <Button classname="btn-block" type="submit" loading={loading}>
                <SvgIcon icon={securePaymentSvg} classname="button-inline-svg" />
                {paymentButtonLabel}
              </Button>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }

  private formattedCardType(type: string) {
    let formattedType
    if (type == null) {
      formattedType = ['Carte Bleue', 'CB']
    } else if (type === 'jcb') {
      formattedType = ['JCB', 'JCB']
    } else if (type === 'visa') {
      formattedType = ['VISA', 'VISA']
    } else if (type === 'american_express') {
      formattedType = ['American Express', 'Amex']
    } else {
      const cardType = type.charAt(0).toUpperCase() + type.slice(1)
      formattedType = [cardType, cardType]
    }

    return formattedType
  }

  public static isCardExpired(card: any) {
    const expiryDate = moment([card.year, card.month - 1])
    const endOfMonth = expiryDate.endOf('month')
    return endOfMonth.isBefore(moment())
  }

  private formatYear(year: number) {
    return (year % 100)
  }

  private scopedIdFor = (id: number | string) => {
    return `${this.uniquePrefix}_${id.toString()}`
  }
}

export default RegisteredCreditCardForm

import React, { Component, Fragment } from 'react'
import { get, upperCase, isUndefined, filter, join, uniqueId } from 'lodash'
import { Button } from 'components'
import schema from 'utils/models/schemas/paypal'
import { Formik } from 'formik'
import { Form, Col } from 'react-bootstrap'
import SvgIcon from 'components/SvgIcon'
import { I18n } from 'react-redux-i18n'
import T from 'types'
import paypalPaymentSVG from 'assets/icons/payment/methods/paypal.svg'
import securePaymentSvg from 'assets/icons/payment/methods/securePayment.svg'

import './styles.sass'

interface IProps {
  cgu?: string
  terms?: string
  loading?: boolean
  profile?: T.Profile
  submitPayment: (p: T.PaymentPayload) => void
  paymentMethod: T.PaymentMethodHash
  paymentButtonLabel: string
}

class PaypalForm extends Component<IProps> {

  uniquePrefix: string

  constructor(props: IProps) {
    super(props)
    this.uniquePrefix = uniqueId()
  }

  public handleGlobalSubmit = (data: T.PaypalAccountPayload) => {
    const { submitPayment, paymentMethod } = this.props

    const paymentPayload : T.PaymentMethodWithData = {
      paymentMethod,
      data: data,
    }
    submitPayment(paymentPayload)
  }

  public render() {
    const { cgu, terms, paymentButtonLabel, loading, profile } = this.props

    const defaultFirstName = get(profile, 'firstName', '') || ""
    const defaultLastName = get(profile, 'lastName', '') || ""
    const defaultCreditCardHolderName = upperCase(`${defaultFirstName} ${defaultLastName}`)

    return (
      <Fragment>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: defaultCreditCardHolderName,
            cguChecked: isUndefined(cgu),
            termsChecked: isUndefined(terms),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="paypal-payment-form">
              <div className="">
                <Form.Group controlId={this.scopedIdFor("paypal-name")}>
                  <Form.Label>{I18n.t(`paymentForm.fields.labels.fullName`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.name}
                    placeholder={I18n.t(`paymentForm.fields.placeholders.name`)}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                {cgu && (
                  <Form.Group>
                    <Form.Check id={this.scopedIdFor("paypal-cgu")}>
                      <Form.Check.Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.cguChecked}
                        checked={values.cguChecked}
                        name="cguChecked"
                      />
                      <Form.Check.Label className="cgu-label">
                        <span dangerouslySetInnerHTML={{ __html: cgu }} />
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.cguChecked}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                )}
                {terms && (
                  <Form.Group>
                    <Form.Check id={this.scopedIdFor("paypal-terms")}>
                      <Form.Check.Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.termsChecked}
                        checked={values.termsChecked}
                        name="termsChecked"
                      />
                      <Form.Check.Label className="cgu-label">
                        <span dangerouslySetInnerHTML={{ __html: terms }} />
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.termsChecked}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                )}
              </div>
              <Button
                classname="btn-block"
                type="submit"
                loading={loading}
              >
                <SvgIcon icon={securePaymentSvg} classname="button-inline-svg" />
                {paymentButtonLabel}
              </Button>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }

  private scopedIdFor = (id: number | string) => {
    return `${this.uniquePrefix}_${id.toString()}`
  }
}

export default PaypalForm

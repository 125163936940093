import { connect } from 'react-redux'
import PurchasePrescription from 'page/Pages/PurchasePrescription'
import {
  purchasePrescription,
  startPurchasePrescription,
  getPaymentMethods
} from 'actions'
import T from 'types'

const mapStateToProps = ({
  prescriptions: { focusedPrescription, lastPrescription, focusedDeliveryOrder, loading },
  auth: { profile },
  storefront
}: T.Store) => ({
  profile,
  loading,
  prescription: focusedPrescription || lastPrescription,
  order: focusedDeliveryOrder,
  storefront
})

const mapDispatchToProps = (dispatch: Function) => ({
  purchasePrescription: (prescription: T.Prescription, data: T.PaymentPayload, onSuccess: () => void) =>
    dispatch(purchasePrescription(prescription, data, onSuccess)),
  startPurchasePrescription: (prescription: T.Prescription) =>
    dispatch(startPurchasePrescription(prescription)),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePrescription)

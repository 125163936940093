import React, { Component, Fragment, RefObject } from 'react'
import ReactJWPlayer from 'react-jw-player'
import cx from 'classnames'
import build from 'redux-object'
import { isUndefined, map, first, find } from 'lodash'
import T from 'types'

export interface Props {
  isUnlocked: boolean
  isProgramUnlocked: boolean
  videoPayload?: T.JWPayload
  videoThumbnail?: string
  startVideo: () => void
  startPurchase: () => void
  isWaitingForPayment: boolean
  programTitle: string
}

class VideoViewer extends Component<Props> {
  public render() {
    const {
      videoThumbnail,
      videoPayload,
      isUnlocked,
      isProgramUnlocked,
      startVideo,
      startPurchase,
      isWaitingForPayment,
      programTitle
    } = this.props
    const playlist = isUndefined(videoPayload) ? {} : videoPayload.playlist
    return (
      <div className="video-thumbnail-container">
        {isUnlocked ? (
          <ReactJWPlayer
            playerId="video-player"
            playerScript="https://cdn.jwplayer.com/libraries/o51p7o8S.js"
            playlist={playlist}
            // the onPlay callback is only fired once, even though the video has changed
            onVideoLoad={(event: any) => startVideo()}
          />
        ) : (
          <div className="">
            <div className="video-thumbnail-cover d-flex text-center align-items-center justify-content-center">
              {isProgramUnlocked ? (
                <div className="p-4">
                  Vous devez d'abord compléter les séances précédentes avant de débloquer celle-ci.
                </div>
              ) : (
                <div className="p-4">
                  {isWaitingForPayment ? `Je reprends le ${programTitle}` : `Je commence le ${programTitle}`}
                  <br />
                  <button className="mt-3 btn btn-primary" onClick={() => startPurchase()}>
                    {isWaitingForPayment ? "Continuer le programme" : "Commencer"}
                  </button>
                </div>
              )}
            </div>
            <img className="img-fluid w-100 video-thumbnail-cover-image" src={videoThumbnail} />
          </div>
        )}
      </div>
    )
  }
}

export default VideoViewer

import React, { Component, Fragment } from 'react'
import T from 'types'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Button } from 'components'
import DoctorDetails from './DoctorDetails'
import { ContextualDoctorProfile } from 'containers'
import { isUndefined } from 'lodash'
import { I18n } from 'react-redux-i18n'

interface IProps {
  doctor?: T.Doctor | undefined
  loading?: boolean
  medium: string
  slot?: Date | undefined
  handleChangeDoctor: () => void
  handleAcceptDoctor: () => void
}

class SubStepAutochosenDoctorConfirmation extends Component<IProps> {
  private renderAutoChosenDocConfirmation() {
    const { doctor, loading, medium, slot, handleAcceptDoctor, handleChangeDoctor } = this.props
    if (isUndefined(doctor)) {
      return null
    }
    return (
      <LayoutStep>
        <LayoutMain variant="medium">
          <h3>{I18n.t('onboarding.practitionerSelection.autoSelectedStepTitle')}</h3>

          <div className="doctor-summary mt-4">
            <ContextualDoctorProfile
              loading={loading}
              doctor={doctor}
              showBorders={false}
              selectedSlot={slot}
              isRecap={true}
              medium={medium}
              callToAction={
                <div className="mt-4">
                  <Button onClick={handleAcceptDoctor} loading={loading}>
                    Valider
                  </Button>
                </div>
              }
            >
            </ContextualDoctorProfile>
          </div>
          <div className="mt-2">
            <Button variant="btn-outline-primary mx-auto" onClick={handleChangeDoctor}>{I18n.t('onboarding.practitionerSelection.chooseOther')}</Button>
          </div>
        </LayoutMain>
      </LayoutStep>
    )
  }

  public render() {
    return <div className="">{this.renderAutoChosenDocConfirmation()}</div>
  }
}

export default SubStepAutochosenDoctorConfirmation

import React, { Component, Fragment } from 'react'
import T from 'types'
import { get } from 'lodash'
import { I18n } from 'react-redux-i18n'
import {
  dateFormater,
} from 'utils/helpers'
import './styles.sass'

interface IProps {
  analysisComment: T.AnalysisComment,
  hidePopup: () => void
}

class MedicalDocumentAnalysisComment extends Component<IProps> {

  private handleClosePopup() {
    const { hidePopup } = this.props
    hidePopup()
  }

  private hasLinkToNewConsultation() {
    const { analysisComment } = this.props
    if (analysisComment.subject == 'consultation_needed')
      return <div className="close-popup">
               <a className="btn btn-primary" onClick={() => this.handleClosePopup()} href="/me.html#/new-consultation">Nouvelle consultation</a>
             </div>
    return null;
  }

  private hasWarning() {
    const { analysisComment } = this.props
    if (analysisComment.subject == 'consultation_needed')
      return <p className='container-warning'>
               <span className='analysis-warn analysis-warning'></span>
               <span className='analysis-warning-message'>Aprés l'analyse de vos résultats médicaux, le médecin préconise fortement une nouvelle consultation avec vous</span>
             </p>
    return null
  }

  public render() {
    const { analysisComment } = this.props
    return (
      <div>
        <div className="doctor-bloc-note">
          <img className='doctor-image' src={ analysisComment.doctor.avatarUrl } alt="" />
          <p className='extra-margin'>
            <span className='analysis-infos doctor-name'>Dr { analysisComment.doctor.fullName }, { dateFormater(get(analysisComment, 'createdAt'), 'LLL') }</span>
            <p className='extra-margin'>
              <span className='analysis-infos consultation-result'>Résultat:</span>
              <span className={ "analysis-infos analysis-notice " + analysisComment.subject }>{ I18n.t(`medicalDocuments.${analysisComment.subject}`) }</span>
            </p>
            { this.hasWarning() }
            <div className="analysis-comment-body">{ analysisComment.body }</div>
          </p>
        </div>
        { this.hasLinkToNewConsultation() }
      </div>
    )
  }
}

export default MedicalDocumentAnalysisComment

import React, { Component, Fragment } from 'react'
import moment from 'constants/moment'
import { LayoutStep, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { filter, includes, isEmpty, sortBy } from 'lodash'
import ConsultationBookingList from './ConsultationBookingList'
import { I18n } from 'react-redux-i18n'
import UnavailabilityNotice from './UnavailabilityNotice'
import T from 'types'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import { CHAT, SIMPLE } from 'constants/doctorSelectionStates'

interface IProps {
  collection: any[]
  setChosenTimeSlot: (value: any) => void
  setChosenDoctor: (doctor: T.Doctor) => void
  confirm: () => void
  slot?: Date
  consultation: string
  medium: string
  loading?: boolean
}

interface IState {
  chosenSlot: Date | undefined
  chosenDoctor: T.Doctor | undefined
}

class SubStepDoctorChoice extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      chosenSlot: undefined,
      chosenDoctor: undefined,
    }
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleChosenTimeSlot = this.handleChosenTimeSlot.bind(this)
    this.handleChosenDoctor = this.handleChosenDoctor.bind(this)
  }

  private handleConfirm() {
    const { setChosenDoctor, confirm } = this.props
    setChosenDoctor(this.state.chosenDoctor as T.Doctor)
    confirm()
  }

  private handleChosenTimeSlot(slot: Date) {
    const { setChosenDoctor, setChosenTimeSlot, confirm } = this.props
    this.setState({ chosenSlot: slot }, () => {
      setChosenDoctor(this.state.chosenDoctor as T.Doctor)
      setChosenTimeSlot(this.state.chosenSlot)
      confirm()
    })
  }
  private handleChosenDoctor(doctor: T.Doctor) {
    this.setState({ chosenDoctor: doctor })
  }
  private sortDoctors(doctors: T.Doctor[]): T.Doctor[] {
    const { medium, consultation } = this.props
    if (medium === CHAT) {
      return doctors
    }

    return doctors.sort(
      (a: T.Doctor, b: T.Doctor): number => {
        const nextAvailability = (doctor: T.Doctor): moment.Moment =>
          moment(
            consultation === SIMPLE
              ? doctor.nextFreeSimpleTimeSlot
              : doctor.nextFreeComplexTimeSlot,
          )
        return nextAvailability(a).diff(nextAvailability(b))
      },
    )
  }

  public render() {
    const { medium, consultation, collection, slot, loading } = this.props

    const noticeInfoVisio = I18n.t('onboarding.noticeInfo.visio')

    const filteredCollection = filter(
      collection,
      (doctor: T.Doctor) =>
        includes(doctor.communicationChannels, medium) && // filter out doctor without required channel
        // filter out doctor without required consultation type
        includes(doctor.consultationTypes, consultation) &&
        // keep all if chat
        (medium === CHAT ||
          // filter out doctor without any availability for given consultation type
          !isEmpty(
            doctor[consultation === 'simple' ? 'freeSimpleSlots' : 'freeComplexSlots'],
          )),
    )
    const sortedCollection = sortBy(this.sortDoctors(filteredCollection), (d) => !d.available)

    return (
      <LayoutStep>
        <LayoutMain variant="medium">
          {isEmpty(filteredCollection) && medium !== CHAT ? (
            <UnavailabilityNotice />
          ) : (
            <Fragment>
              <h3>{I18n.t('onboarding.practitionerSelection.stepTitle')}</h3>
              {false && <p className="notice-info-visio">{medium !== CHAT && noticeInfoVisio}</p>}
              <ConsultationBookingList
                loading={loading}
                collection={sortedCollection}
                setChosenDoctor={this.handleChosenDoctor}
                setChosenTimeSlot={this.handleChosenTimeSlot}
                confirm={this.handleConfirm}
                medium={medium}
                consultationType={consultation}
                slot={slot}
              />
            </Fragment>
          )}
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default SubStepDoctorChoice

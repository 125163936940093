import { connect } from 'react-redux'
import {
  setChosenDoctor,
  setChosenTimeSlot,
  setChosenMedium,
  setChosenConsultType,
  getSurveyAndSubmission,
  setSurveySubmission,
} from 'actions'
import { DoctorSelection } from 'components'
import T from 'types'

const mapStateToProps = ({
  surveys: { focusedSurvey },
  ux: { lastFocusedLocation },
}: T.Store) => ({
  focusedSurvey,
  lastFocusedLocation,
})

const mapDispatchToProps = (dispatch: Function) => ({
  setChosenDoctor: (doctor: T.Doctor) => dispatch(setChosenDoctor(doctor)),
  setChosenTimeSlot: (slot: Date) => dispatch(setChosenTimeSlot(slot)),
  setChosenMedium: (medium: string) => dispatch(setChosenMedium(medium)),
  setChosenConsultType: (consultType: string) => dispatch(setChosenConsultType(consultType)),
  getSurveyAndSubmission: (specialty: string, onSuccess: () => void) => dispatch(getSurveyAndSubmission(specialty, onSuccess)),
  setSurveySubmission: (surveyData: any) => dispatch(setSurveySubmission(surveyData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoctorSelection)

import { useCallback, useEffect, useState } from "react";
import useDevicePermission from "helpers/hooks/useDevicePermission";
import useDevicesListing, { DeviceListing } from "helpers/hooks/useDevicesListing";
import { isNil } from "lodash";

export enum WizardState {
  PERMISSION_PROMPT = 'permission_prompt',
  PERMISSION_PROMPT_OPEN = 'permission_prompt_open',
  PERMISSION_TROUBLESHOOT = 'permission_troubleshoot',
  DEVICE_MISSING = 'device_missing',
  SUCCESS = 'success'
}

export type WizardStepProps = {
  config: MediaConfig
  trackingConfig: MediaSetupTrackingContext
  onSuccess: () => void
  onDeviceMissing: () => void
  onSkipped: () => void
}

export type MediaConfig = {
  devices: MediaDeviceInfo[]
  hasCamera: boolean
  hasMic: boolean
  hasSpeaker: boolean
  initializing: boolean
  promptOpened: boolean
  promptRefusedToOpen: boolean
  promptError: PromptError
  permissionForMic: PermissionState
  permissionForCamera: PermissionState
  wizardState: WizardState
  promptForVisioPermissions: () => void
}

export type MediaSetupTrackingContext = {
  context: 'onboarding' | 'personalSpace'
  consultationId?: number
}

export type PromptError = {
  name: string
  message: string
} | null


const useVisioConfiguration = (): MediaConfig => {

  const { devices, refreshDevices }: DeviceListing = useDevicesListing()
  const [promptOpened, setPromptOpened] = useState<boolean>(false)
  const [promptAccepted, setPromptAccepted] = useState<boolean>(false)
  const [promptRefusedToOpen, setPromptRefusedToOpen] = useState<boolean>(false)
  const [error, setError] = useState<PromptError>(null)
  const micInfo = useDevicePermission('microphone', promptAccepted)
  const cameraInfo = useDevicePermission('camera', promptAccepted)

  const promptForVisioPermissions = useCallback(() => {
    setPromptOpened(true)
    const startTime = Date.now()
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        setTimeout(() => stream.getTracks().forEach((track: any) => { track.stop() }), 500)
        setError(null)
        setPromptOpened(false)
        setPromptAccepted(true)

      })
      .catch(err => {
        const delta = Date.now() - startTime;
        let errorMessage = 'Erreur lors de la configuration de la caméra et du micro'
        if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
          errorMessage = `Votre caméra et/ou votre micro n'ont pas bien été détectés, êtes-vous sûr d'avoir une caméra et un micro branchés ?`
        } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
          errorMessage = 'Votre caméra ou votre micro est actuellement utilisé par une autre application. Veuillez fermer les applications qui utilisent votre caméra ou votre micro et recharger la page pour réessayer.'
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError' ||
          err.name === 'PermissionDismissedError'
        ) {
          errorMessage = "Vous devez autoriser l'accès à votre caméra et votre micro. Veuillez modifier les réglages de votre navigateur et réessayer."
        }
        setError({ name: err.name, message: errorMessage })
        setPromptOpened(false)
        setPromptRefusedToOpen(delta < 500)
      })
  }, [setError, setPromptOpened, setPromptRefusedToOpen])

  useEffect(() => {
    refreshDevices()
  }, [cameraInfo.permissionState, micInfo.permissionState])

  const hasDevice = useCallback((kind: MediaDeviceKind) => {
    return devices.some(device => device.kind === kind)
  }, [devices])

  const hasCamera = hasDevice('videoinput')
  const hasMic = hasDevice('audioinput')
  const hasSpeaker = hasDevice('audiooutput')

  const wizardState = useCallback((): WizardState => {
    if (hasMic && hasCamera){
      return WizardState.SUCCESS;
    } else if (!isNil(error) || micInfo.permissionState === "denied" || cameraInfo.permissionState === "denied") {
      return WizardState.PERMISSION_TROUBLESHOOT;
    } else if (micInfo.permissionState === "prompt" || cameraInfo.permissionState === "prompt") {
        return promptOpened
        ? WizardState.PERMISSION_PROMPT_OPEN
        : WizardState.PERMISSION_PROMPT;
    } else if (!hasDevice("videoinput") || !hasDevice("audioinput")) {
      return WizardState.DEVICE_MISSING;
    } else {
      return WizardState.SUCCESS;
    }
  }, [promptOpened, micInfo.permissionState, cameraInfo.permissionState, hasCamera, hasMic, hasSpeaker])();

  return {
    devices,
    hasCamera,
    hasMic,
    hasSpeaker,
    initializing: micInfo.initializing || cameraInfo.initializing,
    permissionForMic: micInfo.permissionState,
    permissionForCamera: cameraInfo.permissionState,
    promptOpened,
    promptError: error,
    promptForVisioPermissions,
    promptRefusedToOpen,
    wizardState,
  }
}

export default useVisioConfiguration;
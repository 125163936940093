import React, { Component } from 'react'
import { Navbar as BootstrapNavbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getIcon } from 'assets/icons'
import SvgIcon from '../SvgIcon'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import './styles.sass'

interface IProps {
  sidebarActive: boolean
  toggleSidebar: (value: boolean) => void
}

export default class Navbar extends Component<IProps> {
  public render() {
    const { sidebarActive, toggleSidebar } = this.props
    return (
      <BootstrapNavbar fixed="top" className="personal-space-navbar">
        <Link to="/home">
          <span className="navbar-brand">
            <SvgIcon classname="icon" icon={getIcon(IS_CHARLES_PLATFORM ? "logo.charles" : "logo.mia")} />
          </span>
        </Link>
        <SvgIcon classname="icon navbar-burger" icon={getIcon("shapes.burger")} onClick={() => this.props.toggleSidebar(!sidebarActive)} />
      </BootstrapNavbar>
    )
  }
}

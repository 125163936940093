import React, { Component, Fragment } from 'react'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import { Link } from 'react-router-dom'
import { isNil, isUndefined } from 'lodash'
import { LayoutStep, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Teleconsultation, SvgIcon } from 'components'
import { ContextualDoctorProfile } from 'containers'
import { getIcon } from 'assets/icons'
import T from 'types'
import '../styles.sass'


const OpenConsultationConfirm = (props: {
  doctor: T.Doctor | undefined
  loading?: boolean
  medium: string
  slot: Date | undefined
}) => {

  const { doctor, loading, medium, slot } = props
  if (isUndefined(doctor)) {
    return null
  }

  return (
    <>
      <SvgIcon icon={getIcon("clinic.calendar")} classname="booking-payment-confirmed-icon" />
      <h3 className="mb-3">
        Votre rendez-vous est confirmé
      </h3>
      <div className="confirmation-text">
        Vous allez recevoir un <b>email de confirmation avec l'heure et la date</b> de votre rendez-vous.<br />
        (Pensez à vérifier vos messages indésirables).
      </div>
      <div className="doctor-summary open-consultation">
        <ContextualDoctorProfile
          loading={loading}
          doctor={doctor}
          showBorders={false}
          selectedSlot={slot}
          isRecap={true}
          medium={medium}
          callToAction={
            <span></span>
          }
        >
        </ContextualDoctorProfile>
      </div>
      <Link className="btn btn-block btn-primary return-home-button"
        to="/home"
      >
          <strong>Revenir à mon espace patient</strong>
      </Link>
    </>
  )
}


class BCConfirmation extends Component<BookingConsultationStepProps> {
  public render() {
    const {
      consultation,
      profile,
      consultationSpecialty,
      doctor,
      loading,
      slot,
      medium,
    } = this.props

    const isOpenConsultation = consultationSpecialty === "open_consultation"

    if (isNil(consultation)) {
      return <Fragment />
    } else if (isOpenConsultation) {
      return (
        <LayoutStep>
          <LayoutMain variant={"medium"}>
            <OpenConsultationConfirm
              doctor={doctor}
              loading={loading}
              slot={slot}
              medium={medium}
            />
          </LayoutMain>
        </LayoutStep>
      )
    }
    else {
      return (
        <Teleconsultation
          title="Votre prochaine consultation"
          consultation={consultation}
          profile={profile}
          summaryOnly={true}
        />
      )
    }
  }
}

export default withNavigation(BCConfirmation)

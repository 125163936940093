import { connect } from 'react-redux'
import SleepCalendarMenu from 'page/Pages/Academy/SleepCalendarMenu'
import build from 'redux-object'
import { first } from 'lodash'
import T from 'types'
import {
  getSleepCalendar,
  createSleepCalendarItem,
  updateSleepCalendar,
  getSleepCalendarItems,
  updateSleepCalendarItem,
  lowerCalendarBedtime,
  increaseCalendarBedtime,
  skipCalendarBedtime
} from 'actions'


const mapStateToProps = ({
  academy
}: T.Store) => {

  const sleepCalendar = first(build(academy, 'sleepCalendar')) as T.SleepCalendar
  const sleepCalendarItems = build(academy, 'sleepCalendarItem') as T.SleepCalendarItem[]

  return {
    sleepCalendar,
    sleepCalendarItems
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  getSleepCalendar: () => dispatch(getSleepCalendar()),
  lowerCalendarBedtime: () => dispatch(lowerCalendarBedtime()),
  increaseCalendarBedtime: () => dispatch(increaseCalendarBedtime()),
  skipCalendarBedtime: () => dispatch(skipCalendarBedtime()),
  createSleepCalendarItem: (data: object, onSuccess: any) => dispatch(createSleepCalendarItem(data, onSuccess)),
  updateSleepCalendar: (params: object) => dispatch(updateSleepCalendar(params)),
  getSleepCalendarItems: () => dispatch(getSleepCalendarItems()),
  updateSleepCalendarItem: (sleepCalendarItemId: number, params: object) => dispatch(updateSleepCalendarItem(sleepCalendarItemId, params))
})

export default connect(mapStateToProps, mapDispatchToProps)(SleepCalendarMenu)

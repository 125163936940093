import * as A from 'constants/actions'
import store from '../store'
import { ApiService, StorefrontApiService } from 'services'
import T from 'types'
import { showNotification } from './ux'

export const getPostConsultationRecommendations = (id: number) => ({
  type: A.GET_POST_CONSULTATION_DETAILS,
  promise: ApiService.getPostConsultationRecommendations(id)
})

export const getLastFinishedConsultation = () => ({
  type: A.GET_LAST_FINISHED_CONSULTATION,
  promise: ApiService.getLastFinishedConsultation()
})

export const startUnifiedCheckout = (successCallback: Function) => ({
  type: A.START_UNIFIED_CHECKOUT,
  promise: StorefrontApiService.beginCheckout([]),
  meta: {
    onSuccess: (response: any) => {
      successCallback(response)
    },
  },
})

export const setCurrentStore = (pharmacistId: number, orderNumber: string) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('info', 'Pharmacie enregistré avec succès'))
    }
  },
  promise: ApiService.updateCurrentStore(pharmacistId, orderNumber),
  type: A.SET_CURRENT_STORE,
})

export const storeUnifiedCheckoutPharmacist = (pharmacistId: number) => ({
  type: A.STORE_UNIFIED_CHECKOUT_PHARMACIST,
  payload: pharmacistId
})

export const storePatientCurrentCountry = (frenchCitizen: boolean) => ({
  type: A.STORE_PATIENT_CURRENT_COUNTRY,
  payload: frenchCitizen
})

export const setDrugsFunnelChoice = (forPrescriptions: boolean) => ({
  type: A.SET_DRUGS_FUNNEL_CHOICE,
  payload: forPrescriptions
})

export const getRecommendedProducts = (orderNumber: string, consultationId: number, pharmacistId: number | undefined) => ({
  type: A.GET_RECOMMENDED_PRODUCTS,
  promise: ApiService.getDrugsVariants(orderNumber, consultationId, pharmacistId).then(({ data }) => ({ drugsVariants: data }))
})

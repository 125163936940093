import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import { isUndefined } from 'lodash'
import classnames from 'classnames'
import SvgIcon from '../SvgIcon'
import { getIcon } from 'assets/icons'
import T from 'types'
import './styles.sass'

interface IProps {
  classNotification: string,
  notificationShow: boolean,
  notificationText: string,
  hideNotification: () => void,
}

class Notifications extends Component<IProps> {
  hideInterval?: number

  private handleCloseNotification() {
    const { hideNotification } = this.props

    hideNotification()
  }

  public render() {
    const { classNotification, hideNotification, notificationShow } = this.props
    // Eager-loading icons to prevent coding errors
    const pictos: T.IconsMap = {
      success: getIcon('notifications.success'),
      warning: getIcon('notifications.warning'),
      info: getIcon('notifications.info'),
      error: getIcon('notifications.error'),
    }
    if (isUndefined(pictos[classNotification])) {
      return null
    }
    clearInterval(this.hideInterval)
    this.hideInterval = setTimeout(() => hideNotification(), 4000) as unknown as number
  
    return (
      <>
        {this.props.notificationText.length <= 70 &&
          <div className={classnames('notification', classNotification, { active: notificationShow })}>
            <div className="picto-container" />
            <SvgIcon
                classname="icon-state"
                icon={pictos[classNotification]}
              />
            <div className="message-container">
              <div className="message-body">
                <h6>{I18n.t(`notifications.${this.props.classNotification}`)}</h6>
                  <p>{this.props.notificationText}</p>
              </div>
            </div>
            <div className="button-box">
              <div className="notification-separator-barre" />
              <button
                onClick={() => this.handleCloseNotification()}
              >
                <p className="button-box-close">Fermer</p>
              </button>
            </div>
          </div>
        }
          <div className={classnames('notification-over', classNotification, { active: notificationShow })}>
            <div className="picto-container" />
            <SvgIcon
                classname="icon-state"
                icon={pictos[classNotification]}
              />
            <div className="message-container">
              <div className="message-body">
                <h6>{I18n.t(`notifications.${this.props.classNotification}`)}</h6>
                  <p>{this.props.notificationText}</p>
              </div>
            </div>
            <div className="button-box">
              <div className="notification-separator-barre" />
              <button
                onClick={() => this.handleCloseNotification()}
              >
                <p className="button-box-close">Fermer</p>
              </button>
            </div>
          </div>
    </>
    )
  }
}

export default Notifications
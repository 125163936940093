import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { SvgIcon, CustomDropdown, Button } from 'components'
import {
  productFrequencyForVariant, variantOptionsAsObject,
} from 'utils/models/variants'
import T from 'types'
import { isNil } from 'lodash'
import { I18n } from 'react-redux-i18n'
import '../styles.sass'
import {
  getVariantsGroupedByDuration,
  productImagePath,
  variantDuration,
} from 'utils/helperComponents/phytoHelpers'
import { getIcon } from 'assets/icons'
import { money } from 'utils/models/money'
import { isSubscribable } from 'helpers/segment/properties/phytotherapy'

type IProps = {
  product: any
  recommended: boolean
  recommendedDuration?: number
  handleNewSelection: (p: any, choice: T.CheckoutVariantChoice | null) => void,
}

const NewProductVariantsChoice = (props: IProps) => {
  const { product, recommended, recommendedDuration, handleNewSelection } = props
  const sheet: any = useRef(null)

  const [selectedVariant, setSelectedVariant] = useState<T.Variant | null>(null)
  const [tagFacingUp, setTagFacingUp] = useState<true | false>(false)
  const [duration, setDuration] = useState<number | null>(null)
  const [groupedVariant, setGroupedVariant] = useState<T.SubscribableVariant | null>(null)

  useEffect(() => {
    if (!isNil(duration)) {
      setSelectedVariant(null)
      setGroupedVariant(getVariantsGroupedByDuration(product, duration))
      const inputs = document.getElementsByName('phyto')
      document.getElementsByName('phyto').forEach((input: any) => {
        input.checked = false
      })
    }
  },        [duration])

  const handleClickVariant = (variant: T.Variant | null) => {
    if (isNil(variant)) {
      return
    }
    const variantOptions = variantOptionsAsObject(variant)
    // @ts-ignore
    const shouldSubscribe = variantOptions['subscribable'] === 'subscribable'
    const subscriptionFrequency = productFrequencyForVariant(variant)
    const options =
      shouldSubscribe && subscriptionFrequency
        ? {
          subscribe: shouldSubscribe,
          subscription_frequency_id: subscriptionFrequency.id,
          delivery_number: 1000000,
        }
        : undefined
    handleNewSelection(product, { options, variant, quantity: 1 })
  }

  const handleContreIndicationClick = () => {
    const elem = sheet.current
    if (elem && elem.clientHeight === 0) {
      elem.style.height = `${elem.scrollHeight}px`
    } else if (elem) {
      elem.style.height = '0px'
    }
    setTagFacingUp(!tagFacingUp)
  }

  // return the minimum price for a product among every variant
  const getMinimumPriceForProduct = (product: any) => {
    const prices: number[] = []
    product.variantsIncludingMaster.forEach((v: any) => {
      if (getEveryDuration(product).includes(Number(variantDuration(v)))) {
        prices.push(Number(v.price) / (Number(variantDuration(v))))
      }
    })
    return money(Math.min(...prices)).toFormat()
  }

  // every duration except 6 months if not recommended
  const  getEveryDuration = (product: any) => {
    const durationsDetails = product.subscriptionFrequencies.filter((f: any) => !(f.monthsCount === 6 && recommendedDuration !== 6))
    return durationsDetails.map((d: any) => d.monthsCount)
  }

  // return the minimum price for a variant
  const getMinimumPrice = (variant: T.Variant) => {
    const price = variant.price
    const minimumPrice = money(price).divide(Number(variantDuration(variant)))
    if (minimumPrice.equalsTo(money(price))) {
      return ''
    }
    return `(${minimumPrice.toFormat()}/mois)`
  }
  const getReduction = useMemo(() => {
    if (groupedVariant && groupedVariant.subscribableVariant) {
      const price = Number(groupedVariant.subscribableVariant.price)
      const refPrice = Number(groupedVariant.variant.price)
      return (price / refPrice * 100 - 100).toFixed(0)
    }
  },                           [groupedVariant])

  const getGrammar = (n: number) => {
    if (n > 1) return 'plural'
    return 'singular'
  }

  return (
    <div className={cx('new-block-content', 'new-phyto-product-container', { recommended })}>
      <div className="phyto-product-info">
        <img className="phyto-product-image" src={productImagePath(product)}  alt={product.slug} />
        <div className="phyto-product-text">
          <div className="phyto-product-title">
            <h3>{(I18n.t(`prescriptions.items.${product.slug}`))}</h3>
            <div>À partir de {getMinimumPriceForProduct(product)}/mois</div>
          </div>
          <p className="phyto-product-description">
            <span className="product-indication">Indications :</span>{' '}
            <span>{I18n.t(`prescriptions.productsInformation.${product.slug}.indication`)}</span>
          </p>
          <div className="d-flex flex-column flex-lg-column-reverse">
            <div className="phyto-product-reminder d-none d-lg-flex">
              <SvgIcon classname="icon" icon={getIcon('clinic.letter')} />
              <div>
                {I18n.t('phytotherapy.reminder')}
              </div>
            </div>
            <a
              className="phyto-product-sheet"
              href={I18n.t(`prescriptions.productsInformation.${product.slug}.pathToDescription`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIcon classname="icon d-none d-lg-inline-block" icon={getIcon('download')} />
              Lire la fiche d'information complète
            </a>
            <div className="phyto-product-contre-indication">
              <div className="phyto-product-contre-indication-title" onClick={handleContreIndicationClick}>
                <SvgIcon classname="icon mr-0" icon={getIcon('clinic.doctor')} />
                <div>Contre-indications</div>
                <SvgIcon classname={cx('icon mr-0', { 'icon--up': tagFacingUp })} icon={getIcon('clinic.chevron')} />
              </div>
              <div className="phyto-product-contre-indication-description" ref={sheet}>
                {I18n.t(`prescriptions.productsInformation.${product.slug}.contraindication`)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="phyto-subscription-info">
        <h3 className="d-none d-lg-inline-block mb-4">Choisissez votre offre</h3>
        <CustomDropdown
          options={getEveryDuration(product)}
          handleClick={setDuration}
          defaultOption={recommendedDuration}
          unit="mois"
          placeholder="Choisir la durée du traitement"
        />
        <div className="phyto-variant-input">
          {groupedVariant && duration && (
            Object.values(groupedVariant).map((variant: any, i) => (
              <label className="phyto-variant-label" key={variant.name + i}>
                <input type="radio" value={variant.name} name="phyto" onChange={() => setSelectedVariant(variant)} />
                <div className="phyto-variant-info">
                  <div className="phyto-variant-option">
                    {isSubscribable(variant)
                      ? (<div>
                          <div className="d-lg-inline">{I18n.t(`phytotherapy.subscriptionDelivery`)}</div>
                          <span className="phyto-variant-option-discount">{getReduction} %</span>
                        </div>)
                      : I18n.t(`phytotherapy.uniqueDelivery`)}
                  </div>
                  <div className="phyto-variant-price">
                    <span className={cx('phyto-variant-main-price', { 'font-weight-bold': selectedVariant && selectedVariant.id === variant.id })}>{variant.displayPrice} </span>
                    {getMinimumPrice(variant)}
                  </div>
                  {isSubscribable(variant)
                  ? <>
                      <div className="phyto-variant-disposition">{I18n.t('phytotherapy.subscriptionDeliveryDescription.disposition')}</div>
                      <ul className="phyto-variant-argument">
                        <li>{I18n.t(`phytotherapy.subscriptionDeliveryDescription.${getGrammar(duration)}`, { months: duration })}</li>
                        <li>{I18n.t('phytotherapy.subscriptionDeliveryDescription.noMoreFees')}</li>
                        <li>{I18n.t('phytotherapy.subscriptionDeliveryDescription.cancelAnytime')}</li>
                      </ul>
                      <div className="phyto-variant-reminder d-lg-none">
                        <SvgIcon classname="icon" icon={getIcon('clinic.letter')} />
                        <div>
                          {I18n.t('phytotherapy.reminder')}
                        </div>
                      </div>
                    </>
                  : I18n.t(`phytotherapy.uniqueDeliveryDescription.${getGrammar(duration)}`, { months: duration })}
                </div>
              </label>
            ))
          )}
        </div>
        <Button
          variant="btn-primary"
          classname={cx('phyto-variant-button', { 'mt-auto': !isNil(groupedVariant) })}
          onClick={() => handleClickVariant(selectedVariant)}
          disabled={isNil(selectedVariant)}
        >
          Commander
        </Button>
      </div>
    </div>
  )
}

export default NewProductVariantsChoice

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { last } from 'lodash'
import build from 'redux-object';
import { Block, Loader, ShippingAddress } from 'components'
import T from 'types'
import OrderComplete from '../OrderComplete'

interface IProps extends RouteComponentProps<any> {
  goNext: () => void,
  goPrevious: () => void,
  createDelivery: (
    data: T.Forms.ShippingAddress,
    onSuccess: () => void
  ) => void,
  order: T.Cart
  storefront: T.Storefront
  auth: T.Auth
}

type IState = {
}

class CheckoutShipping extends React.Component<IProps, IState> {

  onValidateSuccess = () => {
    const { goNext } = this.props
    goNext()
  }
  handleValidateDeliveryInfo = (data: T.Forms.ShippingAddress) => {
    const { createDelivery } = this.props
    createDelivery(data, this.onValidateSuccess)
  }
  render() {

    const { auth, storefront, order } = this.props
    const { loading } = storefront
    const profile = auth.profile
    return order.state === 'complete' ? <OrderComplete order={order} /> : (
      <Block classname="pharmacist container-m-800" title="Livraison">
        <div className="charles step-progress">
          <div>
            <p className="checkout-shipping-text">
              Bénéficiez de la livraison en 2 jours ouvrés partout en France
            </p>
            <ShippingAddress
              profile={profile}
              loading={loading}
              onSubmit={this.handleValidateDeliveryInfo}
            />
          </div>
        </div>
      </Block>
    )
  }
}

export default withRouter(CheckoutShipping)


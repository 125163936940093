import React, { Component } from 'react'
import T from 'types'
import { Link } from 'react-router-dom'
import { SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import moment from 'constants/moment'
import tracker from 'utils/trackers'
import './styles.sass'

interface IProps {
  programRecommandation: T.UI.ProgramRecommendationCard,
}

export default class ProgramRecommendationCard extends Component<IProps> {

  constructor(props: IProps) {
    super(props)
    this.handleProgramClick = this.handleProgramClick.bind(this)
  }

  handleProgramClick = () => {
    const { programRecommandation } = this.props
    const programPurchased: boolean =  programRecommandation.isEnrolled || false
    tracker.trackProgramPageClicked(programRecommandation.slug, programPurchased, "digital_therapy_block")
  }

  public render() {
    const { programRecommandation } = this.props
    const buttonLabel = programRecommandation.isEnrolled ? "Reprendre le programme" : "Commencer le programme"

    return (
      <div className="home-page-card block-content program-recommendation-card">
        <h5 className="prescription-card-title mb-4">{programRecommandation.title}</h5>
        <div className="mb-4">
          <u>
            {programRecommandation.fullName}
          </u>
        </div>
        <div>
          {programRecommandation.description}
        </div>
        <ul className="home-page-card-list">
          {/* {prescription.prescriptionItems.map((prescriptionItem: T.PrescriptionItem, index: number) => (
            <li key={`prescription_item_${index}`} className="prescription-card-item mb-3">
              {`${capitalize(prescriptionItem.molecule)} ${prescriptionItem.grammage}`}<br />
              {I18n.t(`dosage.${prescriptionItem.dosage}`)}
            </li>
          ))} */}
        </ul>
        <Link to={programRecommandation.path} className="btn btn-primary btn-block mt-auto" onClick={this.handleProgramClick}>
          {buttonLabel}
        </Link>

      </div>
    )
  }
}
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import React, { Component, Fragment } from 'react'
import { map, sortBy, last } from 'lodash'
import 'react-phone-number-input/style.css'
import 'rc-tooltip/assets/bootstrap.css'
import { I18n } from 'react-redux-i18n'
import { Button } from 'components'
import T from 'types'
import schema from 'utils/models/schemas/address'
import { Formik } from 'formik'
import countries from 'constants/iso-countries'
import { Row, Col, Form } from 'react-bootstrap'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import cx from 'classnames'

import './styles.sass'
import { IS_CHARLES_PLATFORM } from '../../../../../constants/config'
import { RouteComponentProps } from 'react-router'
import ProgramArguments from '../../../../AcademyFunnel/ProgramArguments'

interface IProps {
  patientAttributes?: any
  program: T.Program
  academy: T.AcademyStore
  setExtraPersonalData: (data: object) => void
  createAddress: (
    data: any,
    enrollmentId: string | undefined,
    onSuccess: () => void,
  ) => void,
  handler: (bool: boolean) => void,
}

interface Country {
  iso: string
  name: string
}

export default class AcademyAddress extends Component<IProps> {
  countriesData: countries.LocalizedCountryNames

  constructor(props: IProps) {
    super(props)

    this.countriesData = countries.getNames('fr')
  }
  private onValidateSuccess = () => {
    this.props.handler(true)
  }

  private handleSubmit = (data: any) => {
    const { academy, createAddress, setExtraPersonalData } = this.props
    const { enrollment } = academy
    const enrollmentId = last(Object.keys(enrollment || {}))
    const {
      firstname,
      lastname,
    } = data

    const patientFullName = {
      firstname,
      lastname,
    }
    setExtraPersonalData(patientFullName)
    createAddress(data, enrollmentId, this.onValidateSuccess)
  }

  private sortedCountries = (): Country[] => {
    const countryDictionary = this.countriesData
    const countryIsos: string[] = Object.keys(countryDictionary)
    const countryObjects: Country[] = map(countryIsos, (key: string) => ({
      iso: key,
      name: countryDictionary[key],
    }))
    const sortedCountries = sortBy(countryObjects, (country: Country) => country.name)
    return sortedCountries
  }

  public render() {
    const { academy, program, patientAttributes } = this.props

    return (
      <div className="patient-space-personal-info text-left">
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstname: '',
            lastname: '',
            address1: '',
            address2: '',
            city: '',
            zipcode: '',
            phone: '',
            countryIso: '' || 'FR',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {program.slug === 'vaginisme-program' ? (
                <div className="patient-space-step-title">
                  <h3 className="step-title">Vous y êtes presque !</h3>
                  <div className="step-description">
                    Nous avons besoin de votre adresse pour vous envoyer le Kit Velvi,
                    indispensable pour mener à bien le programme (vous ne paierez rien de
                    plus, c'est inclus).
                  </div>
                </div>
              ) : null}
              <Row>
                <Form.Group as={Col} md="6" controlId="validationFormik01">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.firstname`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.firstname}
                    placeholder={I18n.t(`personalInfo.address.placeholders.firstname`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik02">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.lastname`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.lastname}
                    placeholder={I18n.t(`personalInfo.address.placeholders.lastname`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="validationFormik03">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.address1`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.address1}
                    placeholder={I18n.t(`personalInfo.address.placeholders.address1`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik04">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.address2`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.address2}
                    placeholder={I18n.t(`personalInfo.address.placeholders.address2`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="validationFormik05">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.zipcode`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.zipcode}
                    placeholder={I18n.t(`personalInfo.address.placeholders.zipcode`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik06">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.city`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.city}
                    placeholder={I18n.t(`personalInfo.address.placeholders.city`)}
                    className="personal-info-form-control"
                  />
                  <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="validationFormik07">
                  <Form.Label>{I18n.t(`personalInfo.address.labels.countryIso`)}</Form.Label>
                  <Form.Control
                    as="select"
                    name="countryIso"
                    value={values.countryIso}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.countryIso}
                    placeholder={I18n.t(`personalInfo.address.placeholders.countryIso`)}
                    className="personal-info-form-control"
                  >
                    {map(this.sortedCountries(), (country: Country) =>
                      <option
                        key={`country_option_${country.iso}`}
                        label={country.name}
                        value={country.iso}
                      />,
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.countryIso}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button
                type="submit"
                classname="align-self-center align-self-md-start"
              >
                Valider
              </Button>
            </Form>
          )}
        </Formik>
      </div >
    )
  }
}

import React, { FC } from 'react'
import T from 'types'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import "./style.sass"

import { withNavigation } from 'components/Workflow'

const UCConfirmationConfirmation: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext } } = props

  return (
    <UCStep>
      <span>[CONFIRMATION] - confirmation</span>
      <button onClick={goPrevious}>previous</button>
      <button onClick={goNext}>next</button>


      <div className='checkout-container-bloc'>
        <div className='block-content'>
          <div className='confirmation-payment-checkout'>
            <div className='confirmation-payment-header'>
              <SvgIcon icon={getIcon('clinic.box')} classname='d-flex justify-content-center' />
              <h4 className='mb-1'>Merci pour vos commandes !</h4>
              <div className='confirmation-payment-header-text'>Vous recevrez un e-mail dès que vos traitements auront été expédiés.
                Vous pourrez suivre leurs évolutions dans l’onglet “Mes commandes” de votre espace patient.</div>
            </div>
            <div className='confirmation-payment-content'>
              <div className='confirmation-payment-content-block'>
                <div className='content-block-title'>
                  <SvgIcon icon={getIcon('clinic.pill')} />
                  <span>Médicaments</span>
                </div>
                <div>Votre commande ayant pour référence R070307211 a bien été enregistré.</div>
                <div className='d-flex payment-checkout-separator w-100' />
              </div>
              <div className='confirmation-payment-content-block'>
                <div className='content-block-title'>
                  <SvgIcon icon={getIcon('phyto')} />
                  <span>Phytothérapies</span>
                </div>
                <div>Votre commande ayant pour référence R06548416 a bien été enregistré.</div>
                <div className='d-flex payment-checkout-separator w-100' />
              </div>
              <div className='confirmation-payment-content-block'>
                <div className='content-block-title'>
                  <SvgIcon icon={getIcon('clinic.camera')} />
                  <span>Programme Sommeil</span>
                </div>
                <div className='mb-4'>Le programme sommeil a été débloqué. Chaque étape dure 1 semaine, l’étape suivante sera débloquée en fin de semaine.</div>
                <div>Si vous avez la moindre question pendant le programme n’hésitez pas à nous contacter.</div>
              </div>
              <div className='confirmation-payment-button'>
                <Button
                  classname="w-100 mb-3 button-start-program mr-2"
                >
                  Commencer le programme
                </Button>
                <button
                  className="btn btn-outline-primary w-100"
                >
                  Revenir à mon espace
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UCStep>
  )
}

export default withNavigation(UCConfirmationConfirmation)


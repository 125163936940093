import React from 'react'
import Media from 'react-media'
import { PuffLoader } from 'react-spinners'

const loaderStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
}

export default () => (
  <div style={loaderStyle} >
    <Media
      key="desktop"
      query="(min-width: 768px)"
      render={() => <PuffLoader size={400}  color={'black'} />}
    />
    <Media
      key="mobile"
      query="(max-width: 767px)"
      render={() => <PuffLoader size={100}  color={'black'} />}
    />
  </div>
)

import React, { Component, Fragment, RefObject, useRef } from 'react'
import ReactJWPlayer from 'react-jw-player'
import cx from 'classnames'
import build from 'redux-object'
import schema from 'utils/models/schemas/sleepCalendarItem'
import { Formik, FieldArray, Field } from 'formik'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { Button, SvgIcon } from 'components'
import { isUndefined, map, first, find, range, chunk } from 'lodash'
import { get } from 'lodash'
import moment from 'constants/moment'
import T from 'types'

import './styles.sass'

import iconCalendarSvg from 'assets/icons/calendar-icon.svg'

export interface IProps {
  sleepCalendarItem?: T.SleepCalendarItem
  dayKey: string
  visible: boolean
  getSleepCalendar: () => void
  createSleepCalendarItem: (data: object, onSuccess: any) => void
  updateSleepCalendarItem: (sleepCalendarItemId: number, params: object) => void
  afterSubmitView: JSX.Element
  onClose: () => void
}

type CalendarCell = {
  hour: number
  min: number
  value: number
  isSleptAt: boolean
  isWentToBetAt: boolean
  isAwakeAt: boolean
  isOutOfBedAt: boolean
  isAwakening: boolean
  isNap: boolean
}

interface IState {
  step: number
  calendarRepresentation: T.NormalizedObjects<CalendarCell>
  timeOntoBed: number
  sleptAt: number
  awakeAt: number
  timeOutOfBed: number
  showStickyNav: boolean
  anchor: RefObject<HTMLDivElement>
  stickyRefTop: RefObject<HTMLDivElement>
}

class SleepCalendarItemForm extends Component<IProps, IState> {
  MAXTIMESTAMP = 24*60;

  constructor(props: IProps) {
    super(props)

    let calendar : T.NormalizedObjects<CalendarCell> = {}

    map(range(0, 24), (hOffset: number) =>
      map(range(0, 60, 15), (mOffset: number) => {
        const value = (hOffset % 24) * 60 + mOffset

        calendar[value] = {
          hour: hOffset,
          min: mOffset,
          value: value,
          isSleptAt: false,
          isWentToBetAt: false,
          isAwakeAt: false,
          isOutOfBedAt: false,
          isAwakening: false,
          isNap: false,
        }
      })
    )

    this.state = {
      step: 0,
      timeOntoBed: -1,
      sleptAt: -1,
      awakeAt: -1,
      timeOutOfBed: -1,
      calendarRepresentation: calendar,
      showStickyNav: false,
      anchor: React.createRef(),
      stickyRefTop: React.createRef(),
    }
  }

  public handleScroll = () => {
    if (this.state.stickyRefTop.current) {
      this.setState({showStickyNav: (this.state.stickyRefTop.current.getBoundingClientRect().top) <= 0});
    }
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  public handleCalendarItemCreation(e: any) {
    const { timeOntoBed, sleptAt, awakeAt, timeOutOfBed, calendarRepresentation } = this.state;

    const { createSleepCalendarItem, dayKey, getSleepCalendar } = this.props;

    let awakenings : T.NormalizedObjects<number> = {}
    let naps : T.NormalizedObjects<number> = {}

    for (const key in calendarRepresentation) {
      if (calendarRepresentation[key].isAwakening)
        awakenings[key] = 15
      if (calendarRepresentation[key].isNap)
        naps[key] = 15
    }

    createSleepCalendarItem(
    {
      sleep_calendar_item: {
        day_key: dayKey,
        time_onto_bed: timeOntoBed,
        slept_at: sleptAt,
        awake_at: awakeAt,
        time_out_of_bed: timeOutOfBed,
        awakenings: awakenings,
        naps: naps,
        overall_night_quality_rating: e.overallNightQualityRating,
        wake_up_energy_rating: e.wakeUpEnergyRating,
        day_anxiety_rating: e.dayAnxietyRating,
        took_pill_to_sleep: e.tookPillToSleep
      }
    },
      (e:any) => {
        this.fullyResetCalendarState()
      }
    )
  }

  goPrev() {
    const { step } = this.state;

    if (this.state.anchor.current && step > 4)
      this.state.anchor.current.scrollIntoView()

    if (step > 0) {
      this.setState({step: step - 1})
      this.fullyResetCalendarState(step -1)
    }
  }

  goNext() {
    const { step } = this.state;

    if (this.state.anchor.current && step > 3)
      this.state.anchor.current.scrollIntoView()

    this.setState({step: step + 1})
  }

  fullyResetCalendarState(newStep = 0) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const { sleptAt, awakeAt, timeOntoBed, timeOutOfBed } = this.state

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isWentToBetAt: newStep <= 0 ? false : this.state.calendarRepresentation[key].isWentToBetAt,
        isSleptAt: newStep <= 1 ? false : this.state.calendarRepresentation[key].isSleptAt,
        isAwakeAt: newStep <= 2 ? false : this.state.calendarRepresentation[key].isAwakeAt,
        isOutOfBedAt: newStep <= 3 ? false : this.state.calendarRepresentation[key].isOutOfBedAt,
        isAwakening: newStep <= 4 ? false : this.state.calendarRepresentation[key].isAwakening,
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    this.setState({
      calendarRepresentation: newCalendarRepresentation,
      step: newStep,
      timeOntoBed: (newStep == 0 ? -1 : timeOntoBed),
      sleptAt: (newStep <= 1 ? -1 : sleptAt),
      awakeAt: (newStep <= 2 ? -1 : awakeAt),
      timeOutOfBed: (newStep <= 3 ? -1 : timeOutOfBed)
    })
  }

  setCellAsWentToBed(cell: CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isSleptAt: false,
        isWentToBetAt: false,
        isAwakeAt: false,
        isOutOfBedAt: false,
        isAwakening: false,
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isWentToBetAt = true

    this.setState({calendarRepresentation: newCalendarRepresentation, timeOntoBed: value})
    this.goNext()
  }

  isSelectableAsSleptAt(cell : CalendarCell) {
    const { timeOntoBed } = this.state
    const diff = this.timeBetweenTimestamps(timeOntoBed, cell.value)

    // does nothing but we might change the value in the future
    return (diff < 24*60)
  }

  setCellAsSleptAt(cell: CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    if (!this.isSelectableAsSleptAt(cell))
      return;

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isSleptAt: false,
        isAwakeAt: false,
        isOutOfBedAt: false,
        isAwakening: false,
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isSleptAt = true

    this.setState({calendarRepresentation: newCalendarRepresentation, sleptAt: value})
    this.goNext()
  }

  isSelectableAsAwakeAt(cell : CalendarCell) {
    const { sleptAt, timeOntoBed } = this.state
    const diff = this.timeBetweenTimestamps(sleptAt, cell.value)

    if (this.isBetweenCells(cell, timeOntoBed, sleptAt))
      return false
    if (cell.isWentToBetAt)
      return false
    return (diff > 0)
  }

  setCellAsAwakeAt(cell: CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    if (!this.isSelectableAsAwakeAt(cell))
      return;

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isAwakeAt: false,
        isOutOfBedAt: false,
        isAwakening: false,
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isAwakeAt = true

    this.setState({calendarRepresentation: newCalendarRepresentation, awakeAt: value})
    this.goNext()
  }

  isSelectableAsOutOfBed(cell : CalendarCell) {
    const { timeOntoBed, awakeAt } = this.state

    if (this.isBetweenCells(cell, timeOntoBed, awakeAt))
      return false
    if (cell.isWentToBetAt)
      return false
    return true
  }

  setCellAsOutOfBed(cell: CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    if (!this.isSelectableAsOutOfBed(cell))
      return;

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isOutOfBedAt: false,
        isAwakening: false,
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isOutOfBedAt = true

    this.setState({calendarRepresentation: newCalendarRepresentation, timeOutOfBed: value})
    this.goNext()
  }

  // only if he was asleep
  isSelectableAsAwakening(cell : CalendarCell) {
    const { sleptAt, awakeAt, timeOntoBed, timeOutOfBed } = this.state

    return this.isBetweenCells(cell, sleptAt, awakeAt)
  }

  handleAwakenings(cell : CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    if (!this.isSelectableAsAwakening(cell))
      return;

    // reset all cells
    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
        isNap: false
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isAwakening = !cell.isAwakening
    this.setState({calendarRepresentation: newCalendarRepresentation})
  }

  // only if he was NOT in bed
  isSelectableAsNap(cell : CalendarCell) {
    const { timeOntoBed, timeOutOfBed } = this.state

    return this.isBetweenCells(cell, timeOutOfBed, timeOntoBed)
  }

  handleNaps(cell : CalendarCell) {
    let newCalendarRepresentation : T.NormalizedObjects<CalendarCell> = {}
    const value = cell.value;

    if (!this.isSelectableAsNap(cell))
      return;

    for (let key in this.state.calendarRepresentation) {
      const tmp = {
        ...this.state.calendarRepresentation[key],
      }
      newCalendarRepresentation[key] = tmp
    }

    newCalendarRepresentation[value].isNap = !cell.isNap
    this.setState({calendarRepresentation: newCalendarRepresentation})
  }

  timeBetweenTimestamps(from : number, to : number) {
    if (from <= to )
      return to - from
    return (this.MAXTIMESTAMP - from) + to
  }

  isBetweenCells(cell : CalendarCell, from : number, to : number) {
    if (from <= to)
      return (cell.value > from && cell.value < to)
    return cell.value > from || cell.value < to
  }

  handleSlotClick(cell: CalendarCell) {
    const { step } = this.state;
    const val = cell.value;

    if (step == 0)
      this.setCellAsWentToBed(cell)
    else if (step == 1)
      this.setCellAsSleptAt(cell)
    else if (step == 2)
      this.setCellAsAwakeAt(cell)
    else if (step == 3)
      this.setCellAsOutOfBed(cell)
    else if (step == 4)
      this.handleAwakenings(cell)
    else if (step == 5)
      this.handleNaps(cell)
  }

  stepDescription() {
    switch (this.state.step) {
      case 0:
        return '1/7 Indiquez votre heure de coucher'
      case 1:
        return '2/7 Indiquez votre heure d\'endormissement'
      case 2:
        return '3/7 Indiquez votre heure de reveil'
      case 3:
        return '4/7 Indiquez votre heure de lever'
      case 4:
        return '5/7 Indiquez vos heures de reveils long pendant la nuit (cliquez sur les cases) puis sur Suivant'
      case 5:
        return '6/7 Indiquez vos heures de siestes si vous en avez fait ou cliquez sur suivant'
    }
    return '7/7 Questions sur votre ressenti cette nuit'
  }

  isBetweenBedtimeAndSleep(cell : CalendarCell) {
    const { sleptAt, awakeAt, timeOntoBed, timeOutOfBed } = this.state

    if (timeOntoBed == -1 || sleptAt == -1)
      return false
    return this.isBetweenCells(cell, timeOntoBed, sleptAt)
  }

  isAsleep(cell : CalendarCell) {
    const { sleptAt, awakeAt, timeOntoBed, timeOutOfBed } = this.state

    if (awakeAt == -1 || sleptAt == -1)
      return false
    return this.isBetweenCells(cell, sleptAt, awakeAt)
  }

  isWakingUp(cell : CalendarCell) {
    const { sleptAt, awakeAt, timeOntoBed, timeOutOfBed } = this.state

    if (awakeAt == -1 || timeOutOfBed == -1)
      return false
    return this.isBetweenCells(cell, awakeAt, timeOutOfBed)
  }

  private customClassForCell(cell : CalendarCell) {
    if (cell.isWentToBetAt)
      return 'is-bedtime'
    if (cell.isSleptAt)
      return 'is-sleep'

    if (cell.isAwakeAt)
      return 'is-waketime'

    if (cell.isOutOfBedAt)
      return 'is-out-of-bed'

    if (cell.isAwakening)
      return 'is-nightime-awake'

    if (cell.isNap)
      return 'is-nap'


    if (this.isBetweenBedtimeAndSleep(cell))
      return 'before-sleep'
    if (this.isAsleep(cell))
      return 'during-sleep'
    if (this.isWakingUp(cell))
      return 'waking-up'
    return ''
  }

  renderCalendarCell(cell : CalendarCell) {
    const { step } = this.state;

    let icon = ''
    let isSelectable = true
    let customClass = this.customClassForCell(cell)

    if ((step == 1 && !this.isSelectableAsSleptAt(cell)) ||
        (step == 2 && !this.isSelectableAsAwakeAt(cell)) ||
        (step == 3 && !this.isSelectableAsOutOfBed(cell)) ||
        (step == 4 && !this.isSelectableAsAwakening(cell)) ||
        (step == 5 && !this.isSelectableAsNap(cell))) {
      isSelectable = false
    }

    if (cell.isWentToBetAt)
      icon += '🛌'
    if (cell.isSleptAt)
      icon += '😴'
    if (cell.isAwakeAt)
      icon += '🥱'
    if (cell.isOutOfBedAt)
      icon += '🙂'
    if (cell.isAwakening)
      icon += ' '
    if (cell.isNap)
      icon += '😪'
    if (this.isAsleep(cell) && !cell.isAwakening)
      icon += '🌛'

    return <button className={cx('btn btn-sm btn-block calendar-cell-btn mx-auto', customClass)} disabled={!isSelectable} onClick={() => this.handleSlotClick(cell)}>
      {
        icon.length ?
          icon :
          (isSelectable ? cell.min : '' )
      }
    </button>
  }

  renderCalendar() {
    const { dayKey } = this.props;
    const { step } = this.state;

    return <div className="calendar-table-container">
      <table className="table table-borderless table-sm table-agenda-sommeil">
        <tbody>
        {
          map(range(0, 24), (hOffset: number) => {
            return <tr>
              <th>
                { ((hOffset + 12) % 24).toString().padStart(2, "0") }h
              </th>
              { map(range(0, 60, 15), (mOffset: number) => {
                  return <td className="text-center">
                   { this.renderCalendarCell(this.state.calendarRepresentation[((hOffset + 12) % 24) * 60 + mOffset]) }
                  </td>
                })
              }
            </tr>
          })
        }
        </tbody>
      </table>
    </div>
  }

  public renderCalendarItemForm() {
    const surveyItems = [{
      label: 'Comment avez-vous trouvé votre nuit?',
      fieldName: 'overallNightQualityRating',
      labels: [[3, 'Bonne'], [2, 'Moyenne'], [1, 'Mauvaise']]
    }, {
      label: 'Vous êtes-vous senti reposé en vous réveillant?',
      fieldName: 'wakeUpEnergyRating',
      labels: [[3, 'Très'], [2, 'Un peu'], [1, 'Pas du tout']]
    }, {
      label: 'Avez-vous été anxieux pendant la journée?',
      fieldName: 'dayAnxietyRating',
      labels: [[3, 'Pas du tout'], [2, 'Un peu'], [1, 'Très']]
    }]

    return <Fragment>
      <Formik
        key="formik-sleepcalendar-item"
        onSubmit={(e:any) => this.handleCalendarItemCreation(e)}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={schema}
        initialValues={{
          overallNightQualityRating: null,
          wakeUpEnergyRating: null,
          dayAnxietyRating: null,
          tookPillToSleep: false
        }}
      >
      {({ handleSubmit, handleChange, handleBlur, values, errors, isValid, dirty }) => (
        <Form onSubmit={handleSubmit} className="w-100">
          { map(surveyItems, (item) => {
              return <Row>
                <Col xs={12}>
                  <Form.Label>{item.label}</Form.Label>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className="" controlId={`${item.fieldName}_highValue`}>
                    <Form.Check
                      type="radio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="default-radio"
                      name={item.fieldName}
                      label={item.labels[0][1]}
                      value={item.labels[0][0]}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className="" controlId={`${item.fieldName}_mediumValue`}>
                    <Form.Check
                      type="radio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="default-radio"
                      name={item.fieldName}
                      label={item.labels[1][1]}
                      value={item.labels[1][0]}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className="" controlId={`${item.fieldName}_lowValue`}>
                    <Form.Check
                      type="radio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="default-radio"
                      name={item.fieldName}
                      label={item.labels[2][1]}
                      value={item.labels[2][0]}
                    />
                  </Form.Group>
                </Col>
              </Row>
            })
          }
          <Row>
            <Col xs={12}>
              <Form.Label>Avez-vous pris un somnifère pour dormir ?</Form.Label>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group className="" controlId={`tookPillToSleep_falseValue`}>
                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="default-radio"
                  name="tookPillToSleep"
                  label="Non"
                  value='0'
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group className="" controlId={`tookPillToSleep_trueValue`}>
                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="default-radio"
                  name="tookPillToSleep"
                  label="Oui"
                  value='1'
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <button type="submit" className="btn btn-primary charles-btn" disabled={!(dirty && isValid)}>Valider</button>
          </div>
        </Form>
       )}
      </Formik>
    </Fragment>
  }

  public renderStep() {
    const { step, stickyRefTop } = this.state;
    const { dayKey } = this.props;

    const { showStickyNav } = this.state

    const topbar = <div>
      <div className="p-2 text-center">
        {this.stepDescription()}
      </div>
    </div>

    return <div className="sleep-calendar-items-form" ref={this.state.anchor}>
      <div className="text-center">
        <SvgIcon icon={iconCalendarSvg} classname="sleep-calendar-title"/>
      </div>
      <div ref={stickyRefTop}>
        { topbar }
        {step <= 5 && this.renderCalendar()}
        {step == 6 && this.renderCalendarItemForm()}
      </div>

      { showStickyNav &&
        <div className="sticky-wrapper">
          <div className="sticky">
            <div className="sticky-inner px-5">
              { topbar }
            </div>
          </div>
        </div>
       }
    </div>
  }

  public render() {
    const { sleepCalendarItem, dayKey, afterSubmitView, visible, onClose } = this.props;
    const { step } = this.state;

    const previousEnabled = step > 0 && step != 6
    const nextEnabled = step > 3 && step < 6

    const displayStickyFooter = (previousEnabled || nextEnabled) && visible

    const buttonsEl = <>
      <div className="d-flex justify-content-between align-items-center sleep-calendar-items-form-actions w-100" >
        <button onClick={ (e) => this.goPrev()} className="btn btn-xs btn-outline-primary charles-btn" disabled={!previousEnabled}>Précédent</button>
        <button onClick={(e) => this.goNext()} className="btn btn-xs btn-primary charles-btn" disabled={!nextEnabled}>Suivant</button>
      </div>
    </>
    return (<>
      <Modal
        show={visible}
        centered
        size="lg"
        backdrop='static'
        keyboard={true}
        fullscreen={true}
        dialogClassName='calendar-summary-modal'
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <h3 className="text-center">Nuit du {moment(dayKey).format('dddd Do MMMM')}</h3>
        </Modal.Header>
        <Modal.Body>
          { sleepCalendarItem ? afterSubmitView : this.renderStep() }
        </Modal.Body>
        <Modal.Footer>
          <div className="d-none d-md-flex w-100">
            { buttonsEl }
          </div>
          { displayStickyFooter &&
            <div className="sticky-wrapper d-block d-md-none">
              <div className="sticky-bottom">
                <div className="sticky-inner">
                  {buttonsEl}
                </div>
              </div>
            </div>
          }
        </Modal.Footer>
      </Modal>
    </>
    )
  }
}

export default SleepCalendarItemForm

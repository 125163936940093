import { parseCSV } from '../helpers'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'

const SHEET_ID = '1Pn2uhmu_jDx9Gtdp-IUq8idBVJ1h2xCRC1FJXBQaZss'
const SHEET_RATING_TITLE = 'RatingSheet'
const SHEET_RATING_RANGE = 'A:B'

const defaultValue = 4.5
const defaultCount = 5856

const getUrl = (title: string, range: string) => {
  return (`https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:csv&sheet=${title}&range=${range}`)
}

export const useSatismeterScore = () => {
  const [ratingValue, setRatingValue] = useState(defaultValue)
  const [ratingCount, setRatingCount] = useState(defaultCount)

  const fetchRating = async () => {
    fetch(getUrl(SHEET_RATING_TITLE, SHEET_RATING_RANGE))
      .then(res => res.text())
      .then((rep) => {
        const data = parseCSV(rep)
        // data from sheet is an array of array of string + we want to display the average rating out of 5
        const value = Number(data[1][0] / 2)
        setRatingValue(Math.round(value * 10) / 10)
        setRatingCount(Number(data[1][1]))
      })
      .catch((e) => {
        console.error(e)
        Sentry.captureException(e)
      })
  }

  const reload = () => {
    fetchRating()
  }

  useEffect(() => {
    fetchRating()
  },        [])

  return { reload, ratingValue, ratingCount, maxRating: 5 }
}

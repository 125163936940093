import * as A from 'constants/actions'
import { ApiService } from 'services'

export const getPharmacies = () => ({
  type: A.GET_PHARMACIES,
  promise: ApiService.getPharmacies()
    .then(({ data }) => ({ pharmacies: data })),
})

export const getPharmacy = (id: string) => ({
  type: A.GET_PHARMACY,
  promise: ApiService.getPharmacy(id)
    .then(({ data }) => ({ pharmacy: data })),
})

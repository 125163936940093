import * as A from 'constants/actions'
import { ApiService } from 'services'
import T from 'types'

export const appendMessage = (message: T.Message) => ({
  message,
  type: A.APPEND_MESSAGE,
})

export const createMessage = (userId: number, body: string, patientUserId?: number) => ({
  promise: ApiService.createMessage(userId, body, patientUserId),
  type: A.CREATE_MESSAGE,
})

export const getMessages = (userId: number) => ({
  promise: ApiService.getMessages(userId),
  type: A.GET_MESSAGES,
})

export const getMessageById = (messageId: number) => ({
  type: A.GET_MESSAGE,
  promise: ApiService.getMessage(`${messageId}`).then(({ data }) => ({ message: data }))
})

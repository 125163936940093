import React, { Component } from 'react'
import { map } from 'lodash'
import ChatItem from './ChatItem'
import Block from '../Block'
import './styles.sass'

interface IProps {
  row?: boolean,
}

class ChatForm extends Component<IProps> {
  static defaultProps = {
    flexRow: false,
  }

  private items = [
    {
      title: 'Une question à poser à votre Praticien ?',
      cta: {
        title: 'Contacter votre Praticien',
        path: 'messages',
      },
      picto: 'doctorChat',
    },
    {
      title: 'Une question concernant votre dernière ordonnance ?',
      cta: {
        title: 'Contacter le Pharmacien',
        path: 'messages',
      },
      picto: 'success',
    },
    {
      title: 'Des questions à propos des services ?',
      cta: {
        title: 'Contacter le Support Charles',
      },
      onClick: this.handleSupportClick,
      picto: 'info',
    },
  ]

  private handleSupportClick(e: MouseEvent) {
    return void 0
  }

  private renderChatItem(item: any) {
    const { title, cta, picto } = item

    return (
      <div key={title}>
        <ChatItem
          title={title}
          cta={cta}
          icon={picto}
          key={title}
        />
      </div>
    )
  }

  public render() {
    const { row } = this.props

    return (
      <Block title="Posez vos questions ?">
        <div className={`chat-form-container ${row && 'display-row'}`}>
          {map(this.items, this.renderChatItem)}
        </div>
      </Block>
    )
  }
}

export default ChatForm

import React from 'react';
import VisioControls from './VisioControls';
import VisioVideo from './VisioVideo';
import useVisioSession from './useVisioSession';
import './styles.sass';

const VisioBox = ({ apiKey, sessionId, token }) => {
  const {
    connected,
    error,
    stopped,
    active,
    streams,
    localAudioEnabled,
    localVideoEnabled,
    subscribersCount,
    publisherRef,
    subscriberRef,
    startCall,
    disconnect,
    reconnect,
    retry,
    toggleLocalAudio,
    toggleLocalVideo,
    toggleFullScreen,
  } = useVisioSession(apiKey, sessionId, token);

  return (
    <div className="VisioBox">
      <div className="VisioBox-main">
        <VisioControls
          active={active}
          subscribersCount={subscribersCount}
          localAudioEnabled={localAudioEnabled}
          localVideoEnabled={localVideoEnabled}
          toggleLocalAudio={toggleLocalAudio}
          toggleLocalVideo={toggleLocalVideo}
          toggleFullScreen={toggleFullScreen}
          disconnect={disconnect}
        />
        <VisioVideo
          connected={connected}
          error={error}
          stopped={stopped}
          active={active}
          subscribersCount={subscribersCount}
          publisherRef={publisherRef}
          subscriberRef={subscriberRef}
          startCall={startCall}
          reconnect={reconnect}
          retry={retry}
        />
        <div id="chat" className="VisioBox-chat-container"></div>
      </div>
    </div>
  );
}

export default VisioBox;
import { connect } from 'react-redux'
import { endSecureChallenge } from 'actions'
import { SecureChallengeWrapper } from 'components'
import T from 'types'

const mapStateToProps = ({ ux: { secureChallenge } }: T.Store) => ({
  secureChallenge
})

const mapDispatchToProps = (dispatch: Function) => ({
  endSecureChallenge: (success: boolean) => dispatch(endSecureChallenge(success)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecureChallengeWrapper)

import React, { Component, Fragment } from 'react'
import { Model } from 'survey-core'
import BCSurvey from './BCSurvey'
import { get } from 'lodash'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'

class BCSurveyStart extends Component<BookingConsultationStepProps> {

  public render() {

    const {
      orderNumber,
      uploadSurvey,
      navigation
    } = this.props
    return (
      <BCSurvey
        {...this.props}
        prefix={"post_"}
        questionName=""
        onComplete={(survey: Model) => {
          uploadSurvey(orderNumber, survey.data)
          navigation.goNext()
        }}
      />
    )
  }
}

export default withNavigation(BCSurveyStart)

import { connect } from 'react-redux'
import { LastPrescription } from 'components'
import { getPrescriptions } from 'actions'
import T from 'types'

const mapStateToProps = ({
  consultations: { collection },
  prescriptions: { lastPrescription },
}: T.Store) => ({
  collection,
  lastPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getPrescriptions: () => dispatch(getPrescriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastPrescription)

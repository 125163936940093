import React, { Component } from 'react'
import { get, last, first, map, isNil } from 'lodash'
import T from 'types'
import { Button, Block } from 'components'
import gilbertTest from 'assets/images/gilbert-on-couch-classic.jpg'
import { checkoutGlobalSteps } from 'helpers/checkout-steps-helper'
import './styles.sass'

export default class PostConsultationDetails extends Component<T.UnifiedCheckoutStepProps> {

  public render() {
    const { navigation, consultation } = this.props
    return (
      <Block>
        <div className='checkout-resume'>
          <div className='checkout-doctor-info'>
            <img className='checkout-doctor-info-image' src={gilbertTest} alt="c'est ce bg de gilbert" />
            <div className='checkout-doctor-info-text'>
              <span className='title'>{consultation.doctor.fullName}</span>
              <span className='subtitle'>{get(consultation.doctor, 'specialty')}</span>
            </div>
          </div>
          <div className='checkout-resume-subtitle'>
            Suite à votre consultation, voici les traitements que je vous recommande :
          </div>
          <div className='checkout-stepper'>
            {map(checkoutGlobalSteps(consultation), (step: any, index: number) => (
              <div className="step">
                <div className="v-stepper">
                  <div className="circle">
                    <div className='stepper-circle-number'>{index + 1}</div>
                  </div>
                  <div className="line"></div>
                </div>
                <div className='stepper-text'>
                  <span className='title'>{first(step)}</span>
                  <ul className='list'>
                    {map(last(step), (product: string) => (
                      <li>{product}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className='checkout-resume-button'>
            <Button classname='btn btn-primary checkout-resume-button-continue' onClick={() => navigation.goNext()}>
              Commander maintenant
            </Button>
            <Button classname='d-md-none btn btn-outline-primary previous' onClick={() => navigation.goPrevious()}>
              Plus tard
            </Button>
            <Button classname='d-none d-md-flex btn btn-outline-primary previous' onClick={() => navigation.goPrevious()}>
              Commander plus tard
            </Button>
          </div>
        </div>
      </Block>
    )
  }
}

import React, { Fragment } from 'react'
import { get, map, isEmpty } from 'lodash'
import Media from 'react-media'
import { MedicalDocumentUpload, Block, Button } from '..'
import {
  dateFormater,
} from 'utils/helpers'

import T from 'types'
import './styles.sass'
import { SvgIcon } from 'components'
import eye from 'assets/icons/clinic/32/eye.svg'
import fileAlt from 'assets/icons/clinic/32/file-alt.svg'
import adjustBlack from 'assets/icons/clinic/32/adjust-black.svg'
import adjustWhite from 'assets/icons/clinic/32/adjust-white.svg'

interface IProps {
  medicalDocuments: T.MedicalDocument[] | undefined
  uploadMedicalDocument: (data: FormData) => void
  showPopup: (
    {
      classPopup,
      popupTitle,
      popupType,
      popupProps,
    }: {
      classPopup: string
      popupTitle: string
      popupType: string
      popupProps: any
    },
  ) => void
}

type IState = {
  prescription: any
}

class MedicalDocumentList extends React.Component<IProps> {

  showAnalysisCommentPopup = (analysisComment: T.AnalysisComment) => {
    const { showPopup } = this.props
    showPopup({
      classPopup: '',
      popupTitle: 'Mon ordonnance',
      popupType: 'SHOW_ANALYSIS_COMMENT',
      popupProps: {
        analysisComment
      }
    })
  }

  hasAnalysisComment = (analysisComment: T.AnalysisComment) => {
    if (analysisComment.id){
      return (
        <div className="check-comment" onClick={() => this.showAnalysisCommentPopup(analysisComment)}>
          <SvgIcon icon={fileAlt} classname="mr-2" />
          <span>Voir le commentaire</span>
        </div>
      )
    } else {
      return
    }
  }

  public render() {
    const { medicalDocuments, uploadMedicalDocument } = this.props
    if (isEmpty(medicalDocuments)) {
      return (
        <Block classname="pharmacist">
          <h3 className="h3">Résultats transmis au médecin</h3>
          <div className="medical-docs-container">
            <div className="documents-not-found">
              <p>Aucun résultat n’a été transmis au médecin pour le moment</p>
            </div>
            <MedicalDocumentUpload
              icon={adjustWhite}
              wording="Importer mes résultats"
              onFileSubmit={uploadMedicalDocument}
              classname="btn btn-primary"
            />
          </div>
        </Block>
      )
    } else {
      return (
        <Block classname="pharmacist">
          <h3 className="h3">Résultats transmis au médecin</h3>
          <div className="medical-docs-container">
            <div className="sent-documents mb-3 mt-3">Documents transmis</div>
            <div className="documents-list">
              {map(medicalDocuments, (document: T.MedicalDocument) => (
                <div className="document-row row">
                  <div className="col-md-4 mb-2 mb-md-0">
                    {document.filename.replace(/(.{15})..+/, "$1...")} du {dateFormater(get(document, 'createdAt'), 'L')}
                  </div>
                  <div className="col-md-4 mb-2 mb-md-0 text-lg-center text-md-center">
                    <SvgIcon icon={eye} classname="mr-2" />
                    <a className="" target="_blank" href={get(document, 'url')}>
                      Voir mes analyses
                    </a>
                  </div>
                  <div className="col-md-4 text-lg-center text-md-center">
                    {this.hasAnalysisComment(document.comment)}
                  </div>
                </div>
              ))}
            </div>
            <MedicalDocumentUpload
              icon={adjustBlack}
              wording="Importer d’autres résultats"
              onFileSubmit={uploadMedicalDocument}
              classname="btn btn-outline-primary"
            />
          </div>
        </Block>
      )
    }
  }
}

export default MedicalDocumentList

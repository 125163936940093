import React, { Component, Fragment } from 'react'
import { Survey } from 'survey-react-ui'
import { Model, IQuestion, QuestionMatrixModel, FunctionFactory, JsonObject } from 'survey-core'
import { isNull, mapValues, keyBy, transform, isUndefined, isEmpty, sum, cloneDeep } from 'lodash'
import { surveyCss } from 'page/common/survey'
import T from 'types'
import { scrollMainPanelToTop } from 'page/Pages/MainContainer/helpers'
import { I18n } from 'react-redux-i18n'
import './styles.sass'

interface IProps {
  lesson: T.Lesson
  attendance: T.Attendance
  evaluateLesson: (attendance: T.Attendance, survey: Model) => void
}

interface IState {
  isComplete: boolean
}

const computeScore = function(this: any, params: any): number | boolean {
  if (params.length != 1) return 0
  if (!params[0]) return 0
  const answersData = params[0]
  const question: IQuestion = this.survey.getQuestionByName('evaluation')
  if (question instanceof QuestionMatrixModel) {
    const scores = mapValues(keyBy(question.getColumns(), 'value'), 'score')
    const factors = mapValues(keyBy(question.getRows(), 'value'), 'factor')

    const globalScore = sum(
      transform(
        answersData,
        (rowScores: any[], column: any, row: any) => {
          const rowScore = factors[row] * scores[column] + 2.5
          rowScores.push(rowScore)
        },
        [],
      ),
    )

    return globalScore
  }
  return 0
}
class LessonSurvey extends Component<IProps, IState> {
  model: Model | null

  constructor(props: IProps) {
    super(props)

    this.state = {
      isComplete: false,
    }
    this.model = null
  }

  private buildModel = (survey: Model): Model => {
    FunctionFactory.Instance.register('computeScore', computeScore)
    JsonObject.metaData.addProperty('itemvalue', { name: 'factor' })
    JsonObject.metaData.addProperty('itemvalue', { name: 'score' })

    const programSlug = this.props.lesson.program.slug
    const currentModel = new Model(survey.survey)
    currentModel.locale = 'fr' // set locale for survey translation
    currentModel.showProgressBar = 'off' // show progress bar f  or survey (default is 'off')
    currentModel.showQuestionNumbers = 'off'
    currentModel.completeText = I18n.t(`academy.${programSlug}.completeEvaluation`)
    return currentModel
  }

  private onComplete(survey: Model) {
    const { attendance, evaluateLesson } = this.props
    scrollMainPanelToTop()
    evaluateLesson(attendance, survey.data)

    this.setState({ isComplete: true })
  }

  public render() {
    const { isComplete } = this.state
    const { attendance, lesson } = this.props
    const survey = attendance.survey
    const evaluation = attendance.evaluation

    const hasSurvey = !isEmpty(survey)
    const hasAlreadyFilledSurvey = !isEmpty(evaluation)
    const hasJustFilledSurvey = isComplete

    if (!hasSurvey || (hasAlreadyFilledSurvey && !hasJustFilledSurvey)) {
      return <Fragment />
    }
    if (isNull(this.model)) {
      this.model = this.buildModel(survey)
    }

    const customSurveyCss = cloneDeep(surveyCss)
    customSurveyCss.navigation.complete = 'btn btn-primary d-block m-auto'
    customSurveyCss.error.root = 'mt-3 alert alert-danger'

    return (
      <div className="attendance-survey-container mb-3">
        <Survey
          css={customSurveyCss}
          model={this.model}
          onComplete={(survey: Model) => this.onComplete(survey)}
        />
      </div>
    )
  }
}

export default LessonSurvey

import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { variantOptionsAsObject } from 'utils/models/variants'
import OrderComplete from '../OrderComplete'
import { Block, Button, CouponInput } from 'components'
import { money } from 'utils/models/money'
import { I18n } from 'react-redux-i18n'
import { find, filter, isEmpty, map } from 'lodash'
import T from 'types'

interface IProps extends RouteComponentProps<any> {
  goNext: () => void
  goPrevious: () => void
  applyCoupon: (coupon: string, orderToken: string) => void
  order: any
}

type IState = {
}


class CheckoutSummary extends React.Component<IProps, IState> {

  durationFromLineItem(lineItem: any) {
    const variantOptions = variantOptionsAsObject(lineItem.variant)
    // @ts-ignore
    const isSubscriptionVariant = variantOptions['subscribable'] === 'subscribable'
    // @ts-ignore
    const quantityOptionValue = variantOptions['pills-per-box']
    // const quantityOptionValue = find(
    //   lineItem.variant.optionValues,
    //   optionValue => optionValue.optionType.name === 'pills-per-box'
    // )
    const boxCount = I18n.t('storefront.phytotherapy.box', { count: quantityOptionValue })
    const renewableSuffix = isSubscriptionVariant ? ` (${I18n.t('storefront.phytotherapy.renewable', { count: quantityOptionValue})})` : ``
    const durationLabel = boxCount + renewableSuffix
    return durationLabel
  }

  renderPhytoBlock(phytoItems: T.LineItem[]) {
    if (isEmpty(phytoItems))
      return null;

    return <table className="table">
      <tbody>
        {phytoItems.map((lineItem: any, index: number) => (
          <tr key={`lineItem_${index}`}>
            <td className="text-left">{lineItem.name}</td>
            <td className="text-left">{this.durationFromLineItem(lineItem)}</td>
            <td className="text-right">{lineItem.displayTotal}</td>
          </tr>
        ))}
      </tbody>
    </table>
  }

  renderWowtechBlock(lineItems: T.LineItem[]) {
    if (isEmpty(lineItems))
      return null;

    return <table className="table">
      <tbody>
        {lineItems.map((lineItem: any, index: number) => (
          <tr key={`lineItem_${index}`}>
            <td className="text-left">{lineItem.name}</td>
            <td className="text-left">x{lineItem.quantity}</td>
            <td className="text-left">{lineItem.displayPrice}</td>
            <td className="text-right">{lineItem.displayTotal}</td>
          </tr>
        ))}
      </tbody>
    </table>
  }

  render() {
    const { order, goPrevious, goNext, applyCoupon } = this.props

    let phytoItems : T.LineItem[] = [];
    let otherItems : T.LineItem[] = [];

    let isPhytoOnly = true;

    order.lineItems.forEach((it: T.LineItem) => {
      if (map(it.variant.product.taxons, (taxon: T.Taxon) => taxon.name).includes('phytotherapy'))
        phytoItems.push(it)
      else {
        otherItems.push(it)
        isPhytoOnly = false
      }
    })

    const hasPromotion = !money(order.promoTotal).isZero()

    return (
      <Block classname="pharmacist container-m-800" title="Valider mon achat">
        <div className="charles step-progress">
          {order.state === 'complete' ? <OrderComplete order={order} /> : (
            <Fragment>
              <table className="table">
                <thead className="">
                  <tr className="highlighted-row">
                    <td className="text-left">Produit</td>
                    <td className="text-left"></td>
                    <td className="text-right">Total</td>
                  </tr>
                </thead>
              </table>
              { this.renderWowtechBlock(otherItems) }
              { this.renderPhytoBlock(phytoItems) }
              <table className="table">
                <tbody>
                  <tr>
                    <td className="text-left">Livraison { isPhytoOnly ? "(2 jours ouvrés)" : '' }</td>
                    <td />
                    <td className="text-right">{money(order.shipTotal).toFormat()}</td>
                  </tr>
                  {
                    hasPromotion && (
                      <tr>
                        <td className="text-left">Réduction</td>
                        <td />
                        <td className="text-right">{order.displayPromoTotal}</td>
                      </tr>
                    )
                  }
                  <tr className="border-top highlighted-row">
                    <td className="text-left">Total</td>
                    <td />
                    <td className="text-right">{money(order.total).toFormat()}</td>
                  </tr>
                </tbody>
              </table>
              {
                !isPhytoOnly && !hasPromotion && <CouponInput applyCoupon={(coupon: string) => applyCoupon(coupon, order.token)} />
              }
              <Button
                onClick={() => {
                  goNext()
                }}
              >
                Valider
            </Button>
            </Fragment>
          )}
        </div>
      </Block>
    )
  }
}

export default withRouter(CheckoutSummary)

import React from 'react'
import MasterArguments from './MasterArguments'
import TimeArguments from './TimeArguments'
import SommeilArguments from './SommeilArguments'
import VaginismeArguments from './VaginismeArguments'

export default (props: { slug: string }) => {
  switch (props.slug) {
    case 'vaginisme-program':
      return <VaginismeArguments />
      break
    case 'master-program':
      return <MasterArguments />
      break
    case 'sommeil-program':
      return <SommeilArguments />
      break
    default:
      return <TimeArguments />
      break
  }
}

import React, { Component } from 'react'
import classnames from 'classnames'

// icons from https://www.flaticon.com/packs/credit-cards-2
import americanExpress from 'assets/icons/credit-cards/american-express.svg'
import jcb from 'assets/icons/credit-cards/jcb.svg'
import maestro from 'assets/icons/credit-cards/maestro.svg'
import masterCard from 'assets/icons/credit-cards/master-card.svg'
import visa from 'assets/icons/credit-cards/visa.svg'
import paypal from 'assets/icons/credit-cards/paypal.svg'

const CREDIT_CARDS: any = {
  americanExpress,
  jcb,
  maestro,
  masterCard,
  visa,
  paypal
}

interface IProps {
  type: string,
  classname?: string,
}

class CreditCardIcon extends Component<IProps> {
  public render() {
    const { type, classname } = this.props

    return (
      <span
        className={classnames('credit-card-icon', classname)}
        dangerouslySetInnerHTML={{ __html: CREDIT_CARDS[type] }}
      />
    )
  }
}

export default CreditCardIcon

import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'
import { MainContainer } from 'containers'
import T from 'types'
import { PURCHASE_PRESCRIPTION_PRICE } from 'constants/config'
import { Block, PaymentFormV2, BlockHeader, SvgIcon } from 'components'
import prescriptionSvg from 'assets/icons/clinic/80/prescription.svg'
import './styles.sass'

type IProps = {
  prescription: T.Prescription
  profile: T.Profile
  order: T.OrderV1
  loading: boolean
  storefront: T.Storefront
  purchasePrescription: (
    prescription: T.Prescription,
    data: T.PaymentPayload,
    onSuccess: () => void,
  ) => void
  startPurchasePrescription: (prescription: T.Prescription) => void,
  getPaymentMethods: () => void,
}

type IState = {
  step: number
}

class PurchasePrescription extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      step: 0,
    }
  }

  componentDidMount() {
    const { prescription, startPurchasePrescription, getPaymentMethods } = this.props
    getPaymentMethods()
    startPurchasePrescription(prescription)
  }

  handlePaymentSuccess = (redirectUrl?: string) => {
    if (isNil(redirectUrl)) this.setState({ step: 1 })
    else
      setTimeout(() => {
        window.location.href = redirectUrl
      }, 1000)
  }

  handleValidatePayment = (data: T.PaymentPayload) => {
    const { prescription, purchasePrescription } = this.props

    purchasePrescription(prescription, data, this.handlePaymentSuccess)
  }

  render() {
    const { profile, loading, storefront, getPaymentMethods } = this.props
    return (
      <MainContainer>
        <div className="page download-prescription">
          <div className="prescription-purchase-container home-container">
            {this.state.step === 0 && (
              <div className="block-container">
                <BlockHeader title='Paiement sécurisé' />
                <PaymentFormV2
                  patientAttributes={profile}
                  loading={loading}
                  price={PURCHASE_PRESCRIPTION_PRICE}
                  cgu={I18n.t(`paymentForm.fields.labels.cguPhyto`)}
                  recurringPayment={false}
                  submitPayment={this.handleValidatePayment}
                  storefront={storefront}
                  getPaymentMethods={getPaymentMethods}
                />
              </div>
            )}
            {this.state.step === 1 && (
              <Block title={'Confirmation d’achat de votre ordonnance'}>
                <div className="thank-you-container">
                  <SvgIcon icon={prescriptionSvg} classname='prescription-icon' />
                  <h4 className="mt-3">Merci pour votre commande !</h4>
                  <p className="mt-4">Votre ordonnance est disponible dans votre espace médical.</p>
                  <br />
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <Link className="btn btn-outline-primary" to="/medical-info">
                        Mes ordonnances
                      </Link>
                    </div>
                    <div className="col-md-6 mt-1">
                      <Link className="btn btn-primary charles-btn" to="/home">
                        Accueil
                      </Link>
                    </div>
                  </div>
                </div>
              </Block>
            )}
          </div>
        </div>
      </MainContainer>
    )
  }
}

export default PurchasePrescription

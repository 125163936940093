import React, { Component, Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'constants/moment'
import { isUndefined, get, map, omitBy, isNil } from 'lodash'
import { Loader } from 'components'
import tracker from 'utils/trackers'
import StepAcademyPersonalInfos from './StepAcademyPersonalInfos'
import StepAcademyPayment from './StepAcademyPayment'
import StepAcademyAddress from './StepAcademyAddress'
import StepAcademyConfirmation from './StepAcademyConfirmation'
import StepAcademyFreeEpisode from './StepAcademyFreeEpisode'
import StepAcademyVariantChoice from './StepAcademyVariantChoice'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import charlesAcademyLogo from 'assets/icons/logo-therapiedigitale.svg'
import logoMia from 'assets/images/mia-logo.svg'
import './styles.sass'
import { IS_CHARLES_PLATFORM, TARGET_PLATFORM } from 'constants/config'

interface AcademyFunnelProps extends RouteComponentProps<any> {
  termsOfService: boolean
  isRecovery: boolean
  loading: boolean
  program: T.Program | undefined
  steps: T.StepperStep[]
  createAddress: (
    data: any,
    enrollmentId: string | undefined,
    onSuccess: () => void
  ) => void,
  academy: T.AcademyStore
  storefront: T.Storefront
  patientAttributes: object
  startOnboardingWithProgram: (slug: string) => void
  getPrograms: () => void
  getPaymentMethods: () => void
  setPersonalData: (data: object) => void
  setExtraPersonalData: (data: object) => void
  enrollProgram: (slug: string) => void
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  setTermsOptin: () => void
}

type TrackingListenerProps = {
  children: any
}

const FUNNEL_RESET_TIME = 60 * 1000 * 30

const PAGE_WAS_REFRESHED = 'PAGE_WAS_REFRESHED'
const PAGE_WAS_RESTORED_TOO_LATE = 'PAGE_WAS_RESTORED_TOO_LATE'
const PAGE_NEEDS_LOADING = 'PAGE_NEEDS_LOADING'
const UNKNOWN_CONFIG = 'UNKNOWN_CONFIG'
const CATEGORY = 'Onboarding Academy'

class TrackingListener extends React.Component<TrackingListenerProps> {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    this.sendPageView(this.context.router.history.location)
    this.context.router.history.listen(this.sendPageView)
  }

  sendPageView(location: Location) {
    tracker.trackPageViewV2(CATEGORY, location.pathname)
  }

  render() {
    return this.props.children
  }
}

class AcademyFunnel extends Component<AcademyFunnelProps> {

  public componentDidMount() {
    const { program } = this.props
    const params: T.AcademyParams = this.parseQueryString(window.location.search)
    this.initializeOnboarding(params)
    if (!isUndefined(program)) {
      tracker.trackAcademyStepView(program.shortName, 0)
    }
    const eventName = 'onpagehide' in window ? 'pagehide' : 'unload'
    window.addEventListener(eventName, this.handleWindowUnload, false)
  }
  public componentWillUnmount() {
    const eventName = 'onpagehide' in window ? 'pagehide' : 'unload'
    window.removeEventListener(eventName, this.handleWindowUnload, false)
  }

  handleWindowUnload = (e: any) => {
    const { patientAttributes } = this.props
    const email = get(patientAttributes, 'email')
    // Perf hardening CHAR-1110
    // if (!isUndefined(email)) {
    //   ApiService.cleanDrop(email)
    // }
  }

  private detectLaunchConfig(params: T.AcademyParams, programLoadedAt: Date | undefined): string {
    if (params.refresh || params.orderNumber || params.returnFromThreeDSecure || params.email) {
      return PAGE_NEEDS_LOADING
    }
    if (programLoadedAt) {
      if (programLoadedAt && moment().diff(moment(programLoadedAt)) < FUNNEL_RESET_TIME) {
        return PAGE_WAS_REFRESHED
      } else {
        return PAGE_WAS_RESTORED_TOO_LATE
      }
    } else {
      return UNKNOWN_CONFIG
    }
  }

  private initializeOnboarding(params: T.AcademyParams) {
    const {
      getPrograms,
      setTermsOptin,
      startOnboardingWithProgram,
      academy: { programLoadedAt },
    } = this.props

    const url = new URL(window.location.toString())

    setTermsOptin()

    const currentConfiguration = this.detectLaunchConfig(params, programLoadedAt)
    switch (currentConfiguration) {
      case PAGE_WAS_REFRESHED:
        break
      case PAGE_NEEDS_LOADING:
        startOnboardingWithProgram(params.slug)
        getPrograms()
        break
      case PAGE_WAS_RESTORED_TOO_LATE:
        sessionStorage.clear()
        url.hash = ''
        url.searchParams.append('refresh', 'true')
        window.location.href = url.toString()
        return
        break
      case UNKNOWN_CONFIG:
      default:
        sessionStorage.clear()
        url.hash = ''
        url.searchParams.append('refresh', 'true')
        window.location.href = url.toString()
        return
    }
    url.searchParams.delete('refresh')
    window.history.replaceState({}, document.title, url.toString())
  }

  private parseQueryString(queryString: string): T.AcademyParams {
    const searchParams = new URLSearchParams(queryString)
    const slug = searchParams.get('slug') || 'time-program'
    const optionalValues = {
      email: searchParams.get('email'),
      orderNumber: searchParams.get('orderNumber'),
      refresh: searchParams.get('refresh'),
      returnFromThreeDSecure: searchParams.get('returnFromThreeDSecure'),
    }

    return {
      ...omitBy(optionalValues, isNil),
      slug
    }
  }

  goNext(step: number): void {
    const { history, program, steps } = this.props
    const nextStep = step + 1
    history.push(steps[nextStep].path)
    if (!isUndefined(program)) {
      tracker.trackAcademyStepView(program.shortName, nextStep)
    }
  }

  goPrevious(step?: number): void {
    const { history } = this.props
    history.goBack()
  }

  private stepIndex(path: string): any {
    const { steps } = this.props
    return map(steps, (step: T.StepperStep) => step.path).indexOf(path)
  }

  render() {
    const { program, steps } = this.props
    const goNext = (step: number) => () => this.goNext(step)
    const goPrevious = (step?: number) => () => this.goPrevious(step)
    const selectedLogo = (IS_CHARLES_PLATFORM ? charlesAcademyLogo : logoMia)
    const logoClass = `${TARGET_PLATFORM}-academy-logo-svg`

    return isUndefined(program) ? (
      <Loader />
    ) : (
      <div className={`funnel-` + program.slug}>
        <div className="charles step-progress">
          <TrackingListener>
            <Switch>
              <Route
                path="/informations"
                render={props => (
                  <StepAcademyPersonalInfos
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goNext={goNext(0)}
                    goPrevious={goPrevious(0)}
                  />
                )}
              />
              <Route
                path="/episode-gratuit"
                render={props => (
                  <StepAcademyFreeEpisode
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goPrevious={goPrevious(this.stepIndex("/episode-gratuit"))}
                  />
                )}
              />
              <Route
                path="/choix-formule"
                render={props => (
                  <StepAcademyVariantChoice
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goNext={goNext(1)}
                    goPrevious={goPrevious(1)}
                  />
                )}
              />
              <Route
                path="/adresse"
                render={props => (
                  <StepAcademyAddress
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goNext={goNext(this.stepIndex("/adresse"))}
                    goPrevious={goPrevious(this.stepIndex("/adresse"))}
                  />
                )}
              />
              <Route
                path="/paiement"
                render={props => (
                  <StepAcademyPayment
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goNext={goNext(this.stepIndex("/paiement"))}
                    goPrevious={goPrevious(this.stepIndex("/paiement"))}
                  />
                )}
              />
              <Route
                path="/confirmation"
                render={props => (
                  <StepAcademyConfirmation
                    {...props}
                    {...this.props}
                    program={program}
                    steps={steps}
                    goPrevious={goPrevious(this.stepIndex("/confirmation"))}
                  />
                )}
              />
              <Redirect from="/*" to={'/informations'} />
            </Switch>
          </TrackingListener>
        </div>
      </div>
    )
  }
}

export default AcademyFunnel

import React, { FC } from 'react'
import { isUndefined } from 'lodash'
import T from 'types'
import '../styles.sass'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'

const UCDrugsAddress: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation, storePatientCurrentCountry } = props
  const { steps, stepIndex, goPrevious, goNext } = navigation

  function selectPatientCurrentCountry(frenchCitizen: boolean) {
    if(!isUndefined(storePatientCurrentCountry)) {
      storePatientCurrentCountry(frenchCitizen)
      goNext()
    }
  }

  return (
    <UCStep>
      <div>HABITEZ VOUS EN FRANCE</div>
      <div className='french-citizen-or-not'>
        <button onClick={() => selectPatientCurrentCountry(true)}>oui</button>
        <button onClick={() => selectPatientCurrentCountry(false)}>non</button>
      </div>
      <button onClick={goPrevious}>previous</button>
    </UCStep>
  )
}

export default withNavigation(UCDrugsAddress)

import React, { useState, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { Model } from 'survey-core'
import { SetupMediaWizard, SvgIcon, Button } from 'components'
import Step, { StepProps } from './Step'
import { LayoutStep, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { getIcon } from 'assets/icons'
import yellowUnderline from 'assets/icons/icon-soulignement.svg'
import T from 'types'
import { VISIO } from 'constants/doctorSelectionStates'
import { get } from 'lodash'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import useTrackedVisioConfiguration from "helpers/hooks/useTrackedVisioConfiguration"
import { MediaSetupTrackingContext } from 'helpers/hooks/useVisioConfiguration'

interface IProps extends RouteComponentProps<any>, StepProps {
  doctor: T.Doctor,
  consultation: T.Consultation | null,
  order: T.OrderV1 | null,
  medium: string,
  survey: Model,
  questionName: string,
  isRecovery: boolean,
  invitationToken: string | null,
  invitationAcceptedAt: Date | null,
  createMessage: (userId: number, body: string, patientUserId: number) => void,
  prefix: string,
  uploadSurvey: (orderNumber: string | undefined, survey: Model) => void,
  acceptInvitation: (data: any, onSuccess: () => void) => void,
  goNext: () => void,
  goPrevious: () => void,
  patientAttributes: object,
}

enum StepPreConsultationSetupStep {
  INITIAL = 'initial',
  MEDIA = 'media',
}

const StepPreConsultationSetup = (props: IProps) => {

  const { steps, goPrevious, goNext, consultation, patientAttributes } = props

  const email = get(patientAttributes, 'email', '')
  const [step, setStep] = useState<StepPreConsultationSetupStep>(StepPreConsultationSetupStep.INITIAL)
  const shouldShowSetup = consultation && consultation.communicationChannel === VISIO
  const handleConnect = () => { setTimeout(() => window.location.href = `/me.html?login=${email}#`, 1000) }
  const trackingConfig: MediaSetupTrackingContext = {
    context: 'onboarding',
    consultationId: consultation ? consultation.id : undefined
  }
  const mediaConfig = useTrackedVisioConfiguration(trackingConfig)
  switch (step) {
    case StepPreConsultationSetupStep.INITIAL:
      return (
        <Step steps={steps} goPrevious={goPrevious} currentStep={4}>
          <LayoutStep>
            <LayoutMain variant="small">
              <div className="setup-media-wizard">
                <div className="block-container login-container without-header m-auto">
                  <div className="block-content">
                    <div className="login-block">
                      <div className="setup-media-welcome">
                        {shouldShowSetup ? (
                          <>
                            <SvgIcon classname="logo-icon" icon={getIcon(IS_CHARLES_PLATFORM ? "logo.charlesCircular" : "logo.mia")} />
                            <h3>{IS_CHARLES_PLATFORM ? "Bienvenue chez Charles.co" : "Bienvenue"}</h3>
                            <p>
                              En vue de votre prochaine consultation en visio, nous allons vous aider à paramétrer votre navigateur.
                            </p>
                            <Button
                              variant="btn-primary"
                              classname="mx-auto"
                              onClick={() => setStep(StepPreConsultationSetupStep.MEDIA)}>
                                Je me prépare pour ma consultation
                            </Button>
                          </>
                        ) : (
                          <>
                            <h3>{IS_CHARLES_PLATFORM ? "Bienvenue chez Charles.co" : "Bienvenue"}</h3>
                            <SvgIcon classname="logo-icon" icon={getIcon(IS_CHARLES_PLATFORM ? "logo.charlesCircular" : "logo.mia")} />
                            <Button
                              variant="btn-primary"
                              classname="mx-auto"
                              onClick={() => handleConnect()}>
                                Je me connecte à mon espace Patient
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LayoutMain>
          </LayoutStep>
        </Step>
      )
    case StepPreConsultationSetupStep.MEDIA:
    default:
      return (
        <Step steps={steps} goPrevious={goPrevious} currentStep={4}>
          <LayoutStep>
            <LayoutMain variant="small">
              <div className="block-container login-container without-header m-auto">
                <div className="block-content">
                  <div className="login-block">
                    <SetupMediaWizard
                      onSuccess={() => handleConnect()}
                      onSkipped={() => handleConnect()}
                      onDeviceMissing={() => handleConnect()}
                      trackingConfig={trackingConfig}
                      mediaConfig={mediaConfig}
                    />
                  </div>
                </div>
              </div>
            </LayoutMain>
          </LayoutStep>
        </Step>
      )
    }
}

export default StepPreConsultationSetup
import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import uuidv4 from 'uuid'
import { I18n } from 'react-redux-i18n'
import {
  max,
  map,
  first,
  find,
  isEmpty,
  isNull,
  filter,
  includes,
  values,
  reduce,
  head,
  isUndefined,
  Dictionary,
  get,
  join,
  isArray
} from 'lodash'
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers'
import { Block, Button } from 'components'
import SvgIcon from 'components/SvgIcon'
import { MainContainer } from 'containers'
import T from 'types'
import build from 'redux-object'
import CookiesHelper from 'helpers/cookies-helper'
import { money } from 'utils/models/money'
import recommendedIcon from 'assets/images/recommended.svg'
import { TARGET_PLATFORM } from 'constants/config'
import arrowDown from 'assets/icons/chevron-down.svg'
import playCircle from 'assets/icons/play-circle.svg'

import productsData from './products.json'

import './styles.sass'
import { forEach } from 'react-bootstrap/ElementChildren'

const DEFAULT_RECOMMENDED_DURATION = 3
interface IProps extends RouteComponentProps<any> {
  collectionPrescription: T.Prescription[]
  profile: T.Profile
  getProducts: () => void
  getTaxons: () => void
  getPrescriptions: () => void
  createCart: () => void
  addToCart: (variant: T.Variant, onSuccess: Function) => void
  storefront: T.Storefront
}

type IState = {
  currentProduct: T.Product | null
}

class WowtechProduct extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      currentProduct: null,
    }
  }

  componentDidMount() {
    const { storefront, getProducts, getTaxons, getPrescriptions } = this.props

    if (isEmpty(storefront.product) || isEmpty(storefront.taxon)) {
      getTaxons()
      getProducts()
    }
    this.getCurrentProduct()
  }

  getCurrentProduct() {
    const { storefront, collectionPrescription } = this.props
    const taxons = build(storefront, 'taxon')
    const wowtechTaxon = find(taxons, (taxon: any) => taxon.name === 'wowtech')
    const allWowtechProducts = isUndefined(wowtechTaxon) ? [] : wowtechTaxon.products

    const { match } = this.props
    const slug = match.params.slug

    this.setState({currentProduct: find(allWowtechProducts, (pro)=> pro.slug == slug)})
  }

  handleClick(variant: T.Variant) {
    const { createCart, addToCart, storefront } = this.props

    if (!storefront.cart)
      createCart()
    // add a doubleclick safeguard -- timeout?
    addToCart(variant, ()=> {})
  }

  productImagePath = (productData: any, key : string) => {
    const path = get(productData, key)

    if (!path)
      return '' // TODO - use placeholder

    return `wow/${path}`
  }

  renderSpecificationBlockList = (obj : any, i: number) => {
    let arr = []

    for (const k in obj)
      arr.push(`<li><span class="wowtech-product-specifications-span">${k}</span>: ${obj[k]}</li>`)

    return <ul id={`specification_${i + 1}`} dangerouslySetInnerHTML={{ __html: join(arr, '')}} />
  }

  expandSpecifications(id: number) {
    const toExpand = document.getElementById(`specification_${id}`)
    const toggleIcon = document.getElementById(`togglerIcon_${id}`)
    if (toExpand && toggleIcon) {
      if (toExpand.classList.contains('specification-expand')) {
        toggleIcon.innerText = '+'
      } else {
        toggleIcon.innerText = '-'
      }
      toExpand.classList.toggle('specification-expand')
    }
  }

  renderSpecificationsBlock = (specData: any, i: number) => {
    let ret : JSX.Element[] = []
    for (const property in specData) {
      let body : JSX.Element;
      if (isArray(specData[property]))
        body = <p id={`specification_${i + 1}`} dangerouslySetInnerHTML={{ __html: join(specData[property], '<br /><br />')}} />
      else
        body = this.renderSpecificationBlockList(specData[property], i)

      ret.push(
        <div className="wowtech-product-specifications-card">
          <div className="d-flex justify-content-between w-100">
            <h4 onClick={() => this.expandSpecifications(i + 1)}>{property}</h4>
            <div className="toggler-icon" id={`togglerIcon_${i + 1}`} onClick={() => this.expandSpecifications(i + 1)}>+</div>
          </div>
          {body}
        </div>,
      )
    }

    return <>
      {ret.map((iterator: any) => iterator )}
    </>
  }

  renderHowItWorksBlock = (howItWorksData: any, index: number) => {
    return (
      <div className="wowtech-how-it-work-card">
        <div className="wowtech-how-it-work-image-wrapper">
          <div className="wowtech-how-it-work-bullet-trick">
            <div className="wowtech-how-it-work-bullet-number">{index}</div>
          </div>
          <img src={this.productImagePath(howItWorksData, 'illustration')} className="img-position img-responsive wowtech-product-img" />
        </div>
        <p>{howItWorksData.description}</p>
      </div>
    )
  }

  expandFaqAnswer(index: number) {
    const toExpand = document.getElementsByClassName('wowtech-faq-card-answer')[index]
    toExpand.classList.toggle('expand-card-answer')
    document.getElementsByClassName('wowtech-faq-arrow-icon-wrapper')[index].classList.toggle('flip-arrow-icon')
  }

  renderFaqBlock = (faqData: any, index: number) => {
    return (
      <div className="wowtech-faq-card">
        <div className="wowtech-faq-card-title">
          <h4 onClick={() => this.expandFaqAnswer(index)}>{faqData.question}</h4>
          <div className="wowtech-faq-arrow-icon-wrapper" onClick={() => this.expandFaqAnswer(index)}>
            <SvgIcon icon={arrowDown} classname="wowtech-faq-arrow-icon" />
          </div>
        </div>
        <div className="wowtech-faq-card-answer">
          <p>{faqData.answer}</p>
        </div>
      </div>
    )
  }

  toggleSelectedMedia(element: HTMLMediaElement) {
    const ListE = document.getElementsByClassName('wowtech-border-selected-image')
    if (ListE) {
      Array.from(ListE).forEach((el) => {
        el.classList.remove('wowtech-border-selected-image')
      })
    }
    element.classList.add('wowtech-border-selected-image')
  }

  displaySelectedMedia(type: string, src: string, e: any) {
    const target = e.target
    const video = document.getElementById('display_video') as HTMLMediaElement
    const imageWrapper = document.getElementsByClassName('display_image')[0] as HTMLMediaElement
    const image = document.getElementsByClassName('display_image')[0].firstChild as HTMLMediaElement
    // tslint:disable-next-line:max-line-length
    const magnifier = document.getElementsByClassName('display_image')[0].children[1].children[0] as HTMLMediaElement
    if (type === 'video' && image && video) {
      imageWrapper.style.display = 'none'
      video.style.display = 'block'
      video.setAttribute('src', src)
      video.play()
    } else if (type === 'img' && image && video && magnifier) {
      imageWrapper.style.display = 'block'
      video.style.display = 'none'
      video.pause()
      image.setAttribute('src', src)
      magnifier.setAttribute('src', src)
    }
    this.toggleSelectedMedia(target)
  }

  render() {
    const { storefront, history, addToCart } = this.props
    const { currentProduct } = this.state

    if (!currentProduct)
      return <p>loading...</p>

    const productData : any = get(productsData, currentProduct.slug)

    if (!productsData)
       return <p>Product not found</p>

    return (
      <MainContainer title="Nos produits" showCart={true}>

        <div className="wowtech-product-page-container">
          <div className="wowtech-product-presentation">
            <div className="wowtech-product-images-videos-wrapper">
              {/*<p className="d-md-none">{productData.description}</p>*/}
              <div className="wowtech-product-images-wrapper">
                <div className="wowtech-product-images mb-0 d-flex flex-column align-items-center">
                  <img src={this.productImagePath(productData, 'images.video-thumbnail')} className="wowtech-thumbnail-image" onClick={(event) => {this.displaySelectedMedia('video', productData.video, event)}} />
                  <div className="wowtech-play-image-wrapper">
                    <SvgIcon icon={playCircle} classname="wowtech-play-image" />
                  </div>
                </div>
                <img src={this.productImagePath(productData, 'images.product_1')} className="wowtech-product-images" onClick={(event) => {this.displaySelectedMedia('img', this.productImagePath(productData, 'images.product_1'), event)}} />
                <img src={this.productImagePath(productData, 'images.product_2')} className="wowtech-product-images" onClick={(event) => {this.displaySelectedMedia('img', this.productImagePath(productData, 'images.product_2'), event)}} />
                <img src={this.productImagePath(productData, 'images.product_3')} className="wowtech-product-images" onClick={(event) => {this.displaySelectedMedia('img', this.productImagePath(productData, 'images.product_3'), event)}} />
              </div>
              <div className="wowtech-product-video-wrapper">
                <GlassMagnifier
                  className="display_image"
                  allowOverflow={false}
                  square={true}
                  imageSrc={this.productImagePath(productData, 'images.product_1')}
                  magnifierSize="35%"
                  magnifierBorderSize={2}
                  magnifierBorderColor={'rgba(0, 40, 101, 1)'}
                />
                <video id="display_video" controls={true} autoPlay={false} >
                  <source src={productData.video} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="wowtech-product-text">
              <h1>{currentProduct.name}</h1>
              {productData.subtitle.map((subtitle: string) => (
                <h2>{subtitle}</h2>
              ))}
              <div className="wowtech-product-price">{money(currentProduct.price).toFormat()}</div>
              <p className="">{productData.description}</p>
              <Button classname="align-self-start" onClick={(e) => this.handleClick(currentProduct.variantsIncludingMaster[0])}>Ajouter au panier</Button>
            </div>
          </div>
          {/*<Button onClick={history.goBack}>Retour</Button>*/}
          <div className="wowtech-product-specifications">
            <img src={this.productImagePath(productData, 'images.packaging')} className="wowtech-product-specifications-image" />
            <div className="wowtech-product-specifications-text">
              <h3>Spécifications</h3>
              <div className="wowtech-product-specifications-list">
                {productData.specifications.map((it: any, i: number) => this.renderSpecificationsBlock(it, i))}
              </div>
            </div>
          </div>
          <div className="wowtech-product-how-it-work">
            <h3>Comment ca marche ?</h3>
            <div className="wowtech-product-how-it-work-card-wrapper">
              {productData.how_it_works.map((it : any, i: number) => this.renderHowItWorksBlock(it, i + 1))}
            </div>
            <Button classname="align-self-center d-md-none" onClick={(e) => this.handleClick(currentProduct.variantsIncludingMaster[0])}>Ajouter au panier</Button>
          </div>
          <div className="wowtech-product-faq">
            <h3>Faq</h3>
            {productData.faq.map((it : any, i: number) => this.renderFaqBlock(it, i))}
          </div>
        </div>
      </MainContainer>
    )
  }
}

export default withRouter(WowtechProduct)

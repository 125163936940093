import React from 'react'
import {
  ReactSurveyElement, ReactSurveyElementsWrapper, SurveyQuestionRadiogroup,
} from 'survey-react-ui';
import { Base, ItemValue, QuestionRadiogroupModel, SurveyModel } from 'survey-core';

/*
  SurveyJS considers only data changes when deciding to go
  automatically to next page upon answering.
  On a radiogroup question, clicking an item will change
  the data, and go automatically to next page (if enabled).
  But coming back to the question, the answer is already
  selected, and clicking it will not do anything since it
  does not change the data. This is and feels weird because
  clicking another answer will move to the next page.
  See also : https://github.com/surveyjs/survey-library/issues/1584

  This extension allows to click on an already selected radiogroup
  answer and automatically pass to the next question
  Implementation consists on :
  - using onClick instead of onChange on the input of the item.
  - clearing the value before setting it again when the value was
  already selected.
*/
export class SurveyQuestionRadiogroupExtended extends SurveyQuestionRadiogroup {

  constructor(props: any) {
    super(props)
    this['renderItem'] = this.customRenderItem // needed to redefine the private renderItem method
  }
  private customRenderItem(
    key: string,
    item: ItemValue,
    value: any,
    cssClasses: any,
    index?: string
  ): JSX.Element {
    const renderedItem = (
      <SurveyQuestionRadioItemExtended
        key={key}
        question={this.question}
        cssClasses={cssClasses}
        isDisplayMode={this.isDisplayMode}
        item={item}
        textStyle={this.textStyle}
        index={index}
        isChecked={value === item.value}
      />)
    const survey = this.question.survey as SurveyModel;
    let wrappedItem: JSX.Element | null = null;
    if (!!survey) {
      wrappedItem = ReactSurveyElementsWrapper.wrapItemValue(survey, renderedItem, this.question, item);
    }
    return (wrappedItem !== null && wrappedItem !== undefined) ? wrappedItem : renderedItem
  }
}

export class SurveyQuestionRadioItemExtended extends ReactSurveyElement {
  constructor(props: any) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
  }
  protected getStateElement(): Base {
    return this.item;
  }
  protected get question(): QuestionRadiogroupModel {
    return this.props.question;
  }
  protected get item(): ItemValue {
    return this.props.item;
  }
  protected get textStyle(): any {
    return this.props.textStyle;
  }
  protected get index(): number {
    return this.props.index;
  }
  protected get isChecked(): boolean {
    return this.props.isChecked;
  }
  private get hideCaption(): boolean {
    return this.props.hideCaption === true;
  }
  public shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if (!super.shouldComponentUpdate(nextProps, nextState)) return false;
    if (!this.question) return false;
    return (
      !this.question.customWidget ||
      !!this.question.customWidgetData.isNeedRender ||
      !!this.question.customWidget.widgetJson.isDefaultRender ||
      !!this.question.customWidget.widgetJson.render
    );
  }
  handleOnChange(event: any) {
    this.question.clickItemHandler(this.item);
  }
  handleOnMouseDown(event: any) {
    if (this.isChecked) {
      this.question.renderedValue = null
    }
    this.question.onMouseDown()
  }
  protected canRender(): boolean {
    return !!this.question && !!this.item;
  }
  protected renderElement(): JSX.Element {
    var itemClass = this.question.getItemClass(this.item);
    var labelClass = this.question.getLabelClass(this.item);
    var controlLabelClass = this.question.getControlLabelClass(this.item);
    const itemLabel = !this.hideCaption ? <span className={controlLabelClass}>{this.renderLocString(this.item.locText, this.textStyle)}</span> : null;
    return (
      <div
        className={itemClass}
        role="presentation"
      >
        <label onMouseDown={this.handleOnMouseDown} className={labelClass}>
          <input
            aria-describedby={this.question.ariaDescribedBy}
            className={this.cssClasses.itemControl}
            id={this.question.getItemId(this.item)}
            type="radio"
            name={this.question.questionName}
            checked={this.isChecked}
            value={this.item.value}
            disabled={!this.question.getItemEnabled(this.item)}
            onChange={this.handleOnChange}
          />
          {
            this.cssClasses.materialDecorator ?
              <span className={this.cssClasses.materialDecorator}>
                {this.question.itemSvgIcon ?
                  <svg
                    className={this.cssClasses.itemDecorator}
                  >
                    <use xlinkHref={this.question.itemSvgIcon}></use>
                  </svg> :
                  null
                }
              </span> :
              null
          }
          {itemLabel}
        </label>
      </div>
    );
  }
}

export default SurveyQuestionRadiogroupExtended

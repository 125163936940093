import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { get, last } from 'lodash'
import moment from 'constants/moment'

import { Block, Button, SvgIcon } from 'components'
import { MainContainer } from 'containers'
import { getIcon } from 'assets/icons'
import T from 'types'
import { CookiesHelper } from 'helpers'
import prescriptionImage from 'assets/images/prescription-preview.jpg'

import { I18n } from 'react-redux-i18n'

type IProps = {
  prescription: T.Prescription
  getPharmacies: () => void
  getPrescriptionById: (id: number) => void
  getPrescriptions: () => void
  showPopup: (
    {
      classPopup,
      popupTitle,
      popupType,
      popupProps,
    }: {
      classPopup: string
      popupTitle: string
      popupType: string
      popupProps: any
    },
  ) => void
}

class DownloadPhytotherapyPrescription extends React.Component<IProps> {
  private renderOrderTreatment() {
    const { prescription } = this.props
    const onlyOption = prescription.canBeDownloaded
    const data = [
      <span>Livraison du traitement à domicile en 24h à 48h</span>,
      <span>Même tarif qu'en pharmacie physique</span>,
    ]
    return (
      <Block
        title={onlyOption ? 'Commander mon traitement' : 'Option 1 - Commander mon traitement'}
        classname="treatment"
        icon="clinic.box"
        headerInside
      >
        <ul className="exit-bullets text-left">
          {data.map((datum, index) => (
            <li key={`exit-bullet_${index}`}>{datum}</li>
          ))}
        </ul>
        <div className="d-flex justify-content-center">
          <Link className="btn btn-primary" to={`/phytotherapy/${prescription.id}`}>
            Commander
          </Link>
        </div>
      </Block>
    )
  }

  prescriptionDownloadUrl(prescription: T.Prescription) {
    const actualDownloadURL = `${prescription.downloadUrl
      }?access_token=${CookiesHelper.getPureOauthAccessToken()}`
    return actualDownloadURL
  }

  renderOrderDownload() {
    const { prescription } = this.props

    const actualDownloadURL = this.prescriptionDownloadUrl(prescription)
    return (
      <Block
        title="Option 2 - Télécharger mon e-ordonnance sans commander"
        icon="clinic.download"
        headerInside
      >
        <div className="text-danger">{I18n.t('pages.ordonnance.delivery.phyto_warning')}</div>
        <div className="d-flex justify-content-center">
          <a href={actualDownloadURL} target="_blank" className="btn btn-outline-primary mt-3">
            Voir mon ordonnance
          </a>
        </div>
      </Block>
    )
  }

  showPrescriptionPopup = () => {
    const { showPopup, prescription } = this.props
    showPopup({
      classPopup: '',
      popupTitle: 'Mon ordonnance',
      popupType: 'POPUP_PRESCRIPTION',
      popupProps: {
        prescription,
      },
    })
  }
  render() {
    const { prescription } = this.props
    const actualDownloadURL = this.prescriptionDownloadUrl(prescription)

    return (
      <MainContainer>
        <div className="page download-prescription">
          <div className="home-container">
            <div className="infos-container">
              <div className="h3">Mon ordonnance de Phytothérapie</div>
              <div className="check-presc-container mb-5 row">
                <div className="thumbnail-container col-md-6">
                    <img
                    className="presc-thumbnail img-fluid"
                      src={prescriptionImage}
                      alt="prescription"
                      title="patient"
                    />
                  </div>
                <div className="details-container col-md-6">
                  <div className="details-wording pt-3">
                    <div className="d-flex">
                      <SvgIcon icon={getIcon('clinic.warning')} classname="icon-24px mr-2" />
                      <span>
                        Les phytothérapies ne sont pas rembourséés par la sécurité sociale
                      </span>
                    </div>
                  </div>
                  {prescription.canBeDownloaded ? (
                    <a href={actualDownloadURL} target="_blank" className="btn btn-outline-primary">
                      Voir mon ordonnance
                    </a>
                  ) : (
                    <Button onClick={() => this.showPrescriptionPopup()} variant="btn-outline-primary">
                      Aperçu ordonnance
                    </Button>
                  )}
                </div>
                </div>
                {this.renderOrderTreatment()}
                {this.renderOrderDownload()}
              </div>
            </div>
          </div>
      </MainContainer>
    )
  }
}

export default DownloadPhytotherapyPrescription

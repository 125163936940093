import React from 'react'
import { MediaConfig } from 'helpers/hooks/useVisioConfiguration'

const ShowMediaDevices = (props: { devices: MediaDeviceInfo[], type: MediaDeviceKind }) => {
  const devicesOfType = props.devices.filter(device => device.kind === props.type)
  return (
    <ul>
      {devicesOfType.map((device, index) => (
        <ShowMediaDevice device={device} key={`device_${index}`}/>
      ))}
    </ul>
  )
}
const ShowMediaDevice = (props: { device: MediaDeviceInfo }) => (
  <li>
    {props.device.label}
  </li>
)

const DebugMediaWizard = (props : { config: MediaConfig }) => {
  const {
    devices,
    hasCamera,
    hasMic,
    hasSpeaker,
    promptOpened,
    promptError,
    permissionForCamera,
    permissionForMic,
    promptForVisioPermissions,
    wizardState,
  } = props.config

  return (
    <div>
      <h2 className="text-center">{wizardState}</h2>
      <table className="m-auto mb-2">
        <thead>
          <tr>
            <th>Type</th>
            <th>Détecté</th>
            <th>Autorisé</th>
            <th>Devices</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Caméra</td>
            <td>{hasCamera ? "✅" : "❌"}</td>
            <td>{permissionForCamera}</td>
            <td><ShowMediaDevices devices={devices} type="videoinput" /></td>
          </tr>
          <tr>
            <td>Micro</td>
            <td>{hasMic ? "✅" : "❌"}</td>
            <td>{permissionForMic}</td>
            <td><ShowMediaDevices devices={devices} type="audioinput" /></td>
          </tr>
          <tr>
            <td>Speaker</td>
            <td>{hasSpeaker ? "✅" : "❌"}</td>
            <td>{"N/A"}</td>
            <td><ShowMediaDevices devices={devices} type="audiooutput" /></td>
          </tr>
        </tbody>
      </table>
      {promptError && (<p className="red-error">{promptError.name} - {promptError.message}</p>)}

      <p>Prompt is currently {promptOpened ? "opened" : "closed"}</p>
      <button className="mt-4 btn btn-primary m-auto" onClick={() => promptForVisioPermissions()}>Autoriser</button>
    </div>
  )
}

export default DebugMediaWizard
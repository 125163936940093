import React, { FC, createContext, useEffect } from 'react'
import T from 'types'
import { Workflow } from 'components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import UCFunnel from 'page/Pages/UnifiedCheckout/UCFunnel'
import UCTreatmentPlan from 'page/Pages/UnifiedCheckout/UCTreatmentPlan'
import { checkoutGlobalSteps } from 'helpers/checkout-steps-helper'
import build from 'redux-object'
import './styles.sass'
import { isNil } from 'lodash'

interface IProps extends T.UnifiedCheckoutProps, RouteComponentProps<any> { }

const UnifiedCheckout: FC<IProps> = (props: IProps) => {
  const {
    unifiedCheckout,
    storefront,
    startUnifiedCheckout,
    getPostConsultationRecommendations,
    match,
    getTaxons,
    getProducts
  } = props
  useEffect(() => {
    const { match } = props
    getTaxons()
    getProducts()
    getPostConsultationRecommendations(match.params.id)
    startUnifiedCheckout(() => {})
  },        [])

  const consultation = build(storefront, 'consultation', match.params.id)
  const orderId = unifiedCheckout.orderId
  const order: T.OrderV2 = build(storefront, 'cart', orderId)

  if (isNil(consultation) || isNil(order)) {
    return null
  }  {
    const propsWithConsultation = {
      ...props,
      consultation, // Force typescript to recognize consultation is not null
      order,
    }
    const steps: T.WorkflowStep[] = [
      {
        path: 'presentation',
        comp: <UCTreatmentPlan {...propsWithConsultation} />,
      },
      {
        path: 'f',
        comp: <UCFunnel {...propsWithConsultation} />,
      },
    ]

    return <Workflow steps={steps} level={0} />
  }
}

export default withRouter(UnifiedCheckout)

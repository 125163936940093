import React from 'react'
import { isNil, filter, includes, findIndex, isEmpty } from 'lodash'
import moment from 'constants/moment'
import { randomSelector } from 'utils/helpers'
import { SvgIcon } from 'components'
import { DoctorSelection } from 'containers'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import T from 'types'
import {
  CHAT,
  VISIO,
  PHONE,
} from 'constants/doctorSelectionStates'
import { getIcon } from 'assets/icons'

interface IStep {
  id: string,
  title: string,
  mediums: string[],
  showInSkipDoctorMode: boolean,
  showNextButton: boolean,
}

interface IState {
  autoChosenDoctor: T.Doctor | undefined,
  consultation: string,
  medium: string,
  currentStep: IStep
}

const STEPS: IStep[] = [
  {
    id: 'media',
    title: '3. Comment voulez vous joindre votre médecin ?',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: true,
    showNextButton: false,
  }, {
    id: 'slot-choice',
    title: '3. Choisissez une date de consultation',
    mediums: [PHONE, VISIO],
    showInSkipDoctorMode: true,
    showNextButton: false,
  }, {
    id: 'autoChosenDocConfirmation',
    title: '3. Confirmez votre mécecin',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: false,
    showNextButton: true,
  }, {
    id: 'booking',
    title: '3. Choisissez un médecin',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: false,
    showNextButton: false,
  },
]

class BCBooking extends React.Component<BookingConsultationStepProps, IState> {

  constructor(props: BookingConsultationStepProps) {
    super(props)
    const currentStep = STEPS[0]

    this.state = {
      currentStep,
      autoChosenDoctor: undefined,
      consultation: props.consultationType,
      medium: props.medium,
    }
    this.handleSelectConsult = this.handleSelectConsult.bind(this)
    this.handleSelectMedium = this.handleSelectMedium.bind(this)
    this.handleSelectTimeSlot = this.handleSelectTimeSlot.bind(this)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
  }

  componentDidMount() {
    const { getDoctors, getCommunicationChannels, skipDoctorSelection } = this.props
    getCommunicationChannels()
    if (!skipDoctorSelection) {
      getDoctors()
    }
  }

  private actualSteps(): IStep[] {

    const { skipDoctorSelection } = this.props
    return filter(STEPS, (step: IStep) => {
      const showForMedium = isNil(this.state.medium)
        ? true
        : includes(step.mediums, this.state.medium)

      const shouldShow = !skipDoctorSelection || step.showInSkipDoctorMode
      return showForMedium && shouldShow
    })
  }

  private goNextStep() {
    if (this.isLastStep()) {
      this.handleNextButtonClick()
    } else {
      this.setState({ currentStep: this.nextStep() })
    }
  }
  private nextStep(): IStep {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return actualSteps[currentStepIndex + 1]
  }

  private goPreviousStep() {
    this.setState({ currentStep: this.previousStep() })
  }

  private previousStep(): IStep {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return actualSteps[currentStepIndex - 1]
  }
  private isFirstStep() {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return (currentStepIndex === 0)
  }
  private isLastStep() {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return (currentStepIndex === actualSteps.length - 1)
  }

  private handleSelectConsult(consultation: string) {
    const { setChosenConsultType } = this.props

    this.setState(
      {
        consultation,
        currentStep: this.nextStep(),
      },
      () => setChosenConsultType(consultation),
    )
  }

  private handleSelectMedium(medium: string) {
    const {
      collection,
      setChosenMedium,
      setChosenDoctor,
      consultationType,
    } = this.props

    if (medium === CHAT) {
      const filteredCollection: T.Doctor[] = filter(collection, (doctor: T.Doctor) =>
        includes(doctor.communicationChannels, medium) &&
        includes(doctor.consultationTypes, consultationType),
      )
      const autoChosenDoctor: (T.Doctor | undefined) = this.getRandomDoctor(filteredCollection)
      this.setState(
        {
          medium,
          autoChosenDoctor,
        },
        () => {
          setChosenMedium(medium)
          setChosenDoctor(autoChosenDoctor as T.Doctor)
          this.goNextStep()
        },
      )
    } else {
      this.setState({ medium }, () => {
        setChosenMedium(medium)
        this.goNextStep()
      })
    }
  }

  private getRandomDoctor = (doctors: T.Doctor[]): T.Doctor | undefined => {
    const refilteredDoctors = filter(doctors, (d) => d.available == true)
    return randomSelector(refilteredDoctors, (doctor: T.Doctor) => doctor.z || 100)
  }

  private handleSelectTimeSlot(slot: Date) {
    const { setChosenDoctor, setChosenTimeSlot, collection, medium, consultationType } = this.props

    const filteredCollection: T.Doctor[] = filter(collection, (doctor: T.Doctor) => {
      if (
        includes(doctor.communicationChannels, medium) &&
        includes(doctor.consultationTypes, consultationType)
      ) {
        const freeTimeSlots = consultationType === 'simple' ?
          doctor.freeSimpleSlots :
          doctor.freeComplexSlots
        const matchingSlotIndex = findIndex(
          freeTimeSlots,
          (dateString => moment(dateString).isSame(slot)),
        )
        return (matchingSlotIndex >= 0)
      }
      return false
    })
    const autoChosenDoctor: (T.Doctor | undefined) = this.getRandomDoctor(filteredCollection)

    setChosenTimeSlot(slot)
    setChosenDoctor(autoChosenDoctor as T.Doctor)
    this.goNextStep()
  }

  private handleBackButtonClick() {
    const { navigation } = this.props
    if (this.isFirstStep()) {
      navigation.goPrevious()
    } else {
      this.setState({ currentStep: this.previousStep() })
    }
  }

  private handleNextButtonClick() {
    this.createConsultationAndNext()
  }

  private createConsultationAndNext() {
    const {
      createConsultationFromProfile,
      navigation,
    } = this.props

    createConsultationFromProfile(() => navigation.goNext())
  }

  render() {
    const { loading, collection, doctor, slot, availableChannels } = this.props
    const showLoader = loading || isEmpty(collection)
    return showLoader ?
      null
      : (
        <>
          <div className="step-back" onClick={() => this.handleBackButtonClick()}>
            <SvgIcon classname="step-back-icon" icon={getIcon('shapes.back')} />
            <div>Précédent</div>
          </div>
          <DoctorSelection
            collection={collection}
            availableChannels={availableChannels}
            confirm={() => this.handleNextButtonClick()}
            loading={loading}
            slot={slot}
            doctor={doctor}
            lastConsultSpeciality=""
            handleAcceptDoctor={() => this.handleNextButtonClick()}
            handleChangeDoctor={() => this.goNextStep()}
            handleSelectConsult={(consultation: string) => this.handleSelectConsult(consultation)}
            handleSelectMedium={(medium: string) => this.handleSelectMedium(medium)}
            handleSelectTimeSlot={(date: Date) => this.handleSelectTimeSlot(date)}
            handleBackButtonClick={() => this.handleBackButtonClick()}
            currentStep={this.state.currentStep.id}
            medium={this.state.medium}
            consultation={this.state.consultation}
          />
        </>
      )
  }
}

export default withNavigation(BCBooking)

import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { flatten, map } from 'lodash'
import ProgramViewer from '../../ProgramViewer'
import { SvgIcon, Button } from 'components'
import camilleAndGilbert from 'assets/images/camille-gilbert-couch.png'
import flower from 'assets/icons/mia-flower.svg'
import target from 'assets/icons/target.svg'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel, AccordionItemState,
} from 'react-accessible-accordion'

import { getMiaProgramPresentation } from '../data'
import T from 'types'
import '../styles.sass'
import MinusIcon from 'assets/icons/minus-icon.svg'
import PlusIcon from 'assets/icons/plus-icon.svg'

export interface ProgramPresentationProps {
  academy: T.AcademyStore
  program: T.Program
  isWaitingForPayment: boolean
  isEnrollmentPreview: boolean
  programForPreview: any
  onStart: () => void
}

class MiaProgramPresentation extends Component<ProgramPresentationProps> {
  private subtitle() {
    const { program } = this.props
    return (
      <div className="mx-4 text-center text-white">
        {I18n.t(`academy.${program.slug}.presentation.whyIsItEfficient.subtitle`)}
      </div>
    )
  }

  private whyIsItEfficientDiv() {
    const { program, onStart, isWaitingForPayment } = this.props
    const presentation: T.MiaProgramPresentation = getMiaProgramPresentation(program.slug)
    let mainProgramBackground = 'program-background-3'
    let fontColor = 'text-white'
    let bulletWrapper = 'program-why-efficient-wrapper'
    let bulletItem = 'program-why-efficient-card'

    return (
      <div className={cx('program-section', mainProgramBackground)}>
        <h2 className={cx('program-section-title', fontColor)}>
          {I18n.t(`academy.${program.slug}.presentation.whyIsItEfficient.title`)}
        </h2>
        {this.subtitle()}
        <div className="program-section-content">
          <div className={cx('text-center', bulletWrapper)}>
            {map(presentation.whyIsItEfficient, (bullet, index) => (
              <div className={cx(bulletItem)} key={`program_arguments_${index}`}>
                <div>
                  <img src={bullet.icon} className="program-efficiency-icon" />
                </div>
                <h5>{bullet.title}</h5>
                <p className="program-efficiency-label">{bullet.label}</p>
              </div>
            ))}
          </div>
        </div>
        <Button classname="d-block mt-4 mx-auto" onClick={() => onStart()}>
          {isWaitingForPayment ? `Continuer le programme` : I18n.t(`academy.start`)}
        </Button>
      </div>
    )
  }

  private creatorPresentation() {
    const { program } = this.props;

    return (
      <div className="profile mia">
        {map(this.creators(), creator => (
          <>
            <div className="doctor-informations">
              <h5 className="doctor-title">{creator.name}</h5>
            </div>
            <div className="doctor-description">
              {creator.subtitle}
            </div>
          </>
        ))}
      </div>
    )
  }

  private testimonySection() {
    const { onStart, isWaitingForPayment } = this.props
    const testimonyTitle = 'ELLES TÉMOIGNENT'
    const componentWrapper = 'mia d-flex flex-column w-100 testimony-section'
    const testimonyCard = (
      <>
        {map(this.testimonies(), (testimony, index: number) => (
          <>
            <div className="testimony-book">
              <div className="testimony-svg-div">
                {index === 1 ? <SvgIcon icon={target} classname="testimony-desktop-target testimony-desktop-svg" />
                  : <SvgIcon icon={flower} classname="testimony-desktop-flower testimony-desktop-svg" />}
              </div>
              <div className="left-page">
                <span>
                  {testimony.comment.firstPart}
                </span>
              </div>
              <div className="right-page">
                <span>
                  {testimony.comment.secondPart}
                </span>
                <span className="name-age">
                  {testimony.author}
                </span>
              </div>
              <div className="border-div" />
              <div className="border-div" />
              <div className="border-div" />
            </div>
            <div className="responsive-testimony-book">
              <div>
                {testimony.comment.firstPart}, {testimony.comment.secondPart}
              </div>
              <div className="responsive-testimony-author">
                {testimony.author}
              </div>
            </div>
          </>
        ))}
      </>
    )
    return (
      <div className={cx('program-section program-background-2', componentWrapper)}>
        <h2 className="program-section-title">{testimonyTitle}</h2>
        <div className="program-section-content">
          <div className="d-flex flex-column align-items-start w-100 testimony-book-div">
            {testimonyCard}
          </div>
        </div>
      </div>
    )
  }

  private faqSection() {
    const { program, onStart, isWaitingForPayment } = this.props
    const presentation: T.MiaProgramPresentation = getMiaProgramPresentation(program.slug)
    const flattenFAQItems = flatten(map(presentation.faq, faqEl => faqEl.items))
    return (
      <div className="program-section">
        <Accordion
          className="text-left accordionClass"
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          <div className={cx('program-section')}>
            <h2 className="program-section-title">FAQ</h2>
            <div className="program-section-content px-lg-5 px-2">
              {map(flattenFAQItems, (item, index) => (
                <AccordionItem uuid={`${index}`} key={index} className="accordion-item">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="d-flex flex-row align-items-center justify-content-start">
                        <AccordionItemState>
                          {({ expanded }) => (expanded === true
                            ? <div className="accordion-item-div"><SvgIcon icon={MinusIcon} classname="accordion-button-icon" /></div>
                            : <div className="accordion-item-div"><SvgIcon icon={PlusIcon} classname="accordion-button-icon" /></div>)}
                        </AccordionItemState>
                        <div className="accordion-button-label text-left">
                          {item.text}
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="accordion-body">
                    {item.details && (<span dangerouslySetInnerHTML={{ __html: item.details }} />)}
                  </AccordionItemPanel>
                </AccordionItem>
              )
              )}
            </div>
            <div className="d-flex flex-column text-center pb-5">
              <Button classname="d-block mt-4 mx-auto" onClick={() => onStart()}>
                {isWaitingForPayment ? "Continuer le programme" : I18n.t(`academy.start`)}
              </Button>
            </div>
          </div>
        </Accordion>
      </div>
    )
  }
  public render() {
    const { academy, program, onStart, isWaitingForPayment, isEnrollmentPreview, programForPreview } = this.props
    const presentation: T.MiaProgramPresentation = getMiaProgramPresentation(program.slug)
    const creatorImage = camilleAndGilbert

    return (
      <div className="program-presentation">
        <div className="program-section">
          {isEnrollmentPreview ? (
            programForPreview
          ) : (
            <ProgramViewer
              program={program}
              academy={academy}
              evaluateLesson={() => { }}
              isWaitingForPayment={isWaitingForPayment}
              startLesson={() => { }}
              handlePurchaseProgram={() => onStart()}
            />
          )}
          <div className="row text-center mt-4 px-4">
            {map(presentation.arguments, (bullet, index) => (
              <div className="col-xs-12 col-md mt-4" key={`program_arguments_${index}`}>
                <img src={bullet.icon} className="d-block img-fluid m-auto" />
                <div className="mt-2">{bullet.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="program-section program-background-2">
          <h2 className="program-section-title">
            {I18n.t(`academy.${program.slug}.presentation.howItWorks.title`)}
          </h2>
          <div className="program-section-content">
            <div className="row text-left">
              {map(presentation.howItWorks, (bullet, index) => (
                <div className="col-xs-12 col-lg program-how-it-works-bullet-container" key={`program_arguments_${index}`}>
                  <div className="program-how-it-works-bullet-content">
                    <img
                      src={bullet.icon}
                      className="d-block img-fluid mx-auto program-how-it-works-icon"
                    />
                    <div className="program-how-it-works-bullet-content-body">
                      <div className="program-how-it-works-number">{index + 1}</div>
                      <div className="">
                        <h5>{bullet.title}</h5>
                        <p>{bullet.label}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4 d-flex flex-column text-center">
              <Button classname="d-block mt-4 mx-auto" onClick={() => onStart()}>
                {isWaitingForPayment ? `Continuer le programme` : I18n.t(`academy.start`)}
              </Button>
            </div>
          </div>
        </div>
        <div className="program-section">
          <h2 className="program-section-title mb-4">Le programme en détails</h2>
          <div className="program-section-content">
            {map(program.lessons, (lesson, index) => (
              <div className="mb-1 pb-1">
                <div key={`lesson_detail${index}`} className="row">
                  <div className="d-none d-lg-flex col-lg-3 col-xl-3 justify-content-center">
                    <img src={lesson.videoThumbnail} className="program-section-image" />
                  </div>
                  <div className="col-12 col-lg-9 col-xl-9">
                    <h4>
                      {lesson.number > 0 ? `${lesson.number}.` : ''} {lesson.title}
                    </h4>
                    <p>{lesson.description}</p>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
        <div className="program-section">
          <h2 className="program-section-title">
            {I18n.t(`academy.${program.slug}.presentation.creator.title`)}
          </h2>
          <div className="program-section-content">
            <div className="coach-details-content">
              <div className="featured-doctor">
                <img
                  src={creatorImage}
                  className="featured-image"
                  alt="Gibert Bou Jaoudé Canapé"
                />
                <div className="mt-3" />
                <p className="description">
                  "
                  {I18n.t(`academy.${program.slug}.presentation.creator.fullCaption`)}
                  "
                </p>
              </div>
              {this.creatorPresentation()}
            </div>
          </div>
        </div>
        {this.testimonySection()}
        {this.faqSection()}
      </div>
    )
  }

  // TODO: I18N improvments
  // react-redux-i18n does not support arrays (and is not maintained anymore)
  // i18next does https://www.i18next.com/translation-function/objects-and-arrays :eyes:
  private testimonies = (): any => {
    const { program } = this.props
    const keys = ['testimony1', 'testimony2']
    return map(keys, key => ({
      comment: I18n.t(`academy.${program.slug}.presentation.testimonies.${key}.comment`),
      author: I18n.t(`academy.${program.slug}.presentation.testimonies.${key}.author`),
    }))
  }
  private creators = () => {
    const { program } = this.props
    const keys = ['identity1', 'identity2']
    return map(keys, key => ({
      name: I18n.t(`academy.${program.slug}.presentation.creator.identity.${key}.name`),
      subtitle: I18n.t(`academy.${program.slug}.presentation.creator.identity.${key}.subtitle`),
    }))
  }
}

export default MiaProgramPresentation

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isNil } from 'lodash'
import moment from 'constants/moment'
import { ApiService } from 'services'
import * as SC from 'utils/models/schemas/constants'

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const mangopayEmailRegexp = /([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

const phoneValidation = (value: any) => {
  const regexp = RegExp(phoneRegExp)
  return !isNil(value) && regexp.test(value) && isValidPhoneNumber(value)
}

const schema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(1)
    .required(I18n.t(`onboarding.personnalData.error.firstNameError`)),
  lastName: yup
    .string()
    .trim()
    .min(1)
    .required(I18n.t(`onboarding.personnalData.error.lastNameError`)),
  email: yup
    .string()
    .trim()
    .email(I18n.t(`onboarding.personnalData.error.emailError`))
    .required('Ce champ est obligatoire'),
  phoneNumber: yup
    .string()
    .trim()
    .test('valid-phone', 'Numéro de téléphone invalide', (value: any) => phoneValidation(value))
    .required('Ce champ est obligatoire'),
  birthDate: yup.string().test({
    name: 'birthDate-validation',
    test: function(value: any) {
      const birthDate = moment(value, SC.DATE_INPUT_FORMAT)
      if (!birthDate.isValid()) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.mandatory`),
        })
      }
      const age = moment().diff(birthDate, 'years')
      const isLegal = age >= 18
      const isAVampire = age >= 200
      if (!isLegal) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.underage`),
        })
      }
      if (isAVampire) {
        // @ts-ignore
        return this.createError({
          path: 'birthDate',
          message: I18n.t(`onboarding.personnalData.error.birthDateError.overage`),
        })
      }
      return true
    },
  }),
})

export default schema

import { connect } from 'react-redux'
import { Model } from 'survey-core'
import { ProgramViewer } from 'components'
import T from 'types'
import { enrollmentForProgram } from 'utils/data/academy'
import {
  startLesson,
  evaluateLesson,
  getEnrollments
} from 'actions'

const mapStateToProps = ({
  academy,
  storefront,
}: T.Store, ownProps: { program: T.Program }) => {

  const { program } = ownProps
  const enrollment = enrollmentForProgram(academy, program)

  return {
    academy,
    program,
    enrollment,
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  startLesson: (attendance: T.Attendance) => dispatch(startLesson(attendance)),
  evaluateLesson: (attendance: T.Attendance, survey: Model) => dispatch(evaluateLesson(attendance, survey)),
  getEnrollments: () => dispatch(getEnrollments()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramViewer)

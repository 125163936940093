import React, { Component } from 'react'
import { isNil } from 'lodash'
import { SecureChallenge } from 'components'
import T from 'types'

interface IProps {
  secureChallenge?: T.SecureChallenge,
  endSecureChallenge: (success: boolean) => void
}

class SecureChallengeWrapper extends Component<IProps> {

  public render() {
    const { secureChallenge, endSecureChallenge } = this.props
    if (isNil(secureChallenge)) {
      return null;
    }
    return (
      <SecureChallenge
        secureChallenge={secureChallenge}
        endSecureChallenge={endSecureChallenge}
      />
    )
  }
}

export default SecureChallengeWrapper

import React from 'react'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import { StepHead } from 'page/common/Step'
import T from 'types'
import { WizardStepProps } from 'helpers/hooks/useVisioConfiguration'

const ShowPromptPermissionsButton = (props: WizardStepProps) => (
  <div>
    <StepHead title="Paramétrage Caméra/Micro" />
    <p>Afin que votre médecin puisse vous voir, vous devez autoriser l'accès à votre caméra et votre micro</p>
    <SvgIcon icon={getIcon('setup.camera')} classname="central-icon"/>
    <Button
      variant="btn-primary"
      classname="mx-auto"
      onClick={props.config.promptForVisioPermissions}>
        Autoriser caméra et microphone
    </Button>
  </div>
)

export default ShowPromptPermissionsButton
import React, { Component, Fragment } from 'react'
import ReactJWPlayer from 'react-jw-player'
import { filter, isUndefined, map, last, find, get, includes, first } from 'lodash'
import build from 'redux-object'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import T from 'types'
import { MainContainer, ProgramViewer } from 'containers'
import {
  AgendaSommeilBulletPoints,
  AgendaSommeilCta,
  Button,
  Block,
  Loader,
  ProgramPresentation,
  MiaProgramPresentation,
  SvgIcon,
  SleepCalendarSettings
} from 'components'
import QuickPayment from '../../QuickPayment'
import { enrollmentForProgram } from 'utils/data/academy'
import { buildPatientAttributes } from 'utils/helpers'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import './styles.sass'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import AcademyFunnelForPatients from 'page/Pages/Academy/AcademyFunnelForPatients'
import { organizeProgramVariants } from 'utils/models/variants'
import { money } from 'utils/models/money'

interface IProps {
  slug: string
  academy: T.AcademyStore
  storefront: T.Storefront
  auth: T.Auth
  getPrograms: () => void
  getSleepCalendar: () => void
  getEnrollments: () => void
  updateSleepCalendar: (params: object) => void
  getPaymentMethods: () => void
  enrollProgram: (slug: string) => void
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  startRenewEnrollment: (enrollmentId: number) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  showNotification: (title: string, message: string) => void
  setExtraPersonalData: (data: object) => void
  createAddress: (
    data: any,
    enrollmentId: string | undefined,
    onSuccess: () => void,
  ) => void,
}

interface IState {
  playingVideo: T.Video | null
  chosenProgram: T.Program | null
  renewingProgram: boolean
  selectedVariantId?: number
  addressSent?: boolean
  showModal: boolean
}

class ProgramPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      playingVideo: null,
      chosenProgram: null,
      renewingProgram: false,
      showModal: false,
    }
  }

  public componentDidMount() {
    const { getPrograms, getEnrollments, getPaymentMethods, getSleepCalendar } = this.props
    getPrograms()
    getEnrollments()
    getPaymentMethods()
    getSleepCalendar()
  }

  public handleSelectVideo = (video: T.Video) => {
    this.setState({ playingVideo: video })
  }

  public handleClickProgram = (program: T.Program) => {
    const { enrollProgram } = this.props
    enrollProgram(program.slug)

    this.setState({
      chosenProgram: program,
    })
  }

  public handleAdressSent = (bool: boolean) => {
    this.setState({ addressSent: bool })
  }

  public handleClickBulletPoints = (bool: boolean) => {
    this.setState({ showModal: bool })
  }

  public renderProgramViewer(program: T.Program, isWaitingForPayment: boolean) {
    return <ProgramViewer program={program} isWaitingForPayment={isWaitingForPayment} handlePurchaseProgram={() => this.handleClickProgram(program)} />
  }

  public renderSleepCalendarBlock(program: T.Program, isEnrolled: boolean) {
    const { academy, updateSleepCalendar } = this.props
    const { showModal } = this.state
    const isSommeil = (program.slug == 'sommeil-program')
    const sleepCalendar = first(build(academy, 'sleepCalendar')) as T.SleepCalendar
    const modalTitle = showModal ? "Changement d’heure de lever" : "Bienvenue dans le programme sommeil"

    if (!(isSommeil && isEnrolled) || !sleepCalendar)
      return null;

    return (
      <div className="program-sommeil-sleep-calendar-block">
        <Modal
          show={!sleepCalendar.wakeupTimeInTimezone || showModal}
          size="lg"
          centered
        >
          <Modal.Body>
            <SleepCalendarSettings
              sleepCalendar={sleepCalendar}
              updateSleepCalendar={updateSleepCalendar}
              title={modalTitle}
              handleClick={() => this.handleClickBulletPoints(false)}
            />
          </Modal.Body>
        </Modal>
        <AgendaSommeilCta sleepCalendar={sleepCalendar} />
        <AgendaSommeilBulletPoints
          handleClickBulletPoints={() => this.handleClickBulletPoints(true)}
          sleepCalendar={sleepCalendar}
        />
      </div>
    )
  }

  public render() {
    const {
      slug,
      academy,
      auth,
      storefront,
      applyCoupon,
      selectProgramVariant,
      getPaymentMethods,
      purchase,
    } = this.props

    const { chosenProgram, renewingProgram } = this.state
    const programs = build(academy, 'program')
    const program: T.Program = find(programs, program => program.slug === slug)
    if (isUndefined(program)) {
      return <Loader />
    }
    const enrollment = enrollmentForProgram(academy, program)
    const isWaitingForPayment = includes(['waiting_for_payment', 'preview'], get(enrollment, 'state'))
    const isEnrolled = !isUndefined(enrollment) && !includes(['waiting_for_payment', 'pending'], enrollment.state)
    const isEnrollmentPreview = !isUndefined(enrollment) && enrollment.state === 'preview'
    const isEnrollmentPaused = !isUndefined(enrollment) && enrollment.state === 'paused'
    const isEnrollmentInterrupted = !isUndefined(enrollment) && enrollment.state === 'interrupted'
    const cartId = !isUndefined(enrollment) && enrollment.spreeOrder.id
    const order = build(academy, 'cart', cartId) as T.OrderV2

    return (
      <MainContainer unlimitedWidth noHorizontalPadding noVerticalPadding>
        <div className="page personnal-space-program-manager p-3 p-md-4">
          <div className="personnal-space-program-manager-title-group">
            <h1 className="h3">{I18n.t(`academy.${slug}.title`)}</h1>
            {IS_CHARLES_PLATFORM && <div className="h4 mb-0">{I18n.t(`academy.${slug}.subtitle`)}</div>}
          </div>
          {chosenProgram ? (
            <AcademyFunnelForPatients
              {...this.props}
              storefront={storefront}
              order={order}
              program={program}
              academy={academy}
              patientAttributes={buildPatientAttributes(auth.profile)}
              applyCoupon={applyCoupon}
              rootVariantId={this.state.selectedVariantId}
              selectProgramVariant={selectProgramVariant}
              purchase={purchase}
              goNext={this.handlePaymentSuccess}
              getPaymentMethods={getPaymentMethods}
            />
          ) : (
            <div>
              {(!isEnrolled || isWaitingForPayment) ? (
                <div>
                  {IS_CHARLES_PLATFORM ? (
                    <ProgramPresentation
                      academy={academy}
                      program={program}
                      isWaitingForPayment={isWaitingForPayment}
                      isEnrollmentPreview={isEnrollmentPreview}
                      programForPreview={this.renderProgramViewer(program, isWaitingForPayment)}
                      onStart={() => this.handleClickProgram(program)}
                    />
                  ) : (
                    <MiaProgramPresentation
                      academy={academy}
                      program={program}
                      isWaitingForPayment={isWaitingForPayment}
                      isEnrollmentPreview={isEnrollmentPreview}
                      programForPreview={this.renderProgramViewer(program, isWaitingForPayment)}
                      onStart={() => this.handleClickProgram(program)}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {isEnrollmentPaused && (
                    <div className="program-enrollment-paused-container alert alert-danger">
                      <div className="h4 program-enrollment-paused">Le paiment a échoué.</div>
                      <div>{I18n.t(`academy.${slug}.payment.failedRenewal`)}</div>
                      <Button
                        classname="blue mb-0"
                        onClick={() => {
                          this.setState({ renewingProgram: true })
                        }}
                      >
                        Mettre à jour
                      </Button>
                    </div>
                  )}
                  {isEnrollmentInterrupted && (
                    <div className="program-enrollment-paused-container alert alert-info">
                      <div className="h4 program-enrollment-paused">Programme interrompu.</div>
                      <div>{I18n.t(`academy.${slug}.payment.interrupted`)}</div>
                      <Button
                        classname="blue mb-0"
                        onClick={() => {
                          this.setState({ renewingProgram: true })
                        }}
                      >
                        Continuer le programme
                      </Button>
                    </div>
                  )}
                  {renewingProgram && (
                    <div className="">
                      <QuickPayment enrollment={enrollment} {...this.props} />
                    </div>
                  )}
                  <ProgramViewer program={program} isWaitingForPayment={isWaitingForPayment} />
                </div>
              )}
            </div>
          )}
          {!chosenProgram ? this.renderSleepCalendarBlock(program, isEnrolled) : false }
        </div>
      </MainContainer>
    )
  }

  private handlePaymentSuccess = () => {
    const { showNotification } = this.props
    showNotification('success', 'Le paiement a bien été effectué. La page va se recharger')
    setTimeout(() => {
      sessionStorage.clear()
      document.location.reload()
    }, 2000)
  }
}

export default ProgramPage

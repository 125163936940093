import React, { FC } from 'react'
import T from 'types'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'

import { withNavigation } from 'components/Workflow'

const UCConfirmationOtherPrescription: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext } } = props

  return (
    <UCStep>
      <span>[CONFIRMATION] - ceci est le test Autre Prescription</span>
      <button onClick={goPrevious}>previous</button>
      <button onClick={goNext}>next</button>
    </UCStep>
  )
}

export default withNavigation(UCConfirmationOtherPrescription)

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { isUndefined } from 'lodash'
import SvgIcon from '../SvgIcon'
import info from 'assets/icons/picto-info.svg'
import success from 'assets/icons/picto-success.svg'
import doctorChat from 'assets/icons/picto-doctor-chat.svg'
import T from 'types'

import './styles.sass'

const Pictos = { info, success, doctorChat }

interface IProps {
  title: string,
  cta: T.Cta,
  icon: keyof typeof Pictos,
}

class ChatItem extends Component<IProps> {
  private renderCTA() {
    const { cta } = this.props

    if (isUndefined(cta)) {
      return null
    }

    const { path, onClick, title } = cta

    if (!isUndefined(path) && !isUndefined(onClick)) {
      throw `Error on prop cta: path (${path}) and onClick (${onClick}) cannot be both provided`
    }

    return isUndefined(path) ? (
      <a onClick={onClick} className="chat-cta" >{title}</a>
    ) : (
        <NavLink to={`/${path}`} className="chat-link" exact={true} >{title}</NavLink>
      )
  }

  public render() {
    const { title, icon } = this.props
    return (
      <div className="chat-form-block">
        <SvgIcon icon={Pictos[icon]} classname="chat-picto" />
        <p className="chat-title">{title}</p>
        {this.renderCTA()}
      </div>
    )
  }
}

export default ChatItem

import React, { Component, Fragment, RefObject } from 'react'
import classnames from 'classnames'
import { I18n } from 'react-redux-i18n'
import build from 'redux-object'
import { isEmpty, get, isNil, isUndefined, map, chunk } from 'lodash'
import { Block, Table, Button } from 'components'
import T from 'types'
import { money } from 'utils/models/money'

interface IProps {
  storefront: T.Storefront
  cancelSubscription: (id: number) => void
  getSubscriptions: () => void,
}

class SubscriptionManager extends Component<IProps> {
  public handleInterrupt = (subscriptionId: number) => {
    const { cancelSubscription } = this.props

    if(confirm("Êtes-vous sûr de vouloir arrêter votre abonnement ?")) {
      cancelSubscription(subscriptionId)
    }

  }

  public componentDidMount = () => {
    const { getSubscriptions } = this.props
    getSubscriptions()
  }

  public render() {
    const { storefront } = this.props

    const subscriptions = build(storefront, 'subscription')
    if(isEmpty(subscriptions)){
      return null
    }
    const displayColumns = ['state', 'variant', 'frequency', 'nextOccurrenceAt', 'price', 'cta']
    const collection = map(subscriptions, subscription => (
      [
        ['state', I18n.t(`subscriptions.state.${subscription.state}`)],
        ['variant', subscription.variant.name],
        ['frequency', I18n.t(`subscriptions.frequency.${subscription.subscriptionFrequency.title}`)],
        ['nextOccurrenceAt', subscription.nextOccurrenceAt],
        ['price', money(subscription.price).toFormat()],
        ['cta', subscription.isCancelable ? subscription.id : undefined],
      ]
    ))

    return (
      <Block title={'Abonnements en cours'} classname={'container-m-800 mt-5'}>
        <Table
          displayColumns={displayColumns}
          collection={collection}
          cta={{
            title: "Arrêter",
            renderer: (id: number) => {
              if(isUndefined(id)) {
                return null
              }
              else {
                return (<a className="text-danger" onClick={() => this.handleInterrupt(id)}>Arrêter</a>)
              }
            }
          }}
        />
        </Block>
    )
  }
}

export default SubscriptionManager

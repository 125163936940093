import React, { Component, Fragment } from 'react'
import { showNotification } from 'actions/ux'
import store from '../../store'
import Button from '../Button'
import './styles.sass'
import greenTick from 'assets/icons/visio/green_tick.png'
import trackers from '../../utils/trackers';

interface IState {
  audio: boolean
  video: boolean
  media: any
  error: string
}

export default class UserMedia extends Component<any, IState> {
  constructor(props: any) {
    super(props)

    this.state = {
      audio: false,
      video: false,
      media: undefined,
      error: '',
    }
    this.toggleUserMedia = this.toggleUserMedia.bind(this)
  }
  getUserMedia() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        this.setState({ media: stream })
        store.dispatch(
          showNotification('success', 'Votre caméra et votre micro ont bien été activés'),
        )
        trackers.trackCameraConfigurationSucceeded()
        setTimeout(() => this.stopUserMedia(), 1500)
      })
      .catch(err => {
        console.log(err)
        let errorMessage = 'Erreur lors de la configuration de la caméra et du micro'
        if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
          errorMessage = `Votre caméra et/ou votre micro n'ont pas bien été détectés`
        } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
          errorMessage = 'Votre caméra ou votre micro est actuellement utilisé par une autre application'
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError' ||
          err.name === 'PermissionDismissedError'
        ) {
          errorMessage = "Vous devez autoriser l'accès à votre caméra et votre micro"
        }

        store.dispatch(showNotification('error', errorMessage))
        this.setState({ error: errorMessage })
        trackers.trackCameraConfigurationFailed(err.name, errorMessage)
      })
  }

  stopUserMedia() {
    this.state.media.getTracks().forEach((track: any) => {
      track.stop()
    })
  }

  toggleUserMedia() {
    if (this.state.media !== undefined) {
      this.stopUserMedia()
    } else {
      this.getUserMedia()
    }
  }

  render() {
    return (
      <div className="user-media-container">
        {this.state.error !== '' ? (
          <div>
            <p className="text-danger">{this.state.error}</p>
          </div>
        ) : this.state.media === undefined ? (
          <Button variant="btn-outline-primary btn-block" onClick={this.toggleUserMedia}>
            Configurer la caméra
          </Button>
        ) : (
          <div>
            <p>Votre caméra et votre micro sont bien activés</p>
          </div>
        )}
      </div>
    )
  }
}

import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { map } from 'lodash'
import ProgramViewer from '../ProgramViewer'
import { SvgIcon, Button } from 'components'
import gilbert from 'assets/images/gilbert-on-couch-classic.jpg'
import lombart from 'assets/images/Dr-Remy-Lombard.jpg'

import { getProgramPresentation } from 'components/ProgramPresentation/data'
import T from 'types'
import './styles.sass'
import { SectionArguments } from 'page/common/ConsultationArguments'
import { Container, Row, Col } from 'react-bootstrap'
import { getIcon } from 'assets/icons'
import Carousel, { CarouselSlide } from 'components/Carousel'

export interface ProgramPresentationProps {
  academy: T.AcademyStore
  program: T.Program
  isWaitingForPayment: boolean
  isEnrollmentPreview: boolean
  programForPreview: any
  onStart: () => void
}

class ProgramPresentation extends Component<ProgramPresentationProps> {

  private whyIsItEfficientDiv() {
    const { program, onStart, isWaitingForPayment } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)

    return (
      <div className="bg-c-beige efficient-program">
        <h2 className="text-center efficient-program-h2">{I18n.t(`academy.${program.slug}.presentation.whyIsItEfficient.title`)}</h2>
        <div className="text-center efficient-program-description">{I18n.t(`academy.${program.slug}.presentation.whyIsItEfficient.content`)}</div>
        <Container className="efficient-program-container">
          <Row className="justify-content-center">
            {map(presentation.whyIsItEfficient, (bullet, index) => (
              <Col xs={12} md={8} xl={4} className="efficient-program-col">
                <img src={bullet.icon} className="efficient-program-col-img" />
                <div className="h4 efficient-program-col-title">{bullet.title}</div>
                <div className="efficient-program-col-text">{bullet.label}</div>
              </Col>
            ))}
          </Row>
          <div className="d-flex flex-column text-center efficient-program-button">
            <Button classname="d-block mx-auto" onClick={() => onStart()}>
              {isWaitingForPayment ? `Acheter le programme` : (presentation.ctaLabel || I18n.t(`academy.start`))}
            </Button>
          </div>
          <div className="border-bottom border-dark efficient-program-border" />
        </Container>
      </div>
    )
  }

  private creatorPresentation() {
    const { program } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    let creatorImage;
    if (program.slug === "sommeil-program") {
      creatorImage = lombart
    } else {
      creatorImage = gilbert
    }
    return (
      <div className="creator-program creator bg-c-beige">
        <Container>
          <h2 className="">
            {I18n.t(`academy.${program.slug}.presentation.creator.title`)}
          </h2>
          <div className="coach-details-content">
            <div className="featured-doctor">
              <img
                src={creatorImage}
                className="featured-image"
                alt="Gibert Bou Jaoudé Canapé"
              />
            </div>
            <div className="creator-program-caption">
              <h4 className="doctor-title">{I18n.t(`academy.${program.slug}.presentation.creator.name`)}</h4>
              <div className="">{I18n.t(`academy.${program.slug}.presentation.creator.fullCaption`)}</div>
            </div>
          </div>
        </Container>
      </div>
    )
  }

  private testimonySection() {
    const { onStart, isWaitingForPayment } = this.props
    const testimonyTitle = 'Nos patients témoignent'
    const testimonySubtitle = 'Et ce sont eux qui en parlent le mieux'
    const componentWrapper = 'testimony-program-charles'

    return (
      <div className={cx('testimony-program bg-c-blue', componentWrapper)}>
        <div className="testimony-program-text">
          <h2 className="text-center testimony-program-title text-white">{testimonyTitle}</h2>
          <p className="text-center testimony-program-subtitle text-white">{testimonySubtitle}</p>
        </div>
        <Container className="testimony-program-container">
          <div className="block-content mb-0 bg-white p-0 overflow-hidden">
            <Carousel
              className="testimony-program-carousel"
              stretchItems={true}
              breakpoints={{}}
              slidesPerView={1}
            >
              {map(this.testimonies(), testimony => (
                <CarouselSlide className="bg-white testimony-program-carousel-slide">
                  <div className="testimony-program-carousel-slide-div">
                    <span className="">
                      {testimony.comment}
                    </span>
                    <div className="font-weight-bold testimony-program-author">
                      {testimony.author}
                    </div>
                  </div>
                </CarouselSlide>
              ))}
            </Carousel>
          </div>
        </Container>
      </div>
    )
  }

  private aboutProgram() {
    const { program } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    return (
      <div className="about-program bg-c-blue">
        <Container className="about-program-container">
          <h2 className="about-program-h2 text-white">{I18n.t(`academy.${program.slug}.about.title`)}</h2>
          <Row>
            <Col xs={12} xl={6}>
              <SectionArguments
                sections={presentation.about}
                variant="white"
                itemClass="h4"
              />
            </Col>
            <Col xs={12} xl={6} className="d-flex flex-column about-program-second-col">
              <SvgIcon icon={getIcon('clinic.blackLines')} classname="about-program-block-image" />
              <div className="block-content bg-white text-black about-program-block">
                <div className="about-program-block-content">
                  {I18n.t(`academy.${program.slug}.about.doctorQuote.comment`)}
                </div>
                <div className="about-program-block-bottom">
                  {I18n.t(`academy.${program.slug}.about.doctorQuote.author`)}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  private howItWorks() {
    const { program, onStart, isWaitingForPayment } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    return (
      <div className="bg-c-blue hiw-program">
        <h2 className="text-white text-center">{I18n.t(`academy.${program.slug}.presentation.howItWorks.title`)}</h2>
        <SvgIcon classname="hiw-program-black-underline" icon={getIcon('clinic.blackUnderline')} />
        {/*Maybe a description*/}
        <Container className="hiw-program-container">
          <Row className="justify-content-center hiw-program-row">
            {map(presentation.howItWorks, (el, index) => (
              <Col xs={12} md={8} xl={4} className="text-white hiw-program-col">
                <div className="hiw-program-col-img">
                  <img src={el.icon} />
                </div>
                <div className="h4 text-white hiw-program-col-title">{index + 1}. {el.title}</div>
                <div>{el.label}</div>
              </Col>
            ))}
          </Row>
          <div className="d-flex flex-column text-center hiw-start-button">
            <Button classname="d-block mx-auto" onClick={() => onStart()}>
              {isWaitingForPayment ? `Continuer le programme` : (presentation.ctaLabel || I18n.t(`academy.start`))}
            </Button>
          </div>
        </Container>
      </div>
    )
  }

  private pressBanner() {
    const { program } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    return (
      <div className="bg-c-beige program-press">
        <Container>
          <div className="h4 text-center">{I18n.t(`academy.digital-therapy.press.talkAboutUs`)}</div>
          <div className="d-flex program-press-img-wrapper">
            {map(presentation.press, (el, index) => (
              <>
                <SvgIcon icon={el.icon} classname={cx('program-press-img', { 'd-none d-xl-inline': index > 2 })} />
              </>
            ))}
          </div>
          <div className="program-press-find-out-more">
            <div>{I18n.t(`academy.digital-therapy.press.seeMore`)}</div>
            <SvgIcon icon={getIcon('shapes.back')} classname="program-press-find-out-more-arrow" />
          </div>
        </Container>
      </div>
    )
  }

  public render() {
    const { academy, program, onStart, isWaitingForPayment, isEnrollmentPreview, programForPreview } = this.props
    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    return (
      <div className="program-presentation">
        <div className="program-section">
          {isEnrollmentPreview ? (
            programForPreview
          ) : (
            <ProgramViewer
              program={program}
              academy={academy}
              evaluateLesson={() => { }}
              isWaitingForPayment={isWaitingForPayment}
              startLesson={() => { }}
              handlePurchaseProgram={() => onStart()}
            />
          )}
          <Container>
            <div className="row text-center mt-4">
              {map(presentation.arguments, (bullet, index) => (
                <div className="col-xs-12 col-md mt-4" key={`program_arguments_${index}`}>
                  {/* <img src={bullet.icon} className="d-block img-fluid m-auto" alt="" /> */}
                  <SvgIcon classname="d-block img-fluid m-auto" icon={bullet.icon} />
                  <div className="mt-2">{bullet.label}</div>
                </div>
              ))}
            </div>
          </Container>
        </div>
        {this.aboutProgram()}
        {this.howItWorks()}
        {this.pressBanner()}

        <div className="bg-c-beige detail-program">
          <h2 className="text-center">
          {I18n.t(`academy.digital-therapy.programDetail`)}
          </h2>
          <div className="text-center detail-program-desc">

          </div>
          <Container className="px-0 d-xl-none">
            <Carousel
              stretchItems={true}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1080: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
              }}
              className="px-3"
            >
              {map(program.lessons, (lesson, index) => (
                <CarouselSlide key={index}>
                  <div className="detail-program-carousel-slide">
                    <img src={lesson.videoThumbnail} className={cx({ 'detail-carousel-special-image': index === 6 })} alt="Capture d'écran de gilbert" />
                    <div className="detail-program-carousel-slide-title">{lesson.title}</div>
                    <div className="detail-program-carousel-slide-desc">{lesson.description}</div>
                  </div>
                </CarouselSlide>
              ))}
            </Carousel>
          </Container>
          <Container className="bg-c-beige d-none d-xl-block detail-program-xl-container">
            {map(program.lessons, (lesson, index) => (
              <div className="detail-program-xl-carousel-slide">
                <img src={lesson.videoThumbnail} alt="Capture d'écran de gilbert" />
                <div>
                  <div className="detail-program-carousel-slide-title">{lesson.title}</div>
                  <div className="detail-program-carousel-slide-desc">{lesson.description}</div>
                </div>
              </div>
            ))}
            <div className="detail-program-xl-border-container" />
          </Container>
        </div>

        {this.whyIsItEfficientDiv()}
        {this.creatorPresentation()}
        {this.testimonySection()}
        <div className="programe-faq-1 bg-c-beige">
          <Container className="width: 100%">
            <h2 className="text-center text-xl-left program-faq-title">{I18n.t(`academy.${program.slug}.faq.title`)}</h2>
            <div className="text-center text-xl-left program-faq-text">{I18n.t(`academy.${program.slug}.faq.content`)}</div>
            <Row className="program-faq-content">
              <Col xs={12} xl={8}>
                <SectionArguments
                  sections={presentation.faq}
                  itemClass="h4"
                />
              </Col>
              <Col xs={12} xl={4}>
                <div className="block-content bg-white text-black program-faq-block mx-auto">
                  <div className="program-faq-block-title ">{I18n.t(`academy.${program.slug}.faq.contact.title`)}</div>
                  <div className="program-faq-block-bullet">
                    <SvgIcon classname="program-faq-block-svg" icon={getIcon('clinic.book')} />
                    <a className="">{I18n.t(`academy.${program.slug}.faq.contact.completeFaq`)}</a>
                  </div>
                  <div className="program-faq-block-bullet">
                    <SvgIcon classname="program-faq-block-svg" icon={getIcon('clinic.greenSquarePhone')} />
                    <a href="tel:+33186651733" className="">{I18n.t(`academy.charlesTel`)}</a>
                  </div>
                  <div className="program-faq-block-bullet">
                    <SvgIcon classname="program-faq-block-svg" icon={getIcon('clinic.mail')} />
                    <a href="mailto:contact@charles.co" className="">{I18n.t(`academy.charlesEmail`)}</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  // TODO: I18N improvments
  // react-redux-i18n does not support arrays (and is not maintained anymore)
  // i18next does https://www.i18next.com/translation-function/objects-and-arrays :eyes:
  private testimonies = (): any => {
    const { program } = this.props
    const keys = ['testimony1', 'testimony2', 'testimony3']
    return map(keys, key => ({
      comment: I18n.t(`academy.${program.slug}.presentation.testimonies.${key}.comment`),
      author: I18n.t(`academy.${program.slug}.presentation.testimonies.${key}.author`),
    }))
  }
  private creators = () => {
    const { program } = this.props
    const keys = ['identity1', 'identity2']
    return map(keys, key => ({
      name: I18n.t(`academy.${program.slug}.presentation.creator.identity.${key}.name`),
      subtitle: I18n.t(`academy.${program.slug}.presentation.creator.identity.${key}.subtitle`),
    }))
  }
}

export default ProgramPresentation

import React, { Fragment } from 'react'
import { SvgIcon, Block } from 'components'
import { getIcon } from 'assets/icons'
import { LayoutStep, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Link } from 'react-router-dom'
interface IProps {
  order: any,
}

class OrderComplete extends React.Component<IProps> {

  render() {
    const { order } = this.props
    return (
      <LayoutStep>
        <LayoutMain variant="small">
          <Block title="Commande enregistrée">
            <div className="text-center order-complete-container">
              <SvgIcon icon={getIcon("clinic.largeBoxWithTick")} classname="order-complete-svg" />
              <h4>Merci pour votre commande !</h4>
              <div className="mt-3 order-complete-main-text">
                Votre commande <span className="font-weight-semi">{order.number}</span> est en cours de préparation. Vous avez choisi la livraison à domicile.
                <br />
                <br />
                Vous recevrez un e-mail dès que votre commande aura été expédiée, ainsi qu’un numéro de suivi pour suivre votre colis.
              </div>
              <div className="d-flex justify-content-center order-complete-link-wrapper">
                <Link className="btn btn-outline-primary" to="/orders">
                  Mes commandes
                </Link>
                <Link className="btn btn-primary" to="/" >
                  Accueil
                </Link>
              </div>
            </div>
          </Block>
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default OrderComplete


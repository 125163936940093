import T from 'types'

export const builtSections = (): T.ISection[] => {
  return [{
    title: 'Soyez serein, vous êtes entre de bonnes mains',
    items: [
      {
        icon: 'clinic.box',
        text: "Livraison partout en France en moins de 48h"
      },
      {
        icon: 'clinic.lock',
        text: "Ordonnance sécurisée et renouvelable chaque mois"
      },
      {
        icon: 'clinic.yellowSquarePhone',
        text: "Service patient 7j/7"
      },
      {
        icon: 'clinic.chat',
        text: "Messagerie directe avec le pharmacien"
      },
    ]
  }]
}

import React, { Component } from 'react'
import { prescriptionPdfUrl } from 'utils/helpers'
import PDF from 'react-pdf-js'
import T from 'types'
import './styles.sass'

interface IProps {
  prescription: T.Prescription
}

class PrescriptionPreviewPopup extends Component<IProps> {
  public render() {
    const { prescription } = this.props
    return (
      <PDF
        key={prescription.id}
        className="prescription-preview"
        file={prescriptionPdfUrl(prescription)}
      />
    )
  }
}

export default PrescriptionPreviewPopup


import React, { Component, Fragment } from 'react'
import { map, findIndex, isNull } from 'lodash'
import T from 'types'
import { matchPath } from 'react-router-dom'
import { withRouter, RouteComponentProps } from "react-router"
import cx from 'classnames'
import './styles.sass'

// Design heavily based on this article
// https://ishadeed.com/article/stepper-component-html-css/
// https://codepen.io/shadeed/pen/ZEyQqQX
interface SimpleStepperProps {
  steps: T.StepperStep[]
  currentStep: number
}
class SimpleStepper extends React.Component<SimpleStepperProps> {

  displayedSteps = (): T.StepperStep[] => {
    const { steps } = this.props
    return steps.filter((step: T.StepperStep) => step.hideInStepper !== true)
  }

  getNavState(currentStep: number, stepToDisplay: number) {
    const styles = ['progtrckr']
    if (stepToDisplay < currentStep) {
      styles.push('done')
    } else if (stepToDisplay === currentStep) {
      styles.push('doing')
    } else {
      styles.push('todo')
    }
    return styles.join('-')
  }
  render() {
    const { steps, currentStep } = this.props
    const shownSteps = this.displayedSteps()
    return (
      <div className={cx("c-stepper-container", { 'c-stepper-container-unlimited' : shownSteps.length >= 6 })}>
        <h3 className="d-xl-none text-center">{shownSteps[currentStep].label}</h3>
        <ol className="c-stepper">
          {map(shownSteps, (step: T.StepperStep, stepIndex: number) => (
            <li className={cx("c-stepper__item", this.getNavState(currentStep, stepIndex))} key={stepIndex} value={stepIndex}>
              <h3 className="d-none d-xl-block c-stepper__title">{step.label}</h3>
              <div className="c-stepper__circle"></div>
            </li>
          ))}
        </ol>
      </div>
    )
  }
}

interface RoutingStepperProps extends RouteComponentProps {
  steps: T.StepperStep[]
}

class RoutingStepper extends React.Component<RoutingStepperProps> {
  displayedSteps = (): T.StepperStep[] => {
    const { steps } = this.props
    return steps.filter((step: T.StepperStep) => step.hideInStepper !== true)
  }

  getCurrentStepIndex = (): number => {
    const { steps, location } = this.props
    const foundIndex = findIndex(this.displayedSteps(), (step: T.StepperStep) => {
      const match = matchPath(location.pathname, step.path)
      return !isNull(match)
    })
    // Prevent crashing if the step is not found.
    // Not glorious but route-base matching will most likely fail at some point
    // when someone will change the code and forget this feature.
    return foundIndex >= 0 ? foundIndex : this.displayedSteps().length - 1
  }
  render() {
    const { steps } = this.props
    return <SimpleStepper
      currentStep={this.getCurrentStepIndex()}
      steps={steps}
    />
  }
}

export { SimpleStepper }

export default withRouter(RoutingStepper)

import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { isNil, filter, includes, findIndex, sample, isEmpty, sortBy } from 'lodash'
import { randomSelector } from 'utils/helpers'
import Step, { StepProps, StepFooter } from './Step'
import DoctorSelection from 'containers/DoctorSelection'
import { Button, Loader } from 'components'
import moment from 'constants/moment'
import { Model } from 'survey-core'
import T from 'types'
import { SIMPLE, CHAT, VISIO, PHONE } from 'constants/doctorSelectionStates'
import { I18n } from 'react-redux-i18n'

interface IProps extends RouteComponentProps<any>, StepProps {
  doctor: T.Doctor
  survey: Model
  surveyId: number
  loading: boolean
  createConsultation: (onCreateSuccess: () => void, onSlotUnavailable: () => void) => void
  termsOfService: boolean
  patientAttributes: object
  collection: any[]
  medium: string
  consultationType: string
  skipDoctorSelection: boolean
  goNext: () => void
  goPrevious: () => void
  availableChannels: string[]
  slot: Date
  getDoctors: () => void
  getCommunicationChannels: () => void
  setSurveyQuestionName: (questionName: string) => void
  setChosenTimeSlot: (value: any) => void
  setChosenDoctor: (doctor: any) => void
  setChosenMedium: (medium: string) => void
  setChosenConsultType: (type: string) => void
}

interface IStep {
  id: string
  title: string
  mediums: string[]
  showInSkipDoctorMode: boolean
  showNextButton: boolean
}

interface IState {
  autoChosenDoctor: T.Doctor | undefined
  consultation: string
  medium: string
  currentStep: IStep
  errorMessage: string | null
}

const STEPS: IStep[] = [
  {
    id: 'media',
    title: '3. Comment voulez vous joindre votre médecin ?',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: true,
    showNextButton: false,
  },
  {
    id: 'slot-choice',
    title: '3. Choisissez une date de consultation',
    mediums: [PHONE, VISIO],
    showInSkipDoctorMode: true,
    showNextButton: false,
  },
  {
    id: 'autoChosenDocConfirmation',
    title: '3. Confirmez votre mécecin',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: false,
    showNextButton: true,
  },
  {
    id: 'booking',
    title: '3. Choisissez un médecin',
    mediums: [PHONE, VISIO, CHAT],
    showInSkipDoctorMode: false,
    showNextButton: false,
  },
]

export default class StepConsultationChoices extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const currentStep = STEPS[0]

    this.state = {
      currentStep,
      autoChosenDoctor: undefined,
      consultation: props.consultationType,
      medium: props.medium,
      errorMessage: null
    }
    this.handleSelectConsult = this.handleSelectConsult.bind(this)
    this.handleSelectMedium = this.handleSelectMedium.bind(this)
    this.handleSelectTimeSlot = this.handleSelectTimeSlot.bind(this)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
  }

  componentDidMount() {
    const { getDoctors, getCommunicationChannels, skipDoctorSelection } = this.props
    getCommunicationChannels()
    if (!skipDoctorSelection) {
      getDoctors()
    }
  }

  private actualSteps(): IStep[] {
    const { skipDoctorSelection } = this.props
    return filter(STEPS, (step: IStep) => {
      const showForMedium = isNil(this.state.medium)
        ? true
        : includes(step.mediums, this.state.medium)

      const shouldShow = !skipDoctorSelection || step.showInSkipDoctorMode
      return showForMedium && shouldShow
    })
  }

  private goNextStep() {
    if (this.isLastStep()) {
      this.handleNextButtonClick()
    } else {
      this.setState({ currentStep: this.nextStep() })
    }
  }
  private nextStep(): IStep {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return actualSteps[currentStepIndex + 1]
  }

  private goPreviousStep() {
    this.setState({ currentStep: this.previousStep() })
  }

  private previousStep(): IStep {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return actualSteps[currentStepIndex - 1]
  }
  private isFirstStep() {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return currentStepIndex === 0
  }
  private isLastStep() {
    const actualSteps = this.actualSteps()
    const currentStepIndex = findIndex(actualSteps, this.state.currentStep)
    return currentStepIndex === actualSteps.length - 1
  }

  private handleSelectConsult(consultation: string) {
    const { setChosenConsultType } = this.props

    this.setState(
      {
        consultation,
        currentStep: this.nextStep(),
      },
      () => setChosenConsultType(consultation),
    )
  }

  private handleSelectMedium(medium: string) {
    const { collection, setChosenMedium, setChosenDoctor, consultationType } = this.props
    if (medium === CHAT) {
      const filteredCollection: T.Doctor[] = filter(
        collection,
        (doctor: T.Doctor) =>
          includes(doctor.communicationChannels, medium) &&
          includes(doctor.consultationTypes, consultationType),
      )
      const autoChosenDoctor: T.Doctor | undefined = this.getRandomDoctor(filteredCollection)
      this.setState(
        {
          medium,
          autoChosenDoctor,
        },
        () => {
          setChosenMedium(medium)
          setChosenDoctor(autoChosenDoctor as T.Doctor)
          this.goNextStep()
        },
      )
    } else {
      this.setState({ medium }, () => {
        setChosenMedium(medium)
        this.goNextStep()
      })
    }
  }

  private getRandomDoctor = (doctors: T.Doctor[]): T.Doctor | undefined => {
    const refilteredDoctors = filter(doctors, (d) => d.available == true)
    return randomSelector(refilteredDoctors, (doctor: T.Doctor) => doctor.z || 100)
  }

  private handleSelectTimeSlot(slot: Date) {
    const { setChosenDoctor, setChosenTimeSlot, collection, medium, consultationType } = this.props

    const filteredCollection: T.Doctor[] = filter(collection, (doctor: T.Doctor) => {
      if (
        includes(doctor.communicationChannels, medium) &&
        includes(doctor.consultationTypes, consultationType)
      ) {
        const freeTimeSlots =
          consultationType === 'simple' ? doctor.freeSimpleSlots : doctor.freeComplexSlots
        const matchingSlotIndex = findIndex(freeTimeSlots, dateString =>
          moment(dateString).isSame(slot),
        )
        return matchingSlotIndex >= 0
      }
      return false
    })
    const autoChosenDoctor: T.Doctor | undefined = this.getRandomDoctor(filteredCollection)

    setChosenTimeSlot(slot)
    setChosenDoctor(autoChosenDoctor as T.Doctor)
    this.goNextStep()
  }

  private handleBackButtonClick() {
    const { goPrevious } = this.props
    if (this.isFirstStep()) {
      goPrevious()
    } else {
      this.setState({ currentStep: this.previousStep() })
    }
  }

  private handleNextButtonClick() {
    this.createConsultationAndNext()
  }

  private handleConfirmDoctor() {
    this.createConsultationAndNext()
  }

  private createConsultationAndNext() {
    const { createConsultation, goNext, getDoctors } = this.props

    createConsultation(() => {
      goNext()
    },
      () => {
        console.error("SUCCESS ! Slot unavaiable StepConsultationChoices.tsx")
        getDoctors()
        this.setState({
          currentStep: this.previousStep(),
          errorMessage: I18n.t('onboarding.slotUnavailable')
        })
      })
  }

  public render() {
    const { collection, slot, doctor, loading, steps, availableChannels } = this.props
    const hasAtLeastOneDoctor = !isEmpty(collection)

    return hasAtLeastOneDoctor ? (
      <Step steps={steps} goPrevious={() => this.handleBackButtonClick()}>
        <>
          {!!this.state.errorMessage &&
            <div className="alert alert-danger text-center">{this.state.errorMessage}</div>
          }
          <div className="onboarding-container-bloc">
            <DoctorSelection
              collection={collection}
              confirm={() => this.handleNextButtonClick()}
              loading={loading}
              slot={slot}
              availableChannels={availableChannels}
              doctor={doctor}
              lastConsultSpeciality=""
              handleAcceptDoctor={() => this.handleNextButtonClick()}
              handleChangeDoctor={() => this.goNextStep()}
              handleSelectConsult={(consultation: string) => this.handleSelectConsult(consultation)}
              handleSelectMedium={(medium: string) => this.handleSelectMedium(medium)}
              handleSelectTimeSlot={(date: Date) => this.handleSelectTimeSlot(date)}
              handleBackButtonClick={() => this.handleBackButtonClick()}
              currentStep={this.state.currentStep.id}
              medium={this.state.medium}
              consultation={this.state.consultation}
            />
          </div>

          <StepFooter>
            <Button
              variant="btn-outline-primary"
              classname="onb-prev-btn"
              onClick={() => this.handleBackButtonClick()}
            >
              Précédent
            </Button>
          </StepFooter>
        </>
      </Step>
    ) : (
      <Loader />
    )
  }
}

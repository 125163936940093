import React, { Component, Fragment, RefObject, useRef } from 'react'
import classnames from 'classnames'
import { filter, includes, get, map, sortBy, isEmpty, first, isUndefined } from 'lodash'
import T from 'types'
import cx from 'classnames'
import { SvgIcon, BlockHeader, Button, Block, VisioBox } from 'components'
import { getIcon } from 'assets/icons'
import ChatboxV2ConsultMobile from 'components/ChatboxV2/Mobile/ChatboxV2ConsultMobile'

interface IProps {
  handleSelectConsultation: (consultation: T.Consultation | null) => void
  refreshMessages: (consultation: T.Consultation) => void
  displayMessage: (message: T.Message) => void
  renderSendForm: () => void
  conversationMessages: T.Message[]
  actionLoading?: string[]
  ongoingConsultations: T.Consultation[]
  finishedConsultations: T.Consultation[]
  showPatientNotification: boolean,
  selectedConsultation: T.Consultation | null
  chatMessagesList: RefObject<HTMLDivElement>
}

interface IState {
  showFinishedConsult: boolean
}

class ChatboxV2Mobile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      showFinishedConsult: false
    }
  }

  public handleShowFinishedConsultations(showConsult: boolean) {
    if (showConsult == this.state.showFinishedConsult)
      return;

    this.setState({ showFinishedConsult: showConsult })
  }

  public render() {
    const {
      ongoingConsultations,
      finishedConsultations,
      conversationMessages,
      showPatientNotification,
      selectedConsultation,
    } = this.props

    const hasOngoingConsultations = !isEmpty(ongoingConsultations)
    const lastFinishedConsultation = first(finishedConsultations)
    const otherFinishedConsultations = finishedConsultations.slice(1)
    const hasOtherFinishedConsultations = !isEmpty(otherFinishedConsultations)
    return (
      <div className="ongoing-chats-container-mobile">
        {hasOngoingConsultations && (
          <>
            <BlockHeader icon="clinic.chat" title="Mes consultations par messages en cours" />
            {map(ongoingConsultations, (consultation) =>
              this.renderMobileConsultation(consultation, conversationMessages, showPatientNotification, selectedConsultation)
            )}
          </>
        )}
        {!isUndefined(lastFinishedConsultation) && (
          <>
            <BlockHeader icon="clinic.chatGrey" title="Mes consultations par messages terminées" />
            {this.renderMobileConsultation(lastFinishedConsultation, conversationMessages, showPatientNotification, selectedConsultation)}
            {hasOtherFinishedConsultations && (
              <>
                <Button
                  variant="btn-outline-primary"
                  classname={cx("mt-2 btn-block", { 'd-none': this.state.showFinishedConsult })}
                  onClick={() => this.handleShowFinishedConsultations(!this.state.showFinishedConsult)}
                >
                  <SvgIcon classname="ongoing-chats-container-mobile-svg" icon={getIcon('clinic.plusIcon')} />
                  Voir les consultations terminées
                </Button>
                {this.state.showFinishedConsult &&
                  map(otherFinishedConsultations, (consultation) =>
                    this.renderMobileConsultation(consultation, conversationMessages, showPatientNotification, selectedConsultation)
                  )
                }
                <Button
                  variant="btn-outline-primary"
                  classname={cx("mt-2 btn-block", { 'd-none': !this.state.showFinishedConsult })}
                  onClick={() => this.handleShowFinishedConsultations(!this.state.showFinishedConsult)}
                >
                  <SvgIcon classname="ongoing-chats-container-mobile-svg" icon={getIcon('clinic.minusIcon')} />
                  Cacher les consultations terminées
                </Button>
              </>
            )}
          </>
        )}
      </div>
    )
  }

  private renderMobileConsultation = (consultation: T.Consultation, conversationMessages: T.Message[], showPatientNotification: boolean, selectedConsultation: T.Consultation | null) => {
    const {
      displayMessage,
      refreshMessages,
      renderSendForm,
      handleSelectConsultation,
      chatMessagesList,
    } = this.props
    return (
      <div key={`mobile-consultation-${consultation.id}`}>
        <ChatboxV2ConsultMobile
          {...this.props}
          consultation={consultation}
          conversationMessages={conversationMessages}
          showPatientNotification={showPatientNotification}
          selectedConsultation={selectedConsultation}
          displayMessage={displayMessage}
          refreshMessages={refreshMessages}
          renderSendForm={renderSendForm}
          handleSelectConsultation={handleSelectConsultation}
          chatMessagesList={chatMessagesList}
        />
      </div>
    )
  }
}

export default ChatboxV2Mobile

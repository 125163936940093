import React, { Fragment } from 'react'
import { get, isEmpty, map } from 'lodash'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Block, SvgIcon } from 'components'
import { I18n } from 'react-redux-i18n'
import { renderPrescriptionsBody } from 'utils/helpers'
import T from 'types'
import { CookiesHelper } from 'helpers'
import {
  PRESCRIPTION_TYPE_ERECTION_DRUGS,
  PRESCRIPTION_TYPE_HAIRLOSS_DRUGS,
  PRESCRIPTION_TYPE_WEIGHT_DRUGS,
  PRESCRIPTION_TYPE_PHYTOTHERAPY,
} from 'constants/prescriptions'
import { getIcon } from 'assets/icons'
import moment from 'constants/moment'
import {
  handleDownloadPrescription,
  handleOrderPrescription,
} from './helpers'

interface IProps extends RouteComponentProps<any> {
  prescriptions: T.Prescription[]
  setFocusedPrescription: (p: T.Prescription) => void
}

class PrescriptionList extends React.Component<IProps> {
  private handleOrderPrescription(prescription: T.Prescription) {
    const { setFocusedPrescription, history } = this.props

    switch (prescription.prescriptionType) {
      case PRESCRIPTION_TYPE_PHYTOTHERAPY:
        history.push(`/phytotherapy/${prescription.id}`)
        break
      case PRESCRIPTION_TYPE_ERECTION_DRUGS:
      case PRESCRIPTION_TYPE_HAIRLOSS_DRUGS:
      case PRESCRIPTION_TYPE_WEIGHT_DRUGS:
        setFocusedPrescription(prescription)
        history.push(`/ordonnance/${prescription.id}`)
        break
    }
  }

  private handleDownloadPrescription(prescription: T.Prescription) {
    const { setFocusedPrescription, history } = this.props

    setFocusedPrescription(prescription)
    history.push(`/download-prescription/${prescription.id}`)
  }

  public render() {
    return (
      <Fragment>
        {this.renderPrescriptions()}
      </Fragment>
    )
  }

  private renderPrescriptions = () => {
    const { prescriptions } = this.props

    return (
      <Block classname="pharmacist" title={I18n.t('titles.prescription_history')}>
        {
          isEmpty(prescriptions) ?
            <div>Vous n'avez pas encore d'ordonnance disponible</div>
            :
            map(prescriptions, prescription => this.renderPrescription(prescription))
        }
      </Block>
    )
  }
  private renderPrescription = (prescription: T.Prescription) => {
    const { setFocusedPrescription, history } = this.props
    const { id, downloadUrl, prescriptionType } = prescription
    return (
      <div key={id} className="prescription-history-row">
        {renderPrescriptionsBody(prescription)}
        <div className="d-flex align-items-center justify-content-center justify-content-md-start justify-content-lg-start show-prescription-button">
          {prescriptionType === PRESCRIPTION_TYPE_ERECTION_DRUGS ||
            prescriptionType === PRESCRIPTION_TYPE_HAIRLOSS_DRUGS ||
            prescriptionType === PRESCRIPTION_TYPE_WEIGHT_DRUGS ||
            prescriptionType === PRESCRIPTION_TYPE_PHYTOTHERAPY ? (
            <a
              onClick={() => handleDownloadPrescription(prescription, setFocusedPrescription, history)}
              className="text-underline font-weight-normal d-flex align-items-center justify-content-lg-start"
            >
              <SvgIcon classname="prescription-history-row-icon" icon={getIcon('clinic.eye')} />
              <div className="text-center text-md-left">Mon ordonnance pour<br />{I18n.t(`prescriptions.type.${get(prescription, 'prescriptionType')}`)}</div>
            </a>
          ) : (
            <a
              href={`${downloadUrl}?access_token=${CookiesHelper.getPureOauthAccessToken()}`}
              className="text-underline text-center font-weight-normal d-flex align-items-center"
              target="_blank"
            >
              <SvgIcon classname="prescription-history-row-icon" icon={getIcon('clinic.eye')} />
              <div className="text-center text-md-left">Mon ordonnance pour<br />{I18n.t(`prescriptions.type.${get(prescription, 'prescriptionType')}`)}</div>
            </a>
          )}
        </div>
        <div className="d-flex prescription-delivery-wrapper">
          {moment(prescription.expiredAt).diff(moment()) <= 0 && !prescription.overTheCounter ? (
            <div className="d-flex align-items-center justify-content-center justify-content-md-end justify-content-lg-start">
              <Button classname="prescription-delivery-button" href="#/new-consultation">
                Reconsulter
              </Button>
            </div>
          ) : ([PRESCRIPTION_TYPE_ERECTION_DRUGS, PRESCRIPTION_TYPE_HAIRLOSS_DRUGS, PRESCRIPTION_TYPE_WEIGHT_DRUGS, PRESCRIPTION_TYPE_PHYTOTHERAPY].includes(prescriptionType)) ? (
            <div className="d-flex align-items-center justify-content-center justify-content-md-end justify-content-lg-start">
              <Button classname="prescription-delivery-button" onClick={() => handleOrderPrescription(prescription, setFocusedPrescription, history)}>
                Me faire livrer
              </Button>
            </div>
          ) : (
            <div>
              <Button classname="prescription-delivery-button-placeholder d-none d-md-flex">
                Me faire livrer
              </Button>
            </div>
          )
          }
        </div>
      </div>
    )
  }
}

export default withRouter(PrescriptionList)

import React, { Component, useRef } from 'react';
import { get } from 'lodash'
import { SvgIcon } from 'components'
import classnames from 'classnames'
import './styles.sass'
import T from 'types'
import { getIcon } from '../../assets/icons'
import { Link } from 'react-router-dom'

interface IProps {
  pharmacist: T.Pharmacist
}

const PharmacistProfile = ({ pharmacist }: IProps) => {
  const { name, address } = pharmacist
  const popin = useRef<HTMLDivElement>(null)

  const showPopin = () => {
    const elem = popin.current
    if (elem) {
      elem.classList.toggle('show-popin')
    }
  }

  return (
    <div className="pharmacist-profile block-content">
      <div className="d-flex justify-content-between flex-column">
        <div className="text-center text-md-left d-flex flex-column align-items-start mb-4">
          <div className="pharmacist-name popin">
            <div>{name}</div>
            <SvgIcon onClick={() => showPopin()} icon={getIcon('clinic.info')} classname="pharmacy-info-icon ml-1" />
            <div ref={popin} className="popin-content">
              <div className="font-weight-bold">{name} :</div>
              <div>
                {get(address, 'address1')}
                {get(address, 'address2')}
              </div>
              <div>
                {get(address, 'zipcode')} {get(address, 'city')}
              </div>
            </div>
          </div>
          <div className="pharmacy-reinsurance-title">Détails de la livraison</div>
          <div className="d-flex flex-lg-row pharmacy-reinsurance-wrapper">
            <div className="pharmacy-reinsurance">
              <SvgIcon icon={getIcon('france')} classname="pharmacy-reinsurance-icon d-flex" />
              <div className="ml-1">France metropolitaine</div>
            </div>
            <div className="pharmacy-reinsurance">
            <SvgIcon icon={getIcon('clinic.postalBox')} classname="pharmacy-reinsurance-icon d-flex" />
              <div className="ml-1">Sous 48h</div>
            </div>
            <div className="pharmacy-reinsurance">
              <SvgIcon
                icon={getIcon('clinic.largeBox')}
                classname="pharmacy-reinsurance-icon d-flex"
              />
              <div className="ml-1">Domicile ou point relais</div>
            </div>
          </div>
        </div>
        <Link to={`/pharmacie/${pharmacist.id}`} className="btn btn-primary btn-block">Choisir cette pharmacie</Link>
      </div>
    </div>
  )
}

export default PharmacistProfile

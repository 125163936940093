import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import uuidv4 from 'uuid'
import { I18n } from 'react-redux-i18n'
import {
  max,
  map,
  first,
  find,
  isEmpty,
  isNull,
  filter,
  includes,
  values,
  reduce,
  head,
  isUndefined,
  Dictionary,
  sortBy
} from 'lodash'
import { Block, WowtechProductList, Button } from 'components'
import SvgIcon from 'components/SvgIcon'
import { MainContainer } from 'containers'
import { Row, Col } from 'react-bootstrap'
import T from 'types'
import build from 'redux-object'
import CookiesHelper from 'helpers/cookies-helper'
import { money } from 'utils/models/money'
import recommendedIcon from 'assets/images/recommended.svg'
import { TARGET_PLATFORM } from 'constants/config'

const DEFAULT_RECOMMENDED_DURATION = 3
interface IProps extends RouteComponentProps<any> {
  collectionPrescription: T.Prescription[]
  profile: T.Profile
  getProducts: () => void
  getTaxons: () => void
  getPrescriptions: () => void
  beginCheckout: (variants: T.CheckoutVariantChoice[], successCallback: Function) => void
  addToCart: (variant: T.Variant, onSuccess: Function) => void
  storefront: T.Storefront
}

type IState = {
  selected: Dictionary<T.CheckoutVariantChoice>
  sessionUUID: string
}

class Wowtech extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      selected: {},
      sessionUUID: uuidv4(),
    }
  }

  componentDidMount() {
    const { storefront, getProducts, getTaxons, getPrescriptions } = this.props

    // TODO REACTIVATE WHEN DONE
    if (isEmpty(storefront.product) || isEmpty(storefront.taxon)) {
      getTaxons()
      getProducts()
      getPrescriptions()
    }
  }

  selectProductVariant = (variant: T.Variant, cb: Function) => {
    const { addToCart } = this.props

    addToCart(variant, cb)
  }

  deriveProductList = () => {
    const { storefront, collectionPrescription } = this.props
    const taxons = build(storefront, 'taxon')
    const wowtechTaxon = find(taxons, (taxon: any) => taxon.name === 'wowtech')
    const allWowtechProducts = isUndefined(wowtechTaxon) ? [] : wowtechTaxon.products

    return sortBy(filter(allWowtechProducts, (pro)=> !isEmpty(pro.name)), (it) => it.name)
  }

  render() {
    const { storefront } = this.props

    const products = this.deriveProductList()

    return (
      <>
        <MainContainer title="Nos appareils thérapeutiques" showCart={true}>
          <div className="page Wowtech">
            <div className="home-container">
              <div className="infos-container">
                {!isEmpty(storefront.actionLoading) ? (
                  <div className="m-5 p-5">Chargement...</div>
                ) : (
                  <Fragment>
                    {isEmpty(products) ? (
                      <span>Il n'y a pas encore de produits</span>
                    ) : (
                      <Fragment>
                        {!isEmpty(products) && (
                          <Block classname="without-header">
                            <div className="other-products wowtech-product-card-wrapper">
                              {map(products, this.renderProduct)}
                            </div>
                          </Block>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </MainContainer>
      </>
    )
  }

  renderProduct = (product: any, index: number) => (
    <WowtechProductList
      key={`wowtech_product_${product.id}_choice_${index}`}
      product={product}
      handleProductSelection={this.selectProductVariant}
    />
  )
}

export default withRouter(Wowtech)

import React, { Component } from 'react'

import T from 'types'
import { isNil, map, filter, isEmpty } from 'lodash'
import { SvgIcon, BlockHeader } from 'components'
import { getIcon, icons } from 'assets/icons'
import './styles.sass'
import Carousel, { CarouselSlide } from 'components/Carousel'
import { ProgramRecommendationCard } from 'components'
import { IS_CHARLES_PLATFORM } from 'constants/config'

interface IProps {
  profile: T.Profile,
}

export default class ProgramRecommendationCarousel extends Component<IProps> {

  public render() {
    const { profile } = this.props
    if (!IS_CHARLES_PLATFORM) return null

    const timeRecommandation: T.UI.ProgramRecommendationCard = {
      show: profile.hasTimeProgram || !isNil(profile.sentTimeRecommendationAt) || !profile.isConsultation,
      recommendationDate: profile.sentTimeRecommendationAt,
      isEnrolled: profile.hasTimeProgram,
      title: "Programme TIME",
      fullName: "Thérapie Intensive de Maîtrise de l'Éjaculation",
      description: "90 jours de thérapie en ligne pour reprendre le contrôle de votre éjaculation",
      duration: "3 mois",
      path: '/programme-time',
      slug: 'time-program'
    }
    const masterRecommandation: T.UI.ProgramRecommendationCard = {
      show: profile.hasMasterProgram || !isNil(profile.sentMasterRecommendationAt) || !profile.isConsultation,
      recommendationDate: profile.sentMasterRecommendationAt,
      isEnrolled: profile.hasMasterProgram,
      title: "Programme MASTER",
      fullName: "Maîtrise Appliquée du Stress et des Troubles Érectiles",
      description: "8 semaines de thérapie complète issue de 25 ans d'expérience en sexologie et médecine sexuelle",
      duration: "8 semaines",
      path: '/programme-master',
      slug: 'master-program'
    }
    const sommeilRecommandation: T.UI.ProgramRecommendationCard = {
      show: profile.hasSommeilProgram || !isNil(profile.sentSommeilRecommendationAt) || !profile.isConsultation,
      recommendationDate: profile.sentSommeilRecommendationAt,
      isEnrolled: profile.hasSommeilProgram,
      title: "Programme SOMMEIL",
      fullName: "Retrouvez le sommeil naturellement en 6 semaines",
      description: "Insomnies, endormissements difficiles, nuits non réparatrices, fatigue chronique, sommeil léger... Découvrez la méthode des somnologues pour en finir avec ces problèmes en 6 semaines, sans somnifère.",
      duration: "6 semaines",
      path: '/programme-sommeil',
      slug: 'sommeil-program'
    }
    const allRecommandations: T.UI.ProgramRecommendationCard[] = [timeRecommandation, masterRecommandation, sommeilRecommandation]
    const recommendations: T.UI.ProgramRecommendationCard[] = filter(allRecommandations, (recommendation: T.UI.ProgramRecommendationCard) => recommendation.show)

    return isEmpty(recommendations) ? null : (
      <div className="home-page-carousel">
        <BlockHeader title='Mes thérapies digitales' icon={'clinic.camera'} />
        <Carousel
          breakpoints={{}}
          slidesPerView="auto"
          spaceBetween={20}
        >
          {map(recommendations, (recommendation: T.UI.ProgramRecommendationCard, index: number) => (
            <CarouselSlide key={`prescription_slide_${index}`}>
              <ProgramRecommendationCard
                programRecommandation={recommendation}
              />
            </CarouselSlide>
          ))}
        </Carousel>
      </div>
    )
  }
}

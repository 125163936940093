import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import Step, { StepProps } from '../common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import ProgramArguments from './ProgramArguments'
import T from 'types'
import mailSend from 'assets/images/mail-send.png'
import mobileMailSend from 'assets/images/mobile-mail-send.png'
import Media from 'react-media'
import { getIcon } from 'assets/icons'
import SvgIcon from 'components/SvgIcon'

interface IProps extends RouteComponentProps<any>, StepProps {
  program: T.Program
  academy: T.AcademyStore
}

export default class StepAcademyFreeEpisode extends Component<IProps> {

  public render() {
    const { academy, steps, goPrevious } = this.props

    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <LayoutStep>
          <LayoutMain variant='custom-small'>
            <div className="block-content">
              <SvgIcon icon={getIcon("clinic.envelope")} classname="academy-thankyou-image" />
              <h4 className="mt-3">Un email vous a été envoyé à votre adresse</h4>
              <p className="thankyou-text mt-3">
                En cliquant sur le lien de l'e-mail, vous pourrez créer votre espace personnel et avoir accès à votre 1ère étape gratuitement. Pensez à vérifier dans vos messages indésirables.
              </p>
            </div>
          </LayoutMain>
          <LayoutSide>
            <ProgramArguments slug={academy.onboarding.slug} />
          </LayoutSide>
        </LayoutStep>
      </Step >
    )
  }
}

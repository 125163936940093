import React, { Component } from 'react'
import { map } from 'lodash'
import T from 'types'
import { Link } from 'react-router-dom'

import  { SvgIcon } from 'components'

import iconCalendarSvg from 'assets/icons/calendar-icon.svg'

import './styles.sass'

interface IProps {
  sleepCalendar?: T.SleepCalendar
  onClick?: () => void
}

class AgendaSommeilCta extends Component<IProps> {
  render() {
    const { sleepCalendar, onClick } = this.props

    if (!sleepCalendar || !sleepCalendar.isWaitingForItems)
      return null

    const linkEl = <Link className="btn btn-primary mt-3" to="/agenda-sommeil">
      Remplir mon agenda sommeil
    </Link>

    const buttonEl = onClick ? <button className="btn btn-primary mt-3" onClick={onClick}>
      Remplir mon agenda sommeil
    </button> : null


    return <div className="agenda-sommeil-cta block-content text-center">
      <SvgIcon icon={iconCalendarSvg} />
      <p>Il vous reste {sleepCalendar.missingSleepCalendarItemsKeys.length} {sleepCalendar.missingSleepCalendarItemsKeys.length > 1 ? 'jours' : 'jour'} de votre agenda de sommeil à remplir</p>

      { buttonEl ? buttonEl : linkEl }
    </div>
  }
}

export default AgendaSommeilCta

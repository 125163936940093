import React from 'react'
import T from 'types'
import CardPill from '../../CardPill'
import cx from 'classnames'
import { SvgIcon } from 'components'
import { getIcon } from '../../../assets/icons'
import Media from 'react-media';

const PrescriptionProgramCard = ({
  title,
  description,
  pillArgument,
  className,
  alt,
  image,
  argumentList,
  priceDetails,
  cta
}: T.PrescriptionCard) => {

  return (
    <CardPill text={pillArgument} className={cx('saxenda-card', className)}>
      <h2 className="mb-0">{title}</h2>
      <div className="mb-3">{description}</div>
      <img className="flex-shrink-0 w-100 rounded mb-2 mb-xl-4" alt={alt} src={image} />
      <Media query="(max-width: 1200px)" render={() => <div className="mb-3 mt-2">{cta}</div>} />
      <div className="saxenda-card-grid">
        <div className="empty-cell d-none d-xl-block" />
        <div className="centered-cell logo-cell"><SvgIcon classname="charles-icon mr-0" icon={getIcon('logo.charles')} /></div>
        <div className="centered-cell d-none d-xl-flex">En ville</div>
        {argumentList.map((argument, i) => (
          <>
            <div className={cx({ 'top-left-rounded': i === 0, 'bottom-left-rounded': i === (argumentList.length - 1) })}>{argument.text}</div>
            {argument.both ?
              <div className="double-cell centered-cell">{argument.both}</div> :
              <>
                <div className="centered-cell">{argument.with_charles}</div>
                <div className="centered-cell d-none d-xl-flex">{argument.with_pharmacist}</div>
              </>
            }
          </>
        ))}
      </div>
      <div className="mt-auto">
        {cta}
        {priceDetails && <div className="font-italic">{priceDetails}</div>}
      </div>
    </CardPill>
  )
}

export const PrescriptionProgramCardPrimary = ({
  title,
  description,
  pillArgument,
  alt,
  image,
  argumentList,
  cta,
}: T.PrescriptionCard) => {

  return (
    <PrescriptionProgramCard
      title={title}
      description={description}
      pillArgument={pillArgument}
      alt={alt}
      image={image}
      argumentList={argumentList}
      cta={cta}
      className="green-card-pill green-prescription-card"
    />
  )
}

export const PrescriptionProgramCardSecondary = ({
  title,
  description,
  pillArgument,
  image,
  alt,
  argumentList,
  cta,
  priceDetails,
}: T.PrescriptionCard) => {

  return (
    <PrescriptionProgramCard
      title={title}
      description={description}
      pillArgument={pillArgument}
      image={image}
      alt={alt}
      argumentList={argumentList}
      priceDetails={priceDetails}
      cta={cta}
      className="grey-card-pill grey-prescription-card"
    />
  )
}

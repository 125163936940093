import React, { Component, RefObject } from 'react'
import { Button } from 'components'
import { isUndefined } from 'lodash'
import './styles.sass'

interface IProps {
  loading?: boolean
  applyCoupon: (coupon: string) => void
  activeCoupon?: string
}

class CouponInput extends Component<IProps> {
  coupon: RefObject<HTMLInputElement>

  constructor(props: IProps) {
    super(props)
    this.coupon = React.createRef()
  }

  private handleSubmit = (inputRef: any) => {
    const { applyCoupon } = this.props
    applyCoupon(inputRef.value)
  }

  public render() {
    const { loading, activeCoupon } = this.props
    const couponDefaultValue = activeCoupon || ''
    return (
      <div className="coupon-wrapper">
        <div className="coupon-title">
          Code promo
        </div>
        <div className="coupon-input-wrapper">
          <input
            type="text"
            name="coupon"
            placeholder="Code Promo"
            defaultValue={couponDefaultValue}
            ref={this.coupon}
            className="coupon-text-input"
          />
          <Button
            type="button"
            classname="btn-sm"
            loading={loading}
            onClick={() => this.handleSubmit(this.coupon.current)}
          >
            OK
          </Button>
        </div>
      </div>
    )
  }
}

export default CouponInput

import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { containerClasses } from './utils';
import {
  WaitingRoomMask,
  ConnectingMask,
  StartCallMask,
  ErrorMask,
  ReconnectMask,
} from './Masks.js'

const VisioVideo = ({
  connected,
  error,
  stopped,
  active,
  subscribersCount,
  publisherRef,
  subscriberRef,
  startCall,
  reconnect,
  retry,
}) => {
  const { cameraPublisherClass, cameraSubscriberClass } = containerClasses(
    active,
    subscribersCount,
    true,
    true
  );

  const noSubscribers = subscribersCount === 0;

  return (
    <div className="VisioBox-video-container">
      {!connected && !error && !stopped && <ConnectingMask />}
      {!connected && stopped && !error && <ReconnectMask reconnect={reconnect} />}
      {!connected && error && <ErrorMask retry={retry} />}
      {connected && !active && <StartCallMask startCall={startCall} />}
      {connected && active && noSubscribers && <WaitingRoomMask />}
      <div
        ref={publisherRef}
        id="cameraPublisherContainer"
        className={cameraPublisherClass}
      ></div>
      <div
        ref={subscriberRef}
        id="cameraSubscriberContainer"
        className={cameraSubscriberClass}
      ></div>
    </div>
  );
};

export default VisioVideo;
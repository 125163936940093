import React from 'react'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import { StepHead } from 'page/common/Step'
import T from 'types'
import { WizardStepProps } from 'helpers/hooks/useVisioConfiguration'
import tracker from 'utils/trackers'


const DeviceMissing = (props: WizardStepProps) =>
  (!props.config.hasCamera)
  ? <CameraMissing {...props} />
  : <MicMissing {...props} />

  const CameraMissing = (props: WizardStepProps) => {
    const label = props.trackingConfig.context == 'personalSpace' ? "J'ai besoin d'aide" : "J'ai compris"
    const trackAndGoNext = () => {
      tracker.trackMediaSetupEventDeviceMissing(props.config, props.trackingConfig)
      props.onDeviceMissing && props.onDeviceMissing()
    }
    return (
    <div>
      <StepHead title="Caméra manquante" />
      <SvgIcon icon={getIcon('setup.cameraMissing')} classname="central-icon"/>
      <p>Votre médecin doit pouvoir vous voir pendant la consultation.</p>
      <p>Merci de vous connecter avec un appareil disposant d'une caméra.</p>
      <Button
        variant="btn-primary"
        classname="mx-auto"
        onClick={trackAndGoNext}>
          {label}
      </Button>
    </div>
  )
}

const MicMissing = (props: WizardStepProps) => {
  const label = props.trackingConfig.context == 'personalSpace' ? "J'ai besoin d'aide" : "J'ai compris"
  const trackAndGoNext = () => {
    tracker.trackMediaSetupEventDeviceMissing(props.config, props.trackingConfig)
    props.onDeviceMissing && props.onDeviceMissing()
  }
  return (
    <div>
      <StepHead title="Micro manquant" />
      <SvgIcon icon={getIcon('setup.micMissing')} classname="central-icon"/>
      <p>Vous devez pouvoir parler avec votre médecin pendant la consultation.</p>
      <p>Merci de vous connecter avec un appareil disposant d'un micro.</p>
      <Button
        variant="btn-primary"
        classname="mx-auto"
        onClick={trackAndGoNext}>
          {label}
      </Button>
    </div>
  )
}
export default DeviceMissing
// mia
export const VAGINISME = "vaginisme-program"
// charles
export const TIME = "time-program"
export const MASTER = "master-program"
export const SOMMEIL = "sommeil-program"

export const ACADEMY_PROGRAMS = [VAGINISME, TIME, MASTER, SOMMEIL]

export const FUNNEL_VAGINISME = [
  {
    path: '/informations',
    label: 'Informations'
  },
  {
    path: '/choix-formule',
    label: 'Formule'
  },
  {
    path: '/adresse',
    label: 'Adresse'
  },
  {
    path: '/paiement',
    label: 'Paiement'
  },
  {
    path: '/confirmation',
    label: 'Confirmation'
  }
]

export const FUNNEL_ACADEMY = [
  {
    path: '/informations',
    label: 'Informations'
  },
  {
    path: '/episode-gratuit',
    label: 'Épisode gratuit'
  }
]

import React, { Component } from 'react'
import Switch from 'react-ios-switch'
import classnames from 'classnames'
import { isUndefined } from 'lodash'
import './styles.sass'

interface IProps {
  checked: boolean,
  textOn: string,
  textOff?: string,
  type?: string,
  onUrl?: string,
  offUrl?: string,
  onToggle?: (checked: boolean) => void,
}

interface IState {
  checked: boolean,
}

class ToggleButton extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { checked: props.checked }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    // update state if props are updated
    this.setState({ checked: nextProps.checked })
  }

  private handleToggle() {
    const { onUrl, offUrl, onToggle } = this.props
    const { checked } = this.state

    if (!isUndefined(onToggle)) {
      this.setState({ checked: !checked }, () => onToggle(!checked))
    }

    if (!isUndefined(onUrl) && !isUndefined(offUrl)) {
      fetch(checked ? offUrl : onUrl)
      .then(
        (res) => {
          if (res.ok) {
            this.setState({ checked: !checked })
          } else {
            Promise.reject(res.statusText)
          }
        },
      )
      .catch(error => console.error('Error:', error))
    }
  }

  private renderSwitch() {
    const { textOn, textOff } = this.props
    const { checked } = this.state

    return (
      <div className="toggle-block">
        <Switch
          onChange={() => this.handleToggle()}
          checked={checked}
          className="toggle-button"
          onColor="rgb(119, 251, 221)"
        />
        {
          checked
          ? <span className="toggle-text">{textOn}</span>
          :<span className="toggle-text">{textOff || textOn}</span>
        }
      </div>
    )
  }

  private renderCheckbox() {
    const { textOn } = this.props
    const { checked } = this.state

    return (
      <div className={classnames('checkbox', { checked })}>
        <label>
          <input type="checkbox" onChange={() => this.handleToggle()} />
          <span>
            <span className="check" />
          </span>
          <span className="checkbox">
            <span>{textOn}</span>
          </span>
        </label>
      </div>
    )
  }

  public render() {
    const { type } = this.props

    switch (type) {
      case 'checkbox':
        return this.renderCheckbox()
      default:
        return this.renderSwitch()
    }
  }
}

export default ToggleButton

import React from 'react'
import T from 'types'
import { SectionArguments } from 'page/common/ConsultationArguments'

const sections: T.ISection[] = [
  {
    title: 'Charles, la santé repensée',
    items: [
      { icon: 'clinic.doctor', text: 'Accompagnement par nos coachs' },
      { icon: 'clinic.camera', text: '100% online' },
      { icon: 'clinic.lock', text: 'Confidentiel, discret et à votre rythme depuis chez vous' },
      { icon: 'clinic.checklist', text: 'Thérapie complète issue de 10 ans d\'expérience en médecine du sommeil' },
    ],
  }, {
    title: 'Charles respecte votre vie privée',
    items: [
      { icon: 'clinic.shield', text: 'Vos données sont sécurisées et soumises au secret médical' },
      { icon: 'clinic.hardware', text: 'Charles utilise un Hébergeur Agréé de Données de Santé' },
    ],
  },
]
export default () => (
  <SectionArguments sections={sections} />
)

import { connect } from 'react-redux'
import {
  getConsultations,
  getPrescriptions,
  getPrescriptionPdf,
  updateConsultation,
  uploadMedicalDocument,
  setFocusedPrescription,
} from 'actions'
import Prescriptions from 'page/Pages/Prescriptions'
import T from 'types'

const mapStateToProps = ({
  auth: { profile },
  consultations: { collection },
  prescriptions: { collectionPrescription },
}: T.Store) => ({
  profile,
  collection,
  collectionPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getConsultations: () => dispatch(getConsultations()),
  getPrescriptions: () => dispatch(getPrescriptions()),
  getPrescriptionPdf: (url: string) => dispatch(getPrescriptionPdf(url)),
  updateConsultation: (c: T.Consultation) => dispatch(updateConsultation(c)),
  uploadMedicalDocument: (data: FormData) => dispatch(uploadMedicalDocument(data)),
  setFocusedPrescription: (p: T.Prescription) => dispatch(setFocusedPrescription(p)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Prescriptions)

import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import { Location } from 'history'
import IntercomWrapper from 'page/common/IntercomWrapper'
import { find, isEmpty, isUndefined, isNull, map, last, includes } from 'lodash'
import tracker from 'utils/trackers'
import { Loader } from 'components'
import { createRoutes, shouldSignIn } from 'utils/router'
import {
  Home,
  LoginForm,
} from 'containers'
import T from 'types'
import logoCharles from 'assets/images/charles-digital.png'
import logoMia from 'assets/images/mia-logo.png'
import { MangopayBanner } from 'components/MangopayBanner/MangopayBanner'

const CATEGORY = 'Espace Patient'

type Props = {
  showNotification: (type: string, content: string) => void
  redirectAfterLogin: (url?: string) => void
  redirectedToUrlAfterLogin: () => void
  storeOneTimeAuthToken: (authToken: string | null) => void
  setCurrentProfile: Function
  signedIn: boolean
  sidebarActive: boolean
  profile: T.Profile
  redirectUrlAfterLogin?: string,
  closeSidebar: () => void,
}

type TrackingListenerProps = {
  children: any
  closeSidebar: () => void,
}

const LoginPage = ({ email }: { email?: string }) => {
  const logo: any = IS_CHARLES_PLATFORM ? logoCharles : logoMia

  return (
    <Fragment>
      <MangopayBanner />
      <div className="login-page">
        <div className="logo">
          <img src={logo} />
        </div>
        <LoginForm email={email} />
      </div>
    </Fragment>
  )
}
class TrackingListener extends React.Component<TrackingListenerProps> {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    this.sendPageView(this.context.router.history.location)
    this.context.router.history.listen(this.sendPageView)
    this.context.router.history.listen(this.props.closeSidebar)
  }

  sendPageView(location: Location) {
    tracker.trackPageViewV1(`[PAT] ${location.pathname}`, location.pathname)
    tracker.trackPageViewV2(CATEGORY, location.pathname)
  }

  render() {
    return this.props.children
  }
}

class Me extends React.Component<Props> {
  private shouldFetchProfile() {
    const { signedIn, profile } = this.props

    return signedIn && isEmpty(profile)
  }

  private redirectionPath() {
    const { profile, redirectUrlAfterLogin, redirectedToUrlAfterLogin } = this.props
    const { isAcademy, isConsultation, academyDefaultPath } = profile
    const academyDefaultReturnUrl = academyDefaultPath || '/therapies-digitales'
    const shouldRedirectToAcademy = isAcademy && !isConsultation
    const defaulReturnUrl = shouldRedirectToAcademy ? academyDefaultReturnUrl : '/home'
    const returnURL =
      !isUndefined(redirectUrlAfterLogin) && !isNull(redirectUrlAfterLogin)
        ? redirectUrlAfterLogin
        : defaulReturnUrl
    redirectedToUrlAfterLogin()

    return <Redirect to={returnURL} />
  }

  private renderRoutes() {
    const { showNotification, setCurrentProfile, redirectAfterLogin, signedIn, storeOneTimeAuthToken } = this.props
    if (shouldSignIn(location, signedIn)) {
      const parts = window.location.hash.split('#')
      const selectedPath = last(parts)
      let wantedPath
      if (selectedPath && selectedPath.includes('auth_token')) {
        const splittedPath = selectedPath.split('?')
        wantedPath = splittedPath[0]
        const authToken = splittedPath[1].split('auth_token=')[1]
        storeOneTimeAuthToken(authToken)
      } else {
        wantedPath = selectedPath
      }
      redirectAfterLogin(wantedPath)
      return <Redirect to="/signin" />
    }

    if (
      window.location.search.includes('order=success') &&
      !window.location.hash.includes('#/orders')
    ) {
      window.history.replaceState({}, document.title, `${window.location.pathname}#/orders`)
      showNotification('success', 'Votre commande a bien été enregistrée')
    }

    if (this.shouldFetchProfile()) {
      setCurrentProfile()

      return <Loader />
    }

    const routes = createRoutes()

    const paths = map(routes, route => route[0] as string)

    return (
      <Fragment>
        <Route path="/home" component={Home} exact={true} />
        {map(routes, ([path, Component, optComponentProps]: [string, FunctionComponent, any]) => (
          <Route
            key={path}
            path={path}
            render={props => <Component {...props} {...optComponentProps} />}
          />
        ))}
        <Route
          path="*"
          render={({ location }) =>
            ((paths as unknown) as any[])
              .map(path => path.split('/')[1])
              .indexOf(location.pathname.split('/')[1]) === -1
              ? this.redirectionPath()
              : null}
          exact={true}
        />
      </Fragment>
    )
  }

  public render() {
    const { profile, closeSidebar, signedIn } = this.props
    if (window.location.search.includes('login=')) {
      const queries = window.location.search.substring(1).split('&')
      const query = find(queries, query => query.includes('login=')) || ''
      const login = query.split('=')[1]

      window.history.replaceState({}, document.title, window.location.pathname)

      return <LoginPage email={login} />
    }
    return (
      <Fragment>
        <Router>
          <TrackingListener closeSidebar={closeSidebar}>
            <Switch>
              <Route
                path="/signin"
                render={() => (!shouldSignIn(location, signedIn) ? <Redirect to="/home" /> : <LoginPage />)}
              />
              {this.renderRoutes()}
            </Switch>
          </TrackingListener>
        </Router>
        <IntercomWrapper
          active={!shouldSignIn(location, signedIn) && !this.shouldFetchProfile()}
          showLauncher={true}
          profile={profile}
          patientWorkflow="personalSpace"
        />
      </Fragment>
    )
  }
}

export default Me

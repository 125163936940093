import React, { Component, Fragment } from 'react'
import { last, get } from 'lodash'
import build from 'redux-object'
import Step, { StepHead, StepProps } from 'page/common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { Button, SvgIcon, Block, BlockHeader } from 'components'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import {
  variantFullPrice,
  getNumberOfPayments,
  organizeProgramVariants,
} from 'utils/models/variants'

import { I18n } from 'react-redux-i18n'
import { enrollmentForProgram } from 'utils/data/academy'

import './styles.sass'
import { IS_CHARLES_PLATFORM } from 'constants/config'

import AcademyPaymentForPatients from './AcademyPaymentForPatients'
import AcademyAddress from './AcademyAddress'
import ProgramVariantChoice from 'page/AcademyFunnel/ProgramVariantChoice'
import ProgramArguments from 'page/AcademyFunnel/ProgramArguments'
import { money } from 'utils/models/money'
import { buildPatientAttributes } from 'utils/helpers'

interface IProps {
  patientAttributes?: any
  program: T.Program
  auth: T.Auth
  academy: T.AcademyStore
  storefront: T.Storefront
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  goNext: () => void
  goPrevious?: () => void
  getPaymentMethods: () => void
  showNotification: (title: string, message: string) => void
  createAddress: (
    data: any,
    enrollmentId: string | undefined,
    onSuccess: () => void,
  ) => void,
  setExtraPersonalData: (data: object) => void
  rootVariantId?: number
  order: T.OrderV2
}

interface IState {
  addressSent: boolean
  selectedVariantId?: number
  showSelectedVariant: boolean
}

export default class AcademyFunnelForPatients extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      addressSent: false,
      showSelectedVariant: false,
      selectedVariantId: undefined,
    }
  }

  public render() {
    const {
      program,
      academy,
      order,
      auth,
      storefront,
      applyCoupon,
      selectProgramVariant,
      purchase,
      getPaymentMethods,
      createAddress,
      setExtraPersonalData,
    } = this.props

    return (
      <LayoutStep>
        <LayoutMain variant="medium">
          {!this.state.selectedVariantId && order
            ? this.renderVariantChoice(program)
            : order && this.state.selectedVariantId && !this.state.addressSent && !IS_CHARLES_PLATFORM && (
              <AcademyAddress
                {...this.props}
                program={program}
                academy={academy}
                setExtraPersonalData={setExtraPersonalData}
                createAddress={createAddress}
                handler={() => this.setState({ addressSent: true })}
              />
            )}
          {((this.state.addressSent === true || IS_CHARLES_PLATFORM) && order && this.state.selectedVariantId) && (
            <div className="academy-purchase-bloc-for-patients">
              <AcademyPaymentForPatients
                patientAttributes={buildPatientAttributes(auth.profile)}
                storefront={storefront}
                academy={academy}
                program={program}
                order={order}
                applyCoupon={applyCoupon}
                rootVariantId={this.state.selectedVariantId}
                selectProgramVariant={selectProgramVariant}
                purchase={purchase}
                goNext={this.handlePaymentSuccess}
                getPaymentMethods={getPaymentMethods}
              />
            </div>
          )}
        </LayoutMain>
      </LayoutStep>
    )
  }

  public renderVariantChoice(program: T.Program) {
    const { academy, order } = this.props
    const enrollment = enrollmentForProgram(academy, program)
    const hasFreeEpisode = get(enrollment, 'hasFreeEpisode') == true
    const forPatients = hasFreeEpisode ? false : true
    const { stepByStepVariant, fullOneTimePayment } = organizeProgramVariants(program, forPatients, hasFreeEpisode)
    const showStepByStepVariant = (program.slug === 'time-program' || program.slug === 'master-program' || program.slug === 'sommeil-program') && stepByStepVariant

    // Get price from variantList and add hardcoded promotion cuz when we get it from order there
    // is a delay
    const oneTimePrice = (program.slug === 'sommeil-program') ? (Number(fullOneTimePayment.price) - 30).toString() : fullOneTimePayment.price

    let comparativeFullPrice
    if (showStepByStepVariant && (program.slug) !== 'sommeil-program') {
      comparativeFullPrice = variantFullPrice(stepByStepVariant)
    } else if (showStepByStepVariant && program.slug === 'sommeil-program') {
      comparativeFullPrice = variantFullPrice(fullOneTimePayment)
    } else {
      comparativeFullPrice = undefined
    }

    let allInOneBullet = []
    if (I18n.t(`academy.${program.slug}.selectionStep.bullets.bullet1`) !== 'bullet1') {
      for (let i = 0; i < Object.keys(I18n.t(`academy.${program.slug}.selectionStep.bullets`)).length; i = i + 1) {
        allInOneBullet.push(I18n.t(`academy.${program.slug}.selectionStep.bullets.bullet${i + 1}`))
      }
    } else {
      allInOneBullet = [
        I18n.t(`academy.${program.slug}.selectionStep.oneTimePayment`),
        I18n.t(`academy.${program.slug}.selectionStep.oneTimeSplitPayment`),
      ]
    }

    const steByStepBullets = []
    for (let i = 0; i < Object.keys(I18n.t(`academy.${program.slug}.selectionStep.stepByStep`)).length; i = i + 1) {
      steByStepBullets.push(I18n.t(`academy.${program.slug}.selectionStep.stepByStep.bullet${i + 1}`))
    }

    const reductionString = () => {
      if (I18n.t(`academy.${program.slug}.selectionStep.percent`) === 'percent') {
        return undefined
      }
      return I18n.t(`academy.${program.slug}.selectionStep.percent`)
    }

    return (
      <div className={`funnel-${program.slug} funnel-program`}>
        <div className="program-variants-container">
          <ProgramVariantChoice
            key="all_in_one_variant"
            title="Programme complet"
            ctaLabel="Choisir cette offre"
            variant={fullOneTimePayment}
            price={oneTimePrice}
            priceSuffix=""
            comparativeFullPrice={comparativeFullPrice}
            bullets={allInOneBullet}
            handleSelectVariant={() => this.setState({ selectedVariantId: fullOneTimePayment.id })}
            reductionString={reductionString()}
          />
          {showStepByStepVariant && <ProgramVariantChoice
            key="step_by_step_variant"
            title="Étape par étape"
            ctaLabel="Choisir cette offre"
            variant={stepByStepVariant}
            price={stepByStepVariant.price}
            priceSuffix=" / étape"
            bullets={steByStepBullets}
            handleSelectVariant={() => this.setState({ selectedVariantId: stepByStepVariant.id })}
          />}
        </div>
      </div>
    )
  }

  private handlePaymentSuccess = () => {
    const { showNotification } = this.props
    showNotification('success', 'Le paiement a bien été effectué. La page va se recharger')
    setTimeout(() => {
      sessionStorage.clear()
      document.location.reload()
    }, 2000)
  }
}

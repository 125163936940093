import React from 'react'
import tick from 'assets/icons/academy/check.png'
import { map, find, first, filter, isUndefined, isEmpty } from 'lodash'
import { Button, SvgIcon, Block, BlockHeader } from 'components'
import { money } from 'utils/models/money'
import T from 'types'
import { I18n } from 'react-redux-i18n'
import CardPill from '../../components/CardPill';

interface IProps {
  title: string
  ctaLabel: string
  variant: T.Variant
  price: string
  priceSuffix: string
  bullets: string[]
  comparativeFullPrice?: number
  handleSelectVariant: () => void
  reductionString?: string
}

class ProgramVariantChoice extends React.Component<IProps> {
  public render() {
    const { title,
      ctaLabel,
      // Price with promo included if mentioned
      price,
      bullets,
      priceSuffix,
      comparativeFullPrice,
      variant,
      handleSelectVariant,
      reductionString,
    } = this.props
    const formattedPrice = money(price).toFormat()
    const showComparison = !isUndefined(comparativeFullPrice)
    const reductionPercent = !isUndefined(comparativeFullPrice) ? ((1 - (parseFloat(price) / comparativeFullPrice)) * 100).toFixed() : null

    return (
      <CardPill className="home-page-card" text={reductionString}>
        <BlockHeader>
          {title}<br /><b>{formattedPrice} {priceSuffix}</b>
          {(!isUndefined(comparativeFullPrice) && (comparativeFullPrice !== Number(price))) && (
            <span className="time-variant-choice--comparative-full-price">au lieu de {money(comparativeFullPrice).toFormat()}</span>
          )}
        </BlockHeader>
        <div className="program-variant-bullets">
          {map(bullets, (bullet, index) => (
            <div key={`program_variant_choice_bullet_${index}`} className="d-flex align-items-center program-variant-row">
              <img className="mr-2 color-tick-bullet" src={tick} />
              <div>{bullet}</div>
            </div>
          ))}
        </div>
        <Button
          classname="mx-auto mt-auto"
          onClick={() => handleSelectVariant()}
        >
          {ctaLabel}
        </Button>
      </CardPill >
    )
  }
}

export default ProgramVariantChoice

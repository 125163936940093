import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { get, isNil, isUndefined, some, includes } from 'lodash'
import cx from 'classnames'
import './styles.sass'
import SidebarItem, { SideBarAction, SideBarActionLogoRight } from './SidebarItem'
import { getIcon } from 'assets/icons'
import logoCharles from 'assets/icons/logo-charles-clinique.svg'
import logoMia from 'assets/images/mia-logo.svg'
import chevron from 'assets/icons/sidebar-chevron-input.svg'
import moment from 'constants/moment'
import T from 'types'
import menu from 'assets/images/sidebarToggleButton.svg'
import phone from 'assets/icons/icon-ringing-phone.svg'
import SvgIcon from '../SvgIcon'
import { I18n } from 'react-redux-i18n'
import { SUPPORT_PHONE_NUMBER, IS_CHARLES_PLATFORM } from 'constants/config'

interface IProps extends RouteComponentProps<any> {
  profile: T.Profile
  sidebarActive: boolean
  lastPrescription?: T.Prescription
  consultations: any
  sidebarToDisplay: string
  activeLabels: string[]
  signOut: () => void
  closeSidebar: () => void
  setFocusedPrescription: (p: T.Prescription) => void
  dropdownSidebarToggleElement: (value: string) => void
}

class Sidebar extends Component<IProps> {
  private toggleDropdown(label: string) {
    const { dropdownSidebarToggleElement } = this.props
    dropdownSidebarToggleElement(label)
  }

  private handleSignout() {
    const { signOut } = this.props
    signOut()
  }

  private handleOrderLastPrescription() {
    const { lastPrescription, setFocusedPrescription, history } = this.props
    if (isNil(lastPrescription)) {
      return
    }
    setFocusedPrescription(lastPrescription)
    history.push(`/ordonnance/${lastPrescription.id}`)
  }

  private renderDropdown(title: string, icon: string, elements: any) {
    const { activeLabels } = this.props
    const isActive = includes(activeLabels, title)
    return (
      <div className="dropdown">
        <div
          className="dropdown__toggle dropdown__list-item sidebar-item"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            e.preventDefault()
            this.toggleDropdown(title)
          }}
        >
          <span
            className={cx('d-flex', isActive ? 'dropdown__toggler--active' : 'dropdown_toggler')}
          >
            <span className="label flex-grow-1">{I18n.t(`sidebar.${title}`)}</span>
            <span className="icon" dangerouslySetInnerHTML={{ __html: chevron }} />
          </span>
        </div>
        <ul
          className={cx('dropdown__list list-unstyled', {
            'dropdown__list--active': isActive,
          })}
        >
          {elements.map((element: any, index: number) => (
            <li key={`dropdown_item_${title}_${index}`}>
              {this.renderRenderDropdownItem(element)}
              {this.renderSubitems(element.label, title, index)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  private renderSubitems(label: string, title: string, parentIdx: number) {
    const items = this.subitemsForProgram(label)

    if (!items)
      return null;

    return <ul className={'list-unstyled sidebar-item-subitems'}>
      {
        items.map((item: any, index: number) =>
          <li key={`dropdown_item_${title}_${parentIdx}_sub_${index}`}>
            { item.onClick ?
               <SideBarAction icon={''} label={item.label} onClick={item.onClick} /> :
               <SidebarItem icon={''} label={item.label} path={item.path} />
            }
          </li>
        )
      }
    </ul>
  }

  private renderRenderDropdownItem(element: any) {
    if (element.hide) {
      return null
    }
    if (element.onClick) {
      return <SideBarAction icon={element.icon} label={element.label} onClick={element.onClick} />
    } else {
      return <SidebarItem icon={element.icon} label={element.label} path={element.path} />
    }
  }

  private subitemsForProgram(label: string) {
    const { profile } = this.props
    if (label == 'programme-sommeil')
      return profile.displaySleepCalendar ? [{label: 'agenda-sommeil'}] : null

    return null
  }

  private sidebarTopRender(shouldDisplayOrderLink: boolean, hasPendingConsultation: boolean) {
    const { sidebarToDisplay, profile } = this.props
    const { lastConsultationSpeciality } = profile

    if (!IS_CHARLES_PLATFORM) // MIA
      if (sidebarToDisplay == 'academy')
        return <Fragment>
            <SidebarItem icon="programTime" label="programme-vaginisme"/>
            <SidebarItem icon="support" label="assistance"/>
            <SidebarItem icon="account" label="account"/>
        </Fragment>
      else
        return <Fragment>
          <SidebarItem icon="birdHouse" label="home" />
          <SidebarItem speciality={lastConsultationSpeciality} icon="newConsultation" label="new-consultation" />
          <SidebarItem icon="patient" label="medical-info" />
          <SidebarItem icon="phyto" label="phytotherapy" />
          <SidebarItem icon="shipmentBox" label="orders" />
          <SidebarItem icon="programTime" label="programme-vaginisme" />
          <SidebarItem icon="account" label="account" />
        </Fragment>

    let academyDropdown = [
      { label: 'Présentation', icon: null, path: '/therapies-digitales' },
      { label: 'programme-master', icon: null },
      { label: 'programme-time', icon: null },
      { label: 'programme-sommeil', icon: null, subitems: this.subitemsForProgram('sommeil') }
    ]

    if (sidebarToDisplay != 'consultation')
      academyDropdown.push({ label: 'assistance', icon: null })

    return <Fragment>
      <SidebarItem icon="birdHouse" label="home" />

      {shouldDisplayOrderLink && (
        <SideBarAction
          icon="truck"
          label="order-treatments"
          onClick={() => this.handleOrderLastPrescription()}
        />
      )}
      {profile.isConsultation &&
        <>
          <SidebarItem icon="patient" label="medical-info" />
          {
            this.renderDropdown('medical-section', 'medical', [
              { label: 'documents', icon: null },
              { label: 'orders', icon: null },
              { label: 'messages', icon: null },
            ])
          }
        </>
      }
      <SidebarItem icon="phyto" label="phytotherapy" />
      {this.renderDropdown('therapies-section', 'academy', academyDropdown)}
      <SidebarItem icon="account" label="account" />
    </Fragment>
  }

  private phoneNumber() {
    const { sidebarToDisplay } = this.props
    switch (sidebarToDisplay) {
      case 'academy':
        return (
          <a href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`} className="phone-number-info">
            {SUPPORT_PHONE_NUMBER}
          </a>
        )
        break

      default:
        return (
          <a href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`} className="phone-number-info">
            {SUPPORT_PHONE_NUMBER}
          </a>
        )
        break
    }
  }
  public render() {
    const { profile, sidebarActive, lastPrescription, consultations, sidebarToDisplay } = this.props

    const logoLight: any = IS_CHARLES_PLATFORM ? logoCharles : logoMia
    const hasPendingConsultation = some(
      consultations.collection,
      (currentConsultation: T.Consultation) =>
        currentConsultation.state === 'paid' ||
        currentConsultation.state === 'confirmed' ||
        currentConsultation.state === 'started',
    )

    const sidebarClass = cx('sidebar animate', { active: sidebarActive })

    const shouldDisplayOrderLink = isUndefined(lastPrescription)
      ? false
      : get(lastPrescription, 'canBeReleased')

    return (
      <div className={sidebarClass}>
        <div className="sidebar-top">
          <button
            className="toggle-menu-button"
            type="button"
            onClick={() => this.props.closeSidebar()}
          >
            <SvgIcon classname="toggle-menu-icon" icon={menu} />
          </button>
          <Link to="/home">
            <div className="logo-container" dangerouslySetInnerHTML={{ __html: logoLight }} />
          </Link>

          <a href="tel:+33186651733" className="sidebar-support-number text-center d-block mb-4">
            <SvgIcon classname="icon-24px mr-2" icon={getIcon('clinic.phone')} />
            <span className="mr-2 font-weight-bold">0186651733</span>
            Gratuit
          </a>

          <div className="text-center mb-4">
            <Link className="btn btn-primary btn-xs" to="/new-consultation">
              {I18n.t('sidebar.new-consultation')}
            </Link>
          </div>

          {this.sidebarTopRender(shouldDisplayOrderLink, hasPendingConsultation)}
        </div>
        <footer className="sidebar-bottom">
          <SideBarActionLogoRight icon="logout" label="logout" onClick={() => this.handleSignout()} />
        </footer>
      </div>
    )
  }
}

export default withRouter(Sidebar)

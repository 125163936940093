import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { isEmpty, includes } from 'lodash'
import build from 'redux-object'
import { MainContainer } from 'containers'
import { Block, Table } from 'components'
import { formatedCollection } from 'utils/helpers'
import T from 'types'
import './styles.sass'
import { I18n } from 'react-redux-i18n'

type IProps = {
  storefront: T.Storefront
  collectionOrders: any[]
  getCompletedOrders: () => void
  lastPrescription: T.Prescription
  getPrescriptions: () => void
}

class OrdersHistory extends React.Component<IProps> {
  componentDidMount() {
    const { getCompletedOrders, getPrescriptions } = this.props

    getPrescriptions()
    getCompletedOrders()
  }

  render() {
    const { lastPrescription, storefront } = this.props
    const orderTypes = ['drugs', 'phytotherapy']
    const storeOrders = build(storefront, 'cart')
    const allOrders = storeOrders.filter((order: any) => includes(orderTypes, order.orderType))
    const displayColumns = ['number', 'lineItemsNames', 'completedAt', 'displayTotal', 'state']
    // ugly way to change the wording for phytotherapy complete orders
    const cleanOrders = allOrders.map((order: any) => (
      order.orderType === 'phytotherapy' && order.state === 'complete'
        ? { ...order, state: 'complete_phyto' }
        : order
    ))
    const orders = formatedCollection(cleanOrders, displayColumns)

    return (
      <MainContainer>
        <div className="page orders-history">
          <Block>
            {!isEmpty(storefront.actionLoading) ? (
              <div className="m-5 p-5">Chargement ...</div>
            ) : (
              <Fragment>
                {isEmpty(orders) ? (
                  <p>
                    {I18n.t("pages.ordersHistory.emptyPage")}
                  </p>
                ) : (
                  <Table
                    displayColumns={displayColumns}
                    collection={orders}
                    cta={{
                      title: 'orders',
                    }}
                  />
                )}
              </Fragment>
            )}
          </Block>
          <div className="button-container">
            {isEmpty(lastPrescription) ? null : (
              <NavLink to={`/home`} className="btn btn-primary">
                {I18n.t("pages.ordersHistory.callToAction")}
              </NavLink>
            )}
          </div>
        </div>
      </MainContainer>
    )
  }
}

export default OrdersHistory

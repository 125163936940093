import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { map, isNull } from 'lodash'
import T from 'types'
import { LayoutStep, LayoutMain, LayoutSide } from '../LayoutStep'
import './styles.sass'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import BCSpecialtyPicker from './BCSpecialtyPicker'
import BCCategoryPicker from './BCCategoryPicker'

type IState = {
  chosenSpecialtyCategory: T.SpecialtyCategory | null
}
class BCSpecialty extends React.Component<BookingConsultationStepProps, IState> {

  constructor(props: BookingConsultationStepProps) {
    super(props)
    this.state = {
      chosenSpecialtyCategory: null
    }
  }

  handleChosenSpecialty = (specialty: T.ConsultationSpecialty) => {
    const { setFocusedSpecialty, getSurveyAndSubmission, setOpenConsultationDoctor, navigation, profile: { openConsultationDoctor } } = this.props

    setFocusedSpecialty(specialty.slug)
    let doctorSlug
    if (specialty.slug === "open_consultation" && openConsultationDoctor) {
      doctorSlug = openConsultationDoctor.openConsultationSlug
    }
    setOpenConsultationDoctor(doctorSlug)
    getSurveyAndSubmission(specialty.slug, () => navigation.goNext())
  }

  handleChosenCategory = (category: T.SpecialtyCategory) => {
    const specialties = category.specialties
    if (specialties.length > 1) {
      this.setState({ chosenSpecialtyCategory: category })
    }
    else if (specialties.length == 1) {
      this.handleChosenSpecialty(specialties[0])
    }
  }

  render() {
    const { chosenSpecialtyCategory } = this.state
    const { profile: { openConsultationDoctor }, storefront, specialtyCategories, focusedSpecialty } = this.props

    return (
      <LayoutStep>
        <LayoutMain>
          {isNull(chosenSpecialtyCategory) ? (
            <BCCategoryPicker
              specialtyCategories={specialtyCategories}
              handleChosenCategory={this.handleChosenCategory}
            />
          ) : (
            <BCSpecialtyPicker
              openConsultationDoctor={openConsultationDoctor}
              category={chosenSpecialtyCategory}
              focusedSpecialty={focusedSpecialty}
              handleChosenSpecialty={this.handleChosenSpecialty}
            />
          )}
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default withNavigation(BCSpecialty)


import React, { Component, Fragment } from 'react'
import { ContextualDoctorProfile } from 'containers'
import ConsultationTime from './ConsultationTime'
import DoctorDetails from './DoctorDetails'
import T from 'types'
import { IS_CHARLES_PLATFORM } from 'constants/config'

interface IProps {
  doctor: T.Doctor
  loading?: boolean
  onDisplaySlotClick: () => void
  setTimeSlot: (value: Date) => void
  setDoctor: (value: T.Doctor) => void
  onConfirm: () => void
  onChange: (value: boolean) => void
  classname?: any
  medium?: string
  consultationType?: string
  slot?: Date
  isToggleOn: boolean
}

interface IState {
  isToggleOn: boolean
  isSlotSelected: boolean
}

class ConsultationBookingItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isToggleOn: false,
      isSlotSelected: false,
    }
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    const { isToggleOn } = nextProps

    if (this.props.isToggleOn !== isToggleOn) {
      this.setState({ isToggleOn })
    }

    return true
  }

  private handleSelect() {
    const { doctor, onDisplaySlotClick, setDoctor, onChange } = this.props
    const { isToggleOn, isSlotSelected } = this.state

    if (isToggleOn) {
      if (isSlotSelected) {
        this.setState({ isSlotSelected: false })
      } else {
        onDisplaySlotClick()
      }
    }

    this.setState({ isToggleOn: !isToggleOn }, () => setDoctor(doctor))
    onChange(!isToggleOn)
  }

  private handleButtonClick() {
    const { onConfirm, medium } = this.props
    const { isToggleOn, isSlotSelected } = this.state

    if ((isToggleOn && isSlotSelected) || (isToggleOn && medium === 'chat')) {
      onConfirm()
    }
  }

  private handleSelectSlot(value: Date) {
    const { setTimeSlot } = this.props

    this.setState({ isSlotSelected: true }, () => setTimeSlot(value))
  }

  public render() {
    const { doctor, classname, medium, loading, consultationType, slot } = this.props
    const { isToggleOn, isSlotSelected } = this.state
    const freeTimeSlots =
      consultationType === 'simple' ? doctor.freeSimpleSlots : doctor.freeComplexSlots

    return (
      <Fragment>
        <ContextualDoctorProfile
          loading={loading}
          doctor={doctor}
          showBorders={true}
          onSelect={() => this.handleSelect()}
          onButtonClick={() => this.handleButtonClick()}
          isToggleOn={isToggleOn}
          isSlotSelected={isSlotSelected && isToggleOn}
          classname={classname}
          medium={medium}
          slot={slot}
        >
          {isToggleOn && medium !== 'chat' && (
            <ConsultationTime
              availabilities={freeTimeSlots}
              loading={loading}
              onSelectSlot={(value: Date) => this.handleSelectSlot(value)}
            />
          )}
        </ContextualDoctorProfile>
      </Fragment>
    )
  }
}

export default ConsultationBookingItem

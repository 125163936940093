import React from 'react'
import {
  ReactSurveyElement,
  SurveyQuestionCheckbox, SurveyQuestionCheckboxItem,
} from 'survey-react-ui';
import { Base, ItemValue, QuestionCheckboxModel } from 'survey-core';

// custom behavior for the checkbox question
class SurveyQuestionCheckboxExtended extends SurveyQuestionCheckbox {
  constructor(props: any) {
    super(props)
  }

  protected getItems(cssClasses: any): any[] {
    const renderedItems = []
    for (let i = 0; i < this.question.visibleChoices.length; i++) {
      const item = this.question.visibleChoices[i]
      const key = 'item' + i
      const renderedItem = this.renderItem(key, item, i == 0, cssClasses, '' + i)
      if (item.value === 'none') {
        renderedItems.unshift(renderedItem)
      } else {
        renderedItems.push(renderedItem)
      }
    }
    return renderedItems
  }
}

// custom layout for the checkbox question
export class SurveyQuestionCheckboxItemExtended extends ReactSurveyElement {
  constructor(props: any) {
    super(props);
  }

  protected getStateElement(): Base {
    return this.item;
  }

  protected get question(): QuestionCheckboxModel {
    return this.props.question;
  }

  protected get item(): ItemValue {
    return this.props.item;
  }

  protected get textStyle(): any {
    return this.props.textStyle;
  }

  protected get isFirst(): any {
    return this.props.isFirst;
  }

  protected get index(): number {
    return this.props.index;
  }

  private get hideCaption(): boolean {
    return this.props.hideCaption === true;
  }

  public shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if (!super.shouldComponentUpdate(nextProps, nextState)) return false;
    if (!this.question) return false;
    return (
      !this.question.customWidget ||
      !!this.question.customWidgetData.isNeedRender ||
      !!this.question.customWidget.widgetJson.isDefaultRender ||
      !!this.question.customWidget.widgetJson.render
    );
  }

  handleOnChange = (event: any) => {
    this.question.clickItemHandler(this.item, event.target.checked);
  }

  protected canRender(): boolean {
    return !!this.item && !!this.question;
  }

  protected renderElement(): JSX.Element {
    var isChecked = this.question.isItemSelected(this.item);
    return this.renderCheckbox(isChecked, null);
  }

  protected get inputStyle(): any {
    return null;//{ marginRight: "3px" };
  }

  protected renderCheckbox(
    isChecked: boolean,
    otherItem: JSX.Element | null
  ): JSX.Element {
    const id = this.question.getItemId(this.item);
    const itemClass = this.question.getItemClass(this.item);
    const labelClass = this.question.getLabelClass(this.item);
    const itemLabel = !this.hideCaption ? <span
      className={this.cssClasses.controlLabel}>{this.renderLocString(this.item.locText, this.textStyle)}</span> : null;

    return (
      <div className={itemClass} role="presentation">
        <label className={labelClass}>
          <input
            className={this.cssClasses.itemControl}
            type="checkbox"
            role="option"
            name={this.question.name + this.item.id}
            value={this.item.value}
            id={id}
            style={this.inputStyle}
            disabled={!this.question.getItemEnabled(this.item)}
            checked={isChecked}
            onChange={this.handleOnChange}
          />
          {
            this.cssClasses.materialDecorator ?
              <span className={this.cssClasses.materialDecorator}>
                <svg viewBox="0 0 24 24" className={this.cssClasses.itemDecorator}>
                  <path d="M5,13l2-2l3,3l7-7l2,2l-9,9L5,13z" />
                </svg>
              </span> :
              null
          }
          {itemLabel}
        </label>
        {otherItem}
      </div>
    )
  }
}

export default SurveyQuestionCheckboxExtended

import React from 'react'
import { MainContainer } from 'containers'
// TODO Remove dependency to global CSS ? Problably from the time vars couldn't be used in subfolders CSS
import '../styles.sass'
import './styles.sass'
import T from 'types'
import { Workflow } from 'components'
import { get, find, isNil, isEmpty } from 'lodash'
import BCPersonalInfos from './BCPersonalInfos'
import BCSpecialty from './BCSpecialty'
import BCSurveyStart from './BCSurveyStart'
import BCSurveyEnd from './BCSurveyEnd'
import BCBooking from './BCBooking'
import BCPayment from './BCPayment'
import BCFirstMessage from './BCFirstMessage'
import BCConfirmation from './BCConfirmation'
import BCConsultPresentation from './BCConsultPresentation'
import { Model } from 'survey-core'
import { buildSurvey } from 'page/common/survey'

export type BookingConsultationProps = {
  collection: any[]
  surveys: T.SurveysState
  doctor: T.Doctor
  profile: T.Profile
  slot: Date
  availableChannels: string[]
  medium: string
  consultationType: string
  survey: Model
  hasPreviousSubmission?: boolean
  consultationSpecialty?: string
  focusedSpecialty?: string
  skipDoctorSelection: boolean
  specialtyCategories: T.SpecialtyCategory[]
  consultationSpecialties: T.ConsultationSpecialty[]
  loading: boolean | undefined,
  storefront: T.Storefront
  consultation: T.Consultation | null
  orderNumber: string | undefined
  createConsultationFromProfile: (onSuccess: () => void) => void
  getLastConsultation: () => void
  sendConsultationPayment: ({
    data,
    orderNumber,
    onPaymentSuccess,
    onPaymentError,
  }: {
    orderNumber: string,
    data: T.PaymentPayload,
    onPaymentSuccess: () => void,
    onPaymentError: () => void,
  }) => void,
  createMessage: (userId: number, body: string, patientUserId: number) => void,
  getDoctors: () => void
  getCommunicationChannels: () => void
  getSurvey: (
    params: { pdid?: string, email?: string, orderNumber?: string },
  ) => void,
  getSurveyAndSubmission: (specialty: string, onSuccess: () => void) => void
  setChosenDoctor: (doctor: any) => void
  setChosenMedium: (medium: string) => void
  setChosenConsultType: (type: string) => void
  setChosenTimeSlot: (slot: Date) => void
  setFocusedSpecialty: (
    consultationSpecialty: string,
  ) => void,
  setOpenConsultationDoctor: (slug: string | undefined) => void
  setSurveyObject: (survey: object) => void,
  setSurveyQuestionName: (questionName: string) => void,
  showNotification: (title: string, message: string) => void
  updateProfile: (data: object) => void,
  uploadSurvey: (
    orderNumber: string | undefined,
    survey: object,
  ) => void,
  updateFocusedConsultation: (consultationId: string) => void,
  getAuthProfile: () => void,
  getPaymentMethods: () => void
}

const isMedicalConsultationSpecialty = (props: BookingConsultationProps): boolean => {
  const { consultationSpecialties, consultationSpecialty } = props

  const consultationSpecialtyObject: T.ConsultationSpecialty | undefined = find(consultationSpecialties, (specialty: T.ConsultationSpecialty) => specialty.slug === consultationSpecialty)
  return consultationSpecialtyObject!.medical
}

export interface BookingConsultationStepProps extends BookingConsultationProps, T.WorkflowNavigation {}

class BookingConsultation extends React.Component<BookingConsultationProps> {

  public render() {
    const {
      profile,
      hasPreviousSubmission,
      survey,
      consultation,
      orderNumber,
      uploadSurvey,
     } = this.props

    // TODO FIND ORDER and pass it to steps
    const defaultSteps: T.WorkflowStep[] = [
      {
        path: 'specialite',
        comp: <BCSpecialty {...this.props} />,
      }, {
        path: 'infos-persos',
        show: () => isNil(profile.birthDate) || isEmpty(profile.birthDate),
        comp: <BCPersonalInfos {...this.props} />,
      }, {
        path: 'consult-presentation',
        show: () => !hasPreviousSubmission && isMedicalConsultationSpecialty(this.props),
        comp: <BCConsultPresentation {...this.props} />,
      }, {
        path: 'questionnaire',
        show: () => !hasPreviousSubmission,
        comp: <BCSurveyStart {...this.props} />,
      }, {
        path: 'choix-medecin',
        comp: <BCBooking {...this.props} />
      }, {
        path: 'paiement',
        comp: <BCPayment {...this.props} />,
      }, {
        path: 'dossier-medical',
        show: () => {
          const surveyModel = buildSurvey(survey, "post_")
          const hasPages = surveyModel.pages.length > 0
          return (hasPages && !hasPreviousSubmission)
        },
        comp: <BCSurveyEnd {...this.props} />,
        onSkip: () => uploadSurvey(orderNumber, survey)
      }, {
        path: 'message',
        show: () => get(consultation, 'communicationChannel') === 'chat',
        comp: <BCFirstMessage {...this.props} />,
      }, {
        path: 'confirmation',
        comp: <BCConfirmation {...this.props} />,
      }
    ]
    return (
      <MainContainer>
        <Workflow steps={defaultSteps} level={0} stepperStyle="numbers"/>
      </MainContainer>
    )
  }
}

export default BookingConsultation

import { useState, useEffect } from "react";
import { isNil } from "lodash";

type PermissionInfo = {
  permissionState: PermissionState
  initializing: boolean
}

const useDevicePermission = (name: PermissionName, promptAccepted: boolean): PermissionInfo => {
  const [initializing, setInitializing] = useState<boolean>(true)
  const [permission, setPermission] = useState<PermissionStatus | null>(null);
  const [permissionState, setPermissionState] = useState<PermissionState>('prompt');

  useEffect(() => {
    if (!isNil(navigator) && !isNil(navigator.permissions)) {
      navigator.permissions
        .query({ name })
        .then((permissionStatus) => {
          setPermission(permissionStatus)
          setInitializing(false)
        })
        .catch((err) => {
          setPermission(null)
          setInitializing(false)
        })
    } else {
      setPermission(null)
      setInitializing(false)
    }
  }, [name]);

  useEffect(() => {
    if(permission === null){
      setPermissionState('prompt')
    } else {
      setPermissionState(permission.state)
      const changeHandler = (event: PermissionStatusEventMap['change']) => {
        const target = event.target as PermissionStatus
        setPermission(target)
        setPermissionState(target.state)
      }
      permission.addEventListener('change', changeHandler)
      return () => {
        permission.removeEventListener('change', changeHandler);
      };
    }
  }, [permission]);

  return {
    permissionState: promptAccepted ? 'granted' : permissionState,
    initializing
  }
};

export default useDevicePermission

import React from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'

import bullet8weeks from 'assets/icons/med-gabarit-icon.svg'
import bulletRhythm from 'assets/icons/large-gabarit-icon.svg'
import bulletImprovement from 'assets/icons/thumb-up.svg'
import bulletClock from 'assets/icons/clinic/40/icon-med-sans-attente-rdv-square.svg'
import bulletSpecialists from 'assets/icons/clinic/40/icon-med-specialistes-fr-square.svg'
import howAssistance from 'assets/icons/academy/master/menPhone.jpeg'
import howProgression from 'assets/icons/academy/master/blueStep.jpeg'
import howStepByStep from 'assets/icons/academy/master/remylombard.png'
import whyMental from 'assets/icons/academy/master/why-habit.jpeg'
import whyPhysics from 'assets/icons/academy/master/why-drink.png'
import whyRhythm from 'assets/icons/academy/master/why-remi.png'
import franceInfo from 'assets/icons/logo/france-info.svg'
import franceInter from 'assets/icons/logo/france-inter.svg'
import leMonde from 'assets/icons/logo/le-monde.svg'
import gq from 'assets/icons/logo/gq.svg'

const buildData: () => T.ProgramPresentation = () => ({
  ctaLabel: "Découvrez la thérapie (dès 24,90€)",
  about: [
    {
      items: [
        {
          text: I18n.t('academy.sommeil-program.about.question.item1.text'),
          details: I18n.t('academy.sommeil-program.about.question.item1.details'),
        },
        {
          text: I18n.t('academy.sommeil-program.about.question.item2.text'),
          details: I18n.t('academy.sommeil-program.about.question.item2.details'),
        },
        {
          text: I18n.t('academy.sommeil-program.about.question.item3.text'),
          details: I18n.t('academy.sommeil-program.about.question.item3.details'),
        },
      ],
    }],
  press: [
    {
      icon: franceInter,
    },
    {
      icon: leMonde,
    },
    {
      icon: gq,
    },
    {
      icon: franceInfo,
    },
  ],
  arguments: [
    {
      icon: bulletImprovement,
      label: I18n.t('academy.sommeil-program.presentation.bullets.improvement'),
    },
    {
      icon: bullet8weeks,
      label: I18n.t('academy.sommeil-program.presentation.bullets.days'),
    },
    {
      icon: bulletRhythm,
      label: I18n.t('academy.sommeil-program.presentation.bullets.rhythm'),
    },
    {
      icon: bulletClock,
      label: I18n.t('academy.sommeil-program.presentation.bullets.method'),
    },
    {
      icon: bulletSpecialists,
      label: I18n.t('academy.sommeil-program.presentation.bullets.support'),
    },
  ],
  howItWorks: [
    {
      icon: howStepByStep,
      title: I18n.t('academy.sommeil-program.presentation.howItWorks.steps.title'),
      label: I18n.t('academy.sommeil-program.presentation.howItWorks.steps.label'),
    },
    {
      icon: howAssistance,
      title: I18n.t('academy.sommeil-program.presentation.howItWorks.assistance.title'),
      label: I18n.t('academy.sommeil-program.presentation.howItWorks.assistance.label'),
    },
    {
      icon: howProgression,
      title: I18n.t('academy.sommeil-program.presentation.howItWorks.progression.title'),
      label: I18n.t('academy.sommeil-program.presentation.howItWorks.progression.label'),
    },
  ],
  whyIsItEfficient: [
    {
      icon: whyPhysics,
      title: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.physics.title'),
      label: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.physics.label'),
    },
    {
      icon: whyMental,
      title: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.mental.title'),
      label: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.mental.label'),
    },
    {
      icon: whyRhythm,
      title: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.rhythm.title'),
      label: I18n.t('academy.sommeil-program.presentation.whyIsItEfficient.rhythm.label'),
    },
  ],
  faq: [
    {
      items: [
        {
          text: I18n.t('academy.sommeil-program.faq.faq1.title'),
          details: I18n.t('academy.sommeil-program.faq.faq1.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq2.title'),
          details: I18n.t('academy.sommeil-program.faq.faq2.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq3.title'),
          details: I18n.t('academy.sommeil-program.faq.faq3.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq4.title'),
          details: I18n.t('academy.sommeil-program.faq.faq4.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq5.title'),
          details: I18n.t('academy.sommeil-program.faq.faq5.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq6.title'),
          details: I18n.t('academy.sommeil-program.faq.faq6.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq7.title'),
          details: I18n.t('academy.sommeil-program.faq.faq7.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq8.title'),
          details: I18n.t('academy.sommeil-program.faq.faq8.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq9.title'),
          details: I18n.t('academy.sommeil-program.faq.faq9.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq10.title'),
          details: I18n.t('academy.sommeil-program.faq.faq10.content'),
        },
        {
          text: I18n.t('academy.sommeil-program.faq.faq11.title'),
          details: I18n.t('academy.sommeil-program.faq.faq11.content'),
        },
      ],
    },
  ],
})

export default buildData

import React, { FC } from 'react'
import './styles.sass'

type IProps = {
  handleToggle: (bool: boolean) => void,
}

const Index: FC<IProps> = (props: IProps) => {
  const { handleToggle } = props

  return (
    <label className="switch">
      <input type="checkbox" defaultChecked={true} onChange={e => handleToggle(e.target.checked)} />
      <span className="slider round" />
    </label>
  )
}

export default Index

import { connect } from 'react-redux'
import {
  beginCheckout,
  addToCart,
  purchase,
  createDelivery,
  startUnifiedCheckout,
  getPaymentMethods,
  getLastConsultation,
  getAuthProfile,
  getPostConsultationRecommendations,
  getTaxons,
	getProducts,
  getPharmacies,
  storeUnifiedCheckoutPharmacist,
  storePatientCurrentCountry,
  setDrugsFunnelChoice,
  setCurrentStore,
  getRecommendedProducts
} from 'actions'
import T from 'types'
import UnifiedCheckout from 'page/Pages/UnifiedCheckout'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

const mapStateToProps = ({
  auth: { profile },
  storefront,
  unifiedCheckout,
  pharmacy: { collectionPharmacies }
}: T.Store) => ({
    profile,
    storefront,
    collectionPharmacies,
  unifiedCheckout,
  })

const mapDispatchToProps = (dispatch: Function) => ({
  getAuthProfile: () => dispatch(getAuthProfile()),
  getPostConsultationRecommendations: (consultationId: number) => dispatch(getPostConsultationRecommendations(consultationId)),
  addToCart: (variant: T.Variant, onSuccess: Function) =>
    dispatch(addToCart(variant, onSuccess)),
  beginCheckout: (variants: T.CheckoutVariantChoice[], onSuccess: Function) =>
    dispatch(beginCheckout(variants, onSuccess)),
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: () => void) =>
    dispatch(purchase(token, data, onSuccess)),
  createDelivery: (data: T.Forms.ShippingAddress, onSuccess: Function) =>
    dispatch(createDelivery(data, onSuccess)),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  startUnifiedCheckout: (onSuccess: Function) => dispatch(startUnifiedCheckout(onSuccess)),
  getTaxons: () => dispatch(getTaxons()),
  getProducts: () => dispatch(getProducts()),
  getPharmacies: () => dispatch(getPharmacies()),
  storeUnifiedCheckoutPharmacist: (pharmacistId: number) =>
    dispatch(storeUnifiedCheckoutPharmacist(pharmacistId)),
  storePatientCurrentCountry: (frenchCitizen: boolean) =>
    dispatch(storePatientCurrentCountry(frenchCitizen)),
  setDrugsFunnelChoice: (forPrescriptions: boolean) =>
    dispatch(setDrugsFunnelChoice(forPrescriptions)),
  setCurrentStore: (prescriptionId: number, orderNumber: string) =>
    dispatch(setCurrentStore(prescriptionId, orderNumber)),
  getRecommendedProducts: (orderNumber: string, consultationId: number, pharmacistId: number | undefined) =>
    dispatch(getRecommendedProducts(orderNumber, consultationId, pharmacistId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedCheckout)

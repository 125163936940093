import React, { Fragment } from 'react'
import { MoonLoader } from 'react-spinners'
import cx from 'classnames'
import T from 'types'
import './styles.sass'

type IProps = {
  storeName: string
  redirectionLabel: string
  spinnerColor?: string
  icon: string
  className?: string
}

const StoreRedirectionScreen = (props: IProps) => (
  <div className={cx("store-redirection-screen-container", props.className)}>
    <div className="store-redirection-screen p-4">
      <img src={props.icon} className="img-fluid" />
      <h3 className="store-redirection-name mt-3">{props.storeName}</h3>
      <h4 className="store-redirection-label">
        {props.redirectionLabel}
      </h4>
      <div className="store-redirection-loader">
        <MoonLoader loading={true} size={60} color={props.spinnerColor || '#44962B'} />
      </div>
    </div>
  </div>
)

export default StoreRedirectionScreen

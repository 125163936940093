import React, { useContext } from 'react'
import { Block, PostConsultationDetails } from 'components'
import { MainContainer } from 'containers'
import { last, filter, sortBy, isNil } from 'lodash'
import { withNavigation } from 'components/Workflow'
import T from 'types'

const UCTreatmentPlan = (props: T.UnifiedCheckoutStepProps) => {

  const { consultation } = props
  return isNil(consultation) ? null : (
    <MainContainer>
      <div className="page pharmacy">
        <div className="home-container">
          <div className="infos-container">
            <h3>Votre plan de traitement</h3>
            <div className="row">
              <div className="col-lg-7">
                <PostConsultationDetails
                  {...props}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default withNavigation(UCTreatmentPlan)
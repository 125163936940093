import { connect } from 'react-redux'
import { toggleSidebar } from 'actions'
import Navbar from 'components/Navbar'
import T from 'types'

const mapStateToProps = ({ auth: { profile }, ux: { sidebarActive } }: T.Store) => ({
  profile,
  sidebarActive,
})

const mapDispatchToProps = (dispatch: Function) => ({
  toggleSidebar: (value: boolean) => dispatch(toggleSidebar(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)

import React, { FC, useState } from 'react'
import T from 'types'
import { Workflow } from 'components'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import UCDrugsAddress from './UCDrugsAddress'
import UCDrugsPrescriptions from './UCDrugsPrescriptions'
import UCDrugsFunnelOptions from './UCDrugsFunnelOptions'
import { withNavigation } from 'components/Workflow'
import { get, isNil } from 'lodash'

const UCDrugs: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {

  const { profile, navigation } = props
  const { steps, stepIndex, goPrevious, goNext } = navigation
  const drugsSteps: T.WorkflowStep[] = [
    {
      path: 'adresse',
      show: (props: T.UnifiedCheckoutStepProps) => !isNil(get(profile, 'address')),
      comp: <UCDrugsAddress {...props} />,
    },
    {
      path: 'prescriptions',
      comp: <UCDrugsPrescriptions {...props} />,
    },
    {
      path: 'options',
      comp: <UCDrugsFunnelOptions {...props} />,
    },
  ]

  return (
    <UCStep>
      <Workflow steps={drugsSteps} complete={goNext} goBack={goPrevious} level={2} stepperStyle="progressionBar" />
    </UCStep>
  )
}

export default withNavigation(UCDrugs)

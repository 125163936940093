import React, { Component } from 'react'
import { MoonLoader } from 'react-spinners'
import classnames from 'classnames'
import { isNil } from 'lodash'
import { NavLink } from 'react-router-dom'

interface IProps {
  children: string | JSX.Element | (string | JSX.Element)[]
  classname?: string
  variant?: string
  disabled?: boolean
  type?: string
  loading?: boolean
  loaderColor?: string // default is #ffffff
  href?: string
  target?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export default class Button extends Component<IProps> {
  public render() {
    const { children, classname, variant, href, loading, loaderColor, onClick, target, type } = this.props
    const disabled = this.props.disabled || loading

    if (!isNil(href)) {
      return (
        <a href={href} className={classnames('btn btn-primary', classname, { disabled })} target={target}>
          {children}
        </a>
      )
    }

    const variantClass = variant || 'btn-primary'
    const variantToLoaderColor: { [key: string]: string } = {
      'btn-primary': '#fff',
      'btn-secondary': '#000',
      'btn-outline-primary': '#000',
      'btn-outline-secondary': '#000',
    }
    const actualColor = loaderColor || variantToLoaderColor[variantClass] || '#000'

    return (
      // @ts-ignore
      <button
        className={classnames('btn charles-btn', variantClass, classname, { disabled, loading })}
        {...{ disabled, onClick, type }}
      >
        {loading && <div className="button-loader loading">
          <MoonLoader loading={true} size={15} color={actualColor} />
        </div>}
        {children}
      </button>
    )
  }
}

import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import Media from 'react-media'
import { find, head } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { SvgIcon, Button } from 'components'
import T from 'types'
import { variantOptionsAsObject, productFrequencyForVariant } from 'utils/models/variants'
import oneMonthIcon from 'assets/icons/one-month-leaf.svg'
import threeMonthesIcon from 'assets/icons/three-month-seedling.svg'
import sixMonthesIcon from 'assets/icons/6-months-pagelines.svg'
import recommendedIcon from 'assets/images/dessinsyellow.svg'

type IProps = {
  variant: any
  renewableVariant: any
  selected?: number
  recommendedVariant?: number
  recommendedProduct?: boolean
  handleClick: (v: T.CheckoutVariantChoice) => void
}

type IState = {}
export default class VariantCard extends Component<IProps, IState> {
  static defaultProps = {
    recommendedProduct: false,
  }
  constructor(props: IProps) {
    super(props)
  }

  boxIcon = (boxCount: number) => {
    // two equals to check only value not type to have correct answer if 1 is string
    switch (boxCount) {
      case 3:
        return threeMonthesIcon
        break
      case 6:
        return sixMonthesIcon
        break
      case 1:
      default:
        return oneMonthIcon
        break
    }
  }

  handleClickVariant = (variant: T.Variant) => {
    const { handleClick } = this.props

    const variantOptions = variantOptionsAsObject(variant)
    // @ts-ignore
    const shouldSubscribe = variantOptions['subscribable'] === 'subscribable'
    const subscriptionFrequency = productFrequencyForVariant(variant)
    const options =
      shouldSubscribe && subscriptionFrequency
        ? {
          subscribe: shouldSubscribe,
          subscription_frequency_id: subscriptionFrequency.id,
          delivery_number: 1000000,
        }
        : undefined
    handleClick({
      options,
      variant: variant,
      quantity: 1,
    })
  }

  render() {
    const {
      variant,
      renewableVariant,
      selected,
      recommendedProduct,
      recommendedVariant,
    } = this.props
    const quantityOptionValue = find(
      variant.optionValues,
      optionValue => optionValue.optionType.name === 'pills-per-box',
    )
    const boxCount = I18n.t('storefront.phytotherapy.box', { count: quantityOptionValue.name })
    const boxIcon = this.boxIcon(parseInt(quantityOptionValue.name, 10))
    const variantSelected = selected === variant.id
    const renewableVariantSelected = selected === renewableVariant.id
    const isRecommended = recommendedVariant === variant.id
    return (
      <div className="variant-card">
        <div
          className={cx('variant-card-inner block-content px-3 py-5', {
            'recommended-variant': isRecommended && recommendedProduct,
            'most-popular-variant': isRecommended && !recommendedProduct,
          })}
        >
          <div className="variant-recommended variant-recommended-text">
            { recommendedProduct ? I18n.t('phytotherapy.recommendedBy') : 'Le plus populaire'}
          </div>
          { isRecommended && <SvgIcon classname="variant-recommended-svg" icon={recommendedIcon} /> }
          <div
            className={cx(
              'variant-card-header mb-4 d-flex align-items-center justify-content-center',
              { 'mt-4': isRecommended },
            )}
          >
            <SvgIcon classname="img-responsive variant-img mr-2" icon={boxIcon} />
            <div>{boxCount}</div>
          </div>
          <div className="variant-card-footer d-flex flex-column align-items-stretch">
            <Button
              variant={renewableVariantSelected ? 'btn-c-rust' : 'btn-primary'}
              classname={cx('mt-3 px-0')}
              onClick={() => this.handleClickVariant(renewableVariant)}
            >
              <span>
                <span className="bold">{renewableVariant.displayPrice} </span>
                {renewableVariantSelected ? 'Retirer' : 'renouvelable'}
              </span>
            </Button>
            <div className="mt-1 text-center">
              <span className="variant-subscription-promotion">-10%</span>{' '}
              <span className="variant-subscription-info">
                Pour ne jamais oublier votre traitement
              </span>
            </div>
            <Button
              variant={variantSelected ? 'btn-outline-c-rust' : 'btn-outline-primary'}
              classname={cx('mt-3 px-0')}
              onClick={() => this.handleClickVariant(variant)}
            >
              <span>
                <span className="bold">{variant.displayPrice} </span>
                {variantSelected ? 'Retirer' : 'livraison unique'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import classnames from 'classnames'
import CreditCardIcon from './CreditCardIcon'
import { SvgIcon } from '..'
import cross from 'assets/icons/icon-cross.svg'
import T from 'types'
import './styles.sass'

interface IProps {
  creditCard: T.CreditCard,
  classname?: string,
  onDelete?: (id: number) => void,
}

class CreditCard extends Component<IProps> {
  public render() {
    const { creditCard, classname, onDelete } = this.props

    return (
      <div className={classnames('credit-card', classname)}>
        <span className="card">
          <CreditCardIcon type={creditCard.ccType}/>
          <span className="number">•••• {creditCard.lastDigits}</span>
        </span>
        <span className="exp">Expire {creditCard.month}/{creditCard.year}</span>
        <SvgIcon
          icon={cross}
          classname="delete-icon"
          onClick={() => onDelete && onDelete(creditCard.id)}
        />
      </div>
    )
  }
}

export default CreditCard

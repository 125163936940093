import React, { FC } from 'react'
import T from 'types'
import { isNil, isEmpty } from 'lodash'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import "./styles.sass"
import { Dropdown, Form } from 'react-bootstrap'
import { withNavigation } from 'components/Workflow'

type IProps = {
  goNext: () => void,
  goPrevious: () => void,
  stepIndex: number,
  steps: T.WorkflowStep[],
}

const UCPayment: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext } } = props

  return (
    <UCStep>
      <h2>ceci est le test Payment</h2>
      <button onClick={goPrevious}>previous</button>
      <button onClick={goNext}>next</button>

      <div className='checkout-container-bloc'>
        <div className='block-content'>
          <div className='payment-checkout mr-3 ml-3'>
            <div className='payment-checkout-block mb-5'>
              <div className='payment-checkout-block-title'>
                <SvgIcon icon={getIcon('clinic.pill')} classname="" />
                <div className='block-title-text'>Mes médicaments</div>
              </div>
              <div className='d-flex payment-checkout-separator w-100' />
              <div className='payment-checkout-content'>
                <div className='payment-checkout-content-dropdown-div'>
                  <div className='d-flex mb-1'>Tadalafil 5mg</div>
                  <select className="payment-checkout-content-dropdown">
                    <option className='d-flex justify-center dropdown-option'>Tadalafil 5mg</option>
                  </select>
                </div>
                <div className='payment-checkout-content-subtitle'>Livraison au point relais Memory phone</div>
                <div className='payment-checkout-content-price'>
                  <span>Sous-total</span>
                  <span>XX,xx€</span>
                </div>
              </div>
            </div>

            <div className='payment-checkout-block mb-5'>
              <div className='payment-checkout-block-title'>
                <SvgIcon icon={getIcon('clinic.pill')} classname="" />
                <div className='block-title-text'>Mes médicaments</div>
              </div>
              <div className='d-flex payment-checkout-separator w-100' />
              <div className='payment-checkout-content'>
                <div className='d-flex mb-1'>Tadalafil 5mg</div>
                <div className='payment-checkout-content-dropdown-div'>
                  <div className='d-flex mb-1'>Tadalafil 5mg</div>
                  <select className="payment-checkout-content-dropdown">
                    <option className='d-flex justify-center dropdown-option'>Tadalafil 5mg</option>
                  </select>
                </div>
                <div className='payment-checkout-content-subtitle'>Livraison au point relais Memory phone</div>
                <div className='payment-checkout-content-price'>
                  <span>Sous-total</span>
                  <span>XX,xx€</span>
                </div>
              </div>
            </div>

            <div className='payment-checkout-block mb-5'>
              <div className='payment-checkout-block-title'>
                <SvgIcon icon={getIcon('clinic.pill')} classname="" />
                <div className='block-title-text'>Mes médicaments</div>
              </div>
              <div className='d-flex payment-checkout-separator w-100' />
              <div className='payment-checkout-content'>
                <div className='d-flex mb-1'>Tadalafil 5mg</div>
                <div className='payment-checkout-content-dropdown-div'>
                  <div className='d-flex mb-1'>Tadalafil 5mg</div>
                  <select className="payment-checkout-content-dropdown">
                    <option className='d-flex justify-center dropdown-option'>Tadalafil 5mg</option>
                  </select>
                </div>
                <div className='payment-checkout-content-subtitle'>Livraison au point relais Memory phone</div>
                <div className='payment-checkout-content-price'>
                  <span>Sous-total</span>
                  <span>XX,xx€</span>
                </div>  
              </div>
            </div>

            <div className='d-flex payment-checkout-separator-black w-100' />

            <div className='payment-checkout-total-price'>
              <span>Sous-total</span>
              <span>XX,xx€</span>
            </div>

            <Button classname='btn btn-primary w-100'>Valider mon panier</Button>

          </div>
        </div>
      </div>
      
    </UCStep>
  )
}

export default withNavigation(UCPayment)

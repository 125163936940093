import React from 'react'
import T from 'types'
import { SectionArguments } from 'page/common/ConsultationArguments'

const sections: T.ISection[] = [
  {
    title: 'Mia, la santé repensée',
    items: [
      { icon: 'clinic.doctor', text: "Accompagnement par nos thérapeutes sexologues 5j/7" },
      { icon: 'clinic.clock', text: "90% des patientes constatent une amélioration durable" },
      { icon: 'clinic.lock', text: "Confidentiel, discret et à votre rythme depuis chez vous" },
      { icon: 'clinic.checklist', text: "Thérapie complète issue de 25 ans d'expérience en santé sexuelle" },
    ],
  }, {
    title: 'Mia respecte votre vie privée',
    items: [
      { icon: 'clinic.shield', text: 'Vos données sont sécurisées et soumises au secret médical' },
      { icon: 'clinic.hardware', text: 'Charles utilise un Hébergeur Agréé de Données de Santé' },
    ],
  },
]
export default () => (
  <SectionArguments sections={sections} />
)

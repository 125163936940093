import React, { Component, Ref, RefObject } from 'react'
import { Button, SvgIcon } from 'components'
import { isNil } from 'lodash'
import { Modal } from 'react-bootstrap'
import './styles.sass'
import T from 'types'
import * as M from 'constants/messages'
import { getIcon } from 'assets/icons'
import { SUPPORT_PHONE_NUMBER } from 'constants/config'
import { MoonLoader } from 'react-spinners'

interface IProps {
  secureChallenge: T.SecureChallenge,
  endSecureChallenge: (success: boolean) => void
}
/* iFrame discussion
- it's up to the loaded page to let us display it in an iframe using headers.
we could preventively detect if the page is iframe-able before trying :
https://stackoverflow.com/questions/15273042/catch-error-if-iframe-src-fails-to-load-error-refused-to-display-http-ww
This way we can raise sentry errors to get a sense of the errors count. This is a payment workflow after all...
- no built-in way of catching loading errors :
https://github.com/krakenjs/zoid/issues/85
*/
/* Update
Paypal forces top level navigation, which supersedes the iFrame, and have the entire page load the paypal interface
iFrame's attribute sandbox can help prevent that, but Paypal does not work if top level is disabled.
*/
interface IState {
  openedWindow: Window | null
  state: 'started' | 'blocked' | 'ongoing' | 'finished'
  success: boolean
}

class SecureChallenge extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      openedWindow: null,
      state: 'started',
      success: false
    }
  }

  public componentDidMount() {
    const { secureChallenge } = this.props

    window.addEventListener("message", this.receivedMessage, false)

    const openedWindow = window.open(secureChallenge.gatewaySecureModeRedirectUrl, "_blank")
    if (isNil(openedWindow)) {
      this.setState({ state: 'blocked' })
    }
    else {
      this.setState({ openedWindow, state: 'ongoing' })
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("message", this.receivedMessage, false)
  }

  private receivedMessage = (event: MessageEvent) => {
    const closeChallenge = this.closeSecureChallenge
    switch (event.data) {
      case M.CLOSE_IFRAME_SUCCESS:
        this.setState({ success: true, state: 'finished' }, () => closeChallenge(true, true))
        return;
      case M.CLOSE_IFRAME_FAILURE:
        this.setState({ success: false, state: 'finished' }, () => closeChallenge(false, true))
        return;
    }
  }

  private closeSecureChallenge = (success: boolean, delayClosingPopup: boolean) => {
    const { endSecureChallenge } = this.props
    const { openedWindow } = this.state
    const delay = delayClosingPopup ? 5000 : 0
    setTimeout(() => {
      if (!isNil(openedWindow)) {
        openedWindow.close()
        this.setState({ openedWindow: null })
      }
      endSecureChallenge(success)
    }, delay)
  }

  private focusOpenedWindow = () => {
    if (!isNil(this.state.openedWindow)) {
      this.state.openedWindow.focus()
    }
  }

  public render() {
    return (
      <Modal
        show={true}
        size="lg"
        onHide={() => this.closeSecureChallenge(false, false)}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="max-width-500"
        contentClassName="block-content"
      >
        <Modal.Header className="border-0 justify-content-center">
          <Modal.Title className="h3">Paiement en ligne</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="secure-challenge-popup">
            {this.state.state === 'started' && this.renderWillTransfer()}
            {this.state.state === 'blocked' && this.renderBlockedPopup()}
            {this.state.state === 'ongoing' && this.renderOngoingValidation()}
            {this.state.state === 'finished' && this.renderDidReturnFromValidation()}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  public openBankInterface = () => {
    const { secureChallenge } = this.props
    const openedWindow = window.open(secureChallenge.gatewaySecureModeRedirectUrl, "_blank")
    if (isNil(openedWindow)) {
      this.setState({ state: 'blocked' })
    }
    else {
      this.setState({ openedWindow, state: 'ongoing' })
    }
  }

  public renderBlockedPopup = () => (
    <>
      <SvgIcon
        icon={getIcon('payment.pointer')}
        classname="secure-challenge-svg cursor-pointer"
        onClick={() => this.openBankInterface()}
      />
      <div
        className="cursor-pointer"
        onClick={() => this.openBankInterface()}
      >
        Cliquez sur “continuer” pour accéder à votre service de paiement en ligne.
      </div>
      <Button
        variant="btn-primary"
        classname="btn-block mt-3"
        onClick={() => this.openBankInterface()}
      >
        Continuer sur ma banque
      </Button>
    </>
  )

  public renderWillTransfer = () => (
    <>
      <div className="secure-challenge-loader">
        <MoonLoader loading={true} size={32} color="#000" />
      </div>
      Vous allez être redirigé vers votre service de paiement en ligne
      <Button
        variant="btn-outline-primary"
        classname="btn-block mt-3"
        onClick={() => this.closeSecureChallenge(false, false)}
      >
        Annuler le paiement
      </Button>
    </>
  )

  public renderOngoingValidation = () => (
    <>
      <div className="secure-challenge-loader">
        <MoonLoader loading={true} size={32} color="#000" />
      </div>
      Vous devez confirmer votre paiement en ligne sur votre service de paiement avant de continuer
      <Button
        variant="btn-outline-primary"
        classname="btn-block mt-3"
        onClick={() => this.closeSecureChallenge(false, false)}
      >
        Annuler le paiement
      </Button>
      <Button
        variant="btn-primary"
        classname="btn-block"
        onClick={() => this.focusOpenedWindow()}
      >
        Retourner sur ma banque
      </Button>
    </>
  )

  public renderDidReturnFromValidation = () => {
    const { success } = this.state
    return (
      <>
        {success ? (
          <>
            <SvgIcon icon={getIcon('payment.success')} classname="secure-challenge-svg" />
            Votre paiement a été validé. Vous allez être redirigé pour la suite du questionnaire. (Si vous n’êtes pas redirigé après 5 secondes cliquez sur “continuer”)
            <Button
              variant="btn-primary"
              classname="btn-block mt-3"
              onClick={() => this.closeSecureChallenge(success, false)}
            >
              Continuer
            </Button>
          </>
        ) : (
          <>
            <SvgIcon icon={getIcon('payment.failure')} classname="secure-challenge-svg" />
            Votre paiement a échoué. Essayer à nouveau pour continuer. Si le problème persiste appelez nous au <strong><a href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`} className="phone-number-info">
              {SUPPORT_PHONE_NUMBER}
            </a></strong>
            <Button
              variant="btn-primary"
              classname="btn-block mt-3"
              onClick={() => this.closeSecureChallenge(success, false)}
            >
              Recommencer
            </Button>
          </>
        )}
      </>
    )
  }
}

export default SecureChallenge

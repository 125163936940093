import { includes, last, sortBy, filter, get } from 'lodash'
import moment from 'constants/moment'
import T from 'types'

export const isCharlesTherapy = (): boolean => {
  const specialty = specialtyFromUrl()
  return includes(['charles_couple_therapy', 'charles_psycho_sexo'], specialty)
}

export const defaultSpecialty = (lastSpecialty?: string | undefined): string | undefined => {
  const consultationSpeciality = specialtyFromUrl()
  return isCharlesTherapy() ? consultationSpeciality : lastSpecialty
}

export const specialtyFromUrl = (): string => {
  const splittedLocation = window.location.hash.split('/')
  return splittedLocation[splittedLocation.length - 1]
}
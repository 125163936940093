import { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";

export type DeviceListing = {
  devices: MediaDeviceInfo[]
  refreshDevices: () => void
}

const useDevicesListing = (): DeviceListing => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

  const checkDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const nonEmptyDevices = devices.filter(device => !isEmpty(device.label))
        setDevices(nonEmptyDevices)
      })
  }, [setDevices])

  useEffect(() => {
    checkDevices()
  }, [])


  return {
    devices,
    refreshDevices: checkDevices
  }
};

export default useDevicesListing

import React from 'react'
import { I18n } from 'react-redux-i18n'
import { withRouter, RouteComponentProps } from 'react-router'
import { last, isUndefined, isNil, camelCase, get } from 'lodash'
import { PaymentFormV2 } from 'components'
import build from 'redux-object'
import { TARGET_PLATFORM } from 'constants/config'

import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import tracker from 'utils/trackers'

interface IProps extends RouteComponentProps<any> {
  academy: T.AcademyStore
  enrollment: T.Enrollment | undefined
  auth: T.Auth
  storefront: T.Storefront
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: () => void) => void
  startRenewEnrollment: (enrollmentId: number) => void
  getPaymentMethods: () => void
}

type IState = {}

class QuickPayment extends React.Component<IProps, IState> {
  componentDidMount() {
    const { enrollment, startRenewEnrollment } = this.props
    !isUndefined(enrollment) && startRenewEnrollment(enrollment.id)
  }

  render() {
    const { auth, academy, storefront, getPaymentMethods } = this.props
    const { profile } = auth
    const { loading } = academy
    const order = this.findOrder() as T.Cart
    return (
      <div className="quick-payment">
        {!isUndefined(order) && (
          <>
            {order.state === 'complete' ? (
              ''
            ) : (
              <PaymentFormV2
                patientAttributes={profile}
                submitPayment={this.handleValidatePayment}
                loading={loading}
                price={parseFloat(order.total)}
                cgu={I18n.t(`paymentForm.fields.labels.cguAcademy`)}
                recurringPayment={true}
                storefront={storefront}
                getPaymentMethods={getPaymentMethods}
              />
            )}
          </>
        )}
      </div>
    )
  }

  findOrder = (): T.Cart | undefined => {
    const { academy } = this.props
    const orders = build(academy, 'cart') as T.Cart[]
    return last(orders)
  }

  handlePaymentSuccess = () => {
    const { enrollment } = this.props
    const order = this.findOrder() as T.Cart

    if (!isUndefined(enrollment)){
      const variant = order.variants[0]
      const subscriptionEvent: string = get(enrollment, 'state') === "paused" ? "manual_renew" : "resume"
      tracker.trackDidPayAcademyProgram(order.number, order.total, enrollment.program, variant, subscriptionEvent, order.orderType)
    }

    setTimeout(() => {
      document.location.reload(true)
    }, 1000)
  }

  handleValidatePayment = (data: T.PaymentPayload) => {
    const { purchase } = this.props
    const order = this.findOrder() as T.Cart
    purchase({ orderToken: order.token }, data, this.handlePaymentSuccess)
  }
}

export default withRouter(QuickPayment)

import T from 'types'
import * as A from 'constants/actions'
import { isUndefined } from 'lodash'
import { AnyAction } from 'redux'
import store from '../store'

export const hideNotification = () => ({
  type: A.UX_HIDE_NOTIFICATION,
})

export const showNotification = (classNotification: string, value: string) => ({
  classNotification,
  value,
  type: A.UX_SHOW_NOTIFICATION,
})

export const hidePopup = () => ({
  type: A.UX_HIDE_POPUP,
})

export const showPopup = ({
  classPopup,
  popupTitle,
  popupType,
  popupProps,
}: {
  classPopup: string
  popupTitle: string
  popupType: string
  popupProps: any
}) => ({
  type: A.UX_SHOW_POPUP,
  payload: {
    classPopup,
    popupTitle,
    popupType,
    popupProps,
  },
})

export const toggleSidebar = (value: boolean) => ({
  value,
  type: A.UX_TOGGLE_SIDEBAR,
})

export const dropdownSidebarToggleElement = (value: string) => ({
  payload: value,
  type: A.TOGGLE_DROPDOWN_SIDEBAR_ELEMENT,
})

export const redirectAfterLogin = (redirectUrlAfterLogin?: string) => {

  if (!isUndefined(redirectUrlAfterLogin) &&
    redirectUrlAfterLogin !== '/' &&
    redirectUrlAfterLogin !== '/signin') {
    return {
      payload: {
        redirectUrlAfterLogin
      },
      type: A.REDIRECT_AFTER_LOGIN,
    }
  }
}

export const storeLastFocusedLocation = (lastFocusedLocation?: any) => {
  return {
    payload: {
      lastFocusedLocation
    },
    type: A.STORE_LAST_FOCUSED_LOCATION,
  }
}

export const redirectedToUrlAfterLogin = () => ({
  type: A.REDIRECTED_AFTER_LOGIN,
})

export const startSecureChallenge = (secureChallenge: T.SecureChallenge) => ({
  type: A.START_3DS_CHALLENGE,
  payload: secureChallenge
})

export const endSecureChallenge = (success: boolean) => {
  const uxState = store.getState().ux
  if (uxState.secureChallenge) {
    const endActionPayload = success ?
      uxState.secureChallenge.successAction :
      uxState.secureChallenge.failureAction
    if (!isUndefined(endActionPayload)) {
      store.dispatch(endActionPayload())
    }
    return {
      type: A.END_3DS_CHALLENGE,
      payload: success
    }
  }
  else {
    return {
      type: A.MISPLACED_END_3DS_CHALLENGE
    }
  }
}

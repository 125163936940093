import { each, isNull, isUndefined } from "lodash"

const BIRTH_DATE = "birthDate"
const EMAIL = "email"
const FIRST_NAME = "firstName"
const LAST_NAME = "lastName"
const PHONE_NUMBER = "phoneNumber"

const PATIENT_ATTRIBUTES = [BIRTH_DATE, EMAIL, FIRST_NAME, LAST_NAME, PHONE_NUMBER]

export const differenceFound = (input: any, target: any): boolean => {
  let found = false
  each(PATIENT_ATTRIBUTES, key => {
    const arg1 = input[key]
    const arg2 = target[key]
    if (valid(arg1) && valid(arg2)) {
      if (arg1 !== arg2) {
        found = true
      }
    }
  })
  return found
}

const valid = (input: any) => {
  return isUndefined(input) || isNull(input) ? false : true
}

import { map } from 'lodash'

export const variantsOptions = (data: any) => {
  const options = [{}]
  map(data, (item: any) =>
    options.push({
      label: item.conditioningPresentation,
      value: item.variantId
    })
  )
  return options
}

import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Model, Question } from 'survey-core';
import { isEmpty, includes, map, some } from 'lodash'
import { surveyCss, buildSurvey } from 'page/common/survey';
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import { findSurveyPage } from 'utils/survey'
import { LayoutStep, LayoutMain, LayoutSide } from './LayoutStep'
import { Survey } from 'survey-react-ui'

export interface FCSurveyProps extends BookingConsultationStepProps, RouteComponentProps<any> {
  questionName: string, // TODO CHECK this
  prefix: string,
  onComplete: (survey: Model) => void,
}

interface IState {
  isComplete: boolean,
}

class BCSurvey extends Component<FCSurveyProps, IState> {
  unlistenHistory: any
  model: Model

  constructor(props: FCSurveyProps) {
    super(props)

    this.state = {
      isComplete: false,
    }
    const { survey, prefix } = props
    const currentModel = buildSurvey(survey, prefix)
    this.model = currentModel
  }

  public componentDidMount() {
    const { history } = this.props
    this.unlistenHistory = history.listen((location: any, action: any) => {
      if (action === 'POP') {
        const pageName = location.pathname.split('/').pop()
        this.focusPage(pageName)
      }
    })
  }

  /*
  Should create dedicated component and display only when survey is actually loaded,
  instead of using this deprecated lifecyle method. technicalDebt += 0.1 DOGE
  */
  public UNSAFE_componentWillReceiveProps(nextProps: FCSurveyProps) {
    const { survey, prefix } = nextProps
    this.model = buildSurvey(survey, prefix)
  }

  public focusPage(name: string) {
    const { setSurveyQuestionName } = this.props

    if (this.model) {
      const page = this.model.getPageByName(name)

      if (page) {
        const questionName = page.questions[0].name
        setSurveyQuestionName(questionName)
      }
    }
  }

  public shouldComponentUpdate(nextProps: FCSurveyProps): boolean {
    // TODO Check here if needs to update based on route change
    const { match: oldMatch } = this.props
    const { match: newMatch } = nextProps

    const shouldUpdate = (nextProps.questionName !== this.props.questionName
      || nextProps.survey !== this.props.survey)
    return shouldUpdate
  }

  public componentWillUnmount() {
    // this.unlistenHistory()
  }

  private onValueChanged(result: any) {
    return void 0
  }

  private onComplete(survey: Model) {
    const { setSurveyObject, onComplete } = this.props
    setSurveyObject(survey.data)
    const validSurvey = survey.data.adult !== 'no'
    if (validSurvey) {
      onComplete && onComplete(survey)
    } else {
      this.setState({ isComplete: true })
    }
  }

  private findPage(survey: Model): string | undefined {
    const { questionName } = this.props
    const page = findSurveyPage(survey, questionName)

    return page && page.name
  }

  private renderSurvey() {
    const { isComplete } = this.state
    const { survey, navigation } = this.props

    if (isEmpty(survey)) {
      return <Fragment />
    }

    return (
      <LayoutStep>
        <LayoutMain>
          <Survey
            data={survey}
            css={surveyCss}
            currentPage={this.findPage(this.model)}
            model={this.model}
            onValueChanged={(result: any) => this.onValueChanged(result)}
            onComplete={(survey: Model) => this.onComplete(survey)}
            goToPreviousStep={() => navigation.goPrevious()}
          // onAfterRenderQuestion={
          //   (sender: Model, options: any) =>
          //     this.onAfterRenderQuestion(sender, options)
          // }
          />
        </LayoutMain>
      </LayoutStep>
    )
  }

  public render() {

    return (
      <div className="onboarding-container-bloc">
        {this.renderSurvey()}
      </div>
    )
  }
}

export default withNavigation(withRouter(BCSurvey))

import { connect } from 'react-redux'
import { uploadMedicalDocument, setFocusedPrescription, getPrescriptions, showPopup } from 'actions'
import MedicalDocuments from 'page/Pages/MedicalDocuments'
import T from 'types'

const mapStateToProps = ({
  auth: { profile },
  consultations: { collection },
  prescriptions: { collectionPrescription },
}: T.Store) => ({
  profile,
  collection,
  collectionPrescription,
})

const mapDispatchToProps = (dispatch: Function) => ({
  uploadMedicalDocument: (data: FormData) => dispatch(uploadMedicalDocument(data)),
  getPrescriptions: () => dispatch(getPrescriptions()),
  setFocusedPrescription: (p: any) => dispatch(setFocusedPrescription(p)),
  showPopup: ({
    classPopup,
    popupTitle,
    popupType,
    popupProps,
  }: {
    classPopup: string
    popupTitle: string
    popupType: string
    popupProps: any
  }) =>
    dispatch(
      showPopup({
        classPopup,
        popupTitle,
        popupType,
        popupProps,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalDocuments)

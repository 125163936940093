import React, { Component } from 'react'
import { flatten, map, filter, has } from 'lodash'
import Scrollbar from 'react-scrollbars-custom'
import { Model } from 'survey-core'
import SvgIcon from '../SvgIcon'
import pencil from 'assets/icons/icon-pencil.svg'
import { decamelizeKeys } from 'humps'
import { findAnswerText } from 'utils/survey'
import './styles.sass'

interface IProps {
  survey: Model | any,
  passClick?: (name: string) => void,
}

class SurveyScrollBox extends Component<IProps> {
  private handleClick(name: string) {
    const { passClick } = this.props

    passClick && passClick(name)
  }

  private renderElement(item: any) {
    const { question, answer, name } = item

    return (
      <div key={`question-${name}`} className="question" >
        <p className="sb-question-title sb-question-summary">
          <span className="click-return" onClick={() => this.handleClick(name)}>
            <SvgIcon
              icon={pencil}
              classname="icon"
            />
          </span>
          <span>{question}</span>
        </p>
        <p className="sb-answer" dangerouslySetInnerHTML={{ __html: answer }} />
      </div >
    )
  }

  private buildSummary() {
    const survey = decamelizeKeys(this.props.survey) as Model
    const questions = flatten(map(survey.pages, page => page.elements))

    return filter(
      map(questions, (question: any) => {
        const answer = has(survey[question.name], 'question')
          ? survey[question.name].question
          : survey[question.name]

        return {
          answer: findAnswerText(survey, question.name, answer) || answer,
          question: question.title,
          name: question.name,
        }
      }),
      question => question.answer,
    )
  }

  public render() {
    const summary = this.buildSummary()
    return (
      <div className="sb-question-body">
        <Scrollbar style={{ width: '100%', height: '100%', minHeight: 400 }}>
          {map(summary, item => this.renderElement(item))}
        </Scrollbar>
      </div>
    )
  }
}

export default SurveyScrollBox

import React, { Component, Fragment } from 'react'
import { ClipLoader } from 'react-spinners'
import { Survey } from 'survey-react-ui'
import { Model } from 'survey-core'
import { surveyCss } from 'page/common/survey'

interface IProps {
  lastConsultSpeciality: string
  getSurveyAndSubmission: (specialty: string, onSuccess: () => void) => void,
  focusedSurvey?: any
  setSurveySubmission?: (surveyData: string) => void,
  handleFinishSurvey?: () => void,
}

class SubStepConsultationSurvey extends Component<IProps> {
  model: Model | undefined

  public componentDidMount() {
    const { getSurveyAndSubmission, lastConsultSpeciality } = this.props
    getSurveyAndSubmission(lastConsultSpeciality, () => {})
  }

  private onComplete(survey: Model) {
    const { setSurveySubmission, handleFinishSurvey } = this.props
    if(setSurveySubmission && handleFinishSurvey) {
      setSurveySubmission(survey.data)
      handleFinishSurvey()
    }
  }

  private renderSurvey() {
    const { focusedSurvey } = this.props
    if (focusedSurvey) {
      const model = new Model(focusedSurvey.survey)
      model.locale = 'fr' // set locale for survey translation
      model.showProgressBar = 'off' // show progress bar f  or survey (default is 'off')
      model.goNextPageAutomatic = true
      model.focusFirstQuestionAutomatic = true
      this.model = model

      return (
        <Survey
          data={focusedSurvey.survey}
          css={surveyCss}
          model={this.model}
          onComplete={(survey: Model) => this.onComplete(survey)}
        />
      )
    }
    return <ClipLoader loading={true} size={15} color='blue' />
  }

  public render() {
    return <div className="">{this.renderSurvey()}</div>
  }
}

export default SubStepConsultationSurvey


import React, { FC, Fragment } from 'react'
import T from 'types'
import { I18n } from 'react-redux-i18n'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'
import { getIcon } from 'assets/icons'
import { SvgIcon } from 'components'
import schema from 'utils/models/schemas/address'
import { Formik } from 'formik'
import countries from 'constants/iso-countries'
import { sortBy, map, get } from 'lodash'
import { Col, Form } from 'react-bootstrap'
import { Button } from 'components'
import "./style.sass"

interface Country {
  iso: string
  name: string
}

const UCDelivery: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext }, profile } = props


  const handleSubmit = () => {
    //need data
  }

  const sortedCountries = (): Country[] => {
    const countryDictionary = countries.getNames("france")
    const countryIsos: string[] = Object.keys(countryDictionary)
    const countryObjects: Country[] = map(countryIsos, (key: string) => ({
      iso: key,
      name: countryDictionary[key],
    }))
    const sortedCountries = sortBy(countryObjects, (country: Country) => country.name)
    return sortedCountries
  }

  return (
    <UCStep>
      <h2>ceci est le test Delivery</h2>
      <button onClick={goPrevious}>previous</button>
      <button onClick={goNext}>next</button>

      <div className='checkout-container-bloc'>
        <div className='delivery-div'>
          <h3 className='text-center'>Veuillez renseigner vos coordonnées de livraison</h3>
          <div className='delivery-warning-phyto'>
            <SvgIcon icon={getIcon('shapes.roundedInfo')} />
            <div>Les points relais ne sont pas disponible pour les phytothérapies. Elles seront livrées à domicile.</div>
          </div>
          <div className="block-content-delivery-checkout block-content block-with-header-inside text-left w-100">
            <h4>Mes coordonnées de livraison</h4>
            <div className='payment-checkout-separator' />
            <Formik
              validationSchema={schema}
              onSubmit={() => { handleSubmit() }}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                firstname: get(profile, 'address.firstname') || get(profile, 'firstName'),
                lastname: get(profile, 'address.lastname') || get(profile, 'lastName'),
                address1: get(profile, 'address.address1') || '',
                address2: get(profile, 'address.address2') || '',
                city: get(profile, 'address.city') || '',
                zipcode: get(profile, 'address.zipcode') || '',
                countryIso: get(profile, 'address.countryIso') || 'FR',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-left">
                  <Fragment>
                    <Form.Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.firstname`)}</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.firstname}
                            placeholder={I18n.t(`personalInfo.address.placeholders.firstname`)}
                          />
                          <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik02">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.lastname`)}</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.lastname}
                            placeholder={I18n.t(`personalInfo.address.placeholders.lastname`)}
                          />
                          <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik03">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.address1`)}</Form.Label>
                          <Form.Control
                            type="text"
                            name="address1"
                            value={values.address1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.address1}
                            placeholder={I18n.t(`personalInfo.address.placeholders.address1`)}
                          />
                          <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik04">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.address2`)}</Form.Label>
                          <Form.Control
                            type="text"
                            name="address2"
                            value={values.address2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.address2}
                            placeholder={I18n.t(`personalInfo.address.placeholders.address2`)}
                          />
                          <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik05">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.zipcode`)}</Form.Label>
                          <Form.Control
                            type="text"
                            name="zipcode"
                            value={values.zipcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.zipcode}
                            placeholder={I18n.t(`personalInfo.address.placeholders.zipcode`)}
                          />
                          <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="validationFormik07">
                          <Form.Label>{I18n.t(`personalInfo.address.labels.countryIso`)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="countryIso"
                            value={values.countryIso}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.countryIso}
                            placeholder={I18n.t(`personalInfo.address.placeholders.countryIso`)}
                          >
                            {map(sortedCountries(), (country: Country) =>
                              <option
                                key={`country_option_${country.iso}`}
                                label={country.name}
                                value={country.iso}
                              />
                            )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">{errors.countryIso}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <div className='delivery-selection-div'>
                      <h4>Moyen de livraison pour les médicaments</h4>
                      <div className='payment-checkout-separator' />
                      <div className='delivery-selection-content'>
                        <Form.Row className='d-flex justify-content-between'>
                          <div className='delivery-selection-label'>
                            <Form.Check name="cguAccepted" id="validationFormik10">
                              <Form.Check.Input
                                type="radio"
                                className="sv-visuallyhidden"
                                name="Colissimo"
                              // checked={checkedSpecialty == specialty}
                              />
                              <Form.Check.Label>
                                <div className='d-flex'>
                                  <div>
                                    <span className='delivery-selection-text'>Colissimo
                                      <span className='delivery-selection-text-description'> (livraison en 2/3 jours ouvrés)</span>
                                    </span>
                                  </div>
                                  <span className='pl-4'>Inclus</span>
                                </div>
                              </Form.Check.Label>
                            </Form.Check>
                          </div>
                          <div className='delivery-selection-label'>
                            <Form.Check name="cguAccepted" id="validationFormik10">
                              <Form.Check.Input
                                type="radio"
                                className="sv-visuallyhidden"
                                name="DHL express"
                              // checked={checkedSpecialty == specialty}
                              />
                              <Form.Check.Label>
                                <div className='d-flex'>
                                  <div>
                                    <span className='delivery-selection-text'>DHL express
                                      <span className='delivery-selection-text-description'> (livraison en 2/3 jours ouvrés)</span>
                                    </span>
                                  </div>
                                  <span className='pl-4'>+6€</span>
                                </div>
                              </Form.Check.Label>
                            </Form.Check>
                          </div>
                        </Form.Row>

                        <Form.Row>

                          <div className='delivery-selection-label'>
                            <Form.Check name="cguAccepted" id="validationFormik10">
                              <Form.Check.Input
                                type="radio"
                                className="sv-visuallyhidden"
                                name="Point relais"
                              // checked={checkedSpecialty == specialty}
                              />
                              <Form.Check.Label>
                                <div className='d-flex'>
                                  <div>
                                    <span className='delivery-selection-text'>Point relais
                                      <span className='delivery-selection-text-description'> (livraison en 2/3 jours ouvrés)</span>
                                    </span>
                                  </div>
                                  <span className='pl-4'>Inclus</span>
                                </div>
                              </Form.Check.Label>
                            </Form.Check>
                          </div>
                        </Form.Row>

                      </div>
                    </div>

                    <Button
                      classname='w-100 checkout-delivery-button'
                      type="submit"
                    // loading={loading} // TODO V2 Loading
                    >
                      Choisir un point relais
                    </Button>
                  </Fragment>
                </Form>
              )}
            </Formik>

          </div>
        </div>
      </div>
    </UCStep >

  )
}

export default withNavigation(UCDelivery)


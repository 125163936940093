import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

const schema = yup.object({
  registeredCardId: yup.number().required(),
  cguChecked: yup.boolean().test({
    name: 'cc-valid-cgu',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'cguChecked',
        message: I18n.t(`paymentForm.fields.errors.cgu`),
      })
    },
  }),
  termsChecked: yup.boolean().test({
    name: 'cc-valid-terms',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'termsChecked',
        message: I18n.t(`paymentForm.fields.errors.terms`),
      })
    },
  }),
})

export default schema

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

const schema = yup.object({
  overallNightQualityRating: yup.number().min(1).required(),
  wakeUpEnergyRating: yup.number().min(1).required(),
  dayAnxietyRating: yup.number().min(1).required(),
  tookPillToSleep: yup.number().min(0).max(1).required()
})

export default schema

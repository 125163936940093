import React, { PureComponent, Fragment } from 'react'
import Media from 'react-media'
import classnames from 'classnames'
import StringMask from 'string-mask'
import { isUndefined } from 'lodash'

import './styles.sass'

interface IProps {
  title?: string
  value?: string
  picto?: JSX.Element
  pictoEnd?: JSX.Element
  placeholder?: string
  name?: string
  id?: string
  classname?: string
  type?: string
  mask?: string
  pattern?: string
  required?: boolean
  autoComplete?: string
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  isDateInput?: boolean
  help?: string
  key?: string
}

interface IState {
  active: boolean
  value: string
  formatted: string
}

class InputTextV2 extends PureComponent<IProps, IState> {
  /* tslint:disable */
  input!: HTMLInputElement
  /* tslint:enable */
  mask?: (this: string) => string

  constructor(props: any) {
    super(props)

    const { value, mask } = this.props

    if (mask) {
      this.mask = new StringMask(mask)
    }

    this.state = {
      value: value || '',
      formatted: this.getFormatted(value || ''),
      active: false,
    }
  }

  private getFormatted(value: string) {
    if (this.mask) {
      const masked = this.mask.apply(value.replace(/[^\d\p{L}]/g, ''))
      const trimmed = masked.trim()

      return trimmed
    }

    return value
  }

  private rawValue(formatted: string) {
    if (this.mask) {
      return formatted.replace(/\D+/g, '')
    }

    return formatted
  }

  private handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { onChange, isDateInput } = this.props
    const oldValue = this.state.value
    const rawValue = this.rawValue((e.target as HTMLInputElement).value)
    const value =
      isDateInput && rawValue.length === oldValue.length
        ? rawValue.substr(0, rawValue.length - 1)
        : rawValue

    this.setState({ value, formatted: this.getFormatted(value) })

    if (!isUndefined(onChange)) {
      onChange(value)
    }
  }

  private handleBlur() {
    const { value } = this.state

    this.setState({ active: false })
    this.props.onBlur && this.props.onBlur(value)
  }

  public render() {
    const { id, help, classname, title, onBlur, picto, pictoEnd, ...props } = this.props
    const { active } = this.state

    const inputId = '' + id + title

    return (
      <div className="form-group">
        {title && <label htmlFor={inputId}>{title}</label>}
        <input
          type="email"
          className="form-control"
          id={inputId}
          name={inputId}
          aria-describedby="emailHelp"
          placeholder="Enter email" 
        />
        {help && (
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        )}
        
      </div>
    )
    // const test =(
    //   <div
    //     className={classnames('input', 'input-text', classname, { active })}
    //     onClick={() => { this.input.focus() }}
    //     onBlur={() => this.handleBlur()}
    //   >
    //     {picto && (<div className="input-picto-left">{picto}</div>)}
    //     <div className="input-text-zone">
    //       {title && <p className="input-label" style={labelStyle} >{title}</p>}
    //       <input
    //         {...props}
    //         style={title ? inputStyleWithTitle : inputStyleWithoutTitle}
    //         ref={(el: HTMLInputElement) => { this.input = el }}
    //         value={this.state.formatted}
    //         onFocus={() => { this.setState({ active: true }) }}
    //         onChange={(e: React.FormEvent<HTMLInputElement>) => this.handleChange(e)}
    //       />
    //     </div>
    //     {pictoEnd && (<div className="input-picto-right">{pictoEnd}</div>)}
    //   </div>
    // )
  }
}

export default InputTextV2

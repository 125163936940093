import React from 'react'
import { map } from 'lodash'
import { MainContainer } from 'containers'
import { Block, CreditCard, PersonalInfo, ProgramManager, SubscriptionManager } from 'components'
import T from 'types'

type AccountProps = {
  profile: T.Profile,
  academy: T.AcademyStore,
  storefront: T.Storefront,
  updateProfile: (data: any) => void,
  deleteCreditCard: (id: number) => void,
  interruptEnrollment: (id: number) => void,
  getSubscriptions: () => void,
  cancelSubscription: (id: number) => void,
  getEnrollments: () => void,
}


class Account extends React.Component<AccountProps> {

  componentDidMount() {
    const { getEnrollments } = this.props
    getEnrollments()
  }

  render() {
    const { academy, storefront, profile, updateProfile, getSubscriptions, cancelSubscription, interruptEnrollment } = this.props

    return (
      <MainContainer>
        <div className="page">
          <PersonalInfo
            title="Mon compte"
            profile={profile}
            onSubmit={updateProfile}
          />
          <ProgramManager academy={academy} interruptEnrollment={interruptEnrollment} />
          <SubscriptionManager getSubscriptions={getSubscriptions} cancelSubscription={cancelSubscription} storefront={storefront} />
        </div>
      </MainContainer>
    )
  }
}

export default Account

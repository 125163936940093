import React, { Component } from 'react'
import { map } from 'lodash'
import T from 'types'

import './styles.sass'

interface IProps {
  sleepCalendar?: T.SleepCalendar
  handleClickBulletPoints: () => void
}

class AgendaSommeilBulletPoints extends Component<IProps> {

  private renderValuesSection(value: string, action: string | undefined) {
    if(action) {
      return (
        <div className="align-self-center values-section">
          <span className="hour-value">{value}</span>
          <span className="action"><u>{action}</u></span>
        </div>
      )
    } else {
      return <p className="align-self-center hour-value">{value}</p>
    }
  }

  render() {
    const { sleepCalendar, handleClickBulletPoints } = this.props

    if (!sleepCalendar || !sleepCalendar.wakeupTimeInTimezoneFormatted)
      return null

    let items = []

    if (sleepCalendar.recommendedBedtimeInTimezoneFormatted)
      items.push({label: 'Heure de coucher minimum', value: sleepCalendar.recommendedBedtimeInTimezoneFormatted })
    if (sleepCalendar.wakeupTimeInTimezoneFormatted)
      items.push({label: 'Heure de lever', value: sleepCalendar.wakeupTimeInTimezoneFormatted, action: "Modifier" })
    if (sleepCalendar.averageSleepDuration)
      items.push({label: 'Temps de sommeil moyen', value: `${Math.floor(sleepCalendar.averageSleepDuration / 60)}H${(sleepCalendar.averageSleepDuration % 60).toString().padStart(2, '0')}`})
    if (sleepCalendar.averageSleepEfficiency)
      items.push({label: 'Efficacité de sommeil', value: `${sleepCalendar.averageSleepEfficiency}%`})

    return <div className="bulletpoint-container row my-1">
      { map(items, (it, idx) =>
        <div
          className="col-12 col-md-3 mb-4"
          key={`sommeil-bulletpoint-${idx}`}
          onClick={it.action ? () => handleClickBulletPoints() : () => false}
        >
          <div className="bulletpoint-item block-content d-flex flex-row flex-sm-column justify-content-between justify-content-between justify-content-sm-around">
            <h3 className="align-self-center">{it.label}:</h3>
            {this.renderValuesSection(it.value, it.action)}
          </div>
        </div>
        )
      }
    </div>
  }
}

export default AgendaSommeilBulletPoints

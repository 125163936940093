import { connect } from "react-redux"
import MainContainer from "page/Pages/MainContainer"
import { authSignout, toggleSidebar } from "actions"
import T from 'types'

const mapStateToProps = ({
  ux: { sidebarActive },
  auth: {
    signedIn,
    profile: { isAcademy, isConsultation },
  },
}: T.Store) => ({
  sidebarActive,
  signedIn,
  isAcademy,
  isConsultation,
})

const mapDispatchToProps = (dispatch: Function) => ({
  signOut: () => dispatch(authSignout()),
  closeSidebar: () => dispatch(toggleSidebar(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)

import React, { useState } from 'react'
import DebugMediaWizard from "./DebugMediaWizard"
import useTrackedVisioConfiguration from "helpers/hooks/useTrackedVisioConfiguration"
import ShowPromptPermissionsButton from './ShowPromptPermissionsButton'
import HelpToAcceptPermissions from './HelpToAcceptPermissions'
import TroubleshootPermissions from './TroubleshootPermissions'
import DeviceMissing from './DeviceMissing'
import SuccessScreen from './SuccessScreen'
import { WizardState, MediaSetupTrackingContext, MediaConfig } from 'helpers/hooks/useVisioConfiguration'

import './styles.sass'

type SetupMediaWizardProps = {
  onSuccess: () => void
  onDeviceMissing: () => void
  onSkipped: () => void
  trackingConfig: MediaSetupTrackingContext
  debug?: boolean
  mediaConfig: MediaConfig
}

const SetupMediaWizard = (props: SetupMediaWizardProps) => {
  const mediaConfig = props.mediaConfig

  const WizardStep = {
    [WizardState.PERMISSION_PROMPT]: ShowPromptPermissionsButton,
    [WizardState.PERMISSION_PROMPT_OPEN]: HelpToAcceptPermissions,
    [WizardState.PERMISSION_TROUBLESHOOT]: TroubleshootPermissions,
    [WizardState.DEVICE_MISSING]: DeviceMissing,
    [WizardState.SUCCESS]: SuccessScreen,
  }[mediaConfig.wizardState]

  return (
    <>
      <div className="setup-media-wizard">
        <WizardStep
          config={mediaConfig}
          onSuccess={props.onSuccess}
          onDeviceMissing={props.onDeviceMissing}
          onSkipped={props.onSkipped}
          trackingConfig={props.trackingConfig}
        />
      </div>
      {props.debug && (
        <div className="mt-5">
          <DebugMediaWizard config={mediaConfig} />
        </div>
      )}
    </>
  )
}

export default SetupMediaWizard
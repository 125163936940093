import { connect } from "react-redux"
import CartSlidableContainer from "page/Pages/CartSlidableContainer"
import { getCart, removeFromCart, setVariantQuantity } from "actions"
import T from 'types'

const mapStateToProps = ({
  storefront,
}: T.Store) => ({
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getCart: () => dispatch(getCart()),
  removeFromCart: (itemId: number) => dispatch(removeFromCart(itemId)),
  setVariantQuantity: (lineItemId: number, quantity: number) => dispatch(setVariantQuantity(lineItemId, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(CartSlidableContainer)

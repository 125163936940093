import React, { Component, Fragment } from 'react'
import { get, isEmpty, isNil } from 'lodash'
import moment from 'constants/moment'
import { RouteComponentProps } from 'react-router'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { I18n } from 'react-redux-i18n'
import Step, { StepProps, StepHead, StepFooter } from './Step'
import { Button } from 'components'
import tracker from 'utils/trackers'
import * as SC from 'utils/models/schemas/constants'
import { createSchema, schemaWithSSN } from 'utils/models/schemas/personalInformation'
import MaskedInput from 'react-text-mask'
import { Formik } from 'formik'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TARGET_PLATFORM } from 'constants/config'
import T from 'types'

interface IProps extends RouteComponentProps<any>, StepProps {
  loading: boolean
  consultation: T.Consultation | null
  setPersonalData: (data: object) => void
  goNext: () => void
  goPrevious: () => void
  updateConsultationWithSSN: (onCreateSuccess: () => void) => void
  patientAttributes: object
}

export default class StepWelfare extends Component<IProps, any> {
  public handleGlobalSubmit = (data: any) => {
    const { goNext, setPersonalData, updateConsultationWithSSN } = this.props

    setPersonalData(data)
    updateConsultationWithSSN(() => {
      goNext()
    })
  }
  public handleSkip = () => {
    const { goNext, consultation } = this.props
    const uuid = get(consultation, 'patientUserUuid')
    const traits = {
      nir: "skipped"
    }
    if (uuid) {
      tracker.identifyUser(uuid, traits)
    }
    goNext()
  }

  public render() {
    const { patientAttributes, steps, goPrevious, goNext, loading } = this.props
    const title = (
      <span>
        Pour bénéficier de 25€ de remboursement sur 35€, indiquez votre numéro de sécurité sociale.
      </span>
    )

    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <div className="personal-info-step">
          <Formik
            validationSchema={schemaWithSSN}
            onSubmit={this.handleGlobalSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              ssn: get(patientAttributes, 'ssn', ''),
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>

                <div className="onboarding-container-bloc">
                  <StepHead title={title} />
                  <Fragment>
                    <div className="onboarding-small-inner-container">
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>{I18n.t(`onboarding.personnalData.ssn`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="ssn"
                          value={values.ssn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.ssn}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.ssn`)}
                          as={MaskedInput}
                          mask={[/\d/,' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ssn}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div>Le numéro de sécurité sociale correspond aux 15 chiffres de la face avant de votre carte vitale.</div>
                    </div>
                  </Fragment>
                </div>
                <StepFooter>
                  <div className="onboarding-small-inner-container">
                    <Button
                      classname="w-100"
                      type="submit"
                      loading={loading}
                    >
                      Valider
                    </Button>
                    <div className="mt-2"></div>
                    <Button
                      variant="btn-outline-primary"
                      classname="w-100"
                      type="button"
                      onClick={() => this.handleSkip()}
                      loading={loading} // TODO V2 Loading
                    >
                      Continuer sans remboursement
                    </Button>
                  </div>
                </StepFooter>
              </Form>
            )}
          </Formik>
        </div>
      </Step>
    )
  }

  private handlePrevious() {
    const { goPrevious } = this.props
    goPrevious()
  }
}

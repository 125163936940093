import { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser'

const useVisioSession = (apiKey, sessionId, token) => {
  const [session, setSession] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const subscriberRef = useRef(null);
  const publisherRef = useRef(null);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [active, setActive] = useState(false);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [localAudioEnabled, setLocalAudioEnabled] = useState(true);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [subscriberEvent, setSubscriberEvent] = useState(null);
  const isActive = useRef(active);

  const setIsActive = (value) => {
    isActive.current = value;
    setActive(value);
  }

  useEffect(() => {
    loadOpenTokScript()
      .then(() => {
        if (window.OT && apiKey && sessionId) {
          connect();
        }
      })
      .catch(error => {
        console.error('Error loading OpenTok script', error);
        Sentry.captureException(error)
      });
  }, [apiKey, sessionId]);

  const loadOpenTokScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = "https://static.opentok.com/v2/js/opentok.min.js";
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const handleError = (error) => {
    if (error && error.name !== 'OT_STREAM_DESTROYED') {
      setError(true);
      console.error(error);
      Sentry.captureException(error)
    }
  };

  const setupSessionEventListeners = (session) => {
    session.on('sessionConnected', () => {
      setConnected(true);
    });

    session.on('sessionDisconnected', (event) => {
      console.info('You were disconnected from the session.', event.reason);
    });
    
    session.on('streamCreated', (event) => {
      setSubscriberEvent(event);
      if (isActive.current) {
        connectStream(session, event);
        updateSubscribersCount(session);
      }
    });

    session.on('streamDestroyed', (event) => {
      updateSubscribersCount(session);
      setSubscriberEvent(null);
    });
  };

  const updateSubscribersCount = (session) => {
    const allStreams = session.streams;
    let totalSubscribers = 0;
    allStreams.forEach(stream => {
      totalSubscribers += session.getSubscribersForStream(stream).length;
    });
    setSubscribersCount(totalSubscribers);
  };

  const connect = () => {
    const otSession = window.OT.initSession(apiKey, sessionId);
    if (otSession && token) {
      setSession(otSession);
      setupSessionEventListeners(otSession);
      otSession.connect(token, (error) => {
        if (error) {
          handleError(error);
        }
      });
    }
  };

  const connectStream = (session, event) => {
    const existingSubscribers = session.getSubscribersForStream(event.stream);
    if (existingSubscribers.length > 0) {
      console.warn('Stream already subscribed:', event.stream.streamId);
      return;
    }

    const subscriberOptions = {
      insertMode: "append",
      width: "100%",
      height: '100%',
      fitMode: 'contain',
      showControls: false,
      insertDefaultUI: true,
      style: {
        buttonDisplayMode: "auto",
        videoDisabledDisplayMode: "on",
      },
    };

    session.subscribe(
      event.stream,
      subscriberRef.current,
      subscriberOptions,
      (error) => {
        handleError(error);
        if (!error) {
          updateSubscribersCount(session);
        }
      }
    );
    setSubscribersCount(session.getSubscribersForStream(event.stream).length);
  };

  const disconnect = () => {
    if (session) {
      session.disconnect();
      setStopped(true);
      setConnected(false);
      setIsActive(false);
      setError(false);
      setSubscribersCount(0);
      setPublisher(null);
      setSession(null);
    }
  };

  const reconnect = () => {
    setStopped(false);
    connect();
  };

  const retry = () => {
    setStopped(false);
    setConnected(false);
    setError(false);
    connect();
  };

  const startCall = () => {
    const publisherOptions = {
      insertMode: "append",
      width: "100%",
      height: '100%',
      fitMode: 'contain',
      showControls: false,
      insertDefaultUI: true,
      style: {
        buttonDisplayMode: "auto",
        videoDisabledDisplayMode: "on",
      },
    };
    const publisher = OT.initPublisher(
      publisherRef.current,
      publisherOptions,
      handleError
    );
    if (session && publisher) {
      setPublisher(publisher);
      setIsActive(true);
      session.publish(publisher, handleError).on('streamCreated', (event) => {
        if (subscriberEvent) {
          connectStream(session, subscriberEvent);
          updateSubscribersCount(session);
        }
      });
    }
  };

  const toggleLocalAudio = () => {
    const enabled = !localAudioEnabled;
    setLocalAudioEnabled(enabled);
    publisher.publishAudio(enabled);
  };

  const toggleLocalVideo = () => {
    const enabled = !localVideoEnabled;
    setLocalVideoEnabled(enabled);
    publisher.publishVideo(enabled);
  };

  const toggleFullScreen = () => {
    const video = document
      .getElementById("cameraSubscriberContainer")
      .getElementsByTagName("video")[0];

    if (video == undefined) {
      return null;
    }

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } 
    // Check for WebKit browsers like Safari
    else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } 
    // Check for Mozilla browsers like Firefox
    else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    }
  };

  return {
    connected,
    error,
    stopped,
    active,
    localAudioEnabled,
    localVideoEnabled,
    subscribersCount,
    publisher,
    publisherRef,
    subscriberRef,
    startCall,
    disconnect,
    reconnect,
    retry,
    toggleLocalAudio,
    toggleLocalVideo,
    toggleFullScreen,
  };
};

export default useVisioSession;

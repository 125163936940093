import React, { Fragment, useState } from 'react'
import { map, filter, partition, get } from 'lodash'
import cx from 'classnames'
import BCOpenConsultationPicker from './BCOpenConsultationPicker'
import { Block, Button, SvgIcon } from 'components'
import T from 'types'
import { getIcon } from 'assets/icons'
import { LayoutStep, LayoutMain } from '../LayoutStep'
import { I18n } from 'react-redux-i18n'
import './styles.sass'

interface IProps {
  category: T.SpecialtyCategory
  focusedSpecialty?: string
  openConsultationDoctor?: T.LightDoctor
  handleChosenSpecialty: (specialty: T.ConsultationSpecialty) => void,
}
interface IState {
  showAll: boolean
}

const SpecialtyListPicker = (props: {
  specialties: T.ConsultationSpecialty[],
  onClick: (spe: T.ConsultationSpecialty) => void,
  onShowOthers?: () => void
}) => {

  const [showAll, setShowAll] = useState(false)
  const mainSpecialties: T.ConsultationSpecialty[] = filter(props.specialties, (specialty: T.ConsultationSpecialty) => specialty.priority === "top")
  const shownMedicalSpecialties = showAll ? props.specialties : mainSpecialties
  const hasOthers = shownMedicalSpecialties.length < props.specialties.length

  const handleClickShowOthers = () => {
    setShowAll(true)
    props.onShowOthers && props.onShowOthers()
  }
  return (
    <>
      <div className="specialties-list">
      {map(shownMedicalSpecialties, (specialty, index) => (
        <Fragment key={`specialty_${index}`}>
          <div className="specialties-list-item">
            <Button
              variant=" "
              classname="btn-block onboarding-choice specialties-list-item-button"
              onClick={() => props.onClick(specialty)}
            >
              {specialty.name}
            </Button>
          </div>
          <div className="specialties-list-item-separator" />
        </Fragment>
      ))}
      {hasOthers && !showAll && <div className="specialties-list-item" key={`specialty_show_others`}>
        <Button
          variant=" "
          classname={cx("btn-block specialties-list-item-button see-more-button")}
          onClick={() => handleClickShowOthers()}
        >
          <SvgIcon icon={getIcon("clinic.plusIcon")} classname="mr-3" />
          Voir plus de pathologies
        </Button>
      </div>
      }
    </div>
    </>
  )
}

class BCSpecialtyPicker extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      showAll: false
    }
  }
  render() {
    const { category, handleChosenSpecialty, openConsultationDoctor } = this.props
    const [specialties, openSpecialities]: T.ConsultationSpecialty[][] = partition(
      category.specialties,
      (specialty: T.ConsultationSpecialty) => (!specialty.openSpecialty)
    )
    const [medicalSpecialties, nonMedicalSpecialties]: T.ConsultationSpecialty[][] = partition(
      specialties,
      (specialty: T.ConsultationSpecialty) => (category.ignoreMedicalType === true || specialty.medical)
    )
    return (
      <LayoutStep>
        <LayoutMain>
          <div className="specialty-picker">
            <SvgIcon icon={getIcon(category.icon)} classname="specialty-category-icon mx-auto mb-3" />
            <h3 className="mb-3">
              Précisez votre pathologie
            </h3>
            <div className="home-text">
              <p>{I18n.t("home.reconsultation.categorySelection")}</p>
            </div>

            {openConsultationDoctor && (
              <>
                <BCOpenConsultationPicker
                  doctorFullName={openConsultationDoctor.fullName}
                  doctorAvatarUrl={openConsultationDoctor.avatarUrl}
                  openSpecialty={openSpecialities[0]}
                  onClick={handleChosenSpecialty}
                />
                 <div className="specialty-breaker">
                  ou
                </div>
              </>
            )}

            <h4 className="mb-4">
              {I18n.t("home.reconsultation.categorySelectionSubTitle")}
            </h4>
            <SpecialtyListPicker
              specialties={medicalSpecialties}
              onClick={handleChosenSpecialty}
              onShowOthers={() => this.setState({ showAll: true })}
            />

            {this.state.showAll && nonMedicalSpecialties.length > 0 && (
              <>
                 <h4 className="my-4">
                  Consultation de 30 min avec le thérapeute de votre choix :
                </h4>
                <SpecialtyListPicker
                  specialties={nonMedicalSpecialties}
                  onClick={handleChosenSpecialty}
                />
              </>
            )}
          </div>
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default BCSpecialtyPicker

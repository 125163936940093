import React from 'react'
import { MainContainer } from 'containers'
import PharmaciesList from 'components/PharmaciesList'
import { I18n } from 'react-redux-i18n'
import '../styles.sass'
import { SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import { builtSections } from 'helpers/pharmacist-infos'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { SectionArguments } from 'page/common/ConsultationArguments'

type IProps = {
  match: any,
  collectionPharmacies: any[],
  getPharmacies: () => void,
}

class Prescription extends React.Component<IProps> {
  componentDidMount() {
    const { getPharmacies } = this.props

    getPharmacies()
  }

  render() {
    const { collectionPharmacies } = this.props

    return (
      <MainContainer title={I18n.t('pages.ordonnance.delivery.title')} >
        <div className="pharmacy-choice infos-container">
          <div className="row">
            <div className="col-lg-7">
              <div className="pharmacist pharmacist-infos">
                <div className="notice-block">
                  <SvgIcon icon={getIcon('clinic.info')} classname="warning-icon mr-2" />
                  <span className="pharmacist-choice-notice font-weight-bold">{I18n.t('pages.ordonnance.delivery.franceLimitations')}</span>
                  <span className="pharmacist-choice-notice">{I18n.t('pages.ordonnance.delivery.limitations')}</span>
                </div>
                <PharmaciesList
                  classname="active"
                  collection={collectionPharmacies}
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-arguments">
                <div className="d-none d-lg-block">
                  <SectionArguments sections={builtSections()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContainer >
    )
  }
}

export default Prescription

import React, { useState, Component, Fragment, RefObject } from 'react'
import ReactJWPlayer from 'react-jw-player'
import cx from 'classnames'
import build from 'redux-object'
import schema from 'utils/models/schemas/sleepCalendar'
import { Formik } from 'formik'
import { Form, Col, Tabs, Tab, Modal, Row, ProgressBar } from 'react-bootstrap'
import { AgendaSommeilCta, AgendaSommeilBulletPoints, Button, SleepCalendarItemForm, Block, ToggleButton, SvgIcon, SleepCalendarSettings } from 'components'
import moment from 'constants/moment'
import { isUndefined, map, first, find, range, get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import T from 'types'
import CalendarSummary from './CalendarSummary'

import cogIcon from 'assets/icons/cog.svg'
import thumbUpSvg from 'assets/icons/thumb-up.svg'

import './styles.sass'

export interface Props {
  sleepCalendar: T.SleepCalendar
  sleepCalendarItems: T.SleepCalendarItem[]
  getSleepCalendar: () => void
  createSleepCalendarItem: (data: object, onSuccess: any) => void
  updateSleepCalendar: (params: object) => void
  getSleepCalendarItems: () => void
  lowerCalendarBedtime: () => void
  increaseCalendarBedtime: () => void
  skipCalendarBedtime: () => void
  updateSleepCalendarItem: (sleepCalendarItemId: number, params: object) => void
}

interface IState {
  displayCalendarForm: boolean
  currentDay: string | null
  nextDay: string | null
  displaySettings: boolean
  showModal: boolean
}

class SleepCalendar extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      displayCalendarForm: false,
      currentDay: null,
      nextDay: null,
      displaySettings: false,
      showModal: false
    }
  }

  public componentDidMount() {
    const { getSleepCalendar, getSleepCalendarItems } = this.props

    getSleepCalendar()
  }

  public getSleepCalendarItemForKey(key: string) {
    const { sleepCalendarItems } = this.props;

    return find(sleepCalendarItems, (sci) => sci.dayKey == key)
  }

  public renderSuggestSoonerBedtimeForm() {
    const { lowerCalendarBedtime, skipCalendarBedtime } = this.props;

    return <div className="block-content text-center">
      <p>Au vu de votre rythme de sommeil, avez le choix entre vous coucher 15 min plus tôt ou ne rien changer</p>

      <div className="d-flex justify-content-center">
        <button className="btn btn-primary charles-btn" onClick={() => lowerCalendarBedtime()}>
          Se coucher 15min plus tôt
        </button>
        <button className="btn btn-secondary charles-btn ml-4" onClick={() => skipCalendarBedtime()}>
          Garder mon heure de coucher actuelle
        </button>
      </div>
    </div>
  }

  public renderSuggestLaterBedtimeForm() {
    const { increaseCalendarBedtime } = this.props;

    return <div className="block-content text-center">
      <p>Au vu de votre rythme de sommeil, vous devez vous coucher 15 min plus tard</p>
      <button className="btn btn-primary charles-btn" onClick={() => increaseCalendarBedtime()}>
        J'ai compris
      </button>
    </div>
  }

  public handleClickBulletPoints = (bool: boolean) => {
    this.setState({ showModal: bool })
  }

  public renderSleepCalendarContainer() {
    const { sleepCalendar, getSleepCalendar, updateSleepCalendar } = this.props
    const { displayCalendarForm, showModal } = this.state

    const handleClose = () => {
      this.setState({displayCalendarForm: false})
      getSleepCalendar()
    };
    const handleShow = () => this.setState({displayCalendarForm: true});

    const { missingSleepCalendarItemsKeys, isWaitingForItems } = sleepCalendar;
    const currentDay = missingSleepCalendarItemsKeys[0]
    const sleepCalendarItem = this.getSleepCalendarItemForKey(currentDay)

    let afterSubmitView = (missingSleepCalendarItemsKeys.length > 1) ? (
      <div className="text-center">
        <SvgIcon icon={thumbUpSvg} classname="icon-32px" />
        <p className="my-2">Vous avez renseigné votre agenda de sommeil pour la nuit du {moment(currentDay).format('dddd Do MMMM')}.</p>
        <Button variant="btn-primary" classname="my-4 mx-auto" onClick={() => getSleepCalendar()}>
          Remplir le jour suivant
        </Button>
        <Button variant="btn-outline-primary" classname="mt-2 mx-auto" onClick={() => handleClose()}>
          Le faire plus tard
        </Button>
      </div>
      ) : (
      <Fragment>
        <div className="text-center">
          <SvgIcon icon={thumbUpSvg} classname="icon-32px" />
          <p className="my-2">
            Bravo ! Vous avez fini de remplir votre agenda de sommeil.
          </p>
          <p>Revenez demain pour le mettre à jour et suivez nos conseils afin de prendre de bonnes habitudes et de vous reposer au mieux durant la nuit.</p>
        </div>
      </Fragment>
    )

    return <>
      { isWaitingForItems && <AgendaSommeilCta sleepCalendar={sleepCalendar} onClick={handleShow} /> }
      <Modal
        show={showModal}
        size="lg"
        centered
      >
        <Modal.Body>
          <SleepCalendarSettings
            sleepCalendar={sleepCalendar}
            updateSleepCalendar={updateSleepCalendar}
            title="Changement d’heure de lever"
            handleClick={() => this.handleClickBulletPoints(false)}
          />
        </Modal.Body>
      </Modal>
      <AgendaSommeilBulletPoints
        sleepCalendar={sleepCalendar}
        handleClickBulletPoints={() => this.handleClickBulletPoints(true)}
      />
      { !isWaitingForItems && this.renderCompleteCta() }
      <SleepCalendarItemForm dayKey={currentDay} visible={displayCalendarForm} onClose={handleClose} afterSubmitView={afterSubmitView} sleepCalendarItem={sleepCalendarItem} {...this.props} />
    </>
  }

  public renderCompleteCta() {
    return <div className="agenda-sommeil-cta block-content text-center my-3">
      <SvgIcon icon={thumbUpSvg} />
      <h3>
        Bravo !<br />
        Vous avez fini de remplir votre agenda de sommeil.
      </h3>
      <p>Revenez demain pour le mettre à jour et suivez nos conseils afin de retrouver un sommeil de qualité.</p>
    </div>
  }

  public renderSettingsContainer() {
    const { sleepCalendar, updateSleepCalendar } = this.props

    const { displaySettings } = this.state

    const handleClose = () => this.setState({displaySettings: false});
    const handleShow = () => this.setState({displaySettings: true});

    return <div className="">
      <a className="float-right sleep-calendar-settings-toggle" onClick={handleShow}>
        <SvgIcon icon={cogIcon} />
      </a>
      <Modal
        show={displaySettings}
        size="lg"
        centered
        backdrop={true}
        keyboard={true}
        onHide={handleClose}
        dialogClassName="max-width-500"
      >
        <Modal.Header closeButton>
          Paramètrer mes notifications
        </Modal.Header>
        <Modal.Body>
          <ToggleButton
            checked={sleepCalendar.sendBedtimeSms}
            textOn={"Recevoir les notifications pour aller se coucher par SMS"}
            onToggle={(value) => updateSleepCalendar({sendBedtimeSms: value})}
          />
          <ToggleButton
            checked={sleepCalendar.sendWakeupSms}
            textOn={"Recevoir les notifications matinales SMS"}
            onToggle={(value) => updateSleepCalendar({sendWakeupSms: value})}
          />
          <ToggleButton
            checked={sleepCalendar.sendNewBedtimeEmails}
            textOn={"Recevoir un email quand mon heure conseillée de coucher est mise à jour"}
            onToggle={(value) => updateSleepCalendar({sendNewBedtimeEmails: value})}
          />
        </Modal.Body>
      </Modal>
    </div>
  }

  public render() {
    const {
      sleepCalendar,
      sleepCalendarItems,
      getSleepCalendar,
      updateSleepCalendar
    } = this.props

    if (!sleepCalendar) return null;

    return <div className="sleep-calendar-container">
      { this.renderSettingsContainer() }
      <h1 className="h3">Agenda de sommeil</h1>
      <p className="mb-4">
        Pour que cela fonctionne correctement, faites bien la différence entre les heures au lit et les heures de sommeil qui peuvent être différentes. Les heures de sommeil sont celles où vous dormez, pas celles où vous êtes couché sans dormir.
        <br />
        Une fois que vous avez votre efficacité de sommeil, de façon pratique, nous allons adapter votre heure de coucher.
      </p>

      {sleepCalendar.suggestSoonerBedtime && this.renderSuggestSoonerBedtimeForm()}
      { this.renderSleepCalendarContainer() }
      <CalendarSummary sleepCalendar={sleepCalendar} sleepCalendarItems={sleepCalendarItems} />
    </div>
  }
}

export default SleepCalendar

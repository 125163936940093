import React, { useState } from 'react'
import { SetupMediaWizard, VisioBox } from 'components'
import useTrackedVisioConfiguration from "helpers/hooks/useTrackedVisioConfiguration"
import { MediaSetupTrackingContext, WizardState } from 'helpers/hooks/useVisioConfiguration'

import './styles.sass';

type Props = {
  apiKey: string
  sessionId: string
  token: string
  consultationId: number | undefined
}

const VisioBoxWithSetup = ({ apiKey, sessionId, token, consultationId }: Props) => {
  const [configured, setConfigured] = useState(false)
  const trackingConfig: MediaSetupTrackingContext = {
    context: 'personalSpace',
    consultationId: consultationId
  }
  const mediaConfig = useTrackedVisioConfiguration(trackingConfig)
  const configurationSuccess = mediaConfig.wizardState == WizardState.SUCCESS

  return (configurationSuccess || configured) ? (
    <VisioBox
      apiKey={apiKey}
      sessionId={sessionId}
      token={token}
    />
  ) : (
    <div className="visio-box-with-setup-container">
      <div className="visio-box-with-setup-content">
        <SetupMediaWizard
          onSuccess={() => { setConfigured(true)}}
          onSkipped={() => {}}
          onDeviceMissing={() => {}}
          trackingConfig={trackingConfig}
          mediaConfig={mediaConfig}
          debug={false}
        />
      </div>
    </div>
  )
}

export default VisioBoxWithSetup
import React, { useState } from 'react'
import './styles.sass'
import { SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import cx from 'classnames'

type CustomDropdownProps = {
  options: any,
  defaultOption?: any,
  placeholder?: string,
  handleClick: any,
  unit?: string,
}

const CustomDropdown = (props: CustomDropdownProps) => {
  const { defaultOption, options, handleClick, unit, placeholder } = props
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(placeholder || defaultOption || options[0])

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
    setShowOptions(false)
    handleClick(option)
  }

  const displayPrice = () => {
    if (selectedOption === defaultOption) return `${selectedOption} ${unit} (recommandé)`
    if (selectedOption === placeholder) return selectedOption
    return `${selectedOption} ${unit}`
  }

  return (
    <div className="custom-select-dropdown" tabIndex={1}>
      <div className="my-custom-select" onClick={() => setShowOptions(!showOptions)}>
        <div className="selected">{displayPrice()}</div>
        <SvgIcon classname="caret-icon" icon={getIcon('clinic.chevron')} />
      </div>
      <div className={cx('custom-select-options', { 'custom-select-options-active': showOptions })}>
        {options.map((option: any, index: number) => (
          <div className="select-option" onClick={() => handleOptionClick(option)} key={index}>
            {option} {unit} {option === defaultOption && '(recommandé)'}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomDropdown

import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import Step, { StepProps, StepFooter } from '../common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { isEmpty, first, compact, isUndefined, get, includes, map } from 'lodash'
import build from 'redux-object'
import { Button, CouponInput } from 'components'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'

import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { getNumberOfPayments, organizeProgramVariants } from 'utils/models/variants'
import { money } from 'utils/models/money'

import ProgramVariantPaymentForm from './ProgramVariantPaymentForm'
import ProgramArguments from './ProgramArguments'

import './styles.sass'
import { IS_CHARLES_PLATFORM } from '../../constants/config'

interface IProps extends RouteComponentProps<any>, StepProps {
  patientAttributes?: any
  academy: T.AcademyStore
  program: T.Program
  storefront: T.Storefront
  loading: boolean
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  goNext: () => void
  goPrevious: () => void
  getPaymentMethods: () => void
}

interface IState {
  selectedVariantId?: number
  showSelectedVariant: boolean
}

export default class StepAcademyPayment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const order = first(build(props.academy, 'cart')) as T.OrderV2
    const selectedVariant = first(order.variants)
    this.state = {
      showSelectedVariant: true,
      selectedVariantId: isUndefined(selectedVariant) ? undefined : selectedVariant.id,
    }
  }

  public getPriceOfVariant(variant: T.Variant) {
    const { academy } = this.props
    const order = first(build(academy, 'cart')) as T.OrderV2
    const selected = includes(map(order.variants, variant => variant.id), variant.id)
    const promoAmount = money(selected ? order.promoTotal : '0')
    return money(variant.price).add(promoAmount)
  }

  renderMenuItem(variant: T.Variant, order: T.OrderV2) {
    const numberOfPayments = getNumberOfPayments(variant)
    const isStepByStepPaymentVariant = numberOfPayments > 3
    const isOneShotPayment = numberOfPayments == 1
    const isSplitPaymentVariant = !isStepByStepPaymentVariant && !isOneShotPayment

    let price
    let title
    let stepAcademyPaymentBackground = ''

    if (isSplitPaymentVariant) {
      title = `En ${numberOfPayments} fois`
      price = `${numberOfPayments} x ${this.getPriceOfVariant(variant).toFormat()}`
    } else if (isStepByStepPaymentVariant) {
      title = 'Étape par étape'
      price = this.getPriceOfVariant(variant).toFormat()
    } else {
      title = 'En 1 fois'
      price = this.getPriceOfVariant(variant).toFormat()
    }

    if (!IS_CHARLES_PLATFORM) {
      stepAcademyPaymentBackground = 'step-academy-payment-submenu-block-mia'
    }

    return (
      <label key={`step-academy-render-menu-item-variant-${variant.id}`}
        className={cx('step-academy-payment-submenu-block', stepAcademyPaymentBackground, {
          selected: this.state.selectedVariantId === variant.id,
        })}
      >
        <div className="step-academy-payment-submenu-title">
          {title}
          <div className="step-academy-payment-submenu-price">{price}</div>
        </div>
        <div className="step-academy-payment-submenu-right">
          <input
            type="radio"
            name="variantSelection"
            value={`${variant.id}`}
            className="step-academy-payment-submenu-radio-input"
            checked={this.state.selectedVariantId === variant.id}
            onChange={e => this.handleSelectVariant(variant.id, order.token)}
          />
        </div>
      </label>
    )
  }

  public componentDidMount() {
    const { selectedVariantId } = this.state
    const { program, academy } = this.props

    const order = first(build(academy, 'cart')) as T.OrderV2
    const filteredVariants = variantsToShow(program, selectedVariantId)

    if (filteredVariants[0]) this.handleSelectVariant(filteredVariants[0].id, order.token)
  }

  public render() {
    const {
      loading,
      academy,
      program,
      storefront,
      patientAttributes,
      steps,
      goPrevious,
      goNext,
      applyCoupon,
      purchase,
      getPaymentMethods,
    } = this.props
    const { selectedVariantId, showSelectedVariant } = this.state
    const { patientExtraAttributes } = academy

    const patientFullAttributes = {
      email: patientAttributes.email,
      firstName: get(patientExtraAttributes, 'firstname') || "",
      lastName: get(patientExtraAttributes, 'lastname') || "",
    }

    const order = first(build(academy, 'cart')) as T.OrderV2
    const filteredVariants = variantsToShow(program, selectedVariantId)

    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <LayoutStep>
          <LayoutMain variant="small">
            <h3>{I18n.t(`academy.${program.slug}.purchaseStep.title`)}</h3>
            <div className="academy-payment-container">
              {filteredVariants.length > 1 && (
                <div className="step-academy-payment-submenu">
                  {filteredVariants.map((variant: T.Variant, index: number) =>
                    this.renderMenuItem(variant, order),
                  )}
                </div>
              )}

              <div className="step-academy-payment-variants">
                {filteredVariants.map((variant: T.Variant, index: number) => (
                  <div key={`program_payment_variant_${index}`} className={cx({ 'd-none': this.state.selectedVariantId !== variant.id })}>
                    <ProgramVariantPaymentForm
                      patientAttributes={patientFullAttributes}
                      variant={variant}
                      academy={academy}
                      loading={loading}
                      program={program}
                      order={order}
                      show={showSelectedVariant && selectedVariantId == variant.id}
                      purchase={purchase}
                      storefront={storefront}
                      getPaymentMethods={getPaymentMethods}
                      goNext={goNext}
                      applyCoupon={applyCoupon}
                    />
                  </div>
                ))}
              </div>
            </div>
          </LayoutMain>
          <LayoutSide>
            <ProgramArguments slug={academy.onboarding.slug} />
          </LayoutSide>
        </LayoutStep>
      </Step>
    )
  }

  private handleSelectVariant = (variantId: number, orderToken: string) => {
    const { selectProgramVariant } = this.props

    selectProgramVariant(variantId, orderToken, () => {
      this.setState({
        selectedVariantId: variantId,
        showSelectedVariant: true,
      })
    })
  }
}

const variantsToShow = (program: T.Program, selectedVariantId: number | undefined): T.Variant[] => {
  const { stepByStepVariant, fullSplitPayment, fullOneTimePayment } = organizeProgramVariants(
    program,
    false,
    false,
  )
  const isStepByStepSelected = stepByStepVariant && stepByStepVariant.id == selectedVariantId

  if (isStepByStepSelected) {
    return [stepByStepVariant]
  } else {
    return compact([fullOneTimePayment, fullSplitPayment])
  }
}

import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import Step, { StepProps, StepFooter } from '../common/Step'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { first, isUndefined } from 'lodash'
import build from 'redux-object'
import { Button, SvgIcon, Block, BlockHeader } from 'components'
import { I18n } from 'react-redux-i18n'

import T from 'types'
import { variantFullPrice, getNumberOfPayments, organizeProgramVariants } from 'utils/models/variants'
import ProgramVariantChoice from './ProgramVariantChoice'
import ProgramArguments from './ProgramArguments'

interface IProps extends RouteComponentProps<any>, StepProps {
  patientAttributes?: any
  academy: T.AcademyStore
  program: T.Program
  storefront: T.Storefront
  loading: boolean
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  goNext: () => void
  goPrevious: () => void
}

export default class StepAcademyVariantChoice extends Component<IProps> {

  public render() {
    const { academy, program, goPrevious, steps } = this.props

    const { stepByStepVariant, fullOneTimePayment } = organizeProgramVariants(program, false, false)
    const stepByStepFullPrice = stepByStepVariant && variantFullPrice(stepByStepVariant)
    const showStepByStepVariant = (program.slug === 'time-program' || program.slug === 'master-program' || program.slug === 'sommeil-program') && stepByStepVariant
    const globalTitle = showStepByStepVariant ? 'Choisissez l\'offre qui vous convient' : `${program.title}`
    const subTitle = program.description
    let allInOneBullet = []
    if (I18n.t(`academy.${program.slug}.selectionStep.bullets.bullet1`) !== 'bullet1') {
      for (let i = 0; i < Object.keys(I18n.t(`academy.${program.slug}.selectionStep.bullets`)).length; i = i + 1) {
        allInOneBullet.push(I18n.t(`academy.${program.slug}.selectionStep.bullets.bullet${i + 1}`))
      }
    } else {
      allInOneBullet = [
        I18n.t(`academy.${program.slug}.selectionStep.oneTimePayment`),
        I18n.t(`academy.${program.slug}.selectionStep.oneTimeSplitPayment`),
      ]
    }
    let steByStepBullets = []
    for (let i = 0; i < Object.keys(I18n.t(`academy.${program.slug}.selectionStep.bullets`)).length; i = i + 1) {
      steByStepBullets.push(I18n.t(`academy.${program.slug}.selectionStep.stepByStep.bullet${i + 1}`))
    }

    const reductionString = () => {
      if (I18n.t(`academy.${program.slug}.selectionStep.percent`) === 'percent') {
        return undefined
      }
      return I18n.t(`academy.${program.slug}.selectionStep.percent`)
    }

    return (
      <Step steps={steps} goPrevious={goPrevious}>
        <LayoutStep>
          <LayoutMain>
            <h3>{globalTitle}</h3>
            {program.slug === 'sommeil-program' && (
              <div className="mb-4">{subTitle}</div>
            )}
            <div className="program-variants-container">
              {fullOneTimePayment && (
                <div className="d-flex">
                  <ProgramVariantChoice
                    key="all_in_one_variant"
                    title="Programme complet"
                    ctaLabel="Choisir cette offre"
                    variant={fullOneTimePayment}
                    price={fullOneTimePayment.price}
                    priceSuffix=""
                    comparativeFullPrice={showStepByStepVariant ? stepByStepFullPrice : undefined}
                    bullets={allInOneBullet}
                    handleSelectVariant={() => this.handleSelectVariant(fullOneTimePayment.id)}
                    reductionString={reductionString()}
                  />
                </div>
              )}
              {showStepByStepVariant && (
                <ProgramVariantChoice
                  key="step_by_step_variant"
                  title="Étape par étape"
                  ctaLabel="Choisir cette offre"
                  variant={stepByStepVariant}
                  price={stepByStepVariant.price}
                  priceSuffix=" / étape"
                  bullets={steByStepBullets}
                  handleSelectVariant={() => this.handleSelectVariant(stepByStepVariant.id)}
                />
              )}
            </div>
          </LayoutMain>
          <LayoutSide>
            <ProgramArguments slug={program.slug} />
          </LayoutSide>
        </LayoutStep >
      </Step >
    )
  }

  private handleSelectVariant = (variantId: number) => {
    const { academy, selectProgramVariant, goNext } = this.props
    const order = first(build(academy, 'cart')) as T.OrderV2

    selectProgramVariant(variantId, order.token, () => {
      goNext()
    })
  }
}

import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { includes, map, isUndefined, times, first, get } from 'lodash'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { PaymentFormV2 } from 'components'
import { getNumberOfPayments } from 'utils/models/variants'
import { money } from 'utils/models/money'
import moment from 'constants/moment'
import tracker from 'utils/trackers'

interface IProps {
  order: T.OrderV2
  academy: T.AcademyStore
  variant: T.Variant
  patientAttributes: object
  loading?: boolean | undefined
  program: T.Program
  show: boolean
  storefront: T.Storefront
  goNext: () => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  getPaymentMethods: () => void
  applyCoupon: (coupon: string, orderToken: string) => void
}
class ProgramVariantPaymentForm extends Component<IProps> {
  static PATIENT_PROGRAM_ATTRS: string[] = ['hasMasterProgram', 'hasTimeProgram', 'hasSommeilProgram', 'hasVaginismeProgram']

  public render() {
    const {
      order,
      variant,
      patientAttributes,
      loading,
      show,
      program,
      storefront,
      getPaymentMethods,
      applyCoupon,
    } = this.props

    const numberOfPayments = getNumberOfPayments(variant)
    const isStepByStepPaymentVariant = numberOfPayments > 3
    const isOneShotPayment = numberOfPayments == 1
    const selected = includes(map(order.variants, variant => variant.id), variant.id) || isOneShotPayment
    const isSplitPaymentVariant = !isStepByStepPaymentVariant && !isOneShotPayment

    const variantMoney = money(variant.price)
    const beforePromoTotalPrice = isSplitPaymentVariant
      ? money(variant.price).multiply(numberOfPayments)
      : money(variant.price)
    const promoAmount = money(selected ? order.promoTotal : '0')
    const hasPromotion = !promoAmount.isZero()
    const afterPromoPaymentPrice = money(variant.price).add(promoAmount)
    let variantSplitInformation
    if (isSplitPaymentVariant) {
      variantSplitInformation = `Paiement en ${numberOfPayments} fois`
    } else if (isStepByStepPaymentVariant) {
      variantSplitInformation = 'Paiement étape par étape'
    } else {
      variantSplitInformation = 'Paiement en 1 fois'
    }

    const suffix = hasPromotion ? `au lieu de ${money(variant.price).toFormat()}` : ''
    const variantTitle = `${variantSplitInformation}: ${afterPromoPaymentPrice.toFormat()} ${suffix}`

    // check for coupon in order and set coupon only if oneshotPayment
    // cuz coupon was not resetted when variant change
    const activeCoupon = isOneShotPayment ? first(order.couponCodes) : undefined

    return (
      <div className="program-payment-variant mt-3">
        <div className="text-left">
          <h3 className="program-payment-variant-title">{variantTitle}</h3>
          <div className={cx({ 'd-none': !show })}>
            {isSplitPaymentVariant && (
              <div className="program-payment-variant-content text-left mb-2">
                <div className="my-2 d-flex">
                  <div className="program-payment-variant-due-date">1.</div>
                  <div>
                    Paiement le {moment().format('L')} de {afterPromoPaymentPrice.toFormat()}
                  </div>
                </div>
                {times(numberOfPayments - 1, (index: number) => (
                  <div className="my-2 d-flex" key={`${program.slug}-nb-pay-${index}`}>
                    <div className="program-payment-variant-due-date">{index + 2}.</div>
                    <div>
                      Paiement le{' '}
                      {moment()
                        .add(index + 1, 'month')
                        .format('L')}{' '}
                      de {variantMoney.toFormat()}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {isStepByStepPaymentVariant && (
              <div className="text-left mb-4">
                <div className="my-2 d-flex">
                  <div className="program-payment-variant-due-date">1.</div>
                  <div>{I18n.t(`academy.${program.slug}.selectionStep.stepByStep.bullet1`)}</div>
                </div>
                <div className="my-2 d-flex">
                  <div className="program-payment-variant-due-date">2.</div>
                  <div>{I18n.t(`academy.${program.slug}.selectionStep.stepByStep.bullet2`)}</div>
                </div>
                <div className="my-2 d-flex">
                  <div className="program-payment-variant-due-date">3.</div>
                  <div>{I18n.t(`academy.${program.slug}.selectionStep.stepByStep.bullet3`)}</div>
                </div>
              </div>
            )}
            <PaymentFormV2
              patientAttributes={patientAttributes}
              cgu={I18n.t(`paymentForm.fields.labels.cguAcademy`)}
              recurringPayment={isSplitPaymentVariant || isStepByStepPaymentVariant}
              storefront={storefront}
              loading={loading}
              getPaymentMethods={getPaymentMethods}
              submitPayment={this.handleSubmitPayment}
              price={afterPromoPaymentPrice.toUnit()}
              applyCoupon={(coupon: string) => applyCoupon(coupon, order.token)}
              activeCoupon={activeCoupon}
            />
          </div>
        </div>
      </div>
    )
  }

  handleSubmitPayment = (data: T.PaymentPayload): void => {
    const { order, purchase } = this.props

    purchase({ orderToken: order.token }, data, this.handlePaymentSuccess)
  }

  handlePaymentSuccess = () => {
    const { goNext, order, program, patientAttributes } = this.props
    const variant = order.variants[0]
    const alreadyHasPurchasedAProgram = ProgramVariantPaymentForm.PATIENT_PROGRAM_ATTRS.find(pName => {
      return get(patientAttributes, pName) === true
    })
    const subscriptionEvent: string = isUndefined(alreadyHasPurchasedAProgram) ? "first" : "repeat"
    tracker.trackDidPayAcademyProgram(order.number, order.total, program, variant, subscriptionEvent, order.orderType)
    goNext()
  }
}

export default ProgramVariantPaymentForm

import React from 'react'
import { I18n } from 'react-redux-i18n'
import { withRouter, RouteComponentProps } from 'react-router'
import { isNil, get, some, find, map, filter, includes } from 'lodash'
import { BlockHeader, PaymentFormV2 } from 'components'
import OrderComplete from '../OrderComplete'
import tracker from 'utils/trackers'
import { TARGET_PLATFORM } from 'constants/config'
import { productProperties } from 'helpers/segment/properties/phytotherapy'
import { variantOptionsAsObject } from 'utils/models/variants'
import T from 'types'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'

interface IProps extends RouteComponentProps<any> {
  goNext: () => void,
  goPrevious: () => void,
  storefront: T.Storefront
  order: T.Cart
  auth: T.Auth
  purchaseWith3dsChallenge: (order: IToken, data: T.PaymentPayload, onSuccess: () => void) => void
  getPaymentMethods: () => void
  collectionPrescription: T.Prescription[]
}

class CheckoutPayment extends React.Component<IProps> {
  handlePaymentSuccess = () => {
    const { goNext, order, collectionPrescription } = this.props
    const variants = map(order.lineItems, (lineItem: T.LineItem) => lineItem.variant)
    const productsData = productProperties(collectionPrescription, variants)
    tracker.trackDidPayPhytotherapy(
      order.total,
      order.number,
      order.orderType,
      order.firstOfType,
      productsData
    )
    goNext()
  }

  handleValidatePayment = (data: T.PaymentPayload) => {
    const { order, purchaseWith3dsChallenge } = this.props

    purchaseWith3dsChallenge({ orderToken: order.token }, data, this.handlePaymentSuccess)
  }

  private isRecurringPayment(): boolean {
    const lineItems = this.props.order.lineItems

    // return false if any line item is a phyto-subscription
    return some(lineItems, (lineItem: T.LineItem) => {
      const variantOptions = variantOptionsAsObject(lineItem.variant)
      return (get(variantOptions, 'subscribable', '') === 'subscribable')
    })
  }

  render() {

    const { auth, order, storefront, getPaymentMethods } = this.props
    const { profile } = auth
    const { loading } = storefront

    return order.state === 'complete' ?
      (
        <OrderComplete order={order} />
      ) : (
        <>
          <BlockHeader title="Paiement" />
          <PaymentFormV2
            patientAttributes={profile}
            submitPayment={this.handleValidatePayment}
            loading={loading}
            price={parseFloat(order.total)}
            cgu={I18n.t(`paymentForm.fields.labels.cguPhyto`)}
            recurringPayment={this.isRecurringPayment()}
            storefront={storefront}
            getPaymentMethods={getPaymentMethods}
          />
        </>
      )
  }
}

export default withRouter(CheckoutPayment)

import React, { Component, useState } from 'react'
import { map, isEmpty, range, sumBy } from 'lodash'
import T from 'types'
import { Link } from 'react-router-dom'
import moment from 'constants/moment'

import { Button, SvgIcon } from 'components'

import { Form, Col, Tabs, Tab, Modal, Row, ProgressBar } from 'react-bootstrap'

import iconCalendarSvg from 'assets/icons/calendar-icon.svg'
import expandSvg from 'assets/icons/expand-alt.svg'

import greenDot from 'assets/icons/green-dot.svg'
import yellowDot from 'assets/icons/yellow-dot.svg'
import redDot from 'assets/icons/red-dot.svg'
import iconeCoucher from 'assets/icons/arrow-down.svg'
import iconeLever from 'assets/icons/arrow-up.svg'

import boutonSommeilUp from 'assets/icons/bouton-sommeil-up.png'
import boutonSommeilDown from 'assets/icons/bouton-sommeil-down.png'

interface IProps {
  sleepCalendar?: T.SleepCalendar
  sleepCalendarItems?: T.SleepCalendarItem[]
}

interface IState {
  displayModal: boolean
}

export default class extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      displayModal: false,
    }
  }

  private timestampIsBetween(value : number, from : number, to : number) {
    if (from <= to)
      return (value > from && value < to)
    return value > from || value < to
  }

  private wasSleeping(sci : T.SleepCalendarItem, at: number) {
    if (this.timestampIsBetween(at, sci.sleptAt, sci.awakeAt))
      return !sci.awakenings[at]
    return false
  }

  private wasNaping(sci : T.SleepCalendarItem, at: number) {
    if (sci.naps[at])
      return true
    return false
  }

  private renderLine(sci : T.SleepCalendarItem) {
    let hOffset = 0
    let line = []

    for (let hOffset = 0; hOffset < 24; hOffset += 1)
      for (let mOffset = 0; mOffset < 60; mOffset += 15) {
          const cursor = ((hOffset + 12) % 24) * 60 + mOffset

          if (cursor == sci.timeOntoBed)
            line.push('B')
          else if (cursor == sci.timeOutOfBed)
            line.push('O')
          else if (sci.awakenings[cursor])
            line.push('A')
          else if (this.wasSleeping(sci, cursor))
            line.push('S')
          else if (this.wasNaping(sci, cursor))
            line.push('S')
          else
            line.push(null)
      }

    let i = 0
    let lineEl = []

    while (i < line.length) {
      if (!line[i])
        lineEl.push(<div className="sleep-calendar-line-cell" style={{width: '10px'}}>&nbsp;</div>)
      else if (line[i] == 'O')
        lineEl.push(<div className="sleep-calendar-line-cell sleep-calendar-line-cel-oob" style={{width: '10px'}}><img src={boutonSommeilUp} className="icon-legend" /></div>)
      else if (line[i] == 'B')
        lineEl.push(<div className="sleep-calendar-line-cell sleep-calendar-line-cel-bed" style={{width: '10px'}}><img src={boutonSommeilDown} className="icon-legend" /></div>)
      else {
        let tmp = i;
        let tmpArray = []

        while (i < line.length && (line[i] == 'S' || line[i] == 'A')) {
          tmp = i + 1
          let count = 1;

          while (line[tmp - 1] == line[tmp]) {
            count += 1
            tmp += 1
          }

          if (line[i] == 'S')
            tmpArray.push({count: count, type: 'sleep'})
          else
            tmpArray.push({count: count, type: 'awake'})

          i = tmp
        }

        const total = sumBy(tmpArray, (it) => it.count)

        lineEl.push(<div className="sleep-calendar-line-cell" style={{width: `${10 * total}px`}}>
          <ProgressBar>
            {map(tmpArray, (it, index) =>
              (it.type == 'sleep') ?
                <ProgressBar max={total} variant="charles-sommeil-sleeping" now={it.count} key={index} /> :
                <ProgressBar max={total} striped variant="charles-sommeil-awake" now={it.count} key={index} />
            )}
          </ProgressBar>
        </div>
        )
        continue;
      }

      i += 1
    }

    return <div className="sleep-calendar-item-line d-flex justify-content-between">
      { map(lineEl, (e) => e) }
    </div>
  }

  private ratingToDot(value : number) {
    if (value == 3)
      return <SvgIcon classname="icon-dot" icon={greenDot} />
    else if (value == 2)
      return <SvgIcon classname="icon-dot" icon={yellowDot} />
    return <SvgIcon classname="icon-dot" icon={redDot} />
  }

  public renderCalendarSummary() {
    const { sleepCalendarItems } = this.props

    return <div className="scroll-x">
      <div className="calendar-summary">
        <ul className="d-flex list-unstyled align-items-center mb-4 sleep-calendar-summary-table-caption">
          <li className="mr-1"><ProgressBar variant="charles-sommeil-sleeping" now={100}/></li>
          <li className="mr-4">Sommeil et Siestes</li>
          <li className="mr-1"><ProgressBar variant="charles-sommeil-awake" now={100} striped /></li>
          <li className="mr-4">Réveils</li>
          <li className="mr-1"><img src={boutonSommeilDown} className="icon-legend" /></li>
          <li className="mr-4">Coucher</li>
          <li className="mr-1"><img src={boutonSommeilUp} className="icon-legend" /></li>
          <li className="">Lever</li>
        </ul>
        <table className="table table-borderless table-sm sleep-calendar-summary-table text-center">
          <thead>
            <tr>
              <th>Date</th>
              <th className="d-flex justify-content-between flex-grow-1 force-cell-line-width">{ map(range(0, 24, 2), (hOffset: number) => <div>{(hOffset + 12) % 24}h</div>) }</th>
              <th className="sleep-calendar-summary-legend-rotated">Qualité de la nuit</th>
              <th className="sleep-calendar-summary-legend-rotated">Qualité du réveil</th>
              <th className="sleep-calendar-summary-legend-rotated">Forme de la journée</th>
              <th className="sleep-calendar-summary-legend-rotated">Aide au sommeil</th>
            </tr>
          </thead>
          <tbody>
          {
            map(sleepCalendarItems, (sci) => {
              return <tr>
                <td>{moment(sci.dayKey).format('L')}</td>
                <td className="force-cell-line-width">{this.renderLine(sci)}</td>
                <td>{this.ratingToDot(sci.overallNightQualityRating)}</td>
                <td>{this.ratingToDot(sci.wakeUpEnergyRating)}</td>
                <td>{this.ratingToDot(sci.dayAnxietyRating)}</td>
                <td>{sci.tookPillToSleep ? this.ratingToDot(2) : this.ratingToDot(3)}</td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  }

  public render() {
    const { sleepCalendar } = this.props
    const { displayModal } = this.state

    if (!sleepCalendar)
      return null

    const handleClose = () => this.setState({displayModal: false});
    const handleShow = () => this.setState({displayModal: true});

    return <>
      <div className="agenda-sommeil-summary-cta block-content text-center my-4">
        <SvgIcon icon={iconCalendarSvg} />
        <p>Voir mon agenda de sommeil des derniers jours</p>

        <Button variant="btn-outline-primary" onClick={handleShow} classname="mx-auto">
          <SvgIcon icon={expandSvg} classname="mr-2" />
          Voir mon agenda sommeil
        </Button>
      </div>
      <Modal
        show={displayModal}
        centered
        size="lg"
        backdrop={true}
        keyboard={true}
        onHide={handleClose}
        fullscreen={true}
        dialogClassName='calendar-summary-modal'
      >
        <Modal.Header closeButton>
          <h3>Agenda de Sommeil</h3>
        </Modal.Header>
        <Modal.Body>{this.renderCalendarSummary()}</Modal.Body>
      </Modal>
    </>
  }
}

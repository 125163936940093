import React, { Component, Fragment } from 'react'
import { LayoutStep, LayoutSide, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { ContactForm } from 'components'
import { isUndefined, isNil } from 'lodash'
import { specialtyI18NKey } from 'helpers/doctor-type'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import { I18n } from 'react-redux-i18n'
import '../styles.sass'

class BCFirstMessage extends Component<BookingConsultationStepProps> {

  private handleMessageSubmit(message: string) {
    const {
      profile,
      doctor,
      navigation,
      createMessage,
    } = this.props
    createMessage(doctor.userId, message, profile.userId as number)
    navigation.goNext()
  }
  public render() {

    return (
      <LayoutStep>
        <LayoutMain variant="small">
          <h3>{I18n.t(`onboarding.contactForm.${specialtyI18NKey(undefined)}.stepTitle`)}</h3>
          <ContactForm onSubmit={message => this.handleMessageSubmit(message)} />
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default withNavigation(BCFirstMessage)

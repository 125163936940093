import React, { Component, Fragment } from 'react'
import { upperCase, isUndefined, filter, join, trim, uniqueId } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { Button, CouponInput, SvgIcon } from 'components'
import schema from 'utils/models/schemas/creditCard'
import MaskedInput from 'react-text-mask'
import { Formik } from 'formik'
import { Form, Col } from 'react-bootstrap'
import T from 'types'
import securePaymentSvg from 'assets/icons/payment/methods/securePayment.svg'

interface IProps {
  cgu?: string
  terms?: string
  defaultFirstName?: string
  defaultLastName?: string
  loading?: boolean
  submitPayment: (p: T.PaymentPayload) => void
  paymentMethod: T.PaymentMethodHash
  paymentButtonLabel: string
  onUseExistingCard?: () => void
  applyCoupon?: (coupon: string) => void
  activeCoupon?: string
}

class NewCreditCardForm extends Component<IProps> {
  uniquePrefix: string

  constructor(props: IProps) {
    super(props)
    this.uniquePrefix = uniqueId()
  }

  public handleGlobalSubmit = (data: any) => {
    const {
      submitPayment,
      paymentMethod
    } = this.props
    const { name, number, expiry, verificationValue } = data

    const [month, year] = expiry.split('/')

    const creditCardPayload: T.MangopayPaymentCardPayload = {
      name,
      expiry,
      number,
      verificationValue,
      month: trim(month),
      year: trim(year),
    }
    const paymentPayload: T.PaymentMethodWithData = {
      paymentMethod,
      data: creditCardPayload,
    }

    submitPayment(paymentPayload)
  }

  public render() {
    const { defaultFirstName, defaultLastName, cgu, terms, paymentButtonLabel, loading, onUseExistingCard, applyCoupon, activeCoupon } = this.props

    const nameParts = filter([defaultFirstName, defaultLastName], el => !!el)
    const defaultCreditCardHolderName = upperCase(join(nameParts, ' '))
    return (
      <Fragment>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: defaultCreditCardHolderName,
            number: '',
            expiry: '',
            verificationValue: '',
            cguChecked: isUndefined(cgu),
            termsChecked: isUndefined(terms),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fragment>
                <div className="">
                  <Form.Group controlId={this.scopedIdFor("new-cc-name")}>
                    <Form.Label>{I18n.t(`paymentForm.fields.labels.name`)}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.name}
                      placeholder={I18n.t(`paymentForm.fields.placeholders.name`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId={this.scopedIdFor("new-cc-number")}>
                    <Form.Label>{I18n.t(`paymentForm.fields.labels.number`)}</Form.Label>
                    <Form.Control
                      type="tel"
                      name="number"
                      as={MaskedInput}
                      mask={[
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, /\d/
                      ]}
                      placeholderChar="•"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.number}
                      placeholder={I18n.t(`paymentForm.fields.placeholders.number`)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.number}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Group controlId={this.scopedIdFor("new-cc-expiry")}>
                        <Form.Label>{I18n.t(`paymentForm.fields.labels.expiry`)}</Form.Label>
                        <Form.Control
                          as={MaskedInput}
                          mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                          placeholderChar=" "
                          type="tel"
                          name="expiry"
                          value={values.expiry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.expiry}
                          placeholder={I18n.t(`paymentForm.fields.placeholders.expiry`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.expiry}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={this.scopedIdFor("new-cc-cryptogram")}>
                        <Form.Label>
                          {I18n.t(`paymentForm.fields.labels.verificationValue`)}
                        </Form.Label>
                        <Form.Control
                          as={MaskedInput}
                          mask={[/\d/, /\d/, /\d/]}
                          placeholderChar=" "
                          type="tel"
                          name="verificationValue"
                          value={values.verificationValue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.verificationValue}
                          placeholder={I18n.t(`paymentForm.fields.placeholders.verificationValue`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.verificationValue}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </div>
                {onUseExistingCard && (
                  <a className="switch-credit-card-method" onClick={() => onUseExistingCard()}>
                    Utiliser une carte existante
                  </a>
                )}
                {applyCoupon && (
                  <CouponInput activeCoupon={activeCoupon} loading={loading} applyCoupon={applyCoupon} />
                )}
                <div>
                  {cgu && (
                    <Form.Group>
                      <Form.Check id={this.scopedIdFor("new-cc-cgu")}>
                        <Form.Check.Input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.cguChecked}
                          checked={values.cguChecked}
                          name="cguChecked"
                        />
                        <Form.Check.Label className="cgu-label">
                          <span dangerouslySetInnerHTML={{ __html: cgu }} />
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.cguChecked}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                  )}
                  {terms && (
                    <Form.Group>
                      <Form.Check id={this.scopedIdFor("new-cc-terms")}>
                        <Form.Check.Input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.termsChecked}
                          checked={values.termsChecked}
                          name="termsChecked"
                        />
                        <Form.Check.Label className="cgu-label">
                          <span dangerouslySetInnerHTML={{ __html: terms }} />
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.termsChecked}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                  )}
                </div>
              </Fragment>
              <Button
                classname="btn-block"
                type="submit"
                loading={loading}
              >
                <SvgIcon icon={securePaymentSvg} classname="button-inline-svg" />
                {paymentButtonLabel}
              </Button>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
  private scopedIdFor = (field: string) => {
    return `${this.uniquePrefix}_${field}`
  }
}

export default NewCreditCardForm

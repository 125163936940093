import React, { Component, RefObject } from 'react'
import classnames from 'classnames'
import { isEmpty, map } from 'lodash'
import T from 'types'
import cx from 'classnames'
import { I18n } from 'react-redux-i18n'
import { BlockHeader } from 'components'
import moment from 'constants/moment'

interface IProps {
  handleSelectConsultation: (consultation: T.Consultation | null) => void
  refreshMessages: (consultation: T.Consultation) => void
  displayMessage: (message: T.Message) => void
  renderSendForm: () => void
  conversationMessages: T.Message[]
  actionLoading?: string[]
  ongoingConsultations: T.Consultation[]
  finishedConsultations: T.Consultation[]
  showPatientNotification: boolean,
  selectedConsultation: T.Consultation | null
  chatMessagesList: RefObject<HTMLDivElement>
}

class ChatboxV2Desktop extends Component<IProps> {

  public render() {
    const {
      ongoingConsultations,
      finishedConsultations,
      conversationMessages,
      showPatientNotification,
      displayMessage,
      renderSendForm,
      chatMessagesList,
    } = this.props

    return (
      <>
        <BlockHeader icon="clinic.chat" title="Mes consultations par messages" />
        <div className="ongoing-chats-container-desktop d-flex align-items-stretch">
          <div className="ongoing-chats-left">
            <ul className="nav nav-pills ongoing-doctor-list d-flex flex-column">
              {!isEmpty(ongoingConsultations) && (
                <div className="border-bottom ongoing-chats-header">
                  <div className="ml-3 my-2 font-weight-semi">Consultations en cours</div>
                </div>
              )}
              {map(ongoingConsultations, (consultation: T.Consultation) =>
                this.renderConsultation(consultation)
              )}
              {!isEmpty(finishedConsultations) && (
                <div className="border-top border-bottom ongoing-chats-header">
                  <div className="ml-3 my-2 font-weight-semi">Consultations terminées</div>
                </div>
              )}
              {map(finishedConsultations, (consultation: T.Consultation) =>
                this.renderConsultation(consultation)
              )}
            </ul>
          </div>
          <div className="ongoing-chats-right flex-grow-1">
            <div className="chatroom chatbox-v2">
              <div ref={chatMessagesList} className="chat-list">
                {map(conversationMessages, message => displayMessage(message))}
              </div>
              <p className={classnames('chat-patient-notification', { active: showPatientNotification })}
              >
                {I18n.t('chat.consultation.sentMessage')}
              </p>
              {renderSendForm()}
            </div>
          </div>
        </div>
      </>
    )
  }

  private renderConsultation = (consultation: T.Consultation) => {
    const {
      selectedConsultation,
      handleSelectConsultation,
    } = this.props
    return (
      <li className="nav-item" key={`desktop-consultation-${consultation.id}`}>
        <a onClick={() => handleSelectConsultation(consultation)} className={cx("ongoing-chats-doctor d-flex align-items-center nav-link flex-row", {
          active: (selectedConsultation && selectedConsultation.id == consultation.id),
          finished: consultation.state === "finished"
        })}>
          <img className="doctor-image mr-2" src={consultation.doctorAvatarUrl} alt={consultation.doctor} />
          <div className="doctor-right flex-grow-1">
            {consultation.lastChatMessage && consultation.lastChatMessage.isUnread && <div className="unread-message">Nouveau message</div>}
            <div className="doctor-right-name">{consultation.doctor}</div>
            <div className="doctor-right-specialty">{(consultation.fullDoctor && consultation.fullDoctor.speciality) || 'Praticien'}</div>
            <div className="d-flex align-items-end">
              <div className="doctor-right-last-message flex-grow-1">{consultation.lastChatMessage && this.showLastMessagePreview(consultation.lastChatMessage.body)}</div>
              <div className="doctor-right-last-message-at text-right">{consultation.lastChatMessage && moment(consultation.lastChatMessage.sentAt).fromNow()}</div>
            </div>
          </div>
        </a>
      </li>
    )
  }

  private showLastMessagePreview(str: string) {
    const tmpDiv = document.createElement('div')
    tmpDiv.innerHTML = str

    return tmpDiv.innerText
  }
}

export default ChatboxV2Desktop

import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import Media from 'react-media'
import { I18n } from 'react-redux-i18n'
import { map } from 'lodash'
import moment from 'constants/moment'
import { Table, Button } from '../..'
import {
  formatedCollection,
  renderLegendLastPrescription,
} from 'utils/helpers'
import PrescriptionConsultationInfo from '../PrescriptionConsultationInfo'
import T from 'types'

interface IProps extends RouteComponentProps<any> {
  prescription: T.Prescription,
}

class PhytotherapyPrescriptionContent extends Component<IProps> {

  private handleClickOrder() {
    const { prescription, history } = this.props
    history.push(`/phytotherapy/${prescription.id}`)
  }

  private renderMedication() {
    const { prescription } = this.props
    const displayColumns = ['phytotherapy', 'dosage']

    const medication = formatedCollection(
      map(
        prescription.prescriptionItems,
        ({ molecule, dosage }: T.PrescriptionItem) => ({
          dosage,
          phytotherapy: I18n.t(`prescriptions.items.${molecule}`)
        }),
      ),
      displayColumns,
    )

    return (
      <div className="">
        <Table
          displayColumns={displayColumns}
          collection={medication}
          cta={{ title: 'consulter' }}
        />
        <div className="text-center">
          <Button onClick={() => this.handleClickOrder()}>
            Me faire livrer
          </Button>
        </div>
      </div>
    )
  }

  public render() {

    const { prescription } = this.props
    return (
      <Fragment>
        {!prescription.explainedDuringConsultation && (
          <div className="phyto-prescription-explanation" dangerouslySetInnerHTML={{ __html: I18n.t('dashboard.phyto_recommendation') }} />
        )}
        <PrescriptionConsultationInfo prescription={prescription} />
        {this.renderMedication()}
      </Fragment>
    )
  }
}

export default withRouter(PhytotherapyPrescriptionContent)

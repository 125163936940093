import React, { Component } from 'react'
import Linkify from 'react-linkify'
import { get } from 'lodash'
import moment from 'constants/moment'
import DOMPurify from 'dompurify'
import './styles.sass'
import T from 'types'


// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute('target') &&
    (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new');
  }
});

const LinkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): React.ReactNode => {
  return (
    <a
      key={key}
      href={decoratedHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      {decoratedText}
    </a>
  );
};

interface IProps {
  message: T.Message,
  profile: T.Profile,
}

class ChatboxMessage extends Component<IProps> {
  private renderDateBlock(message: T.Message) {
    const createdAt = moment(message.createdAt)
    const date = createdAt.format('L')
    const hour = createdAt.format('LT')

    return (
      <span className="chat-block-date">
        <span>{date}</span>
        <span>à {hour}</span>
      </span>
    )
  }

  private renderMessagesBlock(message: T.Message, profile: T.Profile) {
    return (
      <div className="chat-message-container">
        <p className="message-body">
          <Linkify componentDecorator={LinkDecorator}>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.body) }} />
          </Linkify>
        </p>
        {this.renderDateBlock(message)}
      </div>
    )
  }

  public render() {
    const { message, profile } = this.props
    const className = get(message, 'sender.id') === get(profile, 'userId')
      ? 'right'
      : 'left'

    return (
      <div className={`chat chat-block-info ${className}`}>
        {this.renderMessagesBlock(message, profile)}
      </div>
    )
  }
}

export default ChatboxMessage

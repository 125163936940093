import T from 'types'
import React, { FC } from 'react'
import NavbarStepper from 'page/common/NavbarStepper'
import '../styles.sass'
import { withNavigation } from 'components/Workflow'

interface IProps extends T.WorkflowNavigation {
  children: JSX.Element | JSX.Element[],
}

export const UCStep: FC<IProps> = (props: IProps) => {
  const { children, navigation } = props
  const { steps, stepIndex, goPrevious, stepperStyle } = navigation

  const formattedStep: T.StepperStep[] = steps.map((step) => {
    return { label: step.label || step.path, path: step.path }
  })

  return (
    <>
      {stepperStyle === 'numbers' && (
        <NavbarStepper showPhoneNumber={false} steps={formattedStep} goPrevious={goPrevious} currentStep={stepIndex} />
      )}
      {children}
    </>
  )
}

export default withNavigation(UCStep)

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isNil } from 'lodash'
import moment from 'constants/moment'
import { ApiService } from 'services'
import * as SC from 'utils/models/schemas/constants'

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const mangopayEmailRegexp = /([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

const phoneValidation = (value: any) => {
  const regexp = RegExp(phoneRegExp)
  return !isNil(value) && regexp.test(value) && isValidPhoneNumber(value)
}

const createSchema = (successCallack: (patientDataWithId: any) => void) => {
  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .email(I18n.t(`onboarding.personnalData.error.emailError`))
      .required('Ce champ est obligatoire')
      .test({
        name: 'valid-email',
        test: (value: any, context: any) => {
          return new Promise<boolean | yup.ValidationError>((resolve, reject) => {
            const regexp = RegExp(mangopayEmailRegexp)
            const validValue = !isNil(value) && regexp.test(value)
            if (validValue) {
              const { email, programSlug } = context.parent
              const patientData = {
                email,
              }

              ApiService.createPatient(patientData, programSlug)
                .then((serverData: any) => {
                  successCallack(serverData)
                  return resolve(true)
                })
                .catch((error: any) => {
                  return resolve(
                    context.createError({
                      path: 'email',
                      message: SC.UNAVAILABLE_EMAIL_KEY,
                    }),
                  )
                })
            } else {
              return resolve(
                context.createError({
                  path: 'email',
                  message: I18n.t(`onboarding.personnalData.error.emailError`),
                }),
              )
            }
          })
        },
      }),
    cguChecked: yup.bool().oneOf([true], "Merci d'accepter les conditions")
  })
  return schema
}

const schema = createSchema(()=> {})
export default schema
export { createSchema }

import React from 'react'
import { Survey, ReactQuestionFactory, ReactElementFactory } from 'survey-react-ui'
import { Model, PageModel } from 'survey-core'
import { map } from 'lodash'
import SurveyQuestionRadiogroupExtended from './extension/SurveyQuestionRadiogroupExtended'
import SurveyQuestionCheckboxExtended
  , {
  SurveyQuestionCheckboxItemExtended,
} from 'page/common/survey/extension/SurveyQuestionCheckboxExtended'

const buildSurvey = (
  survey: Model,
  prefix: string,
): Model => {
  const currentModel = new Model(survey)
  currentModel.locale = 'fr' // set locale for survey translation
  currentModel.showProgressBar = 'bottom' // show progress bar for survey (default is 'off')
  currentModel.goNextPageAutomatic = true
  currentModel.focusFirstQuestionAutomatic = true
  const pagesToDelete: PageModel[] = []

  map(currentModel.pages, page => {
    if (!page.name.startsWith(prefix)) {
      pagesToDelete.push(page)
    }
  })

  map(pagesToDelete, (page: PageModel) => currentModel.removePage(page))
  return currentModel
}

// survey custom style
export const surveyCss = {
  body: 'sv_main',
  container: 'sv_container',
  footer: 'onboarding-container-footer',
  row: 'sv_row',
  navigation: {
    complete: 'btn-primary btn-md onb-complete-btn sv_complete_btn',
    prev: 'btn-outline-primary btn-md onb-prev-btn sv_prev_btn',
    next: 'btn-primary btn-md onb-next-btn sv_next_btn',
    start: 'btn-primary btn-md onb-start-btn sv_start_btn',
  },
  navigationButton: 'btn',
  page: {
    root: 'question-container onboarding-container-bloc',
    title: 'sv_page_title',
  },
  question: {
    number: 'd-none',
    header: 'question-header',
    description: 'question-description',
    mainRoot: 'sv_qstn d-flex flex-column align-items-center',
    title: 'h3 question-title',
  },
  root: 'survey-container',
  text: 'form-control',
  comment: 'form-control',
  progress: 'd-none',
  progressBar: 'progress-bar',
  checkbox: {
    label: 'btn btn-block onboarding-choice question-label',
    other: 'form-control',
    controlLabel: 'question-control-label',
    item: 'survey-item',
    itemControl: 'sv-visuallyhidden survey-checkbox-control',
    materialDecorator: 'survey-checkbox-decorator',
    itemDecorator: 'survey-checkbox-svg',
    itemDisabled: 'survey-item-disabled',
  },
  radiogroup: {
    label: 'btn btn-block onboarding-choice question-label',
    other: 'form-control',
    controlLabel: 'question-control-label',
    item: 'survey-item',
    itemControl: 'sv-visuallyhidden d-none survey-radio-control',
  },
  rating: {
    item: 'sd-rating__item btn btn-block onboarding-choice question-label justify-content-center',
    selected: 'active',
    minText: 'sv_q_rating_min_text',
    itemText: 'question-control-label',
    maxText: 'sv_q_rating_max_text',
  },
  error: {
    root: 'survey-error',
  },
  matrix: {
    root: 'table sv_q_matrix',
    label: 'sv_q_m_label',
    cellText: 'sv_q_m_cell_text',
    cellTextSelected: 'sv_q_m_cell_selected bg-primary',
    cellLabel: 'sv_q_m_cell_label',
  },
  imagepicker: {
    root: 'survey-root-imgsel',
    item: 'survey-imgsel', // This one is added the checked class
    itemChecked: 'checked',
    itemInline: 'survey-imgsel-inline',
    label: 'survey-imgsel-label onboarding-choice',
    itemControl: 'survey-imgsel-control-item d-none',
    image: 'survey-imgsel-image img-fluid',
    itemText: 'survey-imgsel-text',
    checkedItemSvgIcon: 'd-none',
  },
  image: {
    root: ' ',
    image: 'img-fluid',
  },
  file: {
    root: 'sv_q_file',
    fileDecorator: 'survey-file-decorator',
    placeholderInput: 'sv_q_file_placeholder d-none',
    preview: 'sv_q_file_preview sd-file__preview',
    rootAnswered: '',
    removeButton: 'd-none', // Button to clean all images
    fileInput: 'd-none',
    removeFile: 'sv-hidden sv_q_file_remove d-none', // link below image to delete it
    wrapper: 'sd-file__image-wrapper',
    removeFileSvg: 'survey-remove-file-svg',
    fileSignBottom: 'file-sign-bottom',
    removeButtonBottom: 'sv-hidden d-none',
    dragAreaPlaceholder: 'd-none d-md-block',
    fileList: 'file-list',
    defaultImage: 'sv-hidden defaultImage d-none',
    chooseFile: 'sv_q_file_choose_button btn btn-c-rust',
    noFileChosen: 'sv_q_file_placeholder d-none',
    removeFileSvgIconId: 'icon-removefile',
  },
}

ReactElementFactory.Instance.registerElement('survey-checkbox-item', (props: any) => {
  return <SurveyQuestionCheckboxItemExtended {...props} />
})

ReactQuestionFactory.Instance.registerQuestion('radiogroup', (props: any) => {
  return <SurveyQuestionRadiogroupExtended {...props} />
})

ReactQuestionFactory.Instance.registerQuestion('checkbox', (props: any) => {
  return <SurveyQuestionCheckboxExtended {...props} />
})

export {
  buildSurvey,
}

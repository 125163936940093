import React, { FC } from 'react'
import T from 'types'
import { isUndefined, last, find } from 'lodash'
import '../../styles.sass'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'
import build from 'redux-object'
import * as P from 'constants/productsSlugs'

const UCDrugsPrescriptionsOverview: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation, addToCart, storefront } = props
  const { steps, stepIndex, goPrevious, goNext } = navigation

  function addPrescriptionVariantToCart(slug: string) {
    const products = build(storefront, 'product')
    const product = find(products, (product: T.Product) => product.slug === slug)
    if (!isUndefined(product)) {
      const variant = last(product.variantsIncludingMaster)
      if (!isUndefined(variant)) {
        addToCart(variant, (e : any) => goNext())
      }
    }
  }

  return (
    <UCStep>
      <div>Mes ordonnances</div>
      <button>Aperçu rapide de l’ordonnance TODO</button>
      <button onClick={() => addPrescriptionVariantToCart(P.PRESCRIPTION_DELIVERY)}>Recevoir mon ordonnance par la poste</button>
      <button onClick={() => addPrescriptionVariantToCart(P.PRESCRIPTION_DOWNLOAD)}>Télécharger l’ordonnance</button>
    </UCStep>
  )
}

export default withNavigation(UCDrugsPrescriptionsOverview)

import React from 'react'
import { NavLink } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import { getIcon } from 'assets/icons'
import SvgIcon from '../SvgIcon'

type Props = {
  icon: string,
  label: string
  path?: string
  onClick?: () => void
  speciality?: string
}

const NEW_CONSULTATION = 'new-consultation'

export const defaultNavLink = (speciality: string | undefined, label: string): string => {
  return label == NEW_CONSULTATION && speciality ? `/${label}/${speciality}` : `/${label}`
}

export default ({ icon, label, path, speciality }: Props) => (
  <NavLink
    to={path || defaultNavLink(speciality, label)}
    className="sidebar-item"
    exact={true}
  >
    <span className="label">{I18n.t(`sidebar.${label}`)}</span>
  </NavLink>
)

export const SideBarAction = ({ icon, label, onClick }: Props) => (
  <a
    className="sidebar-item"
    href=""
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      onClick && onClick()
    }}
  >
    <span className="label">{I18n.t(`sidebar.${label}`)}</span>
  </a >
)

export const SideBarActionLogoRight = ({ icon, label, onClick }: Props) => (
  <a
    className="sidebar-item d-flex"
    href=""
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      onClick && onClick()
    }}
  >
    <span className="label flex-grow-1 align-self-center">{I18n.t(`sidebar.${label}`)}</span>
    <SvgIcon
      classname="icon align-self-center"
      icon={getIcon(icon)}
    />
  </a >
)

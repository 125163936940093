import React, { Component, Fragment } from 'react'
import { get, isEmpty, map } from 'lodash'
import classnames from 'classnames'
import T from 'types'
import moment from 'constants/moment'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Block, Button } from '..'
import download from 'assets/icons/download.svg'
import { getIcon } from 'assets/icons'
import { dateFormater } from 'utils/helpers'
import { CookiesHelper } from 'helpers'
import { lastPrescriptionWithMolecule } from 'utils/helperComponents/lastConsultationHelpers'
import { BlockHeader } from 'components'
import Carousel, { CarouselSlide } from 'components/Carousel'
import SvgIcon from '../SvgIcon'
import Media from 'react-media'

import './styles.sass'

interface IProps extends RouteComponentProps<any> {
  collectionPrescription: T.Prescription[]
  activePrescriptionsKind: string[]
}

class TherapyPrescriptionBlock extends Component<IProps> {

  private getTitle(kind: string, article: boolean = false) {
    if (article)
      switch (kind) {
        case 'hair':
          return `pour vos cheveux`
        case 'sleep':
          return `pour le sommeil`
        case 'digestion':
          return `pour la digestion`
        case 'weight':
          return `pour la perte de poids`
        case 'sexual':
          return `de sexologie`
        case 'charles_psycho_sexo':
          return `de psycho-sexo`
        case 'charles_couple_therapy':
          return `de thérapie de couple`
        default:
          return ''
      }

    switch (kind) {
      case 'hair':
        return `cheveux`
      case 'sleep':
        return `sommeil`
      case 'digestion':
        return `digestion`
      case 'weight':
        return `poids`
      case 'sexual':
        return `sexologie`
      case 'charles_psycho_sexo':
        return `psycho-sexo`
      case 'charles_couple_therapy':
        return `thérapie de couple`
    }

    return ''
  }

  private renderRecommendationTherapy(kind: string) {
    const { collectionPrescription } = this.props

    const selectedPrescription = lastPrescriptionWithMolecule(collectionPrescription, kind)
    const actualDownloadURL = `${
      get(selectedPrescription, 'downloadUrl')
    }?access_token=${CookiesHelper.getPureOauthAccessToken()}`

    const doctor = get(selectedPrescription, 'doctor')

    return <div className="home-page-card block-content therapy-recommendation-card">
      <h3 className="therapy-card-title mb-2 text-center">Consultation {this.getTitle(kind)}</h3>
      <div className="therapy-card-body d-flex flex-row align-items-center mb-2">
        <div className="therapy-image">
          <SvgIcon icon={getIcon(`therapies.${kind}`)} classname="" />
        </div>
        <div className="therapy-description">
          <p>
            <b>Le docteur {doctor}</b><br/>
            vous recommande une consultation {this.getTitle(kind, true)} sur la plate-forme.
          </p>
        </div>
      </div>
      <Link to={`/new-consultation/${kind}`} className="btn btn-primary btn-block mt-auto">
        Prendre une consultation
      </Link>
    </div>
  }

  public render() {
    const { activePrescriptionsKind } = this.props
    const title = "Mes recommandations de consultations"

    if (isEmpty(activePrescriptionsKind))
      return null;

    return (
      <div className="home-page-carousel">
        <BlockHeader title={title} icon='clinic.largeSpecialist' />
        <Carousel
          breakpoints={{}}
          slidesPerView="auto"
          spaceBetween={20}
        >
          {map(activePrescriptionsKind, (kind: string, index: number) => (
            <CarouselSlide key={`therapy_prescription_slide_${index}`}>
              { this.renderRecommendationTherapy(kind) }
            </CarouselSlide>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default withRouter(TherapyPrescriptionBlock)

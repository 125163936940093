import React, { Component, Fragment, RefObject } from 'react'
import './styles.sass'
import { SvgIcon } from 'components'

interface IProps {
  onFileSubmit: (formdata: FormData) => void
  classname: string
  wording: string
  icon: any
}

class MedicalDocumentUpload extends Component<IProps> {

  form: RefObject<HTMLFormElement>
  fileInput: RefObject<HTMLInputElement>

  constructor(props: IProps) {
    super(props)

    this.form = React.createRef()
    this.fileInput = React.createRef()
  }

  private handleChange(event: React.FormEvent<EventTarget>) {
    event.preventDefault()
    const formData = new FormData(this.form.current as HTMLFormElement)
    this.props.onFileSubmit(formData)
  }

  public render() {
    const { classname, wording, icon } = this.props
    return (
      <form
        ref={this.form}
        className="medical-upload-form mt-4"
      >
        <label
          htmlFor="profile_medical_documents"
          className={classname}
        >
          <SvgIcon icon={icon} classname="mr-2" />
          <span>{wording}</span>
        </label>
        <input
          type="file"
          name="profile[medical_documents][]"
          id="profile_medical_documents"
          className="hidden"
          ref={this.fileInput}
          onChange={e => this.handleChange(e)}
        />
      </form>
    )
  }
}

export default MedicalDocumentUpload

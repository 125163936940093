import React, { Fragment } from 'react'
import { map } from 'lodash'
import { SvgIcon } from 'components'
import T from 'types'
import { getIcon } from 'assets/icons';
import { LayoutStep, LayoutMain, LayoutSide } from '../LayoutStep'
import './styles.sass'
import { I18n } from 'react-redux-i18n'

interface IProps {
  handleChosenCategory: (category: T.SpecialtyCategory) => void,
  specialtyCategories: T.SpecialtyCategory[]
}

class BCCategoryPicker extends React.Component<IProps> {

  render() {

    const { specialtyCategories } = this.props
    const filteredSpecialtyCategories = specialtyCategories.filter((category: T.SpecialtyCategory) => {
      return category.featurePreview !== true || process.env.REACT_APP_LIVE_PROD_ENVIRONMENT !== "true"
    })

    return (
      <LayoutStep>
        <LayoutMain>
          <div className="specialty-categories-picker">
            <h3 className="mb-3">
              Nos spécialistes sont à votre écoute
            </h3>
            <div className="mb-4">
              {I18n.t("home.reconsultation.categorySelection")}
            </div>
            <div className="specialty-categories-container">
              {map(filteredSpecialtyCategories, (category, index) => (
                <Fragment key={`category_${index}`}>
                  <div
                    className={`specialty-categories-item-selector border-${category.theme}`}
                    onClick={() => this.props.handleChosenCategory(category)}
                  >
                    <SvgIcon icon={getIcon(category.icon)} classname="specialty-category-icon" />
                    <div className="d-flex align-items-center">
                      {category.title}
                      <div className="specialty-category-name-chevron" />
                    </div>
                  </div>
                  <div className="specialty-categories-item-separator" />
                </Fragment>
              ))}
            </div>
          </div>
        </LayoutMain>
      </LayoutStep>
    )
  }
}

export default BCCategoryPicker


import moment from 'constants/moment'

export const friendlyDisplayDay = (date: moment.Moment): string => {
  if (date.isSame(moment(), 'day')) {
    return `aujourd'hui`
  }
  if (date.isSame(moment().add(1, 'days'), 'day')) {
    return `demain`
  }
  return date.format('dddd DD MMMM')
}

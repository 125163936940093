import React, { FC, useState } from 'react'
import T from 'types'
import { Workflow } from 'components'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import UCDrugsPharmacy from './UCDrugsPharmacy'
import UCDrugsQuantity from './UCDrugsQuantity'
import UCDrugsPrescriptionsOverview from './UCDrugsPrescriptionsOverview'
import UCDrugsPrescriptionsThankyou from './UCDrugsPrescriptionsThankyou'
import { withNavigation } from 'components/Workflow'
import { get, isNil } from 'lodash'

const UCDrugsFunnelOptions: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {

  const { profile, navigation, unifiedCheckout } = props
  const { forPrescriptions } = unifiedCheckout
  const { steps, stepIndex, goPrevious, goNext } = navigation
  const innerDrugsSteps: T.WorkflowStep[] = forPrescriptions ? [
    {
      path: 'overview',
      comp: <UCDrugsPrescriptionsOverview {...props} />,
    },
    {
      path: 'thankyou',
      comp: <UCDrugsPrescriptionsThankyou {...props} />,
    }
  ] : [
    {
      path: 'pharmacie',
      comp: <UCDrugsPharmacy {...props} />,
    },
    {
      path: 'quantite',
      comp: <UCDrugsQuantity {...props} />,
    }
  ]

  return (
    <UCStep>
      <Workflow steps={innerDrugsSteps} complete={goNext} goBack={goPrevious} level={3} />
    </UCStep>
  )
}

export default withNavigation(UCDrugsFunnelOptions)

import React, { FC, useState, useEffect } from 'react'
import { map, isEmpty, isUndefined, filter, find, includes } from 'lodash'
import T from 'types'
import '../../styles.sass'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'
import { variantsOptions } from 'helpers/drugs-variants-helper'
import build from 'redux-object'

const UCDrugsQuantity: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const [productsWithVariants, setProductsWithVariants] = useState(null)
  const [selectedVariants, setSelectedVariants] = useState([])
  const [variantsToAdd, setVariantsToAdd] = useState({})
  const [error, setError] = useState("")

  const { navigation, consultation, getRecommendedProducts, order, unifiedCheckout, storefront, addToCart } = props
  const { pharmacistId } = unifiedCheckout
  const { steps, stepIndex, goPrevious, goNext } = navigation

  function handleChange(e: any) {
    const oldVariantsToAdd: any = variantsToAdd
    oldVariantsToAdd[e.target.name] = e.target.value
    setVariantsToAdd(oldVariantsToAdd)
  }

  function handleSubmit(e: any) {
    const values = Object.values(variantsToAdd)
    if(isEmpty(values)) {
      setError("there is an error")
    } else {
      const variants = build(storefront, 'variant')
      const filteredVariants = filter(variants, (variant: T.Variant) => includes(values, variant.id))
      if(isEmpty(filteredVariants)) {
        map(filteredVariants, (variant: T.Variant) =>
          addToCart(variant, ()=> {})
        )
        goNext()
      }
    }
  }

  useEffect(() => {
    if (!isUndefined(getRecommendedProducts)) {
      getRecommendedProducts(order.number, consultation.id, pharmacistId).then((data: any) => {
        setProductsWithVariants(data.payload.drugsVariants)
      })
    }
  }, [])

  if (isEmpty(productsWithVariants)) {
    return null
  } else {
    return (
      <UCStep>
        <p>{error}</p> {/* TODO */}
        <form>
          {map(filter(productsWithVariants, (item: any) => item.hasAvailableVariants === true), (item: any, index: number) =>
            <div>
              <label>{item.productMolecule} {item.productGrammage}</label>
              <select name={item.productMolecule} onChange={handleChange}>
                {variantsOptions(item.data).map((option: any) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          )}
        </form>
        <button onClick={goPrevious}>previous</button>
        <button onClick={handleSubmit}>next</button>
      </UCStep>
    )
  }
}

export default withNavigation(UCDrugsQuantity)

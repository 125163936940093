import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import T from 'types'
import { isUndefined, split, map, isNull } from 'lodash'

interface IProps {
  doctor?: T.Doctor
  className?: string
  ulClassName?: string
}

class DoctorDetails extends Component<IProps> {
  render() {
    const { doctor, className, ulClassName } = this.props
    if (isUndefined(doctor) || isUndefined(doctor.description) || isNull(doctor.description)) {
      return null
    }
    const { description } = doctor
    const parts = map(split(description, /\r\n|\n|\r/), el => el.trim())

    return (
      <div className={className}>
        <ul className={cx('health-profesionnal-qualifications', ulClassName)}>
          {map(parts, (line, index) => (
            <li key={`line_${index}`}>{line}</li>
          ))}
        </ul>
      </div>
    )
  }
}

export default DoctorDetails

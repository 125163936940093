import React from 'react'
import T from 'types'
import digitalData from './digital-therapy'
import timeData from './time-data'
import masterData from './master-data'
import sommeilData from './sommeil-data'
import vaginismeData from './vaginisme-data'

export const getProgramPresentation = (slug: string): T.ProgramPresentation => {
  switch (slug){
    case 'master-program':
      return masterData()
    case 'sommeil-program':
      return sommeilData()
    case 'digital-therapy':
      return digitalData()
    // case 'vaginisme-program':
    //   return throw new Error("Unsupported - use getMiaProgramPresentation")
    case 'time-program':
      return timeData()
    default:
      return timeData()
  }
}

export const getMiaProgramPresentation = (slug: string): T.MiaProgramPresentation => {
  switch (slug){
    case 'vaginisme-program':
      return vaginismeData()
      default:
      return vaginismeData()
  }
}

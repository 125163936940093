import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'components'
import * as M from 'constants/messages'

export type SecureChallengeStatusRouteArgs = {
  status: string
}
export default (props: RouteComponentProps<SecureChallengeStatusRouteArgs>) => {

  useEffect(() => {
    const { match } = props
    const status = match.params.status
    const success = status === "success"
    const message = success ? M.CLOSE_IFRAME_SUCCESS : M.CLOSE_IFRAME_FAILURE

    opener.postMessage(message, "*")
    parent.postMessage(message, "*")

    window.close()
  }, [])

  return (
    <div/>
  )
}

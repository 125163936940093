import React, { Component } from 'react'
import schema from 'utils/models/schemas/sleepCalendar'
import { Formik } from 'formik'
import { Form, Col, Modal, Row } from 'react-bootstrap'
import { Block } from 'components'
import { map, range } from 'lodash'
import T from 'types'

export interface Props {
  sleepCalendar: T.SleepCalendar
  updateSleepCalendar: (params: object) => void
  handleClick: () => void
  title: string
}

interface IState {
}

class SleepCalendarSettings extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
  }

  public handleCalendarItemCreation(e: any) {
    const { updateSleepCalendar, handleClick } = this.props

    const wakeupTimeTimestamp = e.wakeupTimeHour * 60 + Math.floor(e.wakeupTimeMin)

    updateSleepCalendar(
      {
        sleep_calendar: {
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          wakeup_time: wakeupTimeTimestamp
        }
      })
    handleClick()
  }

  public render() {
    const { sleepCalendar, title } = this.props;

    return <Formik
      key="formik-sleepcalendar-item"
      onSubmit={(e: any) => this.handleCalendarItemCreation(e)}
      validateOnChange={true}
      validationSchema={schema}
      initialValues={{
        wakeupTimeHour: 8,
        wakeupTimeMin: 30
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <Form onSubmit={handleSubmit} className="w-100 p-3">
          <Form.Group>
            <h4 className="mb-3">{title}</h4>
            <Form.Label>Avant de débuter, quelle heure de lever est compatible avec votre mode de vie ? *</Form.Label>
            <br />
            <small>
              *cette heure de lever sera fixe et nous permettra de vous fournir un suivi au plus proche de vos besoins pendant toute la durée du programme.
            </small>
            <br />
            <br />
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                className="form-control-sm"
                style={{ maxWidth: '70px' }}
                name="wakeupTimeHour"
                value={values.wakeupTimeHour}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.wakeupTimeHour}
                placeholder={''}
              >
                {map(range(0, 24), (h: number) =>
                  <option
                    key={`wakeupTimeHour_${h}`}
                    label={`${h.toString().padStart(2, '0')}`}
                    value={h}
                  >
                    {`${h.toString().padStart(2, '0')}`}
                  </option>,
                )
                }
              </Form.Control>
              <span>H</span>
              <Form.Control
                as="select"
                className="form-control-sm"
                style={{ maxWidth: '70px' }}
                name="wakeupTimeMin"
                value={values.wakeupTimeMin}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.wakeupTimeMin}
                placeholder={''}
              >
                {map(range(0, 60, 5), (m: number) =>
                  <option
                    key={`wakeupTimeMin_${m}`}
                    label={m.toString().padStart(2, '0')}
                    value={m}
                  >
                    {m.toString().padStart(2, '0')}
                  </option>,
                )
                }
              </Form.Control>
            </div>
          </Form.Group>
          <button type="submit" className="btn btn-primary charles-btn mt-4">Enregistrer</button>
        </Form>
      )}
    </Formik>

  }
}

export default SleepCalendarSettings;

import React, { Component } from 'react'
import { filter, includes, isEmpty, map, flatten } from 'lodash'
import UnavailabilityNotice from './UnavailabilityNotice'
import AllConsultationSlots from './AllConsultationSlots'
import T from 'types'

import { CHAT } from 'constants/doctorSelectionStates'

interface IProps {
  collection: T.Doctor[],
  slot?: Date,
  consultation: string,
  medium: string,
  availableChannels: string[],
  handleSelectTimeSlot: (slot: Date) => void
}

class SubStepSlotChoice extends Component<IProps> {

  private handleSelectSlot = (currentSlot: Date) => {

    this.props.handleSelectTimeSlot(currentSlot)
  }

  public render() {
    const {
      medium,
      consultation,
      collection,
      availableChannels,
    } = this.props

    const filteredCollection = filter(collection, (doctor: T.Doctor) =>
      includes(doctor.communicationChannels, medium) &&
      includes(doctor.consultationTypes, consultation) &&
      (
        medium === CHAT ||
        !isEmpty(doctor[consultation === 'simple' ? 'freeSimpleSlots' : 'freeComplexSlots'])
      ),
    )

    const combinedSlots = flatten(map(filteredCollection, ((doctor) => {
      const freeTimeSlots = consultation === 'simple' ?
        doctor.freeSimpleSlots :
        doctor.freeComplexSlots
      return freeTimeSlots
    })))

    const bookedSlots = flatten(map(filteredCollection, ((doctor) => {
      const timeSlots = consultation === 'simple' ?
        doctor.bookedSimpleSlots :
        doctor.bookedComplexSlots
      return timeSlots
    })))
    const allSlots = flatten(combinedSlots.concat(bookedSlots))

    return (
      <div className="">
        {isEmpty(filteredCollection) && medium !== CHAT
          ? <UnavailabilityNotice />
          : (
            <div className="text-center">
              <h3 className="block-title">
                Sélectionnez un créneau pour votre consultation
              </h3>
              {includes(availableChannels, CHAT) && <div className="info-consultation-chat-if-no-slot">
                Si les créneaux proposés ne vous conviennent pas,
                vous pouvez choisir de téléconsulter par messages écrits
              </div>}
              <AllConsultationSlots
                availabilities={allSlots}
                freeSlots={combinedSlots}
                onSelectSlot={value => this.handleSelectSlot(value)}
              />
            </div>
          )
        }
      </div>
    )
  }
}

export default SubStepSlotChoice

import React, { Component, Fragment } from 'react'
import { get, isEmpty, isNil } from 'lodash'
import moment from 'constants/moment'
import { RouteComponentProps } from 'react-router'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { I18n } from 'react-redux-i18n'
import { LayoutStep, LayoutMain } from '../LayoutStep'
import { Button } from 'components'
import { ApiService } from 'services'
import tracker from 'utils/trackers'
import T from 'types'
import * as SC from 'utils/models/schemas/constants'
import { schemaWithoutEmail } from 'utils/models/schemas/personalInformation'
import MaskedInput from 'react-text-mask'
import { Formik } from 'formik'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TARGET_PLATFORM } from 'constants/config'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'

class BCPersonalInfos extends Component<BookingConsultationStepProps> {

  public handleGlobalSubmit = (data: any) => {
    const { updateProfile, navigation } = this.props
    const { firstName, lastName, birthDate, phoneNumber, cguAccepted } = data
    const formattedBirthdate = moment(birthDate, SC.DATE_INPUT_FORMAT).format(SC.DATE_OUTPUT_FORMAT)
    const patientData = {
      firstName,
      lastName,
      phoneNumber,
      cguAccepted,
      birthDate: formattedBirthdate
    }
    updateProfile(patientData)
    // TODO Track specialty on rebooking. Needs specialty. Also needs clearer spec on what to do
    // tracker.trackLead(specialty)
    navigation.goNext()
  }

  public render() {
    const { loading, profile } = this.props
    const title = (
      <span>
        Transmettez vos coordonnées au {TARGET_PLATFORM == 'mia' ? 'sexologue' : 'médecin'}
      </span>
    )
    return (
      <div className="personal-info-step">
        <Formik
          validationSchema={schemaWithoutEmail}
          onSubmit={this.handleGlobalSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: get(profile, 'firstName', ''),
            lastName: get(profile, 'lastName', ''),
            phoneNumber: get(profile, 'phoneNumber', '') || '',
            birthDate: moment(get(profile, 'birthDate', '')).format(
              SC.DATE_INITIAL_VALUE_FORMAT,
            ),
            cguAccepted: get(profile, 'cguAccepted', false),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <LayoutStep>
                <LayoutMain variant="medium">
                  <h3>{title}</h3>
                  <Fragment>
                    <div className="text-left">
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>{I18n.t(`onboarding.personnalData.firstName`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.firstName}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.firstName`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik02">
                        <Form.Label>{I18n.t(`onboarding.personnalData.lastName`)}</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.lastName}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.lastName`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik03">
                        <Form.Label>{I18n.t(`onboarding.personnalData.birthDate`)}</Form.Label>
                        <Form.Control
                          autoComplete="bday"
                          as={MaskedInput}
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          placeholderChar=" "
                          type="tel"
                          name="birthDate"
                          value={values.birthDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.birthDate}
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.birthDate`)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.birthDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationFormik04">
                        <Form.Label>
                          {I18n.t(`onboarding.personnalData.phoneNumber`)}
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="why-phone-is-required">{I18n.t(`onboarding.personnalData.tooltips.phoneNumber`)}</Tooltip>}
                          >
                            <span className="tooltip-info-icon ml-1">i</span>
                          </OverlayTrigger>
                        </Form.Label>
                        <PhoneInput
                          placeholder={I18n.t(`onboarding.personnalDataPlaceholders.phoneNumber`)}
                          numberInputProps={{ className: 'form-control' }}
                          value={values.phoneNumber}
                          onChange={(value: string) => {
                            setFieldValue('phoneNumber', value)
                          }}
                          onBlur={handleBlur}
                          defaultCountry="FR"
                          countryOptionsOrder={[
                            'FR',
                            'GP',
                            'GF',
                            'MQ',
                            'YT',
                            'NC',
                            'PF',
                            'RE',
                            'BL',
                            'MF',
                            'PM',
                            '|',
                            '...',
                          ]}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="onboarding-medium-inner-container">
                      <Form.Group>
                        <Form.Check name="cguAccepted" id="validationFormik10">
                          <Form.Check.Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.cguAccepted}
                            checked={values.cguAccepted}
                            name="cguAccepted"
                          />
                          <Form.Check.Label
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html: I18n.t('onboarding.personnalData.cgu'),
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cguAccepted}
                          </Form.Control.Feedback>
                        </Form.Check>
                      </Form.Group>
                    </div>
                  </Fragment>
                  <div className="d-flex flex-column flex-md-row-reverse justify-content-between">
                    <Button
                      classname="onb-next-btn mb-3 mb-md-0"
                      type="submit"
                    // loading={loading} // TODO V2 Loading
                    >
                      Valider
                    </Button>
                    <Button
                      variant="btn-outline-primary"
                      classname="onb-prev-btn"
                      onClick={() => this.handlePrevious()}
                      type="button"
                    >
                      Précédent
                    </Button>
                  </div>
                </LayoutMain>
              </LayoutStep>
            </Form>
          )}
        </Formik>
      </div >
    )
  }

  private handlePrevious() {
    const { navigation, setSurveyQuestionName } = this.props
    setSurveyQuestionName && setSurveyQuestionName('lastPage')
    navigation.goPrevious()
  }
}

export default withNavigation(BCPersonalInfos)

import React, { Component } from 'react'
import { flatten, map, take, get, isEmpty, includes } from 'lodash'
import T from 'types'
import { practitionerTitle } from 'helpers/doctor-type'
import { I18n } from 'react-redux-i18n'
import { specialtyI18NKey } from 'helpers/doctor-type'
import './styles.sass'
import { isCharlesTherapy } from 'helpers/new-consultation-helper'
import { SectionArguments } from 'page/common/ConsultationArguments'
import { CHAT, VISIO, PHONE } from 'constants/doctorSelectionStates'

interface IProps {
  items: 'consult' | 'medium' | 'specialty'
  lastConsultSpeciality?: string
  lastFocusedLocation?: any
  channels: string[]
}
export default class DoctorNoticeInfo extends Component<IProps> {


  public render() {
    const sections = this.buildSections()
    return (
      <div className="infos-consultation">
        <SectionArguments sections={sections} />
      </div>
    )
  }

  private channelAvailable(speciality: string) {
    const { channels } = this.props
    if (isEmpty(channels)) {
      return false;
    }
    return includes(channels, speciality)
  }

  private buildSections(): T.ISection[] {
    const { items, lastConsultSpeciality, lastFocusedLocation } = this.props

    const faqI18NNamespace = `faq.${specialtyI18NKey(lastConsultSpeciality)}`
    const isFromHome = lastFocusedLocation ? get(lastFocusedLocation, 'pathname').includes('home') : false

    switch (items) {
      case 'consult':
        return [{
          title: 'Infos pratiques',
          items: [
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.consultation.simple.label`),
              details: I18n.t(`${faqI18NNamespace}.consultation.simple.content`, { specialty: this.selectedPractician() }),

            },
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.consultation.complex.label`),
              details: I18n.t(`${faqI18NNamespace}.consultation.complex.content`, { specialty: this.selectedPractician() }),
            },
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.consultation.refund.label`),
              details: I18n.t(`${faqI18NNamespace}.consultation.refund.content`, { specialty: this.selectedPractician() }),
            },
          ]
        }]
      case 'medium':
        return [{
          title: 'Infos pratiques',
          items: [
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.media.chat.label`),
              details: I18n.t(`${faqI18NNamespace}.media.chat.content`, { specialty: this.selectedPractician() }),
              hide: !this.channelAvailable(CHAT),
            },
            {
              icon: 'clinic.yellowSquarePhone',
              text: I18n.t(`${faqI18NNamespace}.media.phone.label`),
              details: I18n.t(`${faqI18NNamespace}.media.phone.content`, { specialty: this.selectedPractician() }),
              hide: !this.channelAvailable(PHONE),
            },
            {
              icon: 'clinic.camera',
              text: I18n.t(`${faqI18NNamespace}.media.visio.label`),
              details: I18n.t(`${faqI18NNamespace}.media.visio.content`),
              hide: !this.channelAvailable(VISIO),
            },
            {
              icon: 'clinic.info',
              text: I18n.t(`${faqI18NNamespace}.media.after_consult.label`),
              details: I18n.t(`${faqI18NNamespace}.media.after_consult.content`),
            },
            {
              icon: 'clinic.clock',
              text: I18n.t(`${faqI18NNamespace}.media.duration.label`),
              details: I18n.t(`${faqI18NNamespace}.media.duration.content`),
              hide: !isCharlesTherapy() || !isFromHome
            },
            {
              icon: 'clinic.pig',
              text: I18n.t(`${faqI18NNamespace}.media.price.label`),
              details: I18n.t(`${faqI18NNamespace}.media.price.content`),
              hide: !isCharlesTherapy() || !isFromHome
            }
          ]
        }]
      case 'specialty':
        return [{
          title: 'Infos pratiques',
          items: [
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.specialty.sexologue.label`),
              details: I18n.t(`${faqI18NNamespace}.specialty.sexologue.content`)
            },
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.specialty.couple_therapy.label`),
              details: I18n.t(`${faqI18NNamespace}.specialty.couple_therapy.content`)
            },
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.specialty.psycho_sexo.label`),
              details: I18n.t(`${faqI18NNamespace}.specialty.psycho_sexo.content`)
            },
            {
              icon: 'clinic.chat',
              text: I18n.t(`${faqI18NNamespace}.specialty.consultation_duration.label`),
              details: I18n.t(`${faqI18NNamespace}.specialty.consultation_duration.content`)
            },
          ]
        }]
    }
  }

  private selectedPractician() {
    const { lastConsultSpeciality } = this.props
    return practitionerTitle(lastConsultSpeciality)
  }

}

import { connect } from 'react-redux'
import { LoginForm } from 'components'
import { authToken, authSignin } from 'actions'
import T from 'types'

const mapStateToProps = ({ auth: { signingIn, waitingToken, oneTimeAuthToken, errors } }: T.Store) => ({
  signingIn,
  waitingToken,
  oneTimeAuthToken,
  errors,
})

const mapDispatchToProps = (dispatch: Function) => ({
  getToken: (username: string, password: string, cookiesEnabled: boolean) =>
    dispatch(authToken(username, password, cookiesEnabled)),
  signIn: (authParams: T.AuthParams) =>
    dispatch(authSignin(authParams)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)

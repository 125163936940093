import React, { Component } from 'react'
import { MainContainer } from 'containers'
import { Button, SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import { SUPPORT_PHONE_NUMBER } from 'constants/config'
import './styles.sass'

interface IProps {
  sendSupportMessage: (value: string) => void
}

interface IState {
  message: string
}

export default class Assistance extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)

    this.state = {
      message: '',
    }
  }

  private onChange(event: any) {
    this.setState({ message: event.target.value })
  }
  private sendMessage() {
    const { sendSupportMessage } = this.props
    sendSupportMessage(this.state.message)
  }
  render() {
    return (
      <MainContainer>
        <div className="container assistance mt-5">
          <div className="row mb-3">
            <h3 className="col-12 bold">Assistance</h3>
          </div>
          <div className="row mb-3">
            <p className="col-12">
              Nos experts vous accompagnent du lundi au vendredi tout au long du
              programme
            </p>
          </div>
          <div className="row d-flex flex-column assistance-shifted-content mb-3">
            <p className="col-12">
              {' '}
              <SvgIcon icon={getIcon('clinic.phone')} classname="assistance-svg mr-4" />
              Par téléphone au{' '}
              <a style={{ color: 'inherit' }} href={`tel:${SUPPORT_PHONE_NUMBER.replace(/\s/g, '')}`}>
				<span className="support-phone-number bold">{SUPPORT_PHONE_NUMBER}</span>
	          </a>{' '}
            </p>
            <p className="col-12">
              {' '}
              <SvgIcon icon={getIcon('clinic.chat')} classname="assistance-svg mr-4" />En envoyant un message ci-dessous :
          </p>
          </div>
          <div className="row assistance-shifted-content">
          <textarea
            placeholder="Ecrivez votre message ici en précisant à quelle étape du programme vous êtes"
            onChange={event => this.onChange(event)}
            className="assistance-input col-md-8 mb-2"
          />
          </div>
          <div className="assistance-shifted-content shifted-button">
            <Button variant="btn-secondary" classname="mt-2" onClick={() => this.sendMessage()}>
              Envoyer
            </Button>
          </div>
        </div>
      </MainContainer>
    )
  }
}

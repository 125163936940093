import React from 'react'
import { MainContainer } from 'containers'
import { Workflow } from 'components'
// TODO Remove dependency to global CSS ? Problably from the time vars couldn't be used in subfolders CSS
import './styles.sass'
import T from 'types'
import { isEmpty, isNil, filter } from 'lodash'
import UCDrugs from 'page/Pages/UnifiedCheckout/UCFunnel/UCDrugs'
import UCPhyto from 'page/Pages/UnifiedCheckout/UCFunnel/UCPhyto'
import UCAcademy from 'page/Pages/UnifiedCheckout/UCFunnel/UCAcademy'
import UCDelivery from 'page/Pages/UnifiedCheckout/UCFunnel/UCDelivery'
import UCPayment from 'page/Pages/UnifiedCheckout/UCFunnel/UCPayment'
import UCConfirmation from 'page/Pages/UnifiedCheckout/UCFunnel/UCConfirmation'
import { withNavigation } from 'components/Workflow'

class UCFunnel extends React.Component<T.UnifiedCheckoutStepProps> {

  public render() {
    const { profile, consultation, navigation } = this.props
    const { programs, drugsPrescriptions, phytoPrescriptions } = consultation
    const { goPrevious, goNext } = navigation
    const hasDrugs = !isEmpty(drugsPrescriptions)
    const hasPhyto = !isEmpty(phytoPrescriptions)
    const hasAcademy = !isEmpty(programs)
    const defaultSteps = [
      {
        path: 'medicaments',
        label: 'Médicaments',
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return hasDrugs },
        comp: <UCDrugs {...this.props} />,
      }, {
        path: 'phytotherapies',
        label: 'Phytothérapies',
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return hasPhyto },
        comp: <UCPhyto {...this.props} />,
      }, {
        path: 'programme',
        label: 'Programme',
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return hasAcademy },
        comp: <UCAcademy {...this.props} />,
      }, {
        path: 'livraison',
        label: 'Livraison',
        // true if phyto || drugs || paper-prescription ordered
        show: (props: T.UnifiedCheckoutStepProps) => true,
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return (hasDrugs || hasPhyto) },
        comp: <UCDelivery {...this.props} />,
      }, {
        path: 'paiement',
        label: 'Paiement',
        // true if phyto || drugs || program || e-prescription ordered
        show: (props: T.UnifiedCheckoutStepProps) => true,
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return (hasDrugs || hasPhyto || hasAcademy) },
        comp: <UCPayment {...this.props} />,
      }, {
        path: 'confirmation',
        label: 'Confirmation',
        // true if phyto || drugs || program || prescription ordered
        show: () => true,
        shouldShow: (props: T.UnifiedCheckoutStepProps) => { return (hasDrugs || hasPhyto || hasAcademy) },
        comp: <UCConfirmation {...this.props} />,
      },
    ]
    const activeSteps: T.WorkflowStep[] = defaultSteps.filter(step => step.shouldShow(this.props))

    return (
      <Workflow steps={activeSteps} complete={goNext} goBack={goPrevious} level={1} stepperStyle="numbers" />
    )
  }
}

export default withNavigation(UCFunnel)

import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { Button } from 'components';

export const WaitingRoomMask = () => (
  <div className="VisioBox-mask">
    <ScaleLoader height={50} width={10} margin="1em" radius={5} color={'#020779'} />
    <div className="message with-spinner" style={{ margin: '1em auto' }}>En attente du médecin...</div>
  </div>
);

export const ConnectingMask = () => (
  <div className="VisioBox-mask">
    <ScaleLoader height={50} width={10} margin="1em" radius={5} color={'#020779'} />
    <div className="message with-spinner" style={{ margin: '1em auto' }}>Connexion en cours...</div>
  </div>
);

export const StartCallMask = ({ startCall }) => (
  <div className="VisioBox-mask">
    <div className="message" style={{ margin: '1em auto' }}>Vous êtes désormais connecté</div>
    <Button
      classname="btn-block w-auto"
      onClick={startCall}
    >
      Cliquez pour démarrer la consultation par vidéo
    </Button>
  </div>
);

export const ErrorMask = ({ retry }) => (
  <div className="VisioBox-mask">
    <div className="message error" style={{ margin: '1em auto' }}>Une erreur est survenue lors de l'initialisation de la communication par vidéo</div>
    <div className="message error button clickable" onClick={retry}>Cliquez pour relancer la consultation par vidéo</div>
  </div>
);

export const ReconnectMask = ({ reconnect }) => (
  <div className="VisioBox-mask">
    <div className="message" style={{ margin: '1em auto' }}>Votre communication vidéo a été arrêtée</div>
    <Button
      classname="btn-block w-auto"
      onClick={reconnect}
    >
      Cliquez pour relancer la consultation par vidéo
    </Button>
  </div>
);
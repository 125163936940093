import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import { isCreditCardHolderNameValid } from 'utils/helpers'

const schema = yup.object({
  cguChecked: yup.boolean().test({
    name: 'cc-valid-cgu',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'cguChecked',
        message: I18n.t(`paymentForm.fields.errors.cgu`),
      })
    },
  }),
  termsChecked: yup.boolean().test({
    name: 'cc-valid-terms',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'termsChecked',
        message: I18n.t(`paymentForm.fields.errors.terms`),
      })
    },
  }),
  name: yup.string().test({
    name: 'paypal-valid-name',
    test: function(value: any) {
      if (isCreditCardHolderNameValid(value)) {
        return true
      }
      return this.createError({
        path: 'name',
        message: I18n.t(`paymentForm.fields.errors.name`),
      })
    },
  }),
})

export default schema

import React from 'react'
import { Link } from 'react-router-dom'
import { get, last, find } from 'lodash'
import { MainContainer } from 'containers'
import { Block, Button, SvgIcon } from 'components'
import T from 'types'
import '../styles.sass'
import ShippingAddress from 'components/ShippingAddress'

type IProps = {
  prescription: T.Prescription,
  profile: T.Profile,
  order?: T.OrderV1,
  loading: boolean,
  createPrescriptionDelivery: (
    prescription: T.Prescription,
    data: T.Forms.ShippingAddress,
    onSuccess: () => void
  ) => void,
  finalizePrescriptionDelivery: (orderNumber: string, onSuccess: () => void) => void,
}

type IState = {
  step: number,
}

class DownloadPrescription extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      step: 0,
    }

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleValidateDeliveryInfo = this.handleValidateDeliveryInfo.bind(this)
    this.onValidateSuccess = this.onValidateSuccess.bind(this)
    this.onConfirmSuccess = this.onConfirmSuccess.bind(this)
  }

  handleConfirm() {
    const { finalizePrescriptionDelivery, order } = this.props
    if (order) {
      finalizePrescriptionDelivery(order.number, this.onConfirmSuccess)
    }
  }

  handleValidateDeliveryInfo(data: T.Forms.ShippingAddress) {
    const { prescription, createPrescriptionDelivery } = this.props
    createPrescriptionDelivery(prescription, data, this.onValidateSuccess)
  }
  onValidateSuccess() {
    this.setState({ step: 1 })
  }
  onConfirmSuccess() {
    this.setState({ step: 2 })
  }

  render() {

    const { profile, loading } = this.props

    return (
      <MainContainer>
        <div className="page download-prescription">
          <div className="home-container">
            <div className="infos-container">
              <Block
                title="Envoi postal de l'ordonnance"
                classname="container-m-800 treatment"
              >
                <div>
                  {this.state.step === 0 && (
                    <ShippingAddress
                      profile={profile}
                      onSubmit={this.handleValidateDeliveryInfo}
                    />
                  )}
                  {this.state.step === 1 && (
                    <>
                      Vous avez demandé la réception de votre ordonnance par voie postale.
                      <br />
                      <Button
                        onClick={this.handleConfirm}
                        loading={loading}
                        classname="btn btn-primary mt-5"
                      >
                        Confirmer l'envoi
                      </Button>
                    </>
                  )}
                  {this.state.step === 2 && (
                    <>
                      <span>Vous recevrez votre ordonnance par voie postale très bientôt</span>
                      <br />
                      <Link
                        className="btn btn-primary mt-5"
                        to="/home"
                      >
                        Retourner dans mon espace patient
                      </Link>
                    </>
                  )}
                </div>
              </Block>
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }
}

export default DownloadPrescription

import React, { Fragment } from 'react'
import { isUndefined } from 'lodash';
import T from 'types'
import './styles.sass';
import TeleconsultationContentChat from './TeleconsultationContentChat';
import TeleconsultationContentVisio from './TeleconsultationContentVisio';

type Props = {
  consultation: T.Consultation | undefined
  show: boolean
}

const TeleconsultationContent = ( { consultation, show } : Props) => {

  if (isUndefined(consultation)) {
    return null
  }

  switch (consultation.communicationChannel) {
    case 'chat':
      return <TeleconsultationContentChat
        consultation={consultation}
        show={show}
      />

      case 'visio':
      return <TeleconsultationContentVisio
        consultation={consultation}
        show={show}
      />

    case 'phone':
      return <></>
  }

  console.error('Medium cannot be rendered: unknown medium', consultation.communicationChannel)

  return <Fragment />
}

export default TeleconsultationContent
import { IS_MEDICAL } from 'constants/doctorSelectionStates'

export const specialtySlugFromURL = (defaultValue?: string): string | undefined => {
  const search = window.location.search
  // TODO LUCAS REFACTOR THIS, no reason to assume spe is the first param
  const selectedSeciality = search ? search.split('?')[1].split('=')[1] : defaultValue
  return selectedSeciality
}

export const practitionerTitle = (specialty?: string): string => {
  return IS_MEDICAL(specialtySlugFromURL(specialty)) ? 'médecin' : 'sexologue'
}

export const specialtyI18NKey = (specialty: string | undefined) : string => {
  return IS_MEDICAL(specialtySlugFromURL(specialty))? 'medical' : 'nonMedical'
}


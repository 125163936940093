import React, { FC } from 'react'
import T from 'types'
import { Workflow } from 'components'
import { isNil, isEmpty } from 'lodash'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { PRESCRIPTION_TYPE_ANALYSIS, PRESCRIPTION_TYPE_OTHER } from 'constants/prescriptions'
import UCConfirmationOtherPrescription from 'page/Pages/UnifiedCheckout/UCFunnel/UCConfirmation/UCConfirmationOtherPrescription'
import UCConfirmationConfirmation from 'page/Pages/UnifiedCheckout/UCFunnel/UCConfirmation/UCConfirmationConfirmation'
import { withNavigation } from 'components/Workflow'

const UCConfirmation: FC<T.UnifiedCheckoutStepProps> = (props: T.UnifiedCheckoutStepProps) => {
  const { navigation: { stepIndex, steps, goPrevious, goNext } } = props

  const confirmationSteps: T.WorkflowStep[] = [
    {
      path: 'autres-ordonnances',
      label: 'Autres Ordonnances',
      // show: (props: any) => { return !isEmpty(dummyConsultation.prescriptions.filter(foo => foo.prescriptionType === PRESCRIPTION_TYPE_OTHER || PRESCRIPTION_TYPE_ANALYSIS)) }, //TODO
      comp: <UCConfirmationOtherPrescription {...props} />,
    }, {
      path: 'commande',
      label: 'Confirmation',
      comp: <UCConfirmationConfirmation {...props} />,
    },
  ]

  return (
    <UCStep>
      <h2>ceci est le test Confirmation BIG STEP</h2>
      <Workflow steps={confirmationSteps} complete={goNext} goBack={goPrevious} level={2} stepperStyle="progressionBar" />
    </UCStep>
  )
}

export default withNavigation(UCConfirmation)

import { connect } from 'react-redux'
import {
  createMessage,
  getMessages,
} from 'actions'
import { Chatbox } from 'components'
import T from 'types'

const mapStateToProps = ({ auth: { profile }, messages: { messages, actionLoading } }: T.Store) => ({
  messages,
  actionLoading,
  profile,
})

const mapDispatchToProps = (dispatch: Function) => ({
  createMessage: (userId: number, body: string) =>
    dispatch(createMessage(userId, body)),
  getMessages: (userId: number) =>
    dispatch(getMessages(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chatbox)

import React, { Component } from 'react'
import cx from 'classnames'
import { Row, Col } from 'react-bootstrap'
import { VariantCard } from 'components'
import { groupUnsubscribableAndSubscribableVariants } from 'utils/models/variants'
import T from 'types'
import { last, get, find, isUndefined, maxBy } from 'lodash'
import { I18n } from 'react-redux-i18n'
import '../styles.sass'
import { productImagePath } from 'utils/helperComponents/phytoHelpers'

type IProps = {
  product: any
  recommended: boolean
  recommendedDuration?: number
  handleNewSelection: (p: any, choice: T.CheckoutVariantChoice | null) => void
}

type IState = {
  selectedVariant: T.Variant | null
}

export default class ProductVariantsChoice extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      selectedVariant: null,
    }
  }

  handleVariantClick = (choice: T.CheckoutVariantChoice): void => {
    const { product, handleNewSelection } = this.props
    const isAlreadySelected = get(this.state.selectedVariant, 'id') === choice.variant.id
    const newSelection = isAlreadySelected ? null : choice.variant
    const newChoice = isAlreadySelected ? null : choice
    this.setState({ selectedVariant: newSelection })

    handleNewSelection(product, newChoice)
  }

  findRecommendedVariantId = (product: any, recommendedDuration: number | undefined) => {
    const variantQuantity = (variant: any) => {
      const quantityOptionValue = find(
        variant.optionValues,
        optionValue => optionValue.optionType.name === 'pills-per-box',
      )
      const quantity = parseInt(quantityOptionValue.name, 10)
      return quantity
    }

    const recommendedVariant = find(
      product.variants,
      (variant: any) => variantQuantity(variant) === recommendedDuration,
    )

    if (!isUndefined(recommendedVariant)) {
      return recommendedVariant.id
    }

    const maxVariant = maxBy(product.variants, variantQuantity)
    return maxVariant.id
  }

  render() {
    const { product, recommended, recommendedDuration } = this.props

    const recommendedVariantId = this.findRecommendedVariantId(product, recommendedDuration)
    const groupedVariants: T.SubscribableVariant[] = groupUnsubscribableAndSubscribableVariants(
      product.variantsIncludingMaster,
    )

    return (
      <div className={cx('block-content', 'phyto-product-container', { recommended })}>
        <div className="d-flex flex-column flex-md-row phyto-product-description-image-container">
          <div className="phyto-product-image-container text-center">
            <img
              src={productImagePath(product)}
              className="img-position img-responsive phyto-product-img mb-4 phyto-product-image"
            />
          </div>
          <div className="text-center text-md-left ml-md-5">
            <h3 className="mb-3">{product.name}</h3>
            <p className="phyto-product-description">
              <span className="product-indication">Indications :</span>{' '}
              <span className="">
                {I18n.t(`prescriptions.productsInformation.${product.slug}.indication`)}
              </span>
            </p>
            <p className="font-italic phyto-product-description phyto-product-contraindication">
              {I18n.t(`prescriptions.productsInformation.${product.slug}.contraindication`)}
            </p>
            <p className="phyto-product-ext-link">
              <a
                href={I18n.t(
                  `prescriptions.productsInformation.${product.slug}.pathToDescription`,
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Lire la fiche d'information complète
              </a>
            </p>
          </div>
        </div>
        <div className="align-items-center align-items-lg-end d-flex justify-content-center flex-wrap flex-column flex-lg-row" style={{ gap: '2rem'}}>
          {groupedVariants.map((subscribableVariant: T.SubscribableVariant, index: number) => (
            <div
              key={`product_${product.slug}_${subscribableVariant.variant.id}`}
              className="mb-2"
            >
              <VariantCard
                variant={subscribableVariant.variant}
                renewableVariant={subscribableVariant.subscribableVariant}
                recommendedVariant={recommendedVariantId}
                recommendedProduct={recommended}
                selected={get(this.state.selectedVariant, 'id')}
                handleClick={this.handleVariantClick}
              />
            </div>
          ))}
        </div>

        <div className="phyto-product-contraindication text-center">
          Les livraisons renouvelables sont résiliables à tout moment sans frais depuis votre espace
          patient dans la rubrique 'Mon compte'.
        </div>
      </div>
    )
  }
}

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

const schema = yup.object({
  firstname: yup
    .string()
    .trim()
    .min(1)
    .required(() => I18n.t(`personalInfo.address.errors.default`)),
  lastname: yup
    .string()
    .trim()
    .min(1)
    .required(() => I18n.t(`personalInfo.address.errors.default`)),
  city: yup.string().required(() => I18n.t(`personalInfo.address.errors.default`)),
  address1: yup.string().required(() => I18n.t(`personalInfo.address.errors.default`)),
  address2: yup.string(),
  zipcode: yup.string().required(() => I18n.t(`personalInfo.address.errors.default`)),
  countryIso: yup.string().required(() => I18n.t(`personalInfo.address.errors.default`)),
})

export default schema

import { TARGET_PLATFORM } from 'constants/config'

type IntercomSettings = {
  targetPlatform: string
  targetStack: string
}

const settings: { [key: string]: IntercomSettings } = {
  mia: {
    targetPlatform: 'mia',
    targetStack: 'front',
  },
  charles: {
    targetPlatform: 'charles',
    targetStack: 'front',
  },
}

export const intercomSettings = (): IntercomSettings => {
  return settings[TARGET_PLATFORM]
}

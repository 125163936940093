import React, { useState } from 'react'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import { StepHead } from 'page/common/Step'
import T from 'types'
import { WizardStepProps } from 'helpers/hooks/useVisioConfiguration'
import { detect } from 'detect-browser'
import tracker from 'utils/trackers'

const TroubleshootPermissions = (props: WizardStepProps) => {
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const cameraIcon = props.config.hasCamera ? 'setup.cameraGood' : 'setup.cameraMissing'
  const micIcon = props.config.hasMic ? 'setup.micGood' : 'setup.micMissing'
  const label = !props.config.hasCamera && !props.config.hasMic
  ? "Afin que votre médecin puisse vous voir et vous entendre, vous devez autoriser l'accès à votre caméra et votre micro"
  : !props.config.hasCamera
  ? "Afin que votre médecin puisse vous voir, vous devez autoriser l'accès à votre caméra"
  : "Afin que votre médecin puisse vous entendre, vous devez autoriser l'accès à votre micro"

  const DownloadGuide = ({ title = "Comment régler le problème ?"}) => (
    <a
      className="btn btn-primary"
      href="https://charles-public-assets.s3.eu-west-1.amazonaws.com/doc/autorisations_camera_micro.pdf"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => setHasDownloaded(true)}
    >
      {title}
    </a>
  )

  const retry = () => {
    const browser = detect()
    // If on Safari, rechecking permissions results in glitches so just refresh the page
    if (browser && (browser.name == 'safari')){
      window.location.reload()
    } else {
      props.config.promptForVisioPermissions()
    }
  }
  const buttonLabel = props.trackingConfig.context == 'personalSpace' ? "J'ai besoin d'aide" : "Je le ferai plus tard"
  const skip = () => {
    tracker.trackMediaSetupEventSkipped(props.config, props.trackingConfig)
    props.onSkipped && props.onSkipped()
  }

  return (
    <div>
      <StepHead title="Problème de configuration" />
      <p>Nous avons détecté un problème dans la configuration de votre navigateur</p>
      <div className="d-flex mb-2">
        <SvgIcon icon={getIcon(cameraIcon)} classname="central-icon"/>
        <SvgIcon icon={getIcon(micIcon)} classname="central-icon"/>
      </div>
      <p className="configuration-error-message">
        {props.config.promptError ? props.config.promptError.message : label}
      </p>
      <div className="d-flex flex-column align-items-stretch">
        { hasDownloaded ? (
          <>
            { props.config.promptRefusedToOpen ? (
              <>
                <p className="configuration-error-message">
                  Suivez les instructions du PDF ci-dessous et rechargez la page pour réessayer.
                </p>
                <DownloadGuide title="Instructions en PDF" />
              </>
            ) : (
              <Button
                variant="btn-primary"
                onClick={() => retry()}>
                Réessayer
              </Button>
            )}
            <Button
              variant="btn-outline-primary mt-2"
              onClick={skip}>
              {buttonLabel}
            </Button>
          </>
        ) : (
          <DownloadGuide />
        )}
      </div>
    </div>
  )
}

export default TroubleshootPermissions
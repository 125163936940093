import { connect } from 'react-redux'
import { hidePopup, showPopup } from 'actions'
import { Popup } from 'components'
import T from 'types'

const mapStateToProps = ({ ux: { classPopup, popupShow, popupType, popupProps } }: T.Store) => ({
  classPopup,
  popupShow,
  popupType,
  popupProps,
})

const mapDispatchToProps = (dispatch: Function) => ({
  hidePopup: () => dispatch(hidePopup()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Popup)

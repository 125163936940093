import React, { Component } from 'react'
import cx from 'classnames'
import { Row, Col, Button } from 'react-bootstrap'
import { VariantCard } from 'components'
import { Link } from 'react-router-dom'
import { groupUnsubscribableAndSubscribableVariants } from 'utils/models/variants'
import T from 'types'
import { withRouter, RouteComponentProps } from 'react-router'
import { last, get, find, isUndefined, maxBy } from 'lodash'
import { I18n } from 'react-redux-i18n'
import './styles.sass'
import productsData from 'page/Pages/WowtechProduct/products.json'

interface IProps extends RouteComponentProps<any> {
  product: any
  handleProductSelection: (variant: T.Variant, fn: Function) => void
}

type IState = {
  selectedVariant: T.Variant | null
}

const productImagePath = (productData: any) => {
  const path = get(productData, 'images.product_1')

  if (!path)
    return '' // TODO - use placeholder

  return `wow/${path}`
}

class WowtechProductList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      selectedVariant: null
    }
  }

  handleVariantClick = (variant: T.Variant): void => {
    const { handleProductSelection } = this.props

    handleProductSelection(variant, (e : any) => console.log("handleVariantClick: It worked !", e))
  }

  render() {
    const { product, history } = this.props

    // TODO -- think about handling multiple variants (color)
    const productVariant : T.Variant = product.variantsIncludingMaster[0]

    const productData : any = get(productsData, product.slug)

    if (!productsData)
       return <p>Product not found</p>

    return (
      <div className="wowtech-product-card">
        <img
          src={`wow/${productData.images.thumbnail}`}
          className="wowtech-product-img"
        />
        <div className="wowtech-product-card-body">
          <h3 className="wowtech-product-card-body-name">{productData.name}</h3>
          <p className="wowtech-product-card-body-description">
            {productData.subtitle.map((sub: any) => (
              <div className="">
                {sub}
              </div>
            ))}
          </p>
          <p className="wowtech-product-card-body-price">
            {productVariant.displayPrice}
          </p>
        </div>
        <div className="wowtech-product-card-actions">
          <Button onClick={() => this.handleVariantClick(productVariant)} className="">Ajouter au panier</Button>
          <Button variant="outline-primary" onClick={() => history.push(`/products/${product.slug}`)}>Découvrir</Button>
        </div>
      </div>
    )
  }
}

export default withRouter(WowtechProductList)

import React from 'react'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import { StepHead } from 'page/common/Step'
import T from 'types'
import { WizardStepProps } from 'helpers/hooks/useVisioConfiguration'
import tracker from 'utils/trackers'

const SuccessScreen = (props: WizardStepProps) => {
  const trackAndGoNext = () => {
    tracker.trackMediaSetupEventSucceeded(props.config, props.trackingConfig)
    props.onSuccess()
  }
  return (
    <div>
      <StepHead title="Tout est en ordre pour votre consultation" />
      <SvgIcon icon={getIcon('setup.allGood')} classname="central-icon"/>
      <Button
        variant="btn-primary"
        classname="mx-auto"
        onClick={trackAndGoNext}>
          Je me connecte
      </Button>
    </div>
  )
}

export default SuccessScreen
import React, { Fragment } from 'react'
import { groupBy, includes, isEqual, map } from 'lodash'
import { I18n } from 'react-redux-i18n'
import moment from 'constants/moment'
import './styles.sass'
import Slot from './Slot'

type Props = {
  availabilities: any[]
  freeSlots: any[]
  slot: Date | null
  handleChosenSlot: (e: React.MouseEvent<HTMLElement>, slot: Date) => void
}

type SlotsBucket = {
  title?: string
  availabilities: any[]
}

const AllConsultationSlotsForDay = ({ availabilities, freeSlots, slot, handleChosenSlot }: Props) => {
  const frTimezone = 'Europe/Paris'
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const offsetHours = -(new Date().getTimezoneOffset() / 60)
  const displayedTz = `(GMT${offsetHours > 0 ? '+' : ''}${offsetHours !== 0 ? offsetHours : ''} ${timezone})`

  const splitDailySlots = (availabilities: any[]): SlotsBucket[] => {
    const shouldSplitDay = availabilities.length > 24
    if (shouldSplitDay) {
      const slotsPartition = groupBy(
        availabilities,
        (slot: Date): any => {
          if (moment(slot).hours() < 12) {
            return 'morning'
          }
          if (moment(slot).hours() >= 18) {
            return 'evening'
          }
          return 'afternoon'
        },
      )
      const slotsBuckets: SlotsBucket[] = map(
        slotsPartition,
        (slotsSlice: Date[], key: string): SlotsBucket => {
          return {
            title: I18n.t(`onboarding.slots.${key}`),
            availabilities: slotsSlice,
          }
        },
      )
      return slotsBuckets
    }
    return [{ availabilities }]
  }

  const isActiveSlot = (slot: any): boolean => {
    return includes(freeSlots, slot)
  }

  const splittedAvailabilities: SlotsBucket[] = splitDailySlots(availabilities)
  return (
    <div
      className="consultation-day-slots-container"
      style={{ padding: 0 }}
    >
      {timezone !== frTimezone && (
        <i className="consultation-day-slots-timezone-disclaimer">
          {`${I18n.t(`onboarding.slots.disclaimer`)} ${displayedTz}`}
        </i>
      )}
      {map(splittedAvailabilities, (currentBucket: SlotsBucket, bucketIndex: number) => (
        <Fragment key={`splitted-availabilities_${bucketIndex}`}>
          <h5 className="consultation-day-slots-bucket-title">{currentBucket.title}</h5>
          <div className="consultation-day-slots-all" key={`bucket_${bucketIndex}`}>
            {map(currentBucket.availabilities, (currentSlot, i: number) => (
              <Slot
                key={`slot-${i}`}
                slot={currentSlot}
                isAvailable={isActiveSlot(currentSlot)}
                isActive={isEqual(currentSlot, slot)}
                handleClick={handleChosenSlot}
              />
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )

}

export default AllConsultationSlotsForDay

import {
  compact,
  find,
  flatten,
  includes,
  join,
  last,
  map,
  reverse,
  split,
} from 'lodash'
import { Model } from 'survey-core'

// return survey page JSON corresponding to the given questionName
export const findSurveyPage = (survey: Model, questionName: string) => {
  if (questionName === 'lastPage') {
    return last(survey.pages)
  }

  return find(
    survey.pages,
    page => includes(
      map(
        page.elements,
        element => element.name,
      ),
      questionName,
    ),
  )
}

export const findAnswerText = (surv: Model, qName: string, value: string) => {
  const questions = flatten(map(surv.pages, page => page.elements))
  const question: any = find(questions, { name: qName })

  switch (question.type) {
    case 'text':
      const splittedArray = split(value, '-')
      if (question.name === 'birthdate') { return join(reverse(splittedArray), '/') }
    case 'rating':
      const choice = question && find((question as any).rate_values, { value })

      return choice && (choice as any).text
    case 'checkbox':
      const elements = map(value, value => question && find((question as any).choices, { value }))
      const oth: any = (`${qName}-_comment` in surv) ? ({ text: surv[`${qName}-_comment`] }) : null

      if (elements) {
        const array = compact(elements.concat(oth))
        return map(array, element => `- ${element && (element as any).text}`).join('<br/>')
      }
    default:
      const item = question && find((question as any).choices, { value })
      return (
        (`${qName}-_comment` in surv)
          ? surv[`${qName}-_comment`]
          : item && (item as any).text
      )
  }
}

import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import { filter, includes, isUndefined, map, sortBy, isEmpty } from 'lodash'
import ChatboxMessage from './ChatboxMessage'
import VisioBox from '../VisioBox'
import Block from '../Block'
import T from 'types'
import './styles.sass'
import Button from '../Button'
import { I18n } from 'react-redux-i18n'
import 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/lists'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
// import 'tinymce/skins/content/default/content.min.css'
import { Editor } from '@tinymce/tinymce-react'


const MAILBOXER_MESSAGE = 'mailboxer/message'
const MAILBOXER_MESSAGE_VISIO = 'mailboxer/message/visio'

interface IProps {
  createMessage: (userId: number, body: string) => void
  getMessages: (userId: number) => void
  messages: T.Message[]
  actionLoading?: string[]
  profile: T.Profile
  userId: number
  title?: string
  notice?: string
  disabled?: boolean
  isPatient?: boolean
}

interface IState {
  draft: string
  showPatientNotification: boolean
}

class Chatbox extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      draft: '',
      showPatientNotification: false,
    }
  }

  public componentDidMount() {
    const { getMessages, userId } = this.props
    getMessages(userId)
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.sendMessage()
  }

  private sendMessage() {
    const { createMessage, isPatient, userId } = this.props
    const { draft } = this.state

    if (draft.length === 0) {
      return false
    }

    createMessage(userId, draft)

    if (!isUndefined(isPatient)) {
      this.setState(
        {
          draft: '',
          showPatientNotification: isPatient && true,
        },
        () => setTimeout(() => this.setState({ showPatientNotification: false }), 4000),
      )
    } else {
      this.setState({ draft: '' })
    }
  }

  private displayMessage(message: T.Message) {
    const { profile, userId } = this.props

    switch (message.type) {
      case MAILBOXER_MESSAGE:
        return <ChatboxMessage key={message.id} message={message} profile={profile} />
    }
  }

  private renderSendForm() {
    const { disabled, actionLoading } = this.props
    const { draft } = this.state

    if (disabled) {
      return <Fragment />
    }

    return (
      <form className="chat-form" onSubmit={e => this.handleSubmit(e)}>
        <Editor
          init={{
            height: 200,
            menubar: false,
            content_style:
              '.aligned-to-right { text-align: right; list-style-position: inside; }' +
              '.aligned-to-left { text-align: left; }' +
              '.aligned-to-center { text-align: center; list-style-position: inside; }' +
              '.underlined-message { text-decoration: underline }' +
              'html { font-family: noto-sans, Calibri, Helvetica, Sans-Serif;cursor: text; }',
            plugins: 'link lists paste',
            toolbar: 'bold italic underline | link | alignleft aligncenter alignright | bullist numlist',
            default_link_target: '_blank',
            link_assume_external_targets: true,
            paste_as_text: true,
            link_title: false,
            target_list: false,
            formats : {
              alignleft : {selector : 'p,div,ul,ol,li', classes : 'aligned-to-left'},
              aligncenter : {selector : 'p,div,ul,ol,li', classes : 'aligned-to-center'},
              alignright : {selector : 'p,div,ul,ol,li', classes : 'aligned-to-right'},
              underline: { inline: 'span', classes: 'underlined-message', exact: true },
            }
          }}
          onEditorChange={(newText) => this.setState({ draft: newText })}
          value={draft}
        />
        <Button type="submit" loading={!isEmpty(actionLoading)}>
          Envoyer
        </Button>
      </form>
    )
  }

  private renderChatBox() {
    const { messages, userId } = this.props
    const { showPatientNotification } = this.state

    const conversationMessages = sortBy(
      filter(messages, message =>
        includes(map(message.receipts, receipt => receipt.receiver.id === userId), true),
      ),
      'id',
    )
    return (
      <div className="chatroom chatbox-v1">
        <div className="chats">
          <div className="chat-list">
            {map(conversationMessages, message => this.displayMessage(message))}
          </div>
        </div>
        <p className={classnames('chat-patient-notification', { active: showPatientNotification })}
        >
          {I18n.t('chat.consultation.sentMessage')}
        </p>
        {this.renderSendForm()}
      </div>
    )
  }

  public componentDidUpdate() {
    const el = document.getElementsByClassName('chat-list')[0]

    // force scroll to bottom of chatbox
    if (el)
      el.scrollTop = 9999999
  }

  public render() {
    const { title, notice } = this.props

    if (isUndefined(title)) {
      return this.renderChatBox()
    }

    return (
      <div className="chat-box-container chatbox-v1">
        <Block title={title}>
          <>
            {notice && <p className="text-danger">{notice}</p>}
            {this.renderChatBox()}
          </>
        </Block>
      </div>
    )
  }
}

export default Chatbox

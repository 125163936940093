import { get, camelCase, has, isNil } from 'lodash'
import { I18n } from 'react-redux-i18n'
import T from 'types'
import React, { Component, Fragment } from 'react'
import { CookiesHelper } from 'helpers'
import { Button } from 'components'
import Block from '../Block'
import './styles.sass'
import { Formik } from 'formik'
import { Col, Form } from 'react-bootstrap'

interface IProps {
  email?: string
  returnUrl?: string
  signingIn: boolean
  waitingToken: boolean
  oneTimeAuthToken: string | null
  getToken: (email: string, password: string, cookiesEnabled: boolean) => void
  signIn: (authParams: T.AuthParams) => void
  errors?: {
    email?: string
    password?: string
    token?: string
  }
}

interface IState {
  email: string
  password: string
}

class LoginForm extends Component<IProps, IState> {
  static defaultProps: IProps = {
    errors: {},
    signingIn: false,
    waitingToken: false,
    oneTimeAuthToken: null,
    getToken: () => {},
    signIn: () => {},
  }
  cookiesEnabled: boolean

  constructor(props: IProps) {
    super(props)
    this.cookiesEnabled = CookiesHelper.areCookiesEnabled()
    this.state = {
      email: '',
      password: '',
    }
  }

  public componentDidMount() {
    const { signIn, oneTimeAuthToken } = this.props

    if (!isNil(oneTimeAuthToken)) {
      signIn({ oneTimeAuthToken })
    }
  }

  private handleSubmitToken = (data: any) => {
    const { signIn, waitingToken } = this.props

    if (waitingToken) {
      const { email, password } = this.state
      signIn({ username: email, password, token: data.token })
    }
  }

  private handleSubmitEmailAndPassword = (data: any) => {
    const { getToken } = this.props

    getToken(data.email, data.password, this.cookiesEnabled)
    this.setState({ email: data.email, password: data.password })
  }

  private renderLogin() {
    const { email, errors: serverErrors, signingIn } = this.props
    const resetPasswordLink = `${process.env.REACT_APP_API_BASE_URL}password/recover`
    const safeServerErrors = serverErrors || {}
    // validationSchema={schema}
    return (
      <Formik
        key="formik-login"
        onSubmit={this.handleSubmitEmailAndPassword}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: (email || process.env.REACT_APP_USER_EMAIL || ''),
          password: (process.env.REACT_APP_USER_PASSWORD || ''),
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
          <Form onSubmit={handleSubmit} className="w-100">
            <Fragment>
              <div className="text-danger mb-3 font-weight-bold">{safeServerErrors.token}</div>
              <Form.Group controlId="validationFormik03">
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                <Form.Label>{I18n.t(`onboarding.personnalData.email`)}</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email || !!safeServerErrors.email || !!errors.password || !!safeServerErrors.password}
                  placeholder={I18n.t(`onboarding.personnalDataPlaceholders.email`)}
                />
                <Form.Control.Feedback type="invalid">{safeServerErrors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationFormik04">
                <Form.Label>{I18n.t(`onboarding.personnalData.password`)}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email || !!safeServerErrors.email || !!errors.password || !!safeServerErrors.password}
                  placeholder={I18n.t(`onboarding.personnalDataPlaceholders.password`)}
                />
                <Form.Control.Feedback type="invalid">{safeServerErrors.password}</Form.Control.Feedback>
              </Form.Group>
              <Button
                classname="mt-4 btn btn-primary btn-block"
                type="submit"
                loading={signingIn}
              >
                Se connecter
              </Button>
              <div className="text-center">
                <a className="forgot-password d-inline-block my-3" href={resetPasswordLink}>
                  Mot de passe oublié ?
                </a>
              </div>
            </Fragment>
          </Form>
        )}
      </Formik>
    )
  }

  private renderToken() {
    const { signingIn } = this.props
    return (
      <Formik
        key="formik-token"
        onSubmit={this.handleSubmitToken}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          token: process.env.REACT_APP_USER_TOKEN || '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} className="w-100">
            <Fragment>
              <div className="text-danger mb-2 font-weight-bold">
                Un code à 4 chiffres vient de vous être envoyé par SMS et par email
              </div>
              <Form.Group controlId="validationFormik05">
                <Form.Label>{I18n.t(`onboarding.personnalData.token`)}</Form.Label>
                <Form.Control
                  type="tel"
                  name="token"
                  value={values.token}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.token}
                  placeholder={I18n.t(`onboarding.personnalDataPlaceholders.token`)}
                />
                <Form.Control.Feedback type="invalid">{errors.token}</Form.Control.Feedback>
              </Form.Group>
              <Button
                classname="btn btn-primary btn-block"
                type="submit"
                loading={signingIn} // TODO V2 Loading
              >
                Connexion
              </Button>
            </Fragment>
          </Form>
        )}
      </Formik>
    )
  }

  public render() {
    const { signingIn, waitingToken } = this.props
    const shouldShowTokenForm = waitingToken && !signingIn
    const title = I18n.t(`loginForm.pageTitle`)

    return (
      <Block classname="login-container without-header">
        <div className="login-block">
          <h4 className="mb-3 text-center">{title}</h4>
          {!this.cookiesEnabled && (
            <div className="text-danger mb-2 font-weight-bold">{I18n.t('pages.signin.no_cookies')}</div>
          )}
          {shouldShowTokenForm ? this.renderToken() : this.renderLogin()}
        </div>
      </Block>
    )
  }
}

export default LoginForm

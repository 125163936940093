import classNames from 'classnames';

export const containerClasses = (active, subscribersCount, localAudioEnabled, localVideoEnabled) => {
  const activeCameraSubscribers = subscribersCount > 0;

  return {
    controlClass: classNames('VisioBox-control-container', { 'hidden': !active }),
    localAudioClass: classNames('ots-video-control circle audio', { 'toggled': !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { 'toggled': !localVideoEnabled }),
    fullscreenClass: classNames('ots-video-control circle fullscreen', { 'hidden': !activeCameraSubscribers }),
    powerOffClass: classNames('ots-video-control circle power-off'),
    cameraPublisherClass: classNames('video-container', {
      'hidden': !active,
      'small': !!activeCameraSubscribers,
    }),
    cameraSubscriberClass: classNames('video-container', `active-${activeCameraSubscribers}`, {
      'hidden': !activeCameraSubscribers,
    }),
  };
};
import { connect } from 'react-redux'
import { interruptEnrollment, deleteCreditCard, getAuthProfile, updateProfile, cancelSubscription, getSubscriptions, getEnrollments } from 'actions'
import Account from 'page/Pages/Account'
import T from 'types'

const mapStateToProps = ({ academy, storefront, auth: { profile } }: T.Store) => ({
  profile,
  academy,
  storefront,
})

const mapDispatchToProps = (dispatch: Function) => ({
  setCurrentProfile: () => dispatch(getAuthProfile()),
  updateProfile: (data: any) => dispatch(updateProfile(data)),
  deleteCreditCard: (id: number) => dispatch(deleteCreditCard(id)),
  interruptEnrollment: (enrollmentId: number) => dispatch(interruptEnrollment(enrollmentId)),
  getSubscriptions: () => dispatch(getSubscriptions()),
  getEnrollments: () => dispatch(getEnrollments()),
  cancelSubscription: (subscriptionId: number) => dispatch(cancelSubscription(subscriptionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)

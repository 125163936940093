import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import { get, isNil, isUndefined } from 'lodash'
import moment from 'constants/moment'
import { Block, StoreRedirectionScreen, AnyPrescriptionContent, Button } from 'components'
import { MoonLoader } from 'react-spinners'
import { MainContainer } from 'containers'
import { getPureOauthAccessToken } from 'helpers/cookies-helper'
import pharmaCross from 'assets/images/logo-pharma.png'
import T from 'types'
import classnames from 'classnames'
import { SectionArguments } from 'page/common/ConsultationArguments'
import { builtSections } from 'helpers/pharmacist-infos'
import '../styles.sass'

type IProps = {
  match: any
  pharmacy: T.Pharmacist
  prescription: T.Prescription
  getPharmacy: (id: string) => void
  getPrescriptions: () => void
}

interface IState {
  cguChecked: boolean
  cguError: boolean
  showLoadingScreen: boolean
}

export default class PharmacyPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      cguChecked: false,
      cguError: false,
      showLoadingScreen: false
    }
  }

  componentDidMount() {
    const { getPharmacy, getPrescriptions, match } = this.props
    const id = match.params.id

    if (isNil(id)) {
      return null
    }

    getPharmacy(id)
    getPrescriptions()
  }

  private handlePharmacistSelect(e: React.MouseEvent, targetUrl: string) {
    const { cguChecked } = this.state

    if (!cguChecked) {
      e.preventDefault()
      this.setState({ cguError: true })
      let scrollToCheckbox: HTMLElement = document.getElementById('accept-block') as HTMLElement
      scrollToCheckbox.scrollIntoView(true)
    }
    else {
      this.setState({
        showLoadingScreen: true,
      }, () => {
        setTimeout(() => {
          window.location.href = targetUrl
        }, 1500)
      })
    }
  }

  private handleCheck() {
    this.setState({
      cguChecked: !this.state.cguChecked,
    })
  }

  private renderCguInput() {
    const { cguChecked, cguError } = this.state

    return (
      <div className="accept-checkbox">
        <input
          type="checkbox"
          className={classnames('accept-terms', { checked: cguChecked })}
          name="accept-cgu"
          id="accept-cgu"
          checked={cguChecked}
          onChange={() => this.handleCheck()}
        />
        <label
          htmlFor="accept-cgu"
          className={classnames('label-cgu', { 'error-field': cguError })}
        >
          <div className="pharmacist-delivery-note" dangerouslySetInnerHTML={{ __html: I18n.t('pharmacy.acceptCguText') }} />
        </label>
        {this.renderCguErrorTag()}
      </div>
    )
  }

  private renderCguErrorTag() {
    const { cguError } = this.state

    if (cguError) {
      return (
        <div>
          <span className="error-tag">{I18n.t(`pharmacy.acceptCguError`)}</span>
        </div>
      )
    }
  }

  private renderLinks() {
    const { prescription, pharmacy } = this.props
    const connectUrl = get(pharmacy, 'connectUrl')
    const { uuid, canBeReleased } = prescription
    const accessToken = getPureOauthAccessToken()
    const targetLink = `${connectUrl}?access_token=${accessToken}&prescription_id=${uuid}`
    if (canBeReleased) {
      return (
        <div className="links-container" id="accept-block">
          {this.renderCguInput()}
          <a
            className="btn submit-delivery btn-primary"
            onClick={e => this.handlePharmacistSelect(e, targetLink)}
          >
            <strong>Me faire livrer</strong>
          </a>
        </div>
      )
    }
    return moment(prescription.expiredAt).diff(moment(Date.now())) > 0 ? (
      <Button classname="yellow disabled" disabled={true}>
        Commande maximale mensuelle atteinte
      </Button>
    ) : (
      <Button classname="yellow disabled" disabled={true}>
        Cette ordonnance est expirée
      </Button>
    )
  }

  render() {
    const { showLoadingScreen } = this.state
    const { pharmacy, prescription } = this.props
    const mdSections = [{
      title: 'Soyez serein, vous êtes entre de bonnes mains',
      items: [
        {
          icon: 'clinic.box',
          text: "Livraison partout en France en moins de 48h"
        },
        {
          icon: 'clinic.lock',
          text: "Ordonnance sécurisée et renouvelable chaque mois"
        },
        {
          icon: 'clinic.yellowSquarePhone',
          text: "Service patient 7j/7"
        }
      ]
    }]

    if (isUndefined(pharmacy)) {
      return <Fragment />
    }

    if (isUndefined(prescription)) {
      return <Redirect to="/home" />
    }

    if (showLoadingScreen) {
      return <StoreRedirectionScreen
        storeName={pharmacy.name}
        redirectionLabel="Vous allez être redirigé vers l'espace de la pharmacie pour votre livraison."
        icon={pharmaCross}
        spinnerColor="#44962B"
      />
    }
    return (
      <MainContainer>
        <div className="page pharmacy">
          <div className="home-container">
            <div className="infos-container">
              <h3>Mon ordonnance</h3>
              <div className="row">
                <div className="col-lg-7">
                  <Block classname="pharmacist pharmacist-infos pharmacist-infos-custom">
                    <AnyPrescriptionContent prescription={prescription} />
                    {this.renderLinks()}
                  </Block>
                </div>
                <div className="col-lg-5">
                  <div className="section-arguments">
                    <div className="d-none d-md-block">
                      <SectionArguments sections={builtSections()} />
                    </div>
                    <div className="d-md-none">
                      <SectionArguments sections={mdSections} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }
}

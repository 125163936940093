import { connect } from 'react-redux'
import {
  createConsultationFromProfile,
  getDoctors,
  getCommunicationChannels,
  createMessage,
  getLastConsultation,
  setChosenDoctor,
  setChosenTimeSlot,
  showNotification,
  sendConsultationPayment,
  setChosenMedium,
  setChosenConsultType,
  getSurveyAndSubmission,
  setSurveyObject,
  uploadSurvey,
  setSurveyQuestionName,
  updateProfile,
  setFocusedSpecialty,
  getPaymentMethods,
  updateFocusedConsultation,
  getAuthProfile,
  getOpenConsultationDoctor,
  resetOpenConsultationDoctor,
} from 'actions'
import T from 'types'
import BookingConsultation from 'page/Pages/BookingConsultation'
import { flatten, map, get } from 'lodash'

const mapStateToProps = ({
  auth: { profile },
  consultations: { focusedItem, focusedOrder, specialtyCategories, focusedSpecialty },
  doctors: { collection, slot, doctor, availableChannels },
  onboarding: { medium, consultationType, survey, hasPreviousSubmission, consultationSpecialty, skipDoctorSelection },
  storefront,
}: T.Store) => ({
  collection,
  availableChannels,
  doctor,
  medium,
  consultationType,
  profile,
  slot,
  survey,
  hasPreviousSubmission,
  storefront,
  consultationSpecialty,
  focusedSpecialty,
  skipDoctorSelection,
  specialtyCategories,
  loading: storefront.loading,
  consultation: focusedItem,
  order: focusedOrder,
  orderNumber: get(focusedItem, 'orderNumber'),
  consultationSpecialties: flatten(map(specialtyCategories, (specialtyCategory: T.SpecialtyCategory) => specialtyCategory.specialties))
})

const mapDispatchToProps = (dispatch: Function) => ({
  createMessage: (userId: number, body: string, patientUserId: number) =>
    dispatch(createMessage(userId, body, patientUserId)),
  createConsultationFromProfile: (onSuccess: () => void) => dispatch(createConsultationFromProfile(onSuccess)),
  getDoctors: () => dispatch(getDoctors()),
  getCommunicationChannels: () => dispatch(getCommunicationChannels()),
  setChosenDoctor: (doctor: T.Doctor) => dispatch(setChosenDoctor(doctor)),
  setChosenTimeSlot: (slot: Date) => dispatch(setChosenTimeSlot(slot)),
  showNotification: (title: string, message: string) => dispatch(showNotification(title, message)),
  setChosenMedium: (medium: string) => dispatch(setChosenMedium(medium)),
  getLastConsultation: () => dispatch(getLastConsultation()),
  setChosenConsultType: (consultationType: string) =>
    dispatch(setChosenConsultType(consultationType)),
  sendConsultationPayment: ({
    data,
    orderNumber,
    onPaymentSuccess,
    onPaymentError,
  }: {
    orderNumber: string,
    data: T.PaymentPayload,
    onPaymentSuccess: () => void,
    onPaymentError: () => void,
  }) => dispatch(sendConsultationPayment({ data, orderNumber, onPaymentSuccess, onPaymentError })),
  getSurveyAndSubmission: (specialty: string, onSuccess: () => void) => dispatch(getSurveyAndSubmission(specialty, onSuccess)),
  setSurveyObject: (survey: object) => dispatch(setSurveyObject(survey)),
  setSurveyQuestionName: (questionName: string) => dispatch(setSurveyQuestionName(questionName)),
  updateProfile: (data: object) => dispatch(updateProfile(data)),
  uploadSurvey: (
    orderNumber: string | undefined,
    survey: object,
  ) => dispatch(uploadSurvey(orderNumber, survey)),
  setFocusedSpecialty: (
    consultationSpecialty: string,
  ) => dispatch(setFocusedSpecialty(consultationSpecialty)),
  setOpenConsultationDoctor: (
    openConsultationDoctor: string | undefined
  ) => {
    if (openConsultationDoctor) {
      return dispatch(getOpenConsultationDoctor(openConsultationDoctor))
    }
    return dispatch(resetOpenConsultationDoctor())
  },
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  getAuthProfile: () => dispatch(getAuthProfile()),
  updateFocusedConsultation: (consultationId: string) => dispatch(updateFocusedConsultation(consultationId))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingConsultation)

import React, { Fragment } from 'react'
import Media from 'react-media'
import { isEmpty, map } from 'lodash'
import T from 'types'
import './styles.sass'
import {
  renderTableHeaders,
  renderListItem,
  formatSpecificField,
} from './helpers'

type Props = {
  displayColumns: string[],
  collection?: any,
  cta: T.Cta,
}

class Table extends React.Component<Props> {

  public render() {
    return (
      <div className="prescription-table">
        <Media
          key="table"
          query="(min-width: 768px)"
          render={() => this.renderTable()}
        />
        <Media
          key="list"
          query="(max-width: 767px)"
          render={() => this.renderList()}
        />
      </div>
    )
  }

  private renderList() {
    const { collection, cta } = this.props

    return (
      <div>
        {map(collection, (obj, index) =>
          (<ul key={index}>{renderListItem(obj, cta)}</ul>)
        )}
      </div>
    )
  }

  private renderTable() {
    const { displayColumns } = this.props

    return (
      <table className="custom-table table table-borderless">
        <thead>
          <tr className="legend-table">
            {map(displayColumns, label => renderTableHeaders(label))}
          </tr>
        </thead>
        {this.renderTbody()}
      </table>
    )
  }

  private renderTbody() {
    const { collection, cta } = this.props

    if (isEmpty(collection)) {
      return <Fragment />
    }

    return (
      <tbody>
        {map(collection, (obj, index) => (
          <tr className="table-content" key={`order_${index}`}>
            {map(obj, field => {
              const [label, value] = field
              return (<td key={`${label}_${index}`}>{
                formatSpecificField(obj, index, label, value, cta)
              }</td>)
            })}
          </tr>
        ))}
      </tbody>
    )
  }
}

export default Table

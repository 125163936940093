import React from 'react';
import SvgIcon from '../SvgIcon';
import { containerClasses } from './utils';
import roundFullscreen from 'assets/icons/visio/round-fullscreen.svg'
import roundMicOff from 'assets/icons/visio/round-mic-off.svg'
import roundMic from 'assets/icons/visio/round-mic.svg'
import roundPhoneMissed from 'assets/icons/visio/round-phone-missed.svg'
import roundVideocamOff from 'assets/icons/visio/round-videocam-off.svg'
import roundVideocam from 'assets/icons/visio/round-videocam.svg'

const VisioControls = ({
  active,
  subscribersCount,
  localAudioEnabled,
  localVideoEnabled,
  toggleLocalAudio,
  toggleLocalVideo,
  toggleFullScreen,
  disconnect,
}) => {
  const {
    controlClass,
    localAudioClass,
    localVideoClass,
    fullscreenClass,
    powerOffClass,
  } = containerClasses(active, subscribersCount, localAudioEnabled, localVideoEnabled);

  return (
    <div id="controls" className={controlClass}>
      <div className={localAudioClass} onClick={toggleLocalAudio}>
        <SvgIcon icon={roundMic} classname="enable" />
        <SvgIcon icon={roundMicOff} classname="disable" />
      </div>
      <div className={localVideoClass} onClick={toggleLocalVideo}>
        <SvgIcon icon={roundVideocam} classname="enable" />
        <SvgIcon icon={roundVideocamOff} classname="disable" />
      </div>
      <div className={fullscreenClass} onClick={toggleFullScreen}>
        <SvgIcon icon={roundFullscreen} />
      </div>
      <div className={powerOffClass} onClick={disconnect}>
        <SvgIcon icon={roundPhoneMissed} />
      </div>
    </div>
  );
};

export default VisioControls;
import React, { Component, Fragment } from 'react'
import ReactJWPlayer from 'react-jw-player'
import { NavLink } from 'react-router-dom'
import { filter, isUndefined, map, last, find } from 'lodash'
import cx from 'classnames'
import build from 'redux-object'
import { IToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import T from 'types'
import { Container, Row, Col } from 'react-bootstrap'
import Carousel, { CarouselSlide } from '../../../components/Carousel/index'
import { MainContainer, ProgramViewer } from 'containers'
import { Button, Block, Loader, ProgramPresentation, SvgIcon } from 'components'
import QuickPayment from './QuickPayment'

import { enrollmentForProgram } from 'utils/data/academy'
import { buildPatientAttributes } from 'utils/helpers'
import chevron from 'assets/icons/arrow.svg'
import soulignement from 'assets/icons/icon-soulignement.svg'
import './styles.sass'
import { I18n } from 'react-redux-i18n'
import gilbert from '../../../assets/images/gilbert-on-couch-classic.jpg'
import lombart from '../../../assets/images/Dr-Remy-Lombard.jpg'
import quotes from '../../../assets/icons/academy/left-quotes-sign.svg'
import flower from '../../../assets/icons/mia-flower.svg'
import target from '../../../assets/icons/target.svg'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { getProgramPresentation } from '../../../components/ProgramPresentation/data'
import { SectionArguments } from '../../common/ConsultationArguments'
import { IS_CHARLES_PLATFORM } from 'constants/config'
import { getIcon } from '../../../assets/icons'

interface IProps {
  getPrograms: () => void
  getEnrollments: () => void
  getPaymentMethods: () => void
  enrollProgram: (slug: string) => void
  applyCoupon: (coupon: string, orderToken: string) => void
  selectProgramVariant: (variantId: number, orderToken: string, onSuccess: any) => void
  startRenewEnrollment: (enrollmentId: number) => void
  purchase: (token: IToken, data: T.PaymentPayload, onSuccess: any) => void
  showNotification: (title: string, message: string) => void
  academy: T.AcademyStore
  storefront: T.Storefront
  auth: T.Auth
}

interface IState {
  playingVideo: T.Video | null
  chosenProgram: T.Program | null
  renewingProgram: boolean
}

class Academy extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      playingVideo: null,
      chosenProgram: null,
      renewingProgram: false,
    }
  }

  public componentDidMount() {
    const { getPrograms } = this.props
    getPrograms()
  }

  public render() {
    const { academy } = this.props
    const programs = build(academy, 'program')
    const timeProgram = find(programs, program => program.slug === 'time-program')
    const masterProgram = find(programs, program => program.slug === 'master-program')
    const sleepProgram = find(programs, program => program.slug === 'sommeil-program')
    if (isUndefined(timeProgram) || isUndefined(masterProgram)) {
      return <Loader />
    }
    return (
      <MainContainer unlimitedWidth noHorizontalPadding noVerticalPadding>
        <div className="program-presentation">
          <div className="academy-landing-page program-section program-background-1">
            <div className="digital-therapy-main-div program-section-block">
              <div className="programs-wrapper">
                <div className="text-wrapper">
                  <h2 className="program-section-title-descritpion">Nos thérapies pour votre santé sexuelle</h2>
                </div>
                <SvgIcon icon={soulignement} classname="program-section-title-underline" />
                <div className="program-description">
                  <div className="text-wrapper">
                    <h3 className="program-title">{I18n.t(`academy.master-program.title`)}</h3>
                    <h4 className="program-presentation-description">
                      {I18n.t(`academy.digital-therapy.master.title`)}
                    </h4>
                    <div className="video-container d-lg-none">
                      <ReactJWPlayer
                        playerId="master-mobile-video"
                        playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                        playlist={masterProgram.videoPayload.playlist}
                      />
                    </div>
                    <div className="explaination">
                      {I18n.t(`academy.digital-therapy.master.content`)}
                    </div>
                    <NavLink className="program-presentation-link" to="/programme-master">
                      {I18n.t(`academy.digital-therapy.master.access`)}
                      <SvgIcon icon={chevron} classname="chevron-image" />
                    </NavLink>
                  </div>
                  <div className="video-container d-none d-lg-block pl-0 ml-3">
                    <ReactJWPlayer
                      playerId="master-video"
                      playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                      playlist={masterProgram.videoPayload.playlist}
                    />
                  </div>
                </div>
                <div className="digital-therapy-black-separator" />
                <div className="program-description">
                  <div className="text-wrapper">
                    <h3 className="program-title">{I18n.t(`academy.time-program.title`)}</h3>
                    <h4 className="program-presentation-description">
                      {I18n.t(`academy.digital-therapy.time.title`)}
                    </h4>
                    <div className="video-container d-lg-none">
                      <ReactJWPlayer
                        playerId="time-mobile-video"
                        playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                        playlist={timeProgram.videoPayload.playlist}
                      />
                    </div>
                    <div className="explaination">
                      {I18n.t(`academy.digital-therapy.time.content`)}
                    </div>
                    <NavLink className="program-presentation-link" to="/programme-time">
                      {I18n.t(`academy.digital-therapy.time.access`)}
                      <SvgIcon icon={chevron} classname="chevron-image" />
                    </NavLink>
                  </div>
                  <div className="video-container d-none d-lg-block">
                    <ReactJWPlayer
                      playerId="time-video"
                      playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                      playlist={timeProgram.videoPayload.playlist}
                    />
                  </div>
                </div>
                <div className="text-wrapper">
                  <h2 className="program-section-title-descritpion">Nos thérapies pour votre sommeil</h2>
                </div>
                <SvgIcon icon={soulignement} classname="program-section-title-underline-2" />
                <div className="program-description">
                  <div className="text-wrapper">
                    <h3 className="program-title">{I18n.t(`academy.sommeil-program.title`)}</h3>
                    <h4 className="program-presentation-description">
                      {I18n.t(`academy.sommeil-program.subtitle`)}
                    </h4>
                    <div className="video-container d-lg-none">
                      <ReactJWPlayer
                        playerId="sommeil-mobile-video"
                        playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                        playlist={sleepProgram.videoPayload.playlist}
                      />
                    </div>
                    <div className="explaination">
                      {I18n.t(`academy.digital-therapy.sommeil.content`)}
                    </div>
                    <NavLink className="program-presentation-link" to="/programme-sommeil">
                      {I18n.t(`academy.digital-therapy.sommeil.access`)}
                      <SvgIcon icon={chevron} classname="chevron-image" />
                    </NavLink>
                  </div>
                  <div className="video-container d-none d-lg-block">
                    <ReactJWPlayer
                      playerId="sommeil-video"
                      playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
                      playlist={sleepProgram.videoPayload.playlist}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="program-section program-background-2">
              <h2 className="program-section-title">
                {I18n.t(`academy.digital-therapy.presentation.doctor.gilbert.title`)}
              </h2>
              <div className="program-section-content">
                <div className="coach-details-content">
                  <div className="featured-doctor">
                    <img src={gilbert} className="featured-image" alt="Gibert Bou Jaoudé Canapé" />
                    <p className="description" />
                  </div>
                  <div className="profile">
                    <div className="doctor-informations">
                      <h5 className="doctor-title">{I18n.t(`academy.digital-therapy.presentation.doctor.gilbert.name`)}</h5>
                    </div>
                    <div className="doctor-presentation">
                      {I18n.t(`academy.digital-therapy.presentation.doctor.gilbert.content`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="digital-therapy-black-separator-doctor-div">
              <div className="digital-therapy-black-separator-doctor" />
            </div>
            <div className="program-section program-background-2">
              <h2 className="program-section-title">
                {I18n.t(`academy.digital-therapy.presentation.doctor.Lombard.title`)}
              </h2>
              <div className="program-section-content">
                <div className="coach-details-content">
                  <div className="featured-doctor">
                    <img src={lombart} className="featured-image" alt="Dr Rémy Lombard photo" />
                    <p className="description" />
                  </div>
                  <div className="profile">
                    <div className="doctor-informations">
                      <h5 className="doctor-title">{I18n.t(`academy.digital-therapy.presentation.doctor.Lombard.name`)}</h5>
                    </div>
                    <div className="doctor-presentation">
                      {I18n.t(`academy.digital-therapy.presentation.doctor.Lombard.content`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }
  // TODO: I18N improvments
  // react-redux-i18n does not support arrays (and is not maintained anymore)
  // i18next does https://www.i18next.com/translation-function/objects-and-arrays :eyes:
  private testimonies = () => {
    const keys = ['testimony1', 'testimony2']
    return map(keys, key => ({
      comment: I18n.t(`academy.digital-therapy.presentation.testimonies.${key}.comment`),
      author: I18n.t(`academy.digital-therapy.presentation.testimonies.${key}.author`),
    }))
  }
}

export default Academy

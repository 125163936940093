import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import { LayoutStep, LayoutMain } from 'page/Pages/BookingConsultation/LayoutStep'
import { get, isUndefined, isNil } from 'lodash'
import { Button, PaymentFormV2, SvgIcon } from 'components'
import tracker from 'utils/trackers'
import { getIcon } from 'assets/icons'
import { BookingConsultationStepProps } from 'page/Pages/BookingConsultation'
import { withNavigation } from 'components/Workflow'
import { SIMPLE_CONSULTATION_PRICE } from 'constants/config'
import T from 'types'
import '../styles.sass'

class BCPayment extends Component<BookingConsultationStepProps> {

  public render() {
    const {
      loading,
      consultation,
      profile,
      storefront,
      navigation,
      getPaymentMethods,
    } = this.props

    const amount = get(consultation, 'consultationPrice') || SIMPLE_CONSULTATION_PRICE
    const paymentSucceeded = get(consultation, 'orderState') === 'complete'

    return (
      <>
        <div className="step-back" onClick={() => navigation.goPrevious()}>
          <SvgIcon classname="step-back-icon" icon={getIcon('shapes.back')} />
          <div>Précédent</div>
        </div>
        <LayoutStep>
          <LayoutMain variant={"medium"}>
            {paymentSucceeded ? this.renderPaymentSucceeded() : (
              <div className="infos-container">
                <PaymentFormV2
                  patientAttributes={profile}
                  loading={loading}
                  price={amount}
                  cgu={I18n.t(`paymentForm.fields.labels.cgu`)}
                  recurringPayment={false}
                  submitPayment={this.handleSubmit}
                  storefront={storefront}
                  getPaymentMethods={getPaymentMethods}
                />
              </div>
            )}
          </LayoutMain>
        </LayoutStep>
      </>
    )
  }

  public handleSubmit = (data: T.PaymentPayload) => {
    const {
      consultation,
      orderNumber,
      hasPreviousSubmission,
      sendConsultationPayment,
      updateFocusedConsultation,
      navigation,
      getAuthProfile,
    } = this.props

    if (isUndefined(orderNumber)) {
      return null
    }
    const onPaymentSuccess = () => {
      if (!isNil(consultation) && consultation.id) {
        // Proper transition : updates consultation and display the confirmation screen
        getAuthProfile()
        updateFocusedConsultation(consultation.id.toString())

        const transactionId = consultation.orderNumber
        const amount = (consultation && consultation.consultationPrice) || 0
        const transactionValue = amount.toString()
        tracker.trackDidPayConsultation(
          transactionId,
          transactionValue,
          consultation.consultationType as string,
          consultation.communicationChannel as string,
          consultation.specialtySlug as string
        )
        if(hasPreviousSubmission){
          navigation.goNext()
        }
      } else {
        // If for whatever rease the consultation is not there anymore, go directly to next page.
        // This should not happen
        navigation.goNext()
      }
    }
    const onPaymentError = () => {
      if (!isNil(consultation) && consultation.id) {
        updateFocusedConsultation(consultation.id.toString())
      }
    }
    sendConsultationPayment({ orderNumber, data, onPaymentSuccess, onPaymentError })
  }

  private renderPaymentSucceeded() {
    const { navigation } = this.props
    return (
      <>
        <SvgIcon icon={getIcon("clinic.largeSpecialist")} classname="booking-payment-confirmed-icon" />
        <h3>
          <span dangerouslySetInnerHTML={{ __html: I18n.t('onboarding.postPaymentHeader') }} />
        </h3>
        <p className="mb-3">
          <p
            className="thankyou-text"
            dangerouslySetInnerHTML={{ __html: I18n.t('onboarding.postPayment') }}
          />
        </p>
        <Button
          classname="onb-next-btn mx-auto mb-5"
          onClick={() => navigation.goNext()}
        >
          Suivant
        </Button>
        <hr />
        Votre paiement a bien été reçu.
      </>
    )
  }
}

export default withNavigation(BCPayment)

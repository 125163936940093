import React, { Component, Fragment, RefObject } from 'react'
import { I18n } from 'react-redux-i18n'
import { attendanceForLesson, enrollmentForProgram } from 'utils/data/academy'
import { isUndefined, map, concat, first, last, get } from 'lodash'
import { getProgramPresentation } from 'components/ProgramPresentation/data'
import { Model } from 'survey-core'
import LessonItem from './LessonItem'
import TrailerItem from './TrailerItem'
import VideoViewer from './VideoViewer'
import LessonSurvey from './LessonSurvey'
import PlayerDescription from './PlayerDescription'
import T from 'types'
import { Button } from 'components'
import './styles.sass'
import { organizeProgramVariants } from 'utils/models/variants'
import { money } from 'utils/models/money'
import SvgIcon from 'components/SvgIcon'
import { getIcon } from 'assets/icons'

export interface ProgramViewerProps {
  academy: T.AcademyStore
  program: T.Program
  enrollment?: T.Enrollment | undefined
  evaluateLesson: (attendance: T.Attendance, survey: Model) => void
  startLesson: (attendance: T.Attendance) => void
  getEnrollments?: () => void
  handlePurchaseProgram?: () => void
  isWaitingForPayment: boolean
}

interface IState {
  selectedLesson: T.Lesson
  selectedTrailer: boolean
  showTrailer: boolean
}

class ProgramViewer extends Component<ProgramViewerProps, IState> {
  constructor(props: ProgramViewerProps) {
    super(props)

    const { program, enrollment } = props

    if (!isUndefined(enrollment) && enrollment.state !== 'pending') {
      const currentAttendance = last(enrollment.attendances) as T.Attendance
      this.state = {
        selectedLesson: currentAttendance.lesson as T.Lesson,
        showTrailer: false,
        selectedTrailer: false,
      }
    } else {
      this.state = {
        selectedLesson: first(program.lessons) as T.Lesson,
        showTrailer: true,
        selectedTrailer: true,
      }
    }
  }

  public handleStartVideo = (attendance?: T.Attendance) => {
    const { startLesson } = this.props
    if (!isUndefined(attendance)) {
      startLesson(attendance)
    }
  }

  public handleSelectTrailer() {
    this.setState({ selectedTrailer: true })
  }

  public handleSelectLesson(lesson: T.Lesson) {
    const { getEnrollments, academy } = this.props
    const selectedAttendance = attendanceForLesson(academy, lesson)
    if (!isUndefined(getEnrollments) && isUndefined(selectedAttendance)) {
      getEnrollments()
    }
    this.setState({ selectedLesson: lesson, selectedTrailer: false })
  }

  public programSaleBanner(program: T.Program, discount: number, code: string) {
    const { academy } = this.props
    const enrollment = enrollmentForProgram(academy, program)
    const hasFreeEpisode = get(enrollment, 'hasFreeEpisode') === true
    const forPatients = !hasFreeEpisode
    const { fullOneTimePayment } = organizeProgramVariants(
      program,
      forPatients,
      hasFreeEpisode,
    )
    if (!fullOneTimePayment) return null
    const finalPrice = parseFloat(fullOneTimePayment.price) - discount
    const formattedPrice =  money(finalPrice).toFormat()
    return (
      <div className="program-sale-banner">
        <SvgIcon classname="emoji-wrapper" icon={getIcon('emoji.indexPointing')} />
        <div className="text-left ml-4">
          {program.title} à<br className="d-md-none" /> {formattedPrice} avec le code {code}
        </div>
      </div>
    )
  }

  public render() {
    const {
      academy,
      program,
      enrollment,
      evaluateLesson,
      startLesson,
      handlePurchaseProgram,
      isWaitingForPayment,
    } = this.props

    const presentation: T.ProgramPresentation = getProgramPresentation(program.slug)
    const isEnrolled = !isUndefined(enrollment) && enrollment.state !== 'pending' && !isWaitingForPayment
    const { selectedLesson, showTrailer, selectedTrailer } = this.state
    const selectedAttendance = attendanceForLesson(academy, selectedLesson)
    const isSelectedLessonUnlocked = selectedLesson.previewMode || !!selectedAttendance
    const videoPayload = isUndefined(selectedAttendance)
      ? selectedLesson.videoPayload
      : selectedAttendance.videoPayload

    const startLabel = (presentation.ctaLabel || I18n.t(`academy.start`))
    return (
      <div className="program-viewer-container">
        {!isUndefined(selectedAttendance) && !isUndefined(selectedAttendance.survey) && (
          <LessonSurvey
            lesson={selectedLesson}
            attendance={selectedAttendance}
            evaluateLesson={evaluateLesson}
          />
        )}
        <div className="program-viewer container-fluid">
          <div className="row align-items-stretch">
            <div className="col-12 col-md-8">
              <div className="program-viewer-video">
                {selectedTrailer ? (
                  <Fragment>
                    <VideoViewer
                      videoPayload={program.videoPayload}
                      isUnlocked={true}
                      programTitle={program.title}
                      isProgramUnlocked={isEnrolled}
                      isWaitingForPayment={isWaitingForPayment}
                      startVideo={() => {}}
                      startPurchase={() => handlePurchaseProgram && handlePurchaseProgram()}
                    />
                    <div className="mt-4">
                      <PlayerDescription title="Bande annonce" description={program.description} />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <VideoViewer
                      videoPayload={videoPayload}
                      videoThumbnail={selectedLesson.videoThumbnail}
                      isUnlocked={isSelectedLessonUnlocked}
                      isWaitingForPayment={isWaitingForPayment}
                      programTitle={program.title}
                      isProgramUnlocked={isEnrolled}
                      startVideo={() => this.handleStartVideo(selectedAttendance)}
                      startPurchase={() => handlePurchaseProgram && handlePurchaseProgram()}
                    />
                    <div className="mt-4">
                      <PlayerDescription
                        title={
                          selectedLesson.number > 0
                            ? `${selectedLesson.number}. ${selectedLesson.title}`
                            : selectedLesson.title
                        }
                        description={selectedLesson.description}
                        duration={selectedLesson.duration}
                      />
                    </div>
                  </Fragment>
                )}
                {!isEnrolled && (
                  <Button
                    classname="d-block d-md-none mt-4 mx-auto program-presentation-button"
                    onClick={() => handlePurchaseProgram && handlePurchaseProgram()}
                  >
                    {isWaitingForPayment ? "Continuer le programme" : startLabel}
                  </Button>
                )}
              </div>
            </div>
            <div className="col-12 col-md-4 lessons-list-container">
              <div className="lessons-list-header d-sm-block d-md-none mt-2">
                <h3><u>Toutes vos séances</u></h3>
                <p>Chaque épisode se débloque au bout de {program.lessons.slice(-1)[0].duration} jours (correspond à la durée de chaque séance)</p>
              </div>
              <div className="program-lessons-list-container">
                <div className="program-lessons-list">
                  {showTrailer && !isWaitingForPayment && (
                    <TrailerItem
                      program={program}
                      selected={selectedTrailer}
                      selectTrailer={() => this.handleSelectTrailer()}
                    />
                  )}
                  {map(program.lessons, (lesson, index) => (
                    <LessonItem
                      lesson={lesson}
                      key={`lessonItem${index}`}
                      attendance={attendanceForLesson(academy, lesson)}
                      selectLesson={() => this.handleSelectLesson(lesson)}
                      selected={!selectedTrailer && selectedLesson.id == lesson.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {!isEnrolled && (
            <Button
              classname="d-none d-md-block mt-4 mx-auto"
              onClick={() => handlePurchaseProgram && handlePurchaseProgram()}
            >
              {isWaitingForPayment ? "Continuer le programme" : startLabel}
            </Button>
          )}
        </div>
        {!isEnrolled && program.slug === 'sommeil-program' && this.programSaleBanner(program, 30, 'THERAPIE30')}
      </div>
    )
  }
}

export default ProgramViewer

import React, { Component } from 'react'
import SvgIcon from '../SvgIcon'
import { startCase } from 'lodash'
import './styles.sass'
import T from 'types'
import pictoClose from 'assets/icons/close.svg'
import PrescriptionPreviewPopup from '../PrescriptionPreviewPopup'
import MedicalDocumentAnalysisComment from '../MedicalDocumentAnalysisComment'

interface IProps {
  classPopup?: string,
  popupShow?: boolean,
  popupTitle?: string,
  popupType?: string,
  popupProps?: any,
  hidePopup: () => void,
}

class Popup extends Component<IProps> {
  private handleClosePopup() {
    const { hidePopup } = this.props
    hidePopup()
  }

  private renderPopup() {
    const { popupType, popupProps, hidePopup } = this.props

    if (popupType === 'POPUP_PRESCRIPTION') {
      return (<PrescriptionPreviewPopup {...popupProps} />)
    } else if (popupType === 'SHOW_ANALYSIS_COMMENT') {
      return (<MedicalDocumentAnalysisComment {...popupProps} hidePopup={hidePopup} />)
    }
    return null
  }

  public render() {
    const {
      popupShow,
      classPopup,
      popupTitle,
      popupType,
    } = this.props
    let popupClass = ''
    if (popupShow) popupClass += 'active'

    if (popupType == 'POPUP_PRESCRIPTION'){
      return (
        <div className={`popup ${classPopup} ${popupClass}`}>
          <div className="overlay" onClick={() => this.handleClosePopup()} />
          <div className="popup-container">
            <div className="popup-header">
              <h2>{startCase(popupTitle)}</h2>
              <div onClick={() => this.handleClosePopup()}>
                <SvgIcon classname="icon" icon={pictoClose} />
              </div>
            </div>
            <div className="popup-body">
              {this.renderPopup()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`popup ${classPopup} ${popupClass}`}>
          <div className="ovelary-v2" onClick={() => this.handleClosePopup()} />
          <div className="popup-container-v2">
            <div className="popup-body">
              {this.renderPopup()}
              <div className="close-popup">
                <a className="close-popup-button" onClick={() => this.handleClosePopup()}>Retour à mes analyses</a>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Popup

import React, { Component } from 'react'
import { capitalize, groupBy, isEqual, map, slice, sortedUniq, values } from 'lodash'
import classnames from 'classnames'
import { Button } from 'components'
import moment from 'constants/moment'
import './styles.sass'

import { NUMBER_OF_AVAIBILITY_DAYS_DISPLAYED } from 'constants/config'
const INITIAL_NUMBER_OF_DAYS_DISPLAYED = 3

interface IProps {
  availabilities: any[]
  loading?: boolean
  onSelectSlot: (e: any) => void
}

interface IState {
  chosen: { slot: Date } | null
  showAllDays: boolean
}

class ConsultationTime extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { chosen: null, showAllDays: false }
  }

  private handleChosenSlot(e: React.MouseEvent<HTMLElement>, slot: Date) {
    e.stopPropagation()
    this.setState({ chosen: { slot } })
    this.props.onSelectSlot(slot)
  }

  public render() {
    const { availabilities } = this.props
    const { showAllDays } = this.state

    const sortedArray = groupBy(sortedUniq(availabilities.sort()), date => {
      return moment(date)
        .startOf('day')
        .format()
    })

    const numberOfDaysDisplayed = showAllDays
      ? NUMBER_OF_AVAIBILITY_DAYS_DISPLAYED
      : INITIAL_NUMBER_OF_DAYS_DISPLAYED
    const displayedSortedArray = slice(values(sortedArray), 0, numberOfDaysDisplayed)

    return (
      <div className="consultation-slots-container">
        <div className="consultation-slots-all-doctors">
          <h5 className="">Choisissez votre heure de rendez-vous</h5>
          <div className="">{map(displayedSortedArray, this.renderAvailabilityDay)}</div>
          <div
            className="consultation-slots-show-more"
            onClick={() => this.setState({ showAllDays: true })}
          >
            Voir plus d'horaires
          </div>
        </div>
      </div>
    )
  }

  private renderAvailabilityDay = (arrayofDay: any[]) => {
    return (
      <div className="consultation-slot-all-doctors-day-container" key={arrayofDay[0]}>
        <div className="text-left consultation-slots-all-doctors-day-title mb-3">
          {capitalize(moment(arrayofDay[0]).format('dddd D MMMM'))}
        </div>
        <div className="consultation-slots-all-doctors-slots-container">
          {map(arrayofDay, this.renderAvailabilitySlot)}
        </div>
      </div>
    )
  }

  private renderAvailabilitySlot = (slot: Date, index: number) => {
    const { chosen } = this.state
    const { loading } = this.props
    const activeButton = isEqual(chosen, { slot })

    return (
      <Button
        classname={classnames('btn-sm', { active: activeButton })}
        variant="btn-outline-primary"
        onClick={e => this.handleChosenSlot(e, slot)}
        key={`slot-${index}`}
      >
        {moment(slot).format('LT')}
      </Button>
    )
  }
}

export default ConsultationTime

import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'
import {
  isCreditCardNumberValid,
  isCreditCardHolderNameValid,
  isCreditExpiryDateValid,
  isCreditCardCVVValid,
} from 'utils/helpers'

const schema = yup.object({
  name: yup.string().test({
    name: 'cc-valid-name',
    test: function(value: any) {
      if (isCreditCardHolderNameValid(value)) {
        return true
      }
      return this.createError({
        path: 'name',
        message: I18n.t(`paymentForm.fields.errors.name`),
      })
    },
  }),
  number: yup.string().test({
    name: 'cc-valid-number',
    test: function(value: any) {
      if (isCreditCardNumberValid(value)) {
        return true
      }
      return this.createError({
        path: 'number',
        message: I18n.t(`paymentForm.fields.errors.number`),
      })
    },
  }),
  expiry: yup.string().test({
    name: 'cc-valid-expiry',
    test: function(value: any) {
      if (isCreditExpiryDateValid(value)) {
        return true
      }
      return this.createError({
        path: 'expiry',
        message: I18n.t(`paymentForm.fields.errors.expiry`),
      })
    },
  }),
  verificationValue: yup.string().test({
    name: 'cc-valid-verificationValue',
    test: function(value: any) {
      if (isCreditCardCVVValid(value)) {
        return true
      }
      return this.createError({
        path: 'verificationValue',
        message: I18n.t(`paymentForm.fields.errors.verificationValue`),
      })
    },
  }),
  cguChecked: yup.boolean().test({
    name: 'cc-valid-cgu',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'cguChecked',
        message: I18n.t(`paymentForm.fields.errors.cgu`),
      })
    },
  }),
  termsChecked: yup.boolean().test({
    name: 'cc-valid-terms',
    test: function(value: any) {
      if (value) {
        return true
      }
      return this.createError({
        path: 'termsChecked',
        message: I18n.t(`paymentForm.fields.errors.terms`),
      })
    },
  }),
})

export default schema

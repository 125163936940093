import React, { PureComponent, Fragment } from 'react'
import Media from 'react-media'
import classnames from 'classnames'
import StringMask from 'string-mask'
import { isUndefined } from 'lodash'

import './styles.sass'

interface IProps {
  title?: string,
  value?: string,
  picto?: JSX.Element,
  pictoEnd?: JSX.Element,
  placeholder?: string,
  name?: string,
  id?: string,
  classname?: string,
  type?: string,
  mask?: string,
  pattern?: string,
  required?: boolean
  autoComplete?: string,
  onChange?: (value: string) => void,
  onBlur?: (value: string) => void,
  isDateInput?: boolean,
}

interface IState {
  active: boolean,
  value: string,
  formatted: string
}

const containerStyle = {
  lineHeight: 'inherit',
  margin: '0.1em auto 1em',
}

const labelStyle = {
  lineHeight: '2em',
  margin: 0,
  textTransform: 'uppercase' as 'uppercase',
}

const inputStyleWithTitle = {
  border: 'none',
  lineHeight: '2em',
  padding: 0,
}

const inputStyleWithoutTitle = {
  border: 'none',
  padding: 0,
}

class InputText extends PureComponent<IProps, IState> {
  /* tslint:disable */
  input!: HTMLInputElement
  /* tslint:enable */
  mask?: (this: string) => string

  constructor(props: any) {
    super(props)

    const { value, mask } = this.props

    if (mask) {
      this.mask = new StringMask(mask)
    }

    this.state = {
      value: value || '',
      formatted: this.getFormatted(value || ''),
      active: false,
    }
  }

  private getFormatted(value: string) {
    if (this.mask) {
      const masked = this.mask.apply(value.replace(/[^\d\p{L}]/g, ''))
      const trimmed = masked.trim()

      return trimmed
    }

    return value
  }

  private rawValue(formatted: string) {
    if (this.mask) {
      return formatted.replace(/\D+/g, '')
    }

    return formatted
  }

  private handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { onChange, isDateInput } = this.props
    const oldValue = this.state.value
    const rawValue = this.rawValue((e.target as HTMLInputElement).value)
    const value = (isDateInput && (rawValue.length === oldValue.length)) ? rawValue.substr(0, rawValue.length -1) : rawValue

    this.setState({ value, formatted: this.getFormatted(value) })

    if (!isUndefined(onChange)) {
      onChange(value)
    }
  }

  private handleBlur() {
    const { value } = this.state

    this.setState({ active: false })
    this.props.onBlur && this.props.onBlur(value)
  }

  private renderContainer({ containerStyleOverride }: any) {
    const { classname, title, onBlur, picto, pictoEnd, ...props } = this.props
    const { active } = this.state

    return (
      <div
        className={classnames('input', 'input-text', classname, { active })}
        onClick={() => { this.input.focus() }}
        onBlur={() => this.handleBlur()}
        style={{ ...containerStyle, ...containerStyleOverride }}
      >
        {picto && (<div className="input-picto-left">{picto}</div>)}
        <div className="input-text-zone">
          {title && <p className="input-label" style={labelStyle} >{title}</p>}
          <input
            {...props}
            style={title ? inputStyleWithTitle : inputStyleWithoutTitle}
            ref={(el: HTMLInputElement) => { this.input = el }}
            value={this.state.formatted}
            onFocus={() => { this.setState({ active: true }) }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => this.handleChange(e)}
          />
        </div>
        {pictoEnd && (<div className="input-picto-right">{pictoEnd}</div>)}
      </div>
    )
  }

  public render() {
    return (
      <Fragment>
        <Media
          query="(max-width: 1023px)"
          render={() => this.renderContainer({
            containerStyleOverride: {
              margin: '.5em auto',
            },
          })}
        />
        <Media
          query="(min-width: 1024px)"
          render={() => this.renderContainer({})}
        />
      </Fragment>
    )
  }
}

export default InputText

import * as A from 'constants/actions'
import store from '../store'
import { showNotification } from './ux'
import { ApiService } from 'services'
import { defaultSpecialty } from 'helpers/new-consultation-helper'
import T from 'types'

export const getDoctors = () => {
  const baseSpecialty = store.getState().onboarding.consultationSpecialty
  const focusedSpecialty = defaultSpecialty(baseSpecialty)
  return {
    meta: {
      onFailure: () => {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      },
    },
    type: A.SET_DOCTORS,
    promise: ApiService.getDoctors(focusedSpecialty).then(({ data }) => ({ doctors: data })),
  }
}

export const getCommunicationChannels = () => {
  const baseSpecialty = store.getState().onboarding.consultationSpecialty
  const focusedSpecialty = defaultSpecialty(baseSpecialty)
  return {
    meta: {
      onFailure: () => {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      },
    },
    type: A.SET_COMMUNICATION_CHANNELS,
    promise: ApiService.getCommunicationChannels(focusedSpecialty).then(({ data }) => ({ availableChannels: data })),
  }
}

export const getOpenConsultationDoctor = (openConsultationSlug: string) => {
  return {
    meta: {
      onFailure: () => {
        store.dispatch(showNotification('error', 'Une erreur est survenue'))
      },
    },
    type: A.SET_OPEN_CONSULTATION_DOCTOR,
    promise: ApiService.getDoctor(openConsultationSlug).then(({ data }) => ({ doctor: data })),
  }
}

export const resetOpenConsultationDoctor = () => ({
  type: A.RESET_OPEN_CONSULTATION_DOCTOR,
  payload: {},
})

export const setChosenDoctor = (doctor: T.Doctor) => ({
  type: A.SET_CHOSEN_DOCTOR,
  payload: doctor,
})

export const setChosenTimeSlot = (slot: Date) => ({
  type: A.SET_CHOSEN_TIME_SLOT,
  payload: slot,
})

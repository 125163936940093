import React, { FC } from 'react'
import { Button, SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import T from 'types'
import { map } from 'lodash'
import UCStep from 'page/Pages/UnifiedCheckout/UCFunnel/UCStep'
import { withNavigation } from 'components/Workflow'

const bullets = {
  program: {
    firt: {
      body: "6 étapes d’une semaine",
      icon: "clinic.check"
    },
    second: {
      body: "Efficace pour 85% des patients",
      icon: "clinic.check"
    },
    third: {
      body: "Sans engagement",
      icon: "clinic.check"
    },
    four: {
      body: "Résiliable à tout moment",
      icon: "clinic.check"
    },
  },
  consult: {
    firt: {
      body: "Thérapie complète de 6 semaines",
      icon: "clinic.check"
    },
    second: {
      body: "Efficace pour 85% des patients",
      icon: "clinic.check"
    },
    third: {
      body: "Paiement possible en 2x",
      icon: "clinic.check"
    },
  }
}

interface IProps extends T.UnifiedCheckoutStepProps {
  program: T.Program
}

const SelectionProgram: FC<IProps> = (props: IProps) => {
  const { program, navigation: { goPrevious, goNext }} = props

  return (
    <UCStep>
      <span>Selection</span>
      <button onClick={goPrevious}>previous</button>
      <button onClick={goNext}>next</button>

      <div>
        <div className="checkout-container-bloc">
          <h2 className='pb-4 text-center'>Choix de l'offre</h2>
          <div className="program-variants-container">
            <div className='choice-selection-card'>
              <div className="home-page-card price-card-offer-custom-padding block-content block-with-header-inside text-left bloc-content-size">
                <div className='selection-program-title'>
                  9,90€ la première étape puis 24,90€
                </div>
                <div className="program-variant-bullets">
                  <div className="d-flex flex-column">
                    {map(bullets.program, (bullet, index: BigInteger) => (
                      <div key={`program_variant_choice_bullet_${index}`} className="d-flex align-items-center program-variant-row">
                        <SvgIcon icon={getIcon(bullet.icon)} classname="mr-2 checkout-choice-bullets-svg" />
                        <div className="checkout-choice-bullets-body">{bullet.body}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Button
                    classname='w-100'
                  >
                    Choisir cette offre
                  </Button>
                </div>
              </div>
            </div>
            <div className="program-variants-container">
              <div className="home-page-card price-card-offer-custom-padding block-content block-with-header-inside text-left bloc-content-size d-flex justify-content-between">
                <div className='program-reduction-percent-wrapper'>
                  <div style={{ color: "#AF6345", borderColor: "#AF6345" }} className="program-reduction-percent">-20% Meilleure offre</div>
                </div>
                <div className='selection-program-title'>
                  Programme complet 119,90€ <span className='selection-program-title-price'>au lieu de 149,40€</span>
                </div>
                <div className="program-variant-bullets">
                  <div className="d-flex flex-column">
                    {map(bullets.consult, (bullet: any, index: BigInteger) => (
                      <div key={`program_consult_choice_bullet_${index}`} className="d-flex align-items-center program-variant-row">
                        <SvgIcon icon={getIcon(bullet.icon)} classname="mr-2 checkout-choice-bullets-svg" />
                        <div className="checkout-choice-bullets-body">{bullet.body}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Button
                    classname='w-100'
                  >
                    Choisir cette offre
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UCStep>
  )
}

export default withNavigation(SelectionProgram)
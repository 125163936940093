import React, { Component } from 'react'
import cx from 'classnames'
import lock from 'assets/icons/program-lock.svg'
import play from 'assets/icons/program-play.svg'
import SvgIcon from 'components/SvgIcon'
import { get } from 'lodash'
import moment from 'moment'
import T from 'types'

export interface Props {
  program: T.Program
  selected: boolean
  selectTrailer: () => void
}

class TrailerItem extends Component<Props> {

  public render() {
    const { program, selected, selectTrailer } = this.props
    const imageUrl = get(program, 'videoPayload.playlist[0].image')
    const duration = get(program, 'videoPayload.playlist[0].duration') || 0

    const backgroundImage = `center / cover no-repeat url(${imageUrl})`

    return (
      <div className={cx('lessons-list-item py-3', { "selected": selected })} onClick={() => selectTrailer()}>
        <div className="d-flex px-3" style={{gap: '1rem'}}>
          <div className="lessons-list-item-thumbnail d-flex flex-column-reverse" style={{background: backgroundImage}}>
            <span className="lessons-list-item-duration align-self-end pr-1">{moment.utc(duration * 1000).format('mm:ss')}</span>
          </div>
          <div className="lessons-list-item-title">{program.title}</div>
        </div>
      </div>
    )
  }
}

export default TrailerItem
